import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionAccountingCategorisationService extends TaskActionService {
  _label = "Voir l'opération à catégoriser";

  constructor(
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task || !task.metadata?.operationId) {
      return;
    }
    const { CategoryHelpModalComponent } = await import('@dougs/operations/ui');
    this.modalService.open(CategoryHelpModalComponent, {
      data: {
        operationId: task.metadata.operationId,
        task,
      },
    });
  }
}
