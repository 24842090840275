import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileDetailsComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { FourretoutTaskStateService } from '@dougs/task/shared';

@Component({
  selector: 'dougs-unseen-file-attachments-modal',
  templateUrl: './unseen-file-attachments-modal.component.html',
  styleUrls: ['./unseen-file-attachments-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    NgFor,
    FileDetailsComponent,
    NgClass,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
  ],
})
export class UnseenFileAttachmentsModalComponent implements OnInit {
  attachments: Attachment[] = [];
  lastCreatorUrl: string | undefined;
  lastCreatorFullName: string | undefined;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
  ) {}

  async ngOnInit(): Promise<void> {
    const fileAttachmentIds: number[] | undefined = this.data.task.metadata.attachmentIds;
    if (!fileAttachmentIds) {
      return;
    }
    this.attachments = await this.fourretoutTaskStateService.getAttachmentsByIds(this.data.task, fileAttachmentIds);
    const lastAttachment: Attachment = this.attachments[this.attachments.length - 1];
    this.lastCreatorFullName = lastAttachment.creator?.profile?.fullName;
    this.lastCreatorUrl = lastAttachment.creator?.profile?.avatarUrl;

    this.cdr.markForCheck();
  }

  markAsSeen(): void {
    this.modalRef.close(true);
  }
}
