import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';
import { TaskActionFacadeService } from '../task-actions/task-action-facade.service';

@Pipe({
  name: 'taskActionIsAvailable',
  standalone: true,
})
export class TaskActionIsAvailablePipe implements PipeTransform {
  constructor(private readonly taskActionFacadeService: TaskActionFacadeService) {}

  transform(task: Task): boolean {
    return this.taskActionFacadeService.getTaskActionAvailable(task);
  }
}
