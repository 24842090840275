import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileInputComponent,
  FilePillComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
  TrackByPipe,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService, UserTasksStateService } from '@dougs/task/shared';

@Component({
  selector: 'dougs-update-vat-regime-modal',
  templateUrl: './update-vat-regime-modal.component.html',
  styleUrls: ['./update-vat-regime-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
  standalone: true,
  imports: [
    NgIf,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    PanelInfoComponent,
    AvatarMessageComponent,
    FileInputComponent,
    NgFor,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
  ],
})
export class UpdateVatRegimeModalComponent implements OnInit, OnDestroy {
  task!: Task | undefined;
  userTaskSubscription!: Subscription;
  isUploadedFile = true;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
    public readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userTaskSubscription = this.userTasksStateService.tasks$.subscribe((tasks) => {
      this.task = tasks.find((task) => task.id === this.data.task.id);
      this.cdr.markForCheck();
    });
  }

  async onUploadTaskFiles(files: FileList): Promise<void> {
    if (this.task) {
      await this.controlPanelTasksStateService.uploadTaskAttachments(this.task, Array.from(files), {
        fileType: 'vatConfigurationChangedProof',
      });
    }
  }

  confirm(task: Task): void {
    if (task.attachments.length) {
      this.isUploadedFile = true;
      this.modalRef.close(task);
    }
    this.isUploadedFile = false;
  }

  ngOnDestroy(): void {
    this.userTaskSubscription.unsubscribe();
  }
}
