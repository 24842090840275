import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FlashMessagesService, ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerAttachsaleDeedRegisteredByTaxAuthoritiesService extends TaskActionService {
  protected _label = 'Attacher mon acte de cession enregistré par le SIE';
  protected _isAvailable = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { BusinessAssetsSaleModalComponent } = await import('@dougs/legal/ui');

    const taskUpdated: Task | undefined | null = (
      await lastValueFrom(
        this.modalService.open<Task>(BusinessAssetsSaleModalComponent, {
          data: task,
        }).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      const completed: boolean = await this.taskService.completeTask(taskUpdated);

      if (completed) {
        this.flashMessagesService.show('Votre acte de cession à été déposé.', {
          type: 'success',
          timeout: 5000,
        });
      }
    }
  }
}
