import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-initial-creation-appointment-modal',
  templateUrl: './initial-creation-appointment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    ModalFooterDirective,
    ButtonComponent,
    ModalCloseDirective,
  ],
})
export class InitialCreationAppointmentModalComponent implements OnInit {
  calendlyUrl!: string;
  avatarUrl = 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  avatarFullName = 'Sarah Jaouani';
  targetFirstName = '';

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    public readonly window: Window,
  ) {}

  ngOnInit(): void {
    this.calendlyUrl = this.data.task.metadata.calendlyUrl ? this.data.task.metadata.calendlyUrl : '';
    this.targetFirstName = this.data.task.target.profile ? this.data.task.target.profile.firstName : '';
  }

  redirectionCalendly(): void {
    this.window.open(this.calendlyUrl, '_blank');
  }
}
