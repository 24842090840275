import { Pipe, PipeTransform } from '@angular/core';
import { Pill } from '@dougs/ds';
import { Tag } from '@dougs/task/dto';

@Pipe({
  name: 'tagClass',
  standalone: true,
})
export class TagClassPipe implements PipeTransform {
  transform(tag: Tag): Pill {
    switch (tag.state) {
      case 'success':
        return 'success';
      case 'warning':
        return 'warning';
      case 'alert':
        return 'error';
      case 'default':
        return 'primary';
    }

    return 'secondary';
  }
}
