import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filter, lastValueFrom, map, Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { ConfirmationModalComponent, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { CheckoutOptions } from '@dougs/services/dto';
import { ServicesStateService } from '@dougs/services/shared';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionComponentService {
  dateFormControl: FormControl = new FormControl('', { updateOn: 'blur' });

  dateFormControlChanges$: Observable<void> = this.dateFormControl.valueChanges.pipe(
    filter(() => !this.dateFormControl.invalid),
    concatMap((value) =>
      this.subscriptionStateService.updateSubscriptionPeriodsEndDate(this.companyStateService.activeCompany, value),
    ),
    concatMap(() => this.companyStateService.refreshCompany()),
  );

  populateDateFormControl$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    map((activeCompany) => this.setFormControlDate(activeCompany.subscription?.periodEndsAt)),
  );

  refreshSignedTerms$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.subscriptionStateService.refreshSubscriptionSignedTerms(activeCompany.id)),
  );

  constructor(
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly servicesStateService: ServicesStateService,
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
  ) {}

  async regenerateTermOfUse(company: Company): Promise<void> {
    await this.subscriptionStateService.regenerateTermOfUse(company?.id);
  }

  private setFormControlDate(periodEndsAt?: Date | string): void {
    if (periodEndsAt) {
      this.dateFormControl.setValue(periodEndsAt, { emitEvent: false });
    }
  }

  async openPlansModal(upsellCampaign?: string): Promise<void> {
    const { ActivateComptastartModalComponent } = await import(
      '../modals/activate-comptastart/activate-comptastart-modal.component'
    );
    const { PlansModalComponent } = await import('../modals/plans-modal/plans-modal.component');
    const plansModalComponent: typeof ActivateComptastartModalComponent | typeof PlansModalComponent = this
      .userStateService.activeUser.isEligibleToComptastart
      ? ActivateComptastartModalComponent
      : PlansModalComponent;

    this.metricsService.pushMixpanelEvent('Accounting Display Plans Clicked', {
      'Settings Completion Percent': this.companyStateService.activeCompany?.completion?.percent,
    });

    await lastValueFrom(
      this.modalService.open<boolean | null>(plansModalComponent, {
        data: {
          upsellCampaign,
        },
      }).afterClosed$,
    );
  }

  downloadSignedTerm(signedTerm: Attachment): void {
    window.open(signedTerm.file.url, '_blank');
  }

  async openCheckoutModal(company: Company, options: CheckoutOptions): Promise<string | null | undefined> {
    const { CheckoutModalComponent } = await import('../modals/checkout-modal/checkout-modal.component');
    return (
      await lastValueFrom(
        this.modalService
          .open<string>(CheckoutModalComponent, {
            data: {
              company,
              options,
            },
          })
          .afterClosed$.pipe(take(1)),
      )
    )?.data;
  }

  async updateCard(company: Company): Promise<void> {
    const clientSecret: string | null | undefined = await this.openCheckoutModal(company, {
      title: 'Mettre à jour ma carte',
      buttonText: 'Valider ma carte',
      loadingText: 'Mise à jour de la carte...',
    });
    if (clientSecret) {
      await this.subscriptionStateService.updateCard(company.id, clientSecret);
      await this.companyStateService.refreshCompany();
    }
  }

  async deleteCard(company: Company): Promise<void> {
    const modalResult: OverlayCloseEvent<boolean | null | undefined> = await lastValueFrom(
      this.modalService.open<boolean>(ConfirmationModalComponent, {
        data: {
          title: 'Supprimer la carte bancaire',
          body: `Êtes-vous sûr de vouloir supprimer cette carte bancaire ? Cette action est définitive.`,
          noText: 'Annuler',
          yesText: 'Oui, supprimer la carte',
        },
      }).afterClosed$,
    );
    if (modalResult?.data) {
      const clientSecret: string | null = await this.servicesStateService.getStripeClientSecret(company);
      if (clientSecret) {
        await this.subscriptionStateService.deleteCard(company.id, clientSecret);
        await this.companyStateService.refreshCompany();
      }
    }
  }

  async deleteCustomerOnStripe(company: Company): Promise<void> {
    const modalResult: OverlayCloseEvent<boolean | null | undefined> = await lastValueFrom(
      this.modalService.open<boolean>(ConfirmationModalComponent, {
        data: {
          title: 'Supprimer le client sur Stripe',
          body: `Êtes-vous sûr de vouloir supprimer ce client sur Stripe ? Cette action est définitive.`,
          noText: 'Annuler',
          yesText: 'Oui, supprimer le client sur Stripe',
        },
      }).afterClosed$,
    );
    if (modalResult?.data) {
      const clientSecret: string | null = await this.servicesStateService.getStripeClientSecret(company);
      if (clientSecret) {
        await this.subscriptionStateService.deleteCustomerOnStripe(company.id, clientSecret);
        await this.companyStateService.refreshCompany();
      }
    }
  }
}
