<dougs-avatar-message
  [fullName]="'Romain, votre comptable Dougs'"
  size="medium"
  [avatarUrl]="'https://images.prismic.io/test-dougs/58a190f4-a09e-494d-96a1-ea4c885bd1ef_romain5.jpeg'"
>
  <div *ngIf="isSameOrBeforeClosingDate">
    <p class="small">
      {{ user.profile?.firstName }}, vous liquidez au
      <b>{{ closingDate }}</b>
      . Afin de commencer votre bilan de liquidation au plus vite, j'ai besoin d'informations complémentaires.
    </p>
    <p class="small">
      Pour cela, je vous ai préparé un questionnaire de bilan de liquidation pour que vous puissiez répondre à votre
      convenance. Je rajouterai des questions complémentaires après votre date de liquidation, mais vous pouvez d'ores
      et déjà commencer à répondre.
    </p>
  </div>
  <div *ngIf="!isSameOrBeforeClosingDate">
    <p class="small">
      {{ user.profile?.firstName }}, votre date de liquidation ({{ closingDate }}) est maintenant passée.
    </p>
    <p class="small">
      Pour pouvoir continuer à travailler sur votre bilan de liquidation, j'ai besoin de vos réponses au questionnaire.
      Je vous invite à répondre au plus vite.
    </p>
    <p class="small">Merci.</p>
  </div>
</dougs-avatar-message>
