import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import {
  ButtonComponent,
  DougsDatePipe,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalService,
  ModalTitleDirective,
} from '@dougs/ds';
import { OperationModalComponent } from '@dougs/operations/ui';
import { SubscriptionDetail } from '@dougs/subscription/dto';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-subscription-details-modal',
  templateUrl: './subscription-details-modal.component.html',
  styleUrls: ['./subscription-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    NgFor,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
    DougsDatePipe,
  ],
})
export class SubscriptionDetailsModalComponent implements OnInit {
  isLoading = false;
  subscriptionDetails: SubscriptionDetail[] | null = [];

  constructor(
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.subscriptionDetails = await this.subscriptionStateService.getSubscriptionDetails(
      this.companyStateService.activeCompany,
    );

    this.isLoading = false;

    this.cdr.markForCheck();
  }

  openOperationModal(operationId: number, companyId: number): void {
    this.modalService.open(OperationModalComponent, {
      data: { operationId, companyId },
    });
  }
}
