import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrustResourceHtmlPipe } from '@dougs/ds';

@Component({
  selector: 'dougs-email-preview',
  standalone: true,
  imports: [CommonModule, TrustResourceHtmlPipe],
  templateUrl: './email-preview.component.html',
  styleUrl: './email-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class EmailPreviewComponent {
  @Input()
  subject!: string;

  @Input()
  content!: string;
}
