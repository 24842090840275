import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import {
  ButtonComponent,
  CheckboxComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { BillingInvoice, BillingInvoiceItem } from '@dougs/subscription/dto';
import { BillingInvoiceStateService } from '@dougs/subscription/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OpenTaskModalService } from '@dougs/task/ui';
import { UserStateService } from '@dougs/user/shared';
import { BillingInvoiceComponent } from '../../components/billing-invoices/billing-invoice/billing-invoice.component';

@Component({
  selector: 'dougs-payment-details-modal',
  templateUrl: './payment-details-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    FormFieldComponent,
    LabelFormFieldDirective,
    CheckboxComponent,
    FormsModule,
    ReactiveFormsModule,
    PanelInfoComponent,
    BillingInvoiceComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class PaymentDetailsModalComponent implements OnInit {
  canBlockInvoiceCharge = false;
  formControl: UntypedFormControl = new UntypedFormControl(this.billingInvoice.doNotCharge);
  formControlChanges$!: Observable<unknown>;

  constructor(
    @Inject(MODAL_DATA) public billingInvoice: BillingInvoice,
    private readonly userStateService: UserStateService,
    private readonly billingInvoiceStateService: BillingInvoiceStateService,
    public readonly openTaskModalService: OpenTaskModalService,
  ) {}

  ngOnInit(): void {
    this.canBlockInvoiceCharge =
      !this.billingInvoice.isPaid &&
      !this.billingInvoice.metadata.paidOnStripe &&
      this.userStateService.loggedInUser.isAccountantOrAdmin &&
      this.userStateService.loggedInUser.flags.includes('canBlockBillingInvoicesCharge');

    this.formControlChanges$ = this.formControl.valueChanges.pipe(
      concatMap((value) =>
        from(
          this.billingInvoiceStateService.updateBillingInvoice({
            ...this.billingInvoice,
            doNotCharge: value,
          }),
        ),
      ),
    );
  }

  trackById(index: number, item: BillingInvoiceItem): number | undefined {
    return item.id;
  }
}
