<div dougsModalTitle>
  <h6>Détails de la facture</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <dougs-form-field *ngIf="canBlockInvoiceCharge">
    <ng-container *ngIf="formControlChanges$ | async"></ng-container>
    <label dougsFormFieldLabel>Interdire de débiter</label>
    <dougs-checkbox [formControl]="formControl" appearance="toggle"></dougs-checkbox>
  </dougs-form-field>
  <dougs-panel-info *ngIf="billingInvoice.metadata.paidOnStripe" [small]="true" class="mb-8">
    Cette facture est marquée comme payée sur Stripe.
  </dougs-panel-info>
  <dougs-billing-invoice
    (openTaskModal)="openTaskModalService.openTaskModal($event)"
    [billingInvoice]="billingInvoice"
    [forceLongLine]="true"
  ></dougs-billing-invoice>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
</div>
