import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PanelInfoComponent } from '@dougs/ds';
import { PayslipStateService } from '@dougs/task/shared';
import { CollaboratorPayslipModalComponentService } from '../../../services/modals/collaborator-payslip/collaborator-payslip-modal.component.service';
import { PayslipLineComponent } from './payslip-line/payslip-line.component';

@Component({
  selector: 'dougs-payslip-list',
  standalone: true,
  imports: [CommonModule, PanelInfoComponent, PayslipLineComponent],
  templateUrl: './payslip-list.component.html',
  styleUrl: './payslip-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayslipListComponent {
  constructor(
    public readonly collaboratorPayslipModalComponentService: CollaboratorPayslipModalComponentService,
    public readonly payslipStateService: PayslipStateService,
  ) {}
}
