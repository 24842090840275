import { Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ModalTaskStateService, QualityControlRemarksStateService } from '@dougs/task/shared';

@Injectable()
export class QualityControlComponentService {
  constructor(
    @Inject(MODAL_DATA) public readonly task: Task,
    public readonly modalTaskStateService: ModalTaskStateService,
    public readonly qualityControlRemarksStateService: QualityControlRemarksStateService,
    public readonly modalRef: ModalRef,
  ) {
    this.taskToControlId.set(task.metadata?.taskToControlId ?? null);
  }

  private readonly taskToControlId: WritableSignal<number | null> = signal<number | null>(null);

  private readonly isLoadingRemarks: WritableSignal<boolean> = signal<boolean>(false);
  isLoadingRemarks$: Signal<boolean> = this.isLoadingRemarks.asReadonly();

  readonly refreshModalTask$: Observable<void> = toObservable(this.taskToControlId).pipe(
    filter((taskId: number | null): taskId is number => taskId !== null),
    switchMap((taskId: number) => this.modalTaskStateService.refreshTasks(taskId)),
  );

  readonly refreshQualityControlRemarks$: Observable<void> = this.modalTaskStateService.modalTask$.pipe(
    filter((task): task is Task => task !== null),
    tap(() => this.isLoadingRemarks.set(true)),
    switchMap((task) => this.qualityControlRemarksStateService.refreshQualityControlRemarks(task.id)),
    tap(() => this.isLoadingRemarks.set(false)),
  );

  readonly resetRemarksState$: Observable<void> = this.modalRef.beforeClosed$.pipe(
    map(() => this.qualityControlRemarksStateService.resetState()),
  );

  private readonly showDeleteButtonRermakId: WritableSignal<number | null> = signal<number | null>(null);
  readonly showDeleteButton$: Signal<number | null> = this.showDeleteButtonRermakId.asReadonly();

  async addRemark(e: KeyboardEvent): Promise<void> {
    e.stopPropagation();
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const value: string = target.value;
    target.blur();
    target.value = '';
    const taskToControlId: number | null = this.taskToControlId();
    if (value !== '' && taskToControlId) {
      await this.qualityControlRemarksStateService.addQualityControlRemark(taskToControlId, value);
    }
  }

  preventLineBreak(e: KeyboardEvent): void {
    if (e.shiftKey && e.code === 'Enter') {
      e.preventDefault();
    }
  }

  showDeleteButton(remarkId: number): void {
    this.showDeleteButtonRermakId.set(remarkId);
  }

  hideDeleteButton(): void {
    this.showDeleteButtonRermakId.set(null);
  }
}
