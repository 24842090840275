import { Injectable } from '@angular/core';
import { concatMap, Observable } from 'rxjs';
import { mergeObjects } from '@dougs/core/utils';
import { ReferrerStateService } from '@dougs/subscription/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class ReferrerComponentService {
  refreshReferralStats$: Observable<void> = this.userStateService.activeUserIdChanged$.pipe(
    concatMap((activeUser) => this.referrerStateService.refreshReferralStats(activeUser.id)),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly referrerStateService: ReferrerStateService,
  ) {}

  async updateSelectedReferrer(selectedUser: User | null): Promise<void> {
    const updatedUser: User = mergeObjects(this.userStateService.activeUser, {
      referrerHash: selectedUser?.referralHash ?? null,
      referrer: selectedUser,
    });
    await this.userStateService.updateUserWithoutRefreshState(updatedUser);
    await this.userStateService.refreshActiveUser(this.userStateService.activeUser);
  }
}
