import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService } from '@dougs/ds';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { Task } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
  ) {}

  async updatePaymentCard(task?: Task): Promise<boolean | undefined> {
    const { CheckoutModalComponent } = await import('../modals/checkout-modal/checkout-modal.component');
    const clientSecret: string | null | undefined = (
      await lastValueFrom(
        this.modalService.open<string>(CheckoutModalComponent, {
          data: {
            company: this.companyStateService.activeCompany,
            options: {
              title: 'Mise à jour de la carte',
              task: task,
              buttonText: 'Mettre à jour ma carte',
              loadingText: 'Mise à jour en cours...',
            },
          },
        }).afterClosed$,
      )
    ).data;

    if (clientSecret) {
      const cardIsUpdated: boolean = await this.subscriptionStateService.updateCard(
        this.companyStateService.activeCompany.id,
        clientSecret,
      );
      return cardIsUpdated;
    }
  }
}
