<dougs-form-field [noMargin]="true">
  <label dougsFormFieldLabel>Code rubrique + Libellé</label>
  <dougs-select
    [ngModel]="collaboratorCardComponentService.selectedCatalogItem$()"
    (ngModelChange)="collaboratorCardComponentService.onSelectCatalogItem($event)"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="disabled"
    dougsFormFieldControl
    maxHeight="500"
    placeholder="Sélectionner une rubrique"
    bindValue="code"
    [searchable]="true"
  >
    @for (
      variableType of collaboratorFormComponentService.groupedVariablesCatalog$
        | async
        | keyvalue: collaboratorFormComponentService.keepOriginalOrder;
      track variableType.key
    ) {
      <dougs-select-option-group [value]="variableType.key | variableTypeLabel">
        @for (variable of variableType.value; track variable.code) {
          <dougs-select-option [value]="variable">
            {{ variable.code + ' - ' + variable.description }}
          </dougs-select-option>
        }
      </dougs-select-option-group>
    }
  </dougs-select>
  @if (
    collaboratorCardComponentService.enableSelectedItemError$() &&
    collaboratorCardComponentService.selectedCatalogItemOnError$()
  ) {
    <span dougsFormFieldError>Ce champs est requis</span>
  }
</dougs-form-field>
