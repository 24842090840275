import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmationModalComponent, ModalService } from '@dougs/ds';
import {
  CompletionReason,
  Task,
  TaskConfiguratorContext,
  TaskConfiguratorContextVariableAnyType,
  TaskForm,
  TaskMetadata,
} from '@dougs/task/dto';
import { ControlPanelTasksStateService, TemplateTaskStateService } from '@dougs/task/shared';

@Injectable({
  providedIn: 'root',
})
// TODO Faire gaffe à utiliser le bon state en fonction de là où on est
// TODO Injection token ??
export class TaskService {
  constructor(
    private readonly modalService: ModalService,
    private readonly templateTaskStateService: TemplateTaskStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  async completeTask(task: Task, isSubTask = false, bypass = false): Promise<boolean> {
    switch (true) {
      case task.isConfigurator:
        return await this.processTaskConfigurationV2(task);
      case task.isConfigurationTrigger:
        return await this.processTaskConfiguration(task);
      default:
        return await this.completeStandardTask(task, isSubTask, bypass);
    }
  }

  async unCompleteTask(task: Task, isSubTask = false): Promise<boolean> {
    const continueUnCompletion: boolean = await this.handleUnCompletionForSpawnedTask(task, isSubTask);
    if (continueUnCompletion) {
      return this.controlPanelTasksStateService.unCompleteTask(task, isSubTask);
    }

    return false;
  }

  private async completeStandardTask(task: Task, isSubTask = false, bypass = false): Promise<boolean> {
    if (task.policy.isCompletable && task.completionReasons?.length) {
      return this.handleCompletionWithReasons(task, isSubTask, bypass);
    }

    const continueCompletion: boolean = await this.handleCompletionForSpawnedTask(task, isSubTask);
    if (continueCompletion) {
      return await this.controlPanelTasksStateService.completeTask(task, isSubTask, bypass);
    }

    return false;
  }

  private async handleCompletionWithReasons(task: Task, isSubTask: boolean, bypass = false): Promise<boolean> {
    const { CompleteTaskModalComponent } = await import('../modals/complete-task-modal/complete-task-modal.component');
    const selectedReason: CompletionReason | null | undefined = (
      await lastValueFrom(
        this.modalService
          .open<CompletionReason, CompletionReason[]>(CompleteTaskModalComponent, {
            data: task.completionReasons,
          })
          .afterClosed$.pipe(take(1)),
      )
    ).data;

    const completionReasonCode: string | undefined = selectedReason?.code;
    let completionReasonMessage: string | undefined = selectedReason?.label;

    if (selectedReason?.messageRequired) {
      const { CompleteTaskInputModalComponent } = await import(
        '../modals/complete-task-input-modal/complete-task-input-modal.component'
      );
      const message: string | null | undefined = (
        await lastValueFrom(
          this.modalService
            .open<string, CompletionReason>(CompleteTaskInputModalComponent, {
              data: selectedReason,
            })
            .afterClosed$.pipe(take(1)),
        )
      ).data;

      if (!message) {
        return await this.completeTask(task);
      }
      completionReasonMessage = message;
    }

    if (selectedReason) {
      return await this.controlPanelTasksStateService.completeTask(
        task,
        isSubTask,
        bypass,
        completionReasonCode,
        completionReasonMessage,
      );
    }
    return false;
  }

  private async handleCompletionForSpawnedTask(task: Task, isSubTask: boolean): Promise<boolean> {
    if (!isSubTask) {
      return true;
    }

    if (task.spawnedTaskId) {
      return this.confirmCompleteDelegatedTaskModal();
    }

    return true;
  }

  private async handleUnCompletionForSpawnedTask(task: Task, isSubTask: boolean): Promise<boolean> {
    if (!task.rootId || !isSubTask) {
      return true;
    }
    const rootTask: Task<TaskMetadata> | null = await this.controlPanelTasksStateService.getTask(task.rootId);
    if (rootTask?.isProcess && !!this.getNextTask(task, rootTask)?.spawnedTaskId) {
      return this.confirmDeactivateDelegatedTaskModal();
    }

    return true;
  }

  private getNextTask(task: Task, rootTask: Task): Task | undefined {
    const parentTask: Task<TaskMetadata> | undefined =
      rootTask.id === task.parentId ? rootTask : rootTask.tasks.find((t) => t.id === task.parentId);

    return parentTask?.tasks?.find((t) => t.index === task.index + 1);
  }

  /** @deprecated */
  private async processTaskConfiguration(task: Task): Promise<boolean> {
    if (!task.isConfigurationTrigger) {
      return false;
    }

    const configForm: TaskForm | null = await this.templateTaskStateService.getTaskConfiguration(task);
    if (!configForm) {
      throw new Error(`configuration form is missing for task ${task.code}`);
    }

    const { ConfigureTaskModalComponent } = await import(
      '../modals/configure-task-modal/configure-task-modal.component'
    );

    const configFormResults: TaskForm | null | undefined = (
      await lastValueFrom(
        this.modalService.open<TaskForm | null, { form: TaskForm; task: Task }>(ConfigureTaskModalComponent, {
          data: {
            form: configForm,
            task: task,
          },
        }).afterClosed$,
      )
    ).data;

    if (!configFormResults) {
      return false;
    }

    return await this.controlPanelTasksStateService.processTaskConfiguration(task, configFormResults);
  }

  private async processTaskConfigurationV2(task: Task): Promise<boolean> {
    const configuratorVariables: TaskConfiguratorContextVariableAnyType[] =
      await this.templateTaskStateService.getTaskConfigurationV2(task);

    const context = await this.openConfiguratorModal(task.parentId ?? task.id, configuratorVariables);

    if (!context) {
      return false;
    }

    return await this.controlPanelTasksStateService.processTaskConfigurationV2(task, context);
  }

  async openConfiguredTask(task: Task): Promise<void> {
    if (!task.parentId) {
      return;
    }

    const configuratorVariables: TaskConfiguratorContextVariableAnyType[] =
      await this.templateTaskStateService.getSubTaskConfigurationV2(task.parentId, task.id);

    await this.openConfiguratorModal(task.parentId ?? task.id, configuratorVariables, true);
  }

  private async openConfiguratorModal(
    taskId: number,
    configuratorVariables: TaskConfiguratorContextVariableAnyType[],
    readonly = false,
  ): Promise<TaskConfiguratorContext | undefined | null> {
    const { TaskConfiguratorModalComponent } = await import(
      '../modals/task-configurator-modal/task-configurator-modal.component'
    );

    return (
      await lastValueFrom(
        this.modalService.open<
          TaskConfiguratorContext,
          {
            taskId: number;
            context: TaskConfiguratorContextVariableAnyType[];
            readonly: boolean;
          }
        >(TaskConfiguratorModalComponent, {
          data: { taskId, context: configuratorVariables, readonly },
        }).afterClosed$,
      )
    ).data;
  }

  private async confirmCompleteDelegatedTaskModal(): Promise<boolean> {
    return await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Forcer la complétion de la sous-tâche maitresse ?',
            body: 'La réalisation de cette sous-tâche est déléguée à une autre tâche. Si vous forcez sa complétion, la tâche déléguée sera abandonnée. La tâche déléguée pourra être réactivée si vous décochez la sous-tâche. Souhaitez-vous continuer ?',
          },
        })
        .afterClosed$.pipe(map((result) => !!result.data)),
    );
  }

  private async confirmDeactivateDelegatedTaskModal(): Promise<boolean> {
    return await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Une tâche déléguée va être désactivée',
            body: 'La sous-tâche active est déléguée à une autre tâche. Si vous continuez, cette autre tâche sera abandonnée et ne pourra pas être réactivée. Souhaitez-vous continuer ?',
          },
        })
        .afterClosed$.pipe(map((result) => !!result.data)),
    );
  }
}
