<div class="main-menu">
  <p
    class="entry pointer py-16"
    [ngClass]="{ active: cockpitRouterComponentService.isCollaboratorDomain$ | async }"
    (click)="routeToLoggedInCollaborator()"
  >
    Mes tâches
  </p>
  <p
    *ngIf="cockpitRouterComponentService.loggedInUserTeam$ | async as team"
    class="entry entry__team pointer py-16"
    [ngClass]="{ active: (cockpitRouterComponentService.isTeamDomain$ | async) && !selectedTeam }"
    (click)="routeToLoggedInCollaboratorTeam()"
  >
    <img [src]="team.avatarUrl | defaultAvatarUrl" [alt]="team.name" />
    <span>{{ team.name }}</span>
  </p>
  <dougs-form-field [autoWidth]="true" [noMargin]="true">
    <dougs-select
      class="cockpit-team-selector select entry py-16"
      [ngClass]="{ active: (cockpitRouterComponentService.isTeamOrDepartmentDomain$ | async) && selectedTeam }"
      [searchable]="true"
      [(ngModel)]="selectedTeam"
      [widthToRefElement]="false"
      placeholder="Équipes"
      (ngModelChange)="routeToTeamOrDepartment($event)"
      maxHeight="500"
    >
      <ng-container
        *ngFor="
          let teams of cockpitTeamComponentService.teamsGroupedByDepartment$ | async | keyvalue;
          trackBy: 'key' | trackBy
        "
      >
        <dougs-select-option-group [value]="cockpitTeamComponentService.DOMAIN_NAME_MAPPER[teams.key]">
          <dougs-select-option [value]="teams.key">
            <div class="cockpit-team-selector__department">
              <div class="cockpit-team-selector__icon">
                <i class="fal" [ngClass]="cockpitTeamComponentService.DOMAIN_ICON_MAPPER[teams.key]"></i>
              </div>
              <p class="cockpit-team-selector__team small">
                <span>{{ cockpitTeamComponentService.DOMAIN_NAME_MAPPER[teams.key] }}</span>
              </p>
            </div>
          </dougs-select-option>
          <dougs-select-option *ngFor="let team of teams.value; trackBy: 'id' | trackBy" [value]="team.userId">
            <p class="cockpit-team-selector__team small">
              <img [src]="team.avatarUrl | defaultAvatarUrl" [alt]="team.name" />
              <span>{{ team.name }}</span>
            </p>
          </dougs-select-option>
        </dougs-select-option-group>
      </ng-container>
    </dougs-select>
  </dougs-form-field>
</div>
