import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { HelpModalComponent, ModalService } from '@dougs/ds';
import { Activity, Comment, Tag } from '@dougs/task/dto';
import { ActivityStateService } from '@dougs/task/shared';
import { EmailModalComponent } from '../../../modals/email-modal/email-modal.component';
import { OpenTaskModalService } from '../../modals/open-task-modal.service';

@Injectable()
export class ActivityComponentService {
  tags: Tag[] = [
    {
      label: 'Supprimé',
      type: 'deleted',
      hidden: false,
      readOnly: false,
      state: 'alert',
    },
    {
      label: 'Dossier perm.',
      type: 'permanent',
      hidden: false,
      readOnly: false,
      state: 'default',
    },
  ];

  constructor(
    private readonly modalService: ModalService,
    private readonly openTaskModalService: OpenTaskModalService,
    private readonly activityStateService: ActivityStateService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  async showEmailContentFromActivity(e: Event, activity: Activity): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    await lastValueFrom(
      this.modalService.open(EmailModalComponent, {
        data: activity,
      }).afterClosed$,
    );
  }

  showWebinarEventFromActivity(e: Event, activity: Activity): void {
    e.preventDefault();

    const message = `
            Titre du webinar: ${activity.data.title}
            <br>
            Il a participé à ${activity.data.attendanceRate}% du webinar
            <br>
            <a href="${activity.data.replayLink}" target="_blank">Lien vers le replay&nbsp;&rarr;</a>
        `;

    this.modalService.open(HelpModalComponent, {
      data: {
        title: 'Webinar',
        body: message,
      },
    });
  }

  async onUploadFiles(files: FileList, comment: Comment, activity: Activity): Promise<void> {
    await this.activityStateService.uploadCommentAttachments(
      activity,
      this.companyStateService.activeCompany,
      comment,
      files,
    );
  }

  async onRemoveFile(attachment: Attachment, comment: Comment, activity: Activity): Promise<void> {
    await this.activityStateService.removeCommentAttachment(
      activity,
      this.companyStateService.activeCompany,
      comment,
      attachment,
    );
  }

  async updateComment(comment: Comment, activity: Activity): Promise<void> {
    await this.activityStateService.updateComment(activity, this.companyStateService.activeCompany, comment);
  }

  async openTaskModal(event: MouseEvent, activity: Activity): Promise<void> {
    event.preventDefault();
    if (activity?.data?.taskId) {
      await this.openTaskModalService.openTaskModal(activity.data.taskId);
    }
  }
}
