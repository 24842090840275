import { CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DividerComponent, ModalService } from '@dougs/ds';
import { BillingInvoiceItem } from '@dougs/subscription/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-billing-invoice-line',
  templateUrl: './billing-invoice-line.component.html',
  styleUrls: ['./billing-invoice-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, DividerComponent, NgIf, CurrencyPipe],
})
export class BillingInvoiceLineComponent {
  public showDeleteInvoiceItem = false;
  public showRedirectToTask = false;
  @Input() isLastItem = false;
  @Input() forceLongLine!: boolean;
  @Output() removeBillingInvoiceItem: EventEmitter<BillingInvoiceItem> = new EventEmitter<BillingInvoiceItem>();
  @Output() openTaskModal: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private readonly window: Window,
    public readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
  ) {}

  private _invoiceItem!: BillingInvoiceItem;

  get invoiceItem(): BillingInvoiceItem {
    return this._invoiceItem;
  }

  @Input()
  set invoiceItem(billingInvoiceItem: BillingInvoiceItem) {
    this._invoiceItem = billingInvoiceItem;
    this.showDeleteInvoiceItem = !!(
      this.userStateService.loggedInUser.isAccountantOrAdmin &&
      this.userStateService.loggedInUser.flags.includes('canAddPendingInvoiceItems') &&
      this.invoiceItem.id &&
      !this.invoiceItem.metadata?.isSubscription &&
      !this.invoiceItem.metadata?.isSubscriptionOffset &&
      !this.invoiceItem.metadata?.isSubscriptionAdjustment &&
      !this.invoiceItem.metadata?.isSubscriptionRemainder &&
      !this.invoiceItem.metadata?.isDiscount &&
      !this.invoiceItem.taskId
    );
    this.showRedirectToTask = !!(this.invoiceItem?.taskId && this.userStateService.loggedInUser.isAccountantOrAdmin);
  }

  public async redirectToTask(): Promise<void> {
    this.openTaskModal.emit(this._invoiceItem.taskId);
  }

  public deleteInvoiceItem(): void {
    this.removeBillingInvoiceItem.emit(this.invoiceItem);
  }
}
