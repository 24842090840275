import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { replaceCompanyIdInUrlAsCommand } from '@dougs/core/routing';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionAccountingMonitoringLargePartnerAccountTransferService extends TaskActionService {
  _label = 'Vérifier la validité des justificatifs';

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userStateService: UserStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly router: Router,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task || !task.metadata?.accountingNumber) {
      return;
    }
    const activeAccountingYear: AccountingYear | null =
      await this.accountingYearStateService.refreshActiveAccountingYear(task.companyId);
    if (!activeAccountingYear) {
      return;
    }
    await this.router.navigate(replaceCompanyIdInUrlAsCommand(task.companyId.toString(), this.router.url));
    const { VerifyOperationListModalComponent } = await import('@dougs/operations/ui');
    const success: boolean | undefined | null = (
      await lastValueFrom(
        this.modalService.open<boolean>(VerifyOperationListModalComponent, {
          data: {
            search: {
              type: 'bank',
              accountingLineDate: {
                minAccountingLineDate: new Date(activeAccountingYear.openingDate),
                maxAccountingLineDate: new Date(activeAccountingYear.effectiveClosingDate),
              },
              accountNumberRanges: [
                {
                  minAccountingNumber: task.metadata.accountingNumber,
                  maxAccountingNumber: task.metadata.accountingNumber,
                },
              ],
              accountingLineAmount: '>200000',
              isInbound: true,
              autogenerated: false,
            },
            panelInfo: `Ces opérations ont toutes un montant supérieur à 2000€.
                        <b>Vérifiez</b> que le justificatif est attaché et valide, puis <b>verrouillez</b> chaque opération. Si des précisions sont nécessaires, contactez le client.
                        Lorsque toutes les opérations sont verrouillées, vous pouvez terminer la vérification.`,
            title: 'Apport personnel de plus de 2000€',
          },
        }).afterClosed$,
      )
    )?.data;
    if (success) {
      await this.controlPanelTasksStateService.completeTask(task);
    }
  }
}
