import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DefaultAvatarUrlPipe, TrackByPipe } from '@dougs/ds';
import { CockpitAccountingStatsCollaborator } from '@dougs/task/dto';
import { CockpitAccountingStatsResponsiveComponentService } from '../../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats-responsive.component.service';
import { CockpitAccountingStatsComponentService } from '../../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats.component.service';
import { CollaboratorActivityBarsComponent } from './collaborator-activity-bars/collaborator-activity-bars.component';
import { CollaboratorBilanBarsComponent } from './collaborator-bilan-bars/collaborator-bilan-bars.component';
import { CollaboratorLineComponent } from './collaborator-line/collaborator-line.component';

@Component({
  selector: 'dougs-collaborators',
  standalone: true,
  imports: [
    CommonModule,
    TrackByPipe,
    DefaultAvatarUrlPipe,
    CollaboratorBilanBarsComponent,
    CollaboratorActivityBarsComponent,
    CollaboratorLineComponent,
  ],
  templateUrl: './collaborators.component.html',
  styleUrls: ['./collaborators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorsComponent {
  constructor(
    public readonly cockpitAccountingStatsComponentService: CockpitAccountingStatsComponentService,
    public readonly accountingStatsResponsiveComponentService: CockpitAccountingStatsResponsiveComponentService,
  ) {}

  @Input() collaboratorStats: CockpitAccountingStatsCollaborator[] = [];
}
