import { Injectable } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { toPromise } from '@dougs/core/utils';
import { FlashMessagesService, ModalService } from '@dougs/ds';
import { Survey } from '@dougs/surveys/dto';
import { SurveyStateService } from '@dougs/surveys/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCharteredAccountantMissionAcceptationService extends TaskActionService {
  _label = 'Signer';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly surveyStateService: SurveyStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return (
      !task?.completedAt !== null &&
      this.userStateService.loggedInUser?.isAccountantOrAdmin &&
      this.userStateService.loggedInUser?.flags.includes('role:charteredAccountant')
    );
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const company: Company | null = await this.companyStateService.getCompanyById(task.companyId);

    if (!company) {
      return;
    }

    if (company.metadata?.hasHighLabftRisk) {
      const { MissionAcceptationModalComponent } = await import('@dougs/surveys/ui');
      await toPromise(
        this.modalService.open(MissionAcceptationModalComponent, {
          data: {
            modifyOnly: false,
            companyId: company.id,
          },
        }).afterClosed$,
      );
      await this.controlPanelTasksStateService.getTask(task);
      return;
    }
    const updatedSurvey: Survey | null = await this.edit(task);
    if (updatedSurvey) {
      const signedSurvey: Survey | null = await this.surveyStateService.signSurvey(updatedSurvey);

      if (signedSurvey) {
        this.flashMessagesService.show("Questionnaire d'acceptation de mission signé", {
          type: 'success',
          timeout: 5000,
        });
        await this.controlPanelTasksStateService.getTask(task);
      }
    }
  }

  private async edit(task: Task): Promise<Survey | null> {
    const survey: Survey[] | null = await this.surveyStateService.getMissionAcceptationSurvey(task.companyId);
    if (survey?.length) {
      return await this.surveyStateService.updateSurvey(survey[0]);
    }

    return null;
  }
}
