<div class="form-layout mb-24">
  <div>
    <div class="pending-title mb-8">
      <h5>Votre prochaine facture</h5>
      <p
        *ngIf="(companyStateService.activeCompany$ | async).subscription?.nextBillingAt as nextBillingAt"
        class="ml-4 small"
      >
        (à venir le {{ nextBillingAt | date: 'PPP' }})
      </p>
    </div>
    <dougs-billing-invoice
      (addService)="onAddService($event)"
      (openTaskModal)="openTaskModalService.openTaskModal($event)"
      (removeBillingInvoiceItem)="deleteService($event)"
      [billingInvoice]="pendingBillingInvoice"
      [canAddService]="true"
    ></dougs-billing-invoice>
  </div>
</div>
