import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeComponent, DefaultAvatarUrlPipe, TrackByPipe } from '@dougs/ds';
import { CockpitAccountingTeamStats } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { AccountingStatsResponsiveDirective } from '../../../../directives/accounting-stats-responsive.directive';
import { CockpitAccountingStatsResponsiveComponentService } from '../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats-responsive.component.service';
import { CockpitAccountingStatsComponentService } from '../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats.component.service';
import { CockpitStatsTeamComponentService } from '../../../../services/cockpit/cockpit-stats/cockpit-stats-team.component.service';
import { CockpitStatsPeriodComponent } from '../cockpit-stats/cockpit-stats-period/cockpit-stats-period.component';
import { CollaboratorActivityBarsComponent } from './collaborators/collaborator-activity-bars/collaborator-activity-bars.component';
import { CollaboratorBilanBarsComponent } from './collaborators/collaborator-bilan-bars/collaborator-bilan-bars.component';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { HeaderComponent } from './header/header.component';
import { LegendComponent } from './legend/legend.component';

@Component({
  selector: 'dougs-cockpit-accounting-stats',
  standalone: true,
  imports: [
    CommonModule,
    CockpitStatsPeriodComponent,
    DefaultAvatarUrlPipe,
    TrackByPipe,
    CollaboratorActivityBarsComponent,
    CollaboratorBilanBarsComponent,
    BadgeComponent,
    HeaderComponent,
    CollaboratorsComponent,
    LegendComponent,
    AccountingStatsResponsiveDirective,
  ],
  templateUrl: './cockpit-accounting-stats.component.html',
  styleUrls: ['./cockpit-accounting-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitAccountingStatsComponent {
  constructor(
    public readonly cockpitStateService: CockpitStateService,
    public readonly cockpitAccountingStatsComponentService: CockpitAccountingStatsComponentService,
    public readonly cockpitStatsTeamComponentService: CockpitStatsTeamComponentService,
    public readonly accountingStatsResponsiveComponentService: CockpitAccountingStatsResponsiveComponentService,
  ) {}

  @Input() stats!: CockpitAccountingTeamStats | null;
  @Input() isLoading = false;
}
