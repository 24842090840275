import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { combineLatest, filter, map, Observable, startWith, tap, withLatestFrom } from 'rxjs';
import { CockpitDomain, CockpitPage, CockpitParameters, TaskDepartment } from '@dougs/task/dto';
import {
  COCKPIT_DEFAULT_CATEGORY,
  COCKPIT_DEFAULT_CODE,
  COCKPIT_DEFAULT_FILTER,
  COCKPIT_DEFAULT_PARTITION,
  COCKPIT_DEFAULT_QUOTE_STATUS,
  COCKPIT_DEFAULT_SORT,
  CockpitStateService,
} from '@dougs/task/shared';
import { Collaborator, Team } from '@dougs/user/dto';
import { CollaboratorStateService, TeamStateService } from '@dougs/user/shared';
import { CockpitActionComponentService } from './cockpit-action.component.service';

/**
 * @see CockpitStateService for a list of observables related to Cockpit.
 */
@Injectable()
export class CockpitComponentService {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly cockpitStateService: CockpitStateService,
    private readonly collaboratorStateService: CollaboratorStateService,
    private readonly teamStateService: TeamStateService,
    private readonly cockpitActionComponentService: CockpitActionComponentService,
  ) {}

  private readonly cockpitParameters$: Observable<CockpitParameters> = combineLatest([
    this.cockpitStateService.collaborator$,
    this.cockpitStateService.team$,
    this.cockpitStateService.department$,
    this.cockpitStateService.category$,
    this.cockpitStateService.code$,
    this.cockpitStateService.quoteStatus$,
    this.cockpitStateService.filter$,
    this.cockpitStateService.sort$,
    this.cockpitStateService.partition$,
    this.cockpitStateService.page$,
    this.cockpitStateService.domain$,
  ]).pipe(
    map(([collaborator, team, department, category, code, quoteStatus, filter, sort, partition, page, domain]) => ({
      collaborator,
      team,
      department,
      category,
      code,
      quoteStatus,
      filter,
      partition,
      sort,
      page,
      domain,
    })),
  );

  private readonly inferActivatedRouteSnapshot$: Observable<ActivatedRouteSnapshot> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(this.activatedRoute),
    withLatestFrom(this.cockpitParameters$),
    map(([_, parameters]): [ActivatedRouteSnapshot, CockpitParameters] => [
      this.getFirstChildActivatedRoute(this.activatedRoute),
      parameters,
    ]),
    filter(([route, _]: [ActivatedRouteSnapshot, CockpitParameters]) => route.outlet === 'primary'),
    filter(([route, params]: [ActivatedRouteSnapshot, CockpitParameters]) =>
      this.notEmitChangedRouteIfOnlyActiveUserChanged(route, params),
    ),
    map(([route, _]) => route),
  );

  private getFirstChildActivatedRoute(route: ActivatedRoute): ActivatedRouteSnapshot {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }

  readonly inferContextThenStartCockpit$: Observable<unknown> = combineLatest([
    this.inferActivatedRouteSnapshot$,
    this.collaboratorStateService.collaborators$,
    this.teamStateService.teams$,
  ]).pipe(
    filter(([_, collaborators, teams]) => !!collaborators && !!teams),
    withLatestFrom(this.cockpitParameters$),
    tap(([[activatedRouteSnapshot, collaborators, teams], parameters]) =>
      this.populateObservables(
        activatedRouteSnapshot,
        collaborators as ReadonlyMap<number, Collaborator>,
        teams as ReadonlyMap<number, Team>,
        parameters,
      ),
    ),
    tap(() => this.cockpitActionComponentService.dispatchHasStartedCockpitAction()),
  );

  readonly storeCurrentUrlOnNavigation$: Observable<void> = this.router.events.pipe(
    map((events) => {
      if (events instanceof NavigationStart && events.url.includes('cockpit')) {
        localStorage.setItem('cockpit-url', events.url);
      }
    }),
  );

  private populateObservables(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    collaborators: ReadonlyMap<number, Collaborator>,
    teams: ReadonlyMap<number, Team>,
    parameters: CockpitParameters,
  ): void {
    const { params, queryParams } = activatedRouteSnapshot;
    const { domain, page } = activatedRouteSnapshot.data;

    switch (domain as CockpitDomain) {
      case 'collaborator':
        this.populateCollaboratorDomain(Number(params['collaboratorUserId']), collaborators);
        break;
      case 'team':
        this.populateTeamDomain(Number(params['teamUserId']), teams, parameters);
        break;
      case 'department':
        this.populateDepartmentDomain(params['departmentKey'] as TaskDepartment, parameters);
        break;
    }

    this.populateParameters(parameters, queryParams);

    // Il faudra éventuellement pré-peupler ces paramètres depuis le guard.
    this.cockpitStateService.updateDomainState(domain as CockpitDomain);
    this.cockpitStateService.updatePageState(page as CockpitPage);
  }

  private populateCollaboratorDomain(
    collaboratorUserId: number,
    collaborators: ReadonlyMap<number, Collaborator>,
  ): void {
    this.cockpitStateService.updateCollaboratorState(collaborators.get(collaboratorUserId) ?? null);

    const collaboratorTeam: Team | null = this.teamStateService.getTeamById(
      collaborators.get(collaboratorUserId)?.teamId ?? 0,
    );

    this.cockpitStateService.updateTeamState(collaboratorTeam);
    this.cockpitStateService.updateDepartmentState((collaboratorTeam?.department as TaskDepartment) ?? null);
  }

  private populateTeamDomain(
    teamUserId: number,
    teams: ReadonlyMap<number, Team>,
    parameters: CockpitParameters,
  ): void {
    const team: Team | null = teams.get(teamUserId) ?? null;

    this.cockpitStateService.updateCollaboratorState(
      team && parameters.collaborator?.teamId === team.id ? parameters.collaborator ?? null : null,
    );

    this.cockpitStateService.updateTeamState(team);
    this.cockpitStateService.updateDepartmentState((team?.department as TaskDepartment) ?? null);
  }

  private populateDepartmentDomain(department: TaskDepartment, parameters: CockpitParameters): void {
    const collaboratorTeam: Team | null = this.teamStateService.getTeamById(parameters.collaborator?.teamId ?? 0);

    this.cockpitStateService.updateCollaboratorState(
      collaboratorTeam?.department === department ? parameters.collaborator ?? null : null,
    );

    this.cockpitStateService.updateTeamState(
      collaboratorTeam?.department === department ? parameters.team ?? null : null,
    );

    this.cockpitStateService.updateDepartmentState(department);
  }

  private populateParameters(parameters: CockpitParameters, _queryParams: Params): void {
    // TODO queryParams : tout passer par l'url pour un partage ezpz
    this.cockpitStateService.updatePartitionState(parameters.partition ?? COCKPIT_DEFAULT_PARTITION);
    this.cockpitStateService.updateCategoryState(parameters.category ?? COCKPIT_DEFAULT_CATEGORY);
    this.cockpitStateService.updateCodeState(parameters.code ?? COCKPIT_DEFAULT_CODE);
    this.cockpitStateService.updateFilterState(parameters.filter ?? COCKPIT_DEFAULT_FILTER);
    this.cockpitStateService.updateSortState(parameters.sort ?? COCKPIT_DEFAULT_SORT);
    this.cockpitStateService.updateQuoteStatusState(parameters.quoteStatus ?? COCKPIT_DEFAULT_QUOTE_STATUS);
  }

  private notEmitChangedRouteIfOnlyActiveUserChanged(
    route: ActivatedRouteSnapshot,
    params: CockpitParameters,
  ): boolean {
    if (route.data.page !== params.page) {
      return true;
    }

    if (route.data.domain !== params.domain) {
      return true;
    }

    switch (route.data.domain as CockpitDomain) {
      case 'collaborator':
        if (Number(route.params.collaboratorUserId) !== params.collaborator?.userId) {
          return true;
        }
        break;
      case 'team':
        if (Number(route.params.teamUserId) !== params.team?.userId) {
          return true;
        }
        break;
      case 'department':
        if (route.params.departmentKey !== params.department) {
          return true;
        }
    }

    return false;
  }
}
