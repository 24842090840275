import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingService, URL } from '@dougs/core/routing';
import { Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerQuoteNotificationService extends TaskActionService {
  protected _label = 'Voir la prestation';
  protected _isAvailable = true;
  protected _closeNotificationOnExecute = true;
  protected _tag = 'Prestations';
  protected _tagColor: Pill = 'warning';
  protected _tagIcon = 'fa-money-check';

  constructor(
    private readonly router: Router,
    private readonly companyStateService: CompanyStateService,
    private readonly routingService: RoutingService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.companyStateService.activeCompany?.isCreated;
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }
    await this.router.navigateByUrl(this.routingService.createUrl([URL.SERVICE]).toString());
  }
}
