import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { isBefore } from 'date-fns';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AvatarMessageComponent,
  ButtonComponent,
  DougsDatePipe,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  MonthPrefixPipe,
} from '@dougs/ds';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PaymentService } from '@dougs/subscription/ui';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-update-payment-card-modal',
  templateUrl: './update-payment-card-modal.component.html',
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    ModalFooterDirective,
    ButtonComponent,
    MonthPrefixPipe,
    DougsDatePipe,
  ],
})
export class UpdatePaymentCardModalComponent implements OnInit {
  isClosable = false;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    public readonly companyStateService: CompanyStateService,
    private readonly modalRef: ModalRef,
    private readonly paymentService: PaymentService,
    private readonly userStateService: UserStateService,
  ) {}

  async ngOnInit() {
    if (this.data.task.dueDate) {
      this.isClosable =
        this.userStateService.loggedInUser.isAccountantOrAdmin ||
        isBefore(new Date(), new Date(this.data.task.dueDate));
    }
  }

  async submit(): Promise<void> {
    const cardIsUpdated: boolean | undefined = await this.paymentService.updatePaymentCard();
    if (cardIsUpdated) {
      this.modalRef.close(true);
    }
  }
}
