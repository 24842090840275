import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MinInHourPipe } from '@dougs/ds';
import { CockpitComputedCollaboratorStats } from '../../../../../services/cockpit/cockpit-stats/cockpit-stats-team.component.service';

@Component({
  selector: 'dougs-cockpit-stats-progress-bar',
  standalone: true,
  imports: [CommonModule, MinInHourPipe],
  templateUrl: './cockpit-stats-progress-bar.component.html',
  styleUrls: ['./cockpit-stats-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitStatsProgressBarComponent {
  @Input() tasks!: CockpitComputedCollaboratorStats['tasks'];
}
