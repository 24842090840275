import { Pipe, PipeTransform } from '@angular/core';
import { Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskActionFacadeService } from '../task-actions/task-action-facade.service';

@Pipe({
  name: 'taskActionTagColor',
  standalone: true,
})
export class TaskActionTagColorPipe implements PipeTransform {
  constructor(private readonly taskActionFacadeService: TaskActionFacadeService) {}

  transform(task: Task): Pill {
    return this.taskActionFacadeService.getTaskActionTagColor(task);
  }
}
