<div class="payroll-variable-card mb-16">
  <div
    class="payroll-variable-card__header py-8 px-16"
    [ngClass]="!readonly ? 'payroll-variable-card__header__' + color : 'payroll-variable-card__header__readonly'"
  >
    <h6>{{ label }}</h6>
    @if (isEditable) {
      <i class="fal fa-times" (click)="delete.emit()"></i>
    }
  </div>
  <div class="payroll-variable-card__body p-16">
    <ng-content></ng-content>
  </div>
</div>
