import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent, ControlFormFieldDirective, SelectComponent, SelectOptionComponent } from '@dougs/ds';
import { TASK_STATUS_FILTER, TaskScope } from '@dougs/task/dto';
import { ControlPanelSearchComponentService } from '../../../services/control-panel/control-panel-search.component.service';
import { CreateTaskComponentService } from '../../../services/create-task.component.service';

@Component({
  selector: 'dougs-task-search',
  templateUrl: './task-search.component.html',
  styleUrls: ['./task-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ControlFormFieldDirective,
    ButtonComponent,
    SelectComponent,
    SelectOptionComponent,
  ],
  providers: [CreateTaskComponentService],
})
export class TaskSearchComponent {
  @Output() addTask: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public readonly controlPanelSearchComponentService: ControlPanelSearchComponentService,
    public readonly createTaskComponentService: CreateTaskComponentService,
  ) {}

  protected readonly TaskScope = TaskScope;
  protected readonly TASK_STATUS_FILTER = TASK_STATUS_FILTER;
}
