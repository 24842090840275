import { Clipboard } from '@angular/cdk/clipboard';
import { LocationStrategy } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { format, parse } from 'date-fns';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { RoutingService, URL } from '@dougs/core/routing';
import {
  ConfirmationModalComponent,
  DrawerService,
  FlashMessage,
  FlashMessagesService,
  HelpModalComponent,
  ModalService,
  OverlayCloseEvent,
} from '@dougs/ds';
import { HelpFieldModalComponent } from '@dougs/fields/ui';
import { Task, TASK_LEGAL_STATE, TaskBillingInvoiceItem, TaskForm, TaskTemplate } from '@dougs/task/dto';
import {
  ControlPanelTasksStateService,
  FourretoutTaskStateService,
  TemplateTaskStateService,
} from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { AbortTaskModalComponent } from '../modals/abort-task-modal/abort-task-modal.component';
import { ActivityTaskModal } from '../modals/activity-task-modal/activity-task-modal.component';
import { AskCustomerInformationModalComponent } from '../modals/ask-customer-information-modal/ask-customer-information-modal.component';
import { DoubleFragmentTaskFormModalComponent } from '../modals/double-fragment-task-form-modal/double-fragment-task-form-modal.component';
import { InvoiceTaskSettingsModal } from '../modals/invoice-task-settings-modal/invoice-task-settings-modal.component';
import { MakeDocumentsAvailableComponent } from '../modals/make-documents-available/make-documents-available.component';
import { WorkloadTaskModalComponent } from '../modals/workload-task-modal/workload-task-modal.component';
import { YousignTaskModalComponent } from '../modals/yousign-task-modal/yousign-task-modal.component';
import { TaskService } from './task.service';

@Injectable()
export class TaskComponentService {
  private readonly billingInvoicesExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  billingInvoicesExpanded$: Observable<boolean> = this.billingInvoicesExpanded.asObservable();

  private readonly subTasksExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subTasksExpanded$: Observable<boolean> = this.subTasksExpanded.asObservable();

  private readonly configurationExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  configurationExpanded$: Observable<boolean> = this.configurationExpanded.asObservable();

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly modalService: ModalService,
    private readonly drawerService: DrawerService,
    private readonly userStateService: UserStateService,
    private readonly taskService: TaskService,
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
    private readonly templateTaskStateService: TemplateTaskStateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routingService: RoutingService,
    private readonly clipboard: Clipboard,
    private readonly locationStrategy: LocationStrategy,
    @Inject(Window) private readonly window: Window,
  ) {}

  toggleBillingInvoicesExpanded(): void {
    this.billingInvoicesExpanded.next(!this.billingInvoicesExpanded.value);
  }

  async toggleSubTasksExpanded(task: Task): Promise<void> {
    this.subTasksExpanded.next(!this.subTasksExpanded.value);
    if (this.subTasksExpanded.value) {
      await this.controlPanelTasksStateService.getTask(task, true);
    }
  }

  async toggleConfigurationExpanded(task: Task): Promise<TaskForm | null> {
    this.configurationExpanded.next(!this.configurationExpanded.value);
    if (task.hasForm && this.configurationExpanded.value) {
      const form: TaskForm | null = await this.templateTaskStateService.getTaskForm(task);

      if (form?.modalGroup) {
        this.configurationExpanded.next(false);

        await lastValueFrom(
          this.modalService.open(DoubleFragmentTaskFormModalComponent, {
            data: {
              form: form,
              task: task,
            },
          }).afterClosed$,
        );
      }

      return form;
    }

    return null;
  }

  shouldShowAskClientInformation(task: Task): boolean {
    return !!(task.companyId && !task.completedAt && !task.abortedAt);
  }

  shouldDisableMakeDocumentsAvailable(task: Task): boolean {
    return !!task.completedAt || !task.isService || !task?.attachments?.length || !!task?.target?.suspended;
  }

  async updateDueDate(e: string, task: Task): Promise<void> {
    await this.controlPanelTasksStateService.updateTaskDueDate(
      task,
      format(parse(e, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
    );
  }

  async updateLegalState(legalState: TASK_LEGAL_STATE | null, task: Task): Promise<void> {
    await this.controlPanelTasksStateService.updateTaskLegalState(task, legalState);
  }

  async deleteService(task: Task, invoiceItem: TaskBillingInvoiceItem): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService.open(ConfirmationModalComponent, {
        data: {
          title: 'Confirmation',
          body: `Êtes-vous sûr de vouloir supprimer la prestation ? Cette action est <b>définitive</b>.`,
          yesText: 'Oui, supprimer la prestation',
          noText: 'Non',
        },
      }).afterClosed$,
    );
    const success = !!modalResult.data;
    if (!success) {
      return;
    }
    const flashMessage: FlashMessage = this.flashMessagesService.show(`Suppression de la prestation en cours...`, {
      type: 'pending',
      timeout: 0,
    });
    await this.controlPanelTasksStateService.deleteService(task, invoiceItem);
    this.flashMessagesService.close(flashMessage);
    this.flashMessagesService.show('Prestation supprimée.', { type: 'success' });
  }

  async openAskCustomerInformationModal(task: Task): Promise<void> {
    if (task.assigneeId !== this.userStateService.loggedInUser.id) {
      this.flashMessagesService.show(
        'Vous ne pouvez pas demander des informations sur une tâche qui ne vous est pas assignée.',
        { type: 'error' },
      );
      return;
    }
    const succeded: boolean | null | undefined = (
      await lastValueFrom(
        this.modalService.open<boolean | null | undefined>(AskCustomerInformationModalComponent, {
          data: task,
          disableClose: true,
          disableCloseOnEscape: false,
        }).afterClosed$,
      )
    )?.data;
    if (succeded) {
      await this.controlPanelTasksStateService.getTask(task);
    }
  }

  async makeDocumentsAvailable(task: Task, taskTemplate: TaskTemplate | null): Promise<void> {
    if (task.attachments?.length && task.isService && !task.completedAt && !!taskTemplate) {
      await lastValueFrom(
        this.drawerService.open(MakeDocumentsAvailableComponent, {
          data: {
            task,
            taskTemplate,
          },
          size: 'small',
        }).afterClosed$,
      );
    }
  }

  async togglePriority(task: Task): Promise<void> {
    await this.controlPanelTasksStateService.updateTask({ ...task, isPriority: !task.isPriority });
  }

  async toggleCompleted(task: Task): Promise<boolean> {
    if (task.completedAt) {
      return await this.controlPanelTasksStateService.unCompleteTask(task);
    }
    return await this.taskService.completeTask(task);
  }

  async abortTask(task: Task): Promise<boolean> {
    return !!(
      await lastValueFrom(
        this.modalService.open<boolean | undefined | null>(AbortTaskModalComponent, {
          data: {
            task,
            isCustomerAbortion: false,
          },
        }).afterClosed$,
      )
    )?.data;
  }

  async restoreTask(task: Task): Promise<void> {
    const succeded = !!(
      await lastValueFrom(
        this.modalService.open<boolean | undefined | null>(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir réactiver cette tâche ?`,
            noText: 'Non',
            yesText: 'Oui',
            secondaryColor: true,
          },
        }).afterClosed$,
      )
    )?.data;
    if (succeded) {
      await this.controlPanelTasksStateService.restoreTask(task);
    }
  }

  async deleteTask(task: Task): Promise<boolean> {
    const succeded = !!(
      await lastValueFrom(
        this.modalService.open<boolean | undefined | null>(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir supprimer cette tâche ?`,
            noText: 'Non',
            yesText: 'Oui',
          },
        }).afterClosed$,
      )
    )?.data;
    if (succeded) {
      return await this.controlPanelTasksStateService.deleteTask(task);
    }

    return false;
  }

  async showRemarks(task: Task): Promise<void> {
    const remarks: string | null = await this.fourretoutTaskStateService.getTaskRemarks(task);

    if (remarks) {
      this.modalService.open(HelpModalComponent, {
        data: {
          title: "Message d'aide",
          body: remarks,
        },
      });
    }
  }

  async openYousignModal(task: Task): Promise<void> {
    await lastValueFrom(
      this.modalService.open(YousignTaskModalComponent, {
        data: {
          task: await this.controlPanelTasksStateService.getTask(task),
          taskTemplate: await this.templateTaskStateService.getTaskTemplate(task.code, task.version),
        },
      }).afterClosed$,
    );
  }

  async openActivityModal(task: Task): Promise<void> {
    this.modalService.open(ActivityTaskModal, { data: task });
  }

  async openInvoiceSettings(task: Task): Promise<void> {
    this.modalService.open(InvoiceTaskSettingsModal, { data: task });
  }

  copyLink(task: Task): void {
    const taskUrl: string = this.routingService
      .createUrl([URL.SETTINGS], true, { queryParams: { taskId: task.id } })
      .toString();
    const url = `${this.window.location.origin}${this.locationStrategy.getBaseHref().slice(0, -1)}${taskUrl}`;
    this.clipboard.copy(url);
    this.flashMessagesService.show('Copié dans le presse-papier !', {
      type: 'success',
    });
  }

  openWorkloadModal(task: Task): void {
    this.modalService.open(WorkloadTaskModalComponent, { data: task });
  }

  showHelpModal(): void {
    this.modalService.open(HelpFieldModalComponent, {
      data: {
        title: 'Devis automatique',
        body: 'Le devis sera ajouté automatiquement au moment de la complétion de la sous-tâche "compléter la tâche pour configurer le processus."',
      },
    });
  }
}
