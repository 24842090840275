import { Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isAfter, isBefore } from 'date-fns';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import {
  ButtonComponent,
  CheckboxComponent,
  DougsDatePipe,
  FormFieldComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
  TrustRessourceUrlPipe,
} from '@dougs/ds';
import { WizardBreakpointService } from '@dougs/legal/shared';
import { PlanTerms, SubscriptionActiveTerms } from '@dougs/subscription/dto';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-accept-subscription-plan-policies-modal',
  templateUrl: './accept-subscription-plan-policies-modal.component.html',
  styleUrls: ['./accept-subscription-plan-policies-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalContentDirective,
    PanelInfoComponent,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    NgClass,
    ButtonComponent,
    CheckboxComponent,
    FormsModule,
    FormFieldComponent,
    ReactiveFormsModule,
    ModalFooterDirective,
    ModalCloseDirective,
    AsyncPipe,
    TrustRessourceUrlPipe,
    DougsDatePipe,
  ],
})
export class AcceptSubscriptionPlanPoliciesModalComponent implements OnInit {
  readonly regenCodeManual = 'manual';
  readonly regenCodeLegalProperty = 'legal_property_changed';
  readonly regenCodeReactivation = 'subscription_reactivated';
  company!: Company;
  previewUrl = `${AppConfig.settings.legacyApiServerUrl}/companies/${this.companyStateService.activeCompany.id}/subscription/preview-terms`;
  termsChanges: string[] = [];
  planName = '';
  canSignLater = false;
  canAccessService = false;
  hasWarnings = false;
  hasDirtyTerms = false;
  onMobile$: Observable<boolean>;
  policies = new FormControl(false, {
    validators: [Validators.requiredTrue],
  });

  readonly phoneDisplayNameMap = new Map<string, string>([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
  ]);

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly modalRef: ModalRef,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly metricsService: MetricsService,
    private readonly wizardBreakpointService: WizardBreakpointService,
    private readonly routingService: RoutingService,
    private readonly router: Router,
  ) {
    this.onMobile$ = this.wizardBreakpointService.getIsMatched([...this.phoneDisplayNameMap.keys()]);
  }

  async ngOnInit(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Accounting Checkout Terms Viewed');
    this.company = this.companyStateService.activeCompany;
    if (this.company.subscription) {
      await this.initTerms(this.company);
      this.planName = this.company.subscription.planDescriptor.name;
      this.hasWarnings = !!(
        this.company.subscription.activeTerms?.isGonnaChange && this.data.task.metadata?.regenerationReason
      );
      this.hasDirtyTerms = this.company.subscription.activeTerms?.isDirty;
      this.updateCanSignLater();
    }
  }

  updateCanSignLater(termsApplicationDate?: Date): void {
    if (this.userStateService.loggedInUser.id !== this.data.task.targetId) {
      this.canSignLater = true;
      return;
    }
    this.canSignLater = !!(
      this.company.subscription?.activeTerms &&
      ((termsApplicationDate && isAfter(termsApplicationDate, Date.now())) ||
        (this.data.task.dueDate && isBefore(Date.now(), new Date(this.data.task.dueDate))))
    );
    this.canAccessService = !!(this.company.isCreatedByDougs && !this.company.subscription?.activeTerms);
  }

  async initTerms(company: Company): Promise<void> {
    const terms: PlanTerms | null = await this.subscriptionStateService.getSubscriptionTerms(this.company);
    if (!terms) {
      return;
    }
    this.updateCanSignLater(new Date(terms.applicationDate));
    const activeTerms: SubscriptionActiveTerms | undefined = company.subscription?.activeTerms;
    if (activeTerms && activeTerms.id === terms.id && activeTerms.version < terms.version) {
      for (let i: number = activeTerms.version + 1; i <= terms.version; ++i) {
        if (terms.changes[i]) {
          this.termsChanges.push(terms.changes[i]);
        }
      }
    }
    this.cdr.markForCheck();
  }

  submit(): void {
    this.metricsService.pushGAEvent(`Modal LDM 1 CTA Accept click GA4`);
    this.metricsService.pushMixpanelEvent('Accounting Checkout Terms Accepted');
    this.acceptPolicies();
  }

  acceptPolicies(): void {
    this.modalRef.close({ success: true, isFirstTime: !this.company.subscription?.activeTerms });
  }

  async redirectService(): Promise<void> {
    await this.router.navigate([this.routingService.createUrl([URL.SERVICE]).toString()]);
  }
}
