<div dougsModalTitle>
  <h6>Demande de confirmation de débit</h6>
  <i *ngIf="userStateService.loggedInUser.isAccountantOrAdmin" class="fal fa-times" dougsModalClose></i>
</div>

<div dougsModalContent>
  <p class="mb-32">
    Votre banque demande votre confirmation pour le débit de {{ data.task.metadata.billingInvoiceAmount }}€
    correspondant
    <br />
    à votre facture Dougs numéro #{{ data.task.metadata.billingInvoiceNumber }} du
    {{ data.task.metadata.billingInvoiceDate | date: 'dd MMMM yyyy' }}.
  </p>

  <dougs-billing-invoice
    (openTaskModal)="openTaskModalService.openTaskModal($event)"
    [billingInvoice]="billingInvoice"
    [displayTitle]="true"
    [forceLongLine]="true"
  ></dougs-billing-invoice>

  <div [ngSwitch]="status" class="mt-8">
    <dougs-panel-info *ngSwitchCase="'success'" type="success">
      Merci, la confirmation bancaire a bien fonctionné
    </dougs-panel-info>
    <dougs-panel-info *ngSwitchCase="'error'" type="warning">
      Mmm... La confirmation bancaire n'a pas fonctionné. Pouvez-vous essayer de nouveau ?
    </dougs-panel-info>
    <dougs-panel-info *ngSwitchCase="'cardError'" type="warning">
      Il y a eu un problème avec votre carte bancaire, le débit n'a pas fonctionné. Contactez-nous au plus vite.
    </dougs-panel-info>
  </div>
</div>

<div dougsModalFooter>
  <dougs-button
    (click)="startConfirmation()"
    *ngIf="status !== 'success' && status !== 'cardError'"
    [disabled]="isPending"
  >
    Confirmer le débit
  </dougs-button>

  <dougs-button (click)="closeModal()" *ngIf="status === 'success' || status === 'cardError'">Fermer</dougs-button>
</div>
