import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { DougsDatePipe } from '@dougs/ds';
import { BillingInvoice, BillingInvoiceItem } from '@dougs/subscription/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OpenTaskModalService } from '@dougs/task/ui';
import { BillingInvoiceService } from '../../../services/billing-invoice.service';
import { BillingInvoiceComponent } from '../billing-invoice/billing-invoice.component';

@Component({
  selector: 'dougs-pending-billing-invoice',
  templateUrl: './pending-billing-invoice.component.html',
  styleUrls: ['./pending-billing-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, BillingInvoiceComponent, AsyncPipe, DougsDatePipe],
})
export class PendingBillingInvoiceComponent {
  @Input() pendingBillingInvoice!: BillingInvoice;

  constructor(
    private readonly billingInvoiceService: BillingInvoiceService,
    public readonly openTaskModalService: OpenTaskModalService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  async onAddService(billingInvoice: BillingInvoice): Promise<void> {
    await this.billingInvoiceService.addServiceInBillingInvoice(billingInvoice);
  }

  async deleteService(billingInvoiceItem: BillingInvoiceItem): Promise<void> {
    await this.billingInvoiceService.removeServiceInBillingInvoice(this.pendingBillingInvoice, billingInvoiceItem);
  }
}
