import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AvatarComponent, DefaultAvatarUrlPipe, PanelInfoComponent } from '@dougs/ds';
import { EmployeeInfosComponentService } from '../../../../services/modals/collaborator-payslip/employee-infos.component.service';

@Component({
  selector: 'dougs-employee-infos',
  standalone: true,
  imports: [CommonModule, AvatarComponent, DefaultAvatarUrlPipe, PanelInfoComponent],
  providers: [EmployeeInfosComponentService],
  templateUrl: './employee-infos.component.html',
  styleUrl: './employee-infos.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeInfosComponent {
  constructor(public readonly employeeInfosComponentService: EmployeeInfosComponentService) {}
}
