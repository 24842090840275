<dougs-avatar-message [fullName]="accountantFullName" size="medium" [avatarUrl]="avatarUrl">
  <div *ngIf="isBeforeClosingDate">
    <p class="small">
      {{ firstName }}, vous clôturez votre exercice au
      <b>{{ closingDate }}</b>
      . Afin de commencer votre bilan au plus vite, j'ai besoin d'informations complémentaires.
    </p>
    <p class="small">
      Pour cela, je vous ai préparé un questionnaire bilan pour que vous puissiez répondre à votre convenance. Je
      rajouterai des questions complémentaires après votre date de clôture, mais vous pouvez d'ores et déjà commencer à
      répondre ! Merci&nbsp;!
    </p>
  </div>
  <div *ngIf="isFirst31DaysAfterClosingDate">
    <p class="small">Votre exercice {{ closingDate }} est maintenant terminé !</p>
    <p class="small">
      Pour pouvoir continuer à travailler sur votre clôture, j'ai absolument besoin de vos réponses au questionnaire
      bilan. Je vous invite à répondre au plus vite !
    </p>
    <p *ngIf="hasGeneralAssembly && !isAGOIncluded" class="small">
      D'ailleurs, pour vous encourager, si vous répondez avant le
      <b>{{ bonusDate }}</b>
      , je pourrais faire une remise commerciale de 50€ HT sur votre assemblée générale d'approbation des comptes dans
      le cas où vous nous confiez sa réalisation.
    </p>
    <p *ngIf="hasGeneralAssembly && isAGOIncluded" class="small">
      N'oubliez pas, si vous répondez avant le
      <b>{{ bonusDate }}</b>
      , votre dossier sera traité en priorité !
    </p>
    <p class="small">Merci&nbsp;!</p>
  </div>
  <div *ngIf="isAfterFirst31DaysAndBeforeDueDate && !hasExtraDaysForSurvey">
    <p class="small">
      {{ firstName }}, il vous reste {{ remainingDaysSentence }} pour répondre à votre questionnaire bilan. Nous vous
      encourageons à le faire dès maintenant&nbsp;!
    </p>
    <p class="small">
      Après la date limite du
      <b>{{ dueDate }}</b>
      , une pénalité de
      <b>150€ HT</b>
      vous sera facturée et Dougs dégagera toute responsabilité quant au respect des échéances fiscales. Cela signifie
      que votre
      <b>bilan risque de sortir en retard, vous exposant à des pénalités fiscales conséquentes</b>
      .
    </p>
    <p class="small">Nous comptons sur vous, merci&nbsp;!</p>
  </div>
  <div *ngIf="isAfterFirst31DaysAndBeforeDueDate && hasExtraDaysForSurvey">
    <p class="small">
      {{ firstName }}, malgré nos multiples relances, vous n'avez toujours pas complété votre questionnaire bilan,
      est-ce que tout va bien&nbsp;?
    </p>
    <p class="small">
      Conformément à notre lettre de mission, nous devrions normalement vous facturer une
      <b>pénalité de 150€ HT</b>
      (ce qui ne nous enchante pas). Malgré tout, nous avons décidé de vous laisser quelques jours supplémentaires.
    </p>
    <p class="small">
      Il vous reste donc
      <b>{{ remainingDaysSentence }}</b>
      pour finaliser le questionnaire. Après le {{ dueDate }}, nous appliquerons la pénalité.
    </p>
    <p class="small">Revenez vers nous si vous rencontrez la moindre difficulté.</p>
    <p>Nous comptons sur vous pour remplir votre questionnaire bilan au plus vite.</p>
  </div>
  <div *ngIf="isFirst15DaysLate">
    <p class="small">
      {{ firstName }}, vous avez dépassé la date limite de réponse à votre questionnaire bilan... Par conséquent, nous
      allons devoir appliquer comme convenu une pénalité de 150€ HT et le cabinet Dougs dégage toute responsabilité
      quant au respect des échéances fiscales.
    </p>
    <p class="small">
      Pour rappel, conformément à notre lettre de mission, si vous ne répondez pas avant le
      <b>{{ deadlineDate }}</b>
      nous serons dans l'obligation de
      <b>mettre fin à notre mission comptable</b>
      et nous ne pourrons pas faire votre bilan ni produire vos déclarations fiscales...
      <b>Vous devrez alors produire votre bilan par vos propres moyens.</b>
    </p>
    <p class="small">
      <b>
        S'il vous plaît, répondez au plus vite à votre questionnaire pour nous permettre de commencer à travailler sur
        votre bilan&nbsp;!
      </b>
    </p>
  </div>
  <div *ngIf="isTooLate">
    <p class="small">
      Malgré nos multiples relances, vous n'avez toujours pas complété votre questionnaire bilan. Conformément à notre
      lettre de mission, nous mettons fin à notre mission comptable.
    </p>
    <p class="small">
      Nous attirons votre attention sur le fait que vos déclarations fiscales ne seront pas préparées par notre cabinet.
      <b>Il vous appartient désormais de remplir ces obligations par vos propres moyens.</b>
    </p>
  </div>
</dougs-avatar-message>
