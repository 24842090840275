import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';

@Injectable()
export class BankCardComponentService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
  ) {}

  showStripeDeleteButton$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.userStateService.loggedInUser$,
  ]).pipe(
    map(
      ([activeCompany, loggedInUser]) =>
        !activeCompany.subscription?.cardBrand &&
        !!activeCompany.subscription?.hasStripeCustomerId &&
        loggedInUser?.flags?.includes(USER_FLAG.CAN_DELETE_STRIPE_CUSTOMER),
    ),
  );
}
