import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { Task, TaskBillingInvoiceItem } from '@dougs/task/dto';

@Pipe({
  name: 'billingInvoiceTooltip',
  standalone: true,
})
export class BillingInvoiceTooltipPipe implements PipeTransform {
  transform(task: Task): string | null {
    if (!task || !task.billingInvoiceItems?.length) {
      return null;
    }

    const taskBillingInvoiceItem: TaskBillingInvoiceItem = task.billingInvoiceItems[0];
    const invoiceNumber = ` (#${taskBillingInvoiceItem.billingInvoiceNumber})`;

    if (taskBillingInvoiceItem.metadata?.paidAt) {
      return `Facture payée le ${format(
        new Date(taskBillingInvoiceItem.metadata.paidAt),
        'dd/MM/yyyy',
      )} ${invoiceNumber}`;
    }

    if (!taskBillingInvoiceItem.isDraft) {
      return `Facture non payée ${invoiceNumber}`;
    }

    return null;
  }
}
