import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-esign-creation-documents-modal',
  templateUrl: './esign-creation-documents-modal.component.html',
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    AvatarMessageComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class EsignCreationDocumentsModalComponent {
  avatarUrl = 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  avatarFullName = 'Sarah Jaouani';

  constructor(
    @Inject(MODAL_DATA)
    public readonly data: {
      task: Task;
    },
  ) {}
}
