import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { toPromise } from '@dougs/core/utils';
import {
  DefaultAvatarUrlPipe,
  DropdownComponent,
  FormFieldComponent,
  SelectComponent,
  SelectOptionComponent,
  SelectOptionGroupComponent,
  TrackByPipe,
} from '@dougs/ds';
import { CockpitDomain, TaskDepartment } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Team } from '@dougs/user/dto';
import { CockpitRouterComponentService } from '../../../../services/cockpit/cockpit-router.component.service';
import { CockpitTeamComponentService } from '../../../../services/cockpit/cockpit-team.component.service';

@Component({
  selector: 'dougs-cockpit-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    DropdownComponent,
    FormFieldComponent,
    SelectComponent,
    SelectOptionGroupComponent,
    SelectOptionComponent,
    FormsModule,
    DefaultAvatarUrlPipe,
    TrackByPipe,
  ],
  providers: [CockpitTeamComponentService],
  templateUrl: './cockpit-main-menu.component.html',
  styleUrls: ['./cockpit-main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitMainMenuComponent implements OnInit {
  selectedTeam: number | TaskDepartment | null = null;

  constructor(
    public readonly cockpitRouterComponentService: CockpitRouterComponentService,
    public readonly cockpitTeamComponentService: CockpitTeamComponentService,
    private readonly cockpitStateService: CockpitStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    const currentDomain: CockpitDomain = await toPromise(this.cockpitStateService.domain$);

    if (currentDomain !== 'team' && currentDomain !== 'department') {
      return;
    }

    const isCurrentTeamTheLoggedInUserTeam: boolean = await toPromise(
      this.cockpitRouterComponentService.isCurrentTeamTheLoggedInUserTeam$,
    );

    if (!isCurrentTeamTheLoggedInUserTeam) {
      const currentTeam: Readonly<Team> | null = await toPromise(this.cockpitStateService.team$);
      const currentDepartement: Readonly<TaskDepartment> | null = await toPromise(this.cockpitStateService.department$);
      if (!currentTeam && currentDepartement) {
        this.selectedTeam = currentDepartement ?? null;
      } else {
        this.selectedTeam = currentTeam?.userId ?? null;
      }
    }
  }

  async routeToLoggedInCollaborator(): Promise<void> {
    this.selectedTeam = null;
    await this.cockpitRouterComponentService.routeToLoggedInCollaborator('to-do');
  }

  async routeToLoggedInCollaboratorTeam(): Promise<void> {
    this.selectedTeam = null;
    await this.cockpitRouterComponentService.routeToLoggedInCollaboratorTeam('to-assign');
  }

  async routeToTeamOrDepartment(teamUserIdOrDepartmentKey: number | TaskDepartment): Promise<void> {
    if (typeof teamUserIdOrDepartmentKey === 'number') {
      await this.cockpitRouterComponentService.routeToTeam(teamUserIdOrDepartmentKey, 'to-assign');
    } else if (teamUserIdOrDepartmentKey) {
      await this.cockpitRouterComponentService.routeToDepartment(teamUserIdOrDepartmentKey, 'to-assign');
    }
  }
}
