import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { MinInHourPipe } from '@dougs/ds';
import { CockpitStatsQuantityPair, CockpitTeamStats, TaskDepartment } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Team } from '@dougs/user/dto';
import { TeamStateService } from '@dougs/user/shared';
import { CockpitActionComponentService } from '../cockpit-action.component.service';
import { CockpitStatsComponentService } from './cockpit-stats.component.service';

export type CockpitComputedDepartmentStats = {
  summary: CockpitComputedDepartmentStatsSummary;
  columns: CockpitComputedTeamStats[];
};

export type CockpitComputedDepartmentStatsSummary = {
  capacity: number;

  appointments: {
    completedThisWeek: CockpitStatsQuantityPair;
  };

  tasks: {
    completedThisWeek: CockpitStatsQuantityPair;
  };

  intercom: {
    closed: number;
  };
};

export type CockpitComputedTeamStats = {
  team: Team | null;

  capacity: number;

  tasks: {
    todo: CockpitStatsQuantityPair & CockpitComputedTaskBarProperties;
    completedThisWeek: CockpitStatsQuantityPair & CockpitComputedTaskBarProperties;
    remaining: CockpitStatsQuantityPair & CockpitComputedTaskBarProperties;
    total: CockpitStatsQuantityPair;

    relativeBarSize: number;
  };
};

type CockpitComputedTaskBarProperties = {
  relativeBarPartSize: number;
  barText: string;
  showBarText: boolean;
};

@Injectable()
export class CockpitStatsDepartmentComponentService {
  private readonly BAR_TEXT_DISPLAY_MIN_PERCENTAGE = 3;

  private readonly isLoadingDepartmentStatsSource = new BehaviorSubject<boolean>(true);
  private readonly departmentStatsSource = new BehaviorSubject<CockpitComputedDepartmentStats | null>(null);

  readonly isLoadingDepartmentStats$: Observable<boolean> = this.isLoadingDepartmentStatsSource.asObservable();
  readonly departmentStats$: Observable<CockpitComputedDepartmentStats | null> =
    this.departmentStatsSource.asObservable();

  readonly refreshDepartmentStats$: Observable<unknown> = combineLatest([
    this.cockpitStatsComponentService.referenceDate$,
    this.cockpitStateService.department$,
    this.cockpitStateService.domain$,
    this.teamStateService.teams$,
    this.cockpitStatsComponentService.shouldShowStats$,

    this.cockpitActionComponentService.actionAssignation$,
    this.cockpitActionComponentService.actionTaskStartDateUpdate$,
    this.cockpitActionComponentService.actionTaskWorkloadUpdate$,
  ]).pipe(
    filter(
      ([_, department, domain, teams, shouldShowStats]) =>
        domain === 'department' && !!department && !!teams && shouldShowStats,
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    tap(() => this.isLoadingDepartmentStatsSource.next(true)),
    switchMap(([referenceDate, department]) =>
      this.cockpitStateService.getDepartmentStats(department as TaskDepartment, referenceDate),
    ),
    withLatestFrom(this.teamStateService.teams$, this.cockpitStatsComponentService.shouldShowHours$),
    map(
      ([departmentStats, teams, shouldShowHours]) => this.computeDepartmentStats(departmentStats, shouldShowHours), //teams as ReadonlyMap<number, Team>,
    ),
    map((departmentStats: CockpitComputedDepartmentStats) => this.departmentStatsSource.next(departmentStats)),
    map(() => this.isLoadingDepartmentStatsSource.next(false)),
  );

  private computeDepartmentStats(
    departmentStats: CockpitTeamStats[],
    shouldShowHours: boolean,
    //teams: ReadonlyMap<number, Team>,
  ): CockpitComputedDepartmentStats {
    const maxWorkload: number = Math.max(...departmentStats.map((teamStats) => teamStats.tasksTotal.workload));

    return {
      summary: this.computeDepartmentSummary(departmentStats),
      columns: departmentStats.map((teamStats) => {
        return {
          ...this.columnTeam(teamStats), // teams),
          ...this.columnCapacity(teamStats),
          ...this.columnTaskBars(teamStats, maxWorkload, shouldShowHours),
        };
      }),
    };
  }

  private computeDepartmentSummary(departmentStats: CockpitTeamStats[]): CockpitComputedDepartmentStatsSummary {
    return {
      capacity: departmentStats.reduce((sum, teamStats) => sum + teamStats.capacity, 0),

      appointments: {
        completedThisWeek: {
          count: departmentStats.reduce((sum, teamStats) => sum + teamStats.appointmentsCompleted.count, 0),
          workload: departmentStats.reduce((sum, teamStats) => sum + teamStats.appointmentsCompleted.workload, 0),
        },
      },

      tasks: {
        completedThisWeek: {
          count: departmentStats.reduce((sum, teamStats) => sum + teamStats.tasksCompletedThisWeek.count, 0),
          workload: departmentStats.reduce((sum, teamStats) => sum + teamStats.tasksCompletedThisWeek.workload, 0),
        },
      },

      intercom: {
        closed: departmentStats.reduce((sum, teamStats) => sum + teamStats.intercom.closed, 0),
      },
    };
  }

  private columnTeam(
    teamStats: CockpitTeamStats,
    //teams: ReadonlyMap<number, Team>,
  ): Pick<CockpitComputedTeamStats, 'team'> {
    return { team: this.teamStateService.getTeamById(teamStats.teamId) ?? null }; // getTeamById utilise le nouvel id
  }

  private columnCapacity(teamStats: CockpitTeamStats): Pick<CockpitComputedTeamStats, 'capacity'> {
    return { capacity: teamStats.capacity };
  }

  private columnTaskBars(
    teamStats: CockpitTeamStats,
    maxWorkload: number,
    shouldShowHours: boolean,
  ): Pick<CockpitComputedTeamStats, 'tasks'> {
    return {
      tasks: {
        completedThisWeek: {
          ...teamStats.tasksCompletedThisWeek,
          ...this.computeTaskBarProperties(
            teamStats.tasksCompletedThisWeek.workload,
            teamStats.tasksTotal.workload,
            teamStats.capacity,
            maxWorkload,
            shouldShowHours,
          ),
        },
        todo: {
          ...teamStats.tasksTodo,
          ...this.computeTaskBarProperties(
            teamStats.tasksTodo.workload,
            teamStats.tasksTotal.workload,
            teamStats.capacity,
            maxWorkload,
            shouldShowHours,
          ),
        },
        remaining: {
          ...teamStats.tasksRemaining,
          ...this.computeTaskBarProperties(
            teamStats.tasksRemaining.workload,
            teamStats.tasksTotal.workload,
            teamStats.capacity,
            maxWorkload,
            shouldShowHours,
          ),
        },
        total: teamStats.tasksTotal,

        relativeBarSize: maxWorkload ? teamStats.tasksTotal.workload / maxWorkload : 0,
      },
    };
  }

  private computeTaskBarProperties(
    workload: number,
    totalWorkload: number,
    capacity: number,
    maxWorkload: number,
    shouldShowHours: boolean,
  ): CockpitComputedTaskBarProperties {
    return {
      relativeBarPartSize: totalWorkload ? Math.ceil((workload / totalWorkload) * 100) : 0,
      barText: (capacity ?? 0) > 0 ? this.minInHourPipe.transform(workload / capacity) : '',
      showBarText:
        shouldShowHours &&
        (maxWorkload && totalWorkload
          ? (totalWorkload / maxWorkload) * (workload / totalWorkload) * 100 > this.BAR_TEXT_DISPLAY_MIN_PERCENTAGE
          : true),
    };
  }

  constructor(
    private readonly cockpitActionComponentService: CockpitActionComponentService,
    private readonly cockpitStatsComponentService: CockpitStatsComponentService,
    private readonly cockpitStateService: CockpitStateService,
    private readonly teamStateService: TeamStateService,
    private readonly minInHourPipe: MinInHourPipe,
  ) {}
}
