import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { CheckboxComponent, ControlFormFieldDirective, DougsDatePipe, LoaderComponent, TrackByPipe } from '@dougs/ds';
import { ActivitiesDividerPipe } from '../../../pipes/control-panel/activities-divider.pipe';
import { ActivitiesComponentService } from '../../../services/control-panel/activity/activities.component.service';
import { ActivityComponent } from '../activity/activity.component';

@Component({
  selector: 'dougs-control-panel-activities',
  templateUrl: './control-panel-activities.component.html',
  styleUrls: ['./control-panel-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ControlFormFieldDirective,
    CheckboxComponent,
    NgIf,
    LoaderComponent,
    NgFor,
    ActivityComponent,
    AutosizeModule,
    AsyncPipe,
    TrackByPipe,
    ActivitiesDividerPipe,
    DougsDatePipe,
  ],
  providers: [ActivitiesComponentService],
})
export class ControlPanelActivitiesComponent {
  constructor(public readonly activitiesComponentService: ActivitiesComponentService) {}
}
