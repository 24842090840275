import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { NeedInformationsModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerNeedInformationService extends TaskActionService {
  protected _label = "Répondre à la demande d'information";
  protected _isAvailable = true;

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    await lastValueFrom(
      this.modalService.open(NeedInformationsModalComponent, {
        data: {
          task: task,
          attachments: await this.controlPanelTasksStateService.getTaskAttachments(task),
        },
      }).afterClosed$,
    );
  }
}
