import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DefaultAvatarUrlPipe, TrackByPipe } from '@dougs/ds';
import { CockpitAccountingStatsCollaborator } from '@dougs/task/dto';
import { CockpitAccountingStatsComponentService } from '../../../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats.component.service';
import { CockpitLineComponentService } from '../../../../../../services/cockpit/cockpit-stats/cockpit-line.component.service';
import { CollaboratorActivityBarsComponent } from '../collaborator-activity-bars/collaborator-activity-bars.component';
import { CollaboratorBilanBarsComponent } from '../collaborator-bilan-bars/collaborator-bilan-bars.component';

@Component({
  selector: 'dougs-collaborator-line',
  standalone: true,
  imports: [
    CommonModule,
    CollaboratorActivityBarsComponent,
    CollaboratorBilanBarsComponent,
    DefaultAvatarUrlPipe,
    TrackByPipe,
  ],
  providers: [CockpitLineComponentService],
  templateUrl: './collaborator-line.component.html',
  styleUrls: ['./collaborator-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('200ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          position: 'absolute',
          top: 0,
          width: '100%',
        }),
        animate('200ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CollaboratorLineComponent {
  constructor(
    public readonly cockpitAccountingStatsComponentService: CockpitAccountingStatsComponentService,
    public readonly cockpitLineComponentService: CockpitLineComponentService,
  ) {}

  private _collaboratorStat!: CockpitAccountingStatsCollaborator;
  get collaboratorStat(): CockpitAccountingStatsCollaborator {
    return this._collaboratorStat;
  }

  @Input() set collaboratorStat(stat: CockpitAccountingStatsCollaborator) {
    this._collaboratorStat = stat;
    this.cockpitLineComponentService.registerStat(this._collaboratorStat);
  }
}
