import { Injectable } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { Comment, Tag, Task } from '@dougs/task/dto';
import { CockpitStateService, ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class TaskConversationComponentService {
  tags: Tag[] = [
    {
      label: 'Supprimé',
      type: 'deleted',
      hidden: false,
      readOnly: false,
      state: 'alert',
    },
    {
      label: 'Dossier perm.',
      type: 'permanent',
      hidden: false,
      readOnly: false,
      state: 'default',
    },
  ];

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly cockpitStateService: CockpitStateService,
    private readonly userStateService: UserStateService,
  ) {}

  async updateComment(task: Task, comment: Comment): Promise<void> {
    await this.controlPanelTasksStateService.updateComment(task, comment);
  }

  async uploadFiles(task: Task, files: FileList, comment: Comment): Promise<void> {
    await this.controlPanelTasksStateService.uploadCommentAttachments(task, comment, files);
  }

  async commentTask(task: Task, comment: string): Promise<void> {
    await this.controlPanelTasksStateService.commentTask(task, { body: comment });
    await this.cockpitStateService.refreshUnseenStatsOfCurrentUser();

    if (comment.match(new RegExp(`[^\\s]*${this.userStateService.loggedInUser.id}`, 'g'))) {
      await this.cockpitStateService.updateTaskSeenState(task.id, false);
    }

    await this.cockpitStateService.updateTasksState([
      {
        id: task.id,
        latestComment: {
          commenterUserId: this.userStateService.loggedInUser.id,
          body: comment,
          createdAt: new Date().toISOString(),
        },
      },
    ]);
  }

  async removeFile(task: Task, attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeCommentAttachment(task, attachment);
  }
}
