<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
  @if (!completed) {
    <dougs-button (click)="finalize.emit()" [disabled]="disabled || isLoading">
      @if (isLoading) {
        <i class="fal fa-sync fa-spin mr-8"></i>
      }
      Finaliser la saisie
    </dougs-button>
  }
</div>
