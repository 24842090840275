import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, ModalCloseDirective, ModalFooterDirective } from '@dougs/ds';

@Component({
  selector: 'dougs-list-footer',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ModalCloseDirective, ModalFooterDirective],
  templateUrl: './list-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFooterComponent {
  @Input() disabled = true;
  @Input() completed = false;
  @Input() isLoading = false;

  @Output() finalize: EventEmitter<void> = new EventEmitter<void>();
}
