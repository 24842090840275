import { Pipe, PipeTransform } from '@angular/core';
import { PayslipInfoStatus } from '@dougs/task/dto';

@Pipe({
  name: 'payslipLineCtaLabel',
  standalone: true,
})
export class PayslipLineCtaLabelPipe implements PipeTransform {
  transform(payslipLineStatus: PayslipInfoStatus): string {
    switch (payslipLineStatus) {
      case PayslipInfoStatus.NOT_STARTED:
        return 'Démarrer la saisie';
      case PayslipInfoStatus.STARTED:
        return 'Reprendre la saisie';
      case PayslipInfoStatus.COMPLETED:
        return 'Consulter la saisie';
      default:
        return 'Démarrer la saisie';
    }
  }
}
