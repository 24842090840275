import { Injectable } from '@angular/core';
import { TaskActionService } from '../task-action.service';

const ACCOUNTING_ONBOARDING_URL =
  'https://app.livestorm.co/dougs/ce-quil-faut-absolument-savoir-pour-mieux-gerer-son-entreprise-avec-dougs/';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerRegisterOnboardingWebinarService extends TaskActionService {
  protected _label = "S'inscrire à la session";
  protected _isAvailable = true;

  constructor(private readonly window: Window) {
    super();
  }

  async execute(): Promise<void> {
    this.window.open(ACCOUNTING_ONBOARDING_URL, '_blank', 'noopener');
  }
}
