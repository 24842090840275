import { FormControl, FormGroup, Validators } from '@angular/forms';
import { format } from 'date-fns';
import { CollaboratorVariable, CollaboratorVariableFormGroup } from '@dougs/task/dto';

export class CollaboratorCardFormUtils {
  static addDepositControls(
    formGroup: FormGroup<CollaboratorVariableFormGroup>,
    collaboratorVariable?: CollaboratorVariable,
  ): void {
    formGroup?.addControl(
      'date',
      new FormControl<string>(collaboratorVariable?.date ?? '', {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'amount',
      new FormControl<number | undefined>(collaboratorVariable?.amount ?? undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),
      {
        emitEvent: false,
      },
    );
  }

  static addHourControls(
    formGroup: FormGroup<CollaboratorVariableFormGroup>,
    collaboratorVariable?: CollaboratorVariable,
  ): void {
    formGroup?.addControl(
      'date',
      new FormControl<string>(collaboratorVariable?.date ?? '', {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'hours',
      new FormControl<number | undefined>(collaboratorVariable?.hours ?? undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
  }

  static addPrimeControls(
    formGroup: FormGroup<CollaboratorVariableFormGroup>,
    collaboratorVariable?: CollaboratorVariable,
  ): void {
    const date: string = collaboratorVariable?.date ? format(new Date(collaboratorVariable?.date), 'yyyy-MM-dd') : '';
    formGroup?.addControl(
      'date',
      new FormControl<string>(date, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'amount',
      new FormControl<number | undefined>(collaboratorVariable?.amount ?? undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'isNet',
      new FormControl<boolean>(collaboratorVariable?.isNet ?? false, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'label',
      new FormControl<string>(collaboratorVariable?.label ?? '', {
        validators: [Validators.required, Validators.maxLength(45)],
        nonNullable: true,
      }),
      { emitEvent: false },
    );
  }

  static addAbsenceControls(
    formGroup: FormGroup<CollaboratorVariableFormGroup>,
    collaboratorVariable?: CollaboratorVariable,
  ): void {
    if (collaboratorVariable?.isOneDay) {
      CollaboratorCardFormUtils.addAbsenceOneDayControls(formGroup, collaboratorVariable);
    } else {
      CollaboratorCardFormUtils.addAbsencePeriodControls(formGroup, collaboratorVariable);
    }
  }

  static addAbsencePeriodControls(
    formGroup: FormGroup<CollaboratorVariableFormGroup>,
    collaboratorVariable?: CollaboratorVariable,
  ): void {
    formGroup?.addControl(
      'isOneDay',
      new FormControl<boolean>(false, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'fromDate',
      new FormControl<string>(collaboratorVariable?.fromDate ?? '', {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'toDate',
      new FormControl<string>(collaboratorVariable?.toDate ?? '', {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
  }

  static addAbsenceOneDayControls(
    formGroup: FormGroup<CollaboratorVariableFormGroup>,
    collaboratorVariable?: CollaboratorVariable,
  ): void {
    formGroup?.addControl(
      'isOneDay',
      new FormControl<boolean>(true, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'date',
      new FormControl<string>(collaboratorVariable?.date ?? '', {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'totalHours',
      new FormControl<number | undefined>(collaboratorVariable?.totalHours ?? undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'extraHours',
      new FormControl<number | undefined>(collaboratorVariable?.extraHours ?? undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
    formGroup?.addControl(
      'customHours',
      new FormControl<number | undefined>(collaboratorVariable?.customHours ?? undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),
      { emitEvent: false },
    );
  }
}
