import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dougs-bank-statement-card',
  templateUrl: './bank-statement-card.component.html',
  styleUrls: ['./bank-statement-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf],
})
export class BankStatementCardComponent {
  @Input() validated = false;
  @Input() title = '';
}
