import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import {
  AvatarComponent,
  AvatarMessageComponent,
  ButtonComponent,
  DividerComponent,
  FileInputComponent,
  FilePillComponent,
  HelpModalComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { Task, TaskForm, TaskFormPartnersData } from '@dougs/task/dto';
import { ControlPanelTasksStateService, TemplateTaskStateService, UserTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-urssaf-contributions-documents-request',
  templateUrl: './urssaf-contributions-documents-request.component.html',
  styleUrls: ['./urssaf-contributions-documents-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    DividerComponent,
    NgFor,
    AvatarComponent,
    FileInputComponent,
    FilePillComponent,
    PanelInfoComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class UrssafContributionsDocumentsRequestComponent implements OnInit, OnDestroy {
  avatarUrl = 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  avatarFullName = 'Sarah Jaouani';
  title!: string;
  taskForm!: TaskForm | null;
  _task!: Task;
  _attachments!: Map<number, Attachment[]>;
  _userTasksSubscription!: Subscription;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly cdr: ChangeDetectorRef,
    private readonly templateTaskStateService: TemplateTaskStateService,
    private readonly modalRef: ModalRef,
    private readonly modalService: ModalService,
    private readonly userStateService: UserStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title = this.data.task.title;
    this._attachments = new Map<number, Attachment[]>();
    this._task = this.data.task;
    this._userTasksSubscription = this.userTasksStateService.tasks$.subscribe(async (tasks: Task[]) => {
      const tempTask: Task | undefined = tasks.find((task: Task) => task.id === this._task.id);
      if (tempTask) {
        this._task = tempTask;
        await this.generateAttachmentsList();
      }
    });
  }

  async generateAttachmentsList(): Promise<void> {
    this.taskForm = await this.templateTaskStateService.getTaskForm(this._task);
    this.taskForm?.inlineGroup?.value?.partnersData?.forEach((partner: TaskFormPartnersData) => {
      const attachments: Attachment[] = partner.attachmentIds
        .map((attachmentId: number) => this.getAttachmentById(attachmentId))
        .filter<Attachment>((a): a is Attachment => a !== undefined);
      this._attachments.set(partner.id, attachments);
    });
    this.cdr.markForCheck();
  }

  getAttachmentById(attachmentId: number): Attachment | undefined {
    return this._task.attachments?.find((attachment) => attachment.id === attachmentId);
  }

  async onRemoveAttachment(attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(this._task, attachment);
  }

  async onUploadFiles(file: File, partnerId: number): Promise<void> {
    if (!this.taskForm) {
      return;
    }
    const partner: TaskFormPartnersData | undefined = this.taskForm.inlineGroup.value?.partnersData?.find(
      (p: TaskFormPartnersData) => p.id === partnerId,
    );
    if (!partner) {
      return;
    }
    for (const attachmentId of partner.attachmentIds) {
      const attachment: Attachment | undefined = this.getAttachmentById(attachmentId);
      if (attachment) {
        await this.onRemoveAttachment(attachment);
      }
    }
    await this.controlPanelTasksStateService.uploadTaskAttachment(this._task, file, {
      fileType: 'urssafContributionsDocument',
      metadata: { partnerId: partnerId },
    });
  }

  async onCloseModal(): Promise<void> {
    if (this.taskForm?.inlineGroup.value?.canComplete) {
      this.modalRef.close(true);
    }
  }

  trackByIdAttachments(index: number, attachment: Attachment): string | number {
    return attachment.id;
  }

  trackByIdPartners(index: number, partner: TaskFormPartnersData): number {
    return partner.id;
  }

  canClose(): boolean {
    return this.userStateService.loggedInUser.isAccountantOrAdmin || !!this.taskForm?.inlineGroup.value?.canSkip;
  }

  onClickHelp(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        body: '<img src="images/help-documents/appel_cotisations_urssaf_2019.png"/>',
      },
    });
  }

  ngOnDestroy(): void {
    this._userTasksSubscription.unsubscribe();
  }
}
