import { Injectable } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerNonRemunerationCertificateService extends TaskActionService {
  protected _isAvailable = true;

  constructor(
    private readonly intercom: Intercom,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    this.intercom.hide();
    this.intercom.show(
      `Vous avez choisi la prestation "Non rémunéré ? Chômage préservé". Cliquez sur l'icône d'envoi pour confirmer votre demande.`,
    );
    await this.taskService.completeTask(task);
  }
}
