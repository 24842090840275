import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PayslipStateService } from '@dougs/task/shared';
import { CustomerFormComponentService } from '../../../../services/modals/collaborator-payslip/customer-form.component.service';
import { PayrollVariablesComponent } from '../../../payroll-survey/payroll-variables/payroll-variables.component';

@Component({
  selector: 'dougs-customer-variables',
  standalone: true,
  imports: [CommonModule, PayrollVariablesComponent],
  templateUrl: './customer-variables.component.html',
  styleUrl: './customer-variables.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerVariablesComponent {
  constructor(
    public readonly customerFormComponentService: CustomerFormComponentService,
    public readonly payslipStateService: PayslipStateService,
  ) {}
}
