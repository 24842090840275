import { Pipe, PipeTransform } from '@angular/core';
import { TASK_LEGAL_STATE } from '@dougs/task/dto';

@Pipe({
  standalone: true,
  name: 'legalState',
})
export class LegalStatePipe implements PipeTransform {
  transform(legalState: TASK_LEGAL_STATE | undefined): string {
    if (!legalState) {
      return 'Non catégorisé';
    }

    switch (legalState) {
      case TASK_LEGAL_STATE.TO_PRODUCE:
        return 'À produire';
      case TASK_LEGAL_STATE.TO_REGISTER:
        return 'Formalités à réaliser';
      case TASK_LEGAL_STATE.WAITING_CLIENT_MEETING:
        return 'RDV à venir';
      case TASK_LEGAL_STATE.WAITING_CLIENT_BENEFITS:
        return 'ARE en cours';
      case TASK_LEGAL_STATE.WAITING_PROJECT_VALIDATION:
        return 'Validation du projet en cours';
      case TASK_LEGAL_STATE.WAITING_CLIENT_SIGNATURE:
        return 'Signature en cours';
      case TASK_LEGAL_STATE.WAITING_CLIENT_CAPITAL_DEPOSIT:
        return 'Dépôt du capital en cours';
      case TASK_LEGAL_STATE.WAITING_TAX_REGISTRATION:
        return 'Enregistrement SIE en cours';
      case TASK_LEGAL_STATE.WAITING_COMPANY_REGISTRATION:
        return 'GTC ou INPI en cours';
      case TASK_LEGAL_STATE.WAITING_BALANCE_SHEET:
        return 'Bilan en cours';
      case TASK_LEGAL_STATE.PENDING_QUOTE:
        return 'Devis en attente';
      case TASK_LEGAL_STATE.WAITING_FOR_INFORMATION:
        return 'Dossier en attente';
      default:
        return 'Non catégorisé';
    }
  }
}
