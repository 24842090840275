import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { Task } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerSalesViaPlatformSurveyService extends TaskActionService {
  protected _label = 'Répondre au questionnaire';
  protected _isAvailable = true;

  constructor(
    private readonly router: Router,
    private readonly routingService: RoutingService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    await this.router.navigateByUrl(this.routingService.createUrl([URL.SETTINGS]));
  }
}
