<ng-container *ngIf="controlPanelSearchComponentService.refreshTasks$ | async"></ng-container>
<ng-container *ngIf="controlPanelSearchComponentService.loadMoreTasks$ | async"></ng-container>

<dougs-task-search></dougs-task-search>
<div
  class="tasks scrollable p-8"
  infiniteScroll
  (scrolled)="controlPanelSearchComponentService.onScroll()"
  [infiniteScrollDisabled]="controlPanelSearchComponentService.isLoading$ | async"
  [scrollWindow]="false"
>
  <dougs-loader *ngIf="controlPanelSearchComponentService.isLoading$ | async"></dougs-loader>
  <ng-container *ngIf="!(controlPanelSearchComponentService.isLoading$ | async)">
    <dougs-task
      [task]="task"
      [@fadeAnimation]="'in'"
      [@.disabled]="controlPanelSearchComponentService.isLoading$ | async"
      *ngFor="let task of controlPanelTasksStateService.tasks$ | async; trackBy: 'id' | trackBy"
    ></dougs-task>
  </ng-container>
  <p
    class="small"
    *ngIf="
      !(controlPanelSearchComponentService.isLoading$ | async) &&
      (controlPanelTasksStateService.tasks$ | async)?.length === 0
    "
  >
    Aucune tâche
  </p>
</div>
