<div dougsModalTitle>
  <h6 class="mr-16">
    Vous avez acheté plus de 460000 € de marchandises en Union Européenne. Vous êtes donc soumis à la déclaration
    d'échanges de biens (DEB).
  </h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-16">
    Vous avez acheté pour plus de 460000 € de marchandises en Union Européenne. A partir d'aujourd'hui vous êtes soumis
    à la déclaration d’échanges de biens (DEB), que vous devrez déclarer à la fin de chaque mois.
  </p>
  <p class="small mb-16">
    Vous pouvez effectuer cette déclaration par voie dématérialisée sur le site des douanes :
    <a rel="noopener" target="_blank" href="https://douane.gouv.fr/">https://douane.gouv.fr/</a>
    .
  </p>
  <p class="small mb-16">
    Vous pouvez trouver plus d’informations sur ces déclarations sur notre page d’aide à ce sujet :
    <a
      rel="noopener"
      target="_blank"
      href="https://aide.dougs.fr/fr/articles/9587580-comment-effectuer-une-deb-declaration-d-echanges-de-biens-ou-une-des-declaration-europeenne-de-services-avec-dougs"
    >
      https://aide.dougs.fr/fr/articles/9587580-comment-effectuer-une-deb-declaration-d-echanges-de-biens-ou-une-des-declaration-europeenne-de-services-avec-dougs
    </a>
    .
  </p>
</div>

<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
  <dougs-button type="button" [dougsModalClose]="true">Ok, j'ai compris</dougs-button>
</div>
