import { Injectable } from '@angular/core';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { InitialCreationAppointmentModalComponent } from '../../modals/initial-creation-appointment-modal/initial-creation-appointment-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerInitialCreationAppointmentService extends TaskActionService {
  protected _label = 'Prendre rendez-vous';
  protected _isAvailable = true;
  protected _tag = 'Rendez-vous';
  protected _tagColor: Pill = 'success';
  protected _tagIcon = 'fa-calendar-check';

  constructor(
    private readonly window: Window,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  async automaticallyExecute(task: Task | null): Promise<void> {
    if (!task) {
      return;
    }
    this.modalService.open(InitialCreationAppointmentModalComponent, {
      data: {
        task: task,
      },
    });
  }

  async execute(task: Task): Promise<void> {
    if (!task?.metadata?.calendlyUrl) {
      return;
    }
    this.window.open(task.metadata.calendlyUrl, '_blank');
  }
}
