import { Route, Routes } from '@angular/router';
import { CockpitComponent } from '../components/cockpit/cockpit.component';
import { CanAccessCockpitCollaboratorGuard } from '../guards/cockpit-collaborator.guard';
import { CanAccessCockpitDepartmentGuard } from '../guards/cockpit-department.guard';
import { CanAccessCockpitGuard } from '../guards/cockpit.guard';
import { CockpitActionComponentService } from '../services/cockpit/cockpit-action.component.service';
import { CockpitRouterComponentService } from '../services/cockpit/cockpit-router.component.service';
import { CockpitComponentService } from '../services/cockpit/cockpit.component.service';

const unseenRoute: Route = {
  path: 'unseen',
  loadComponent: () =>
    import('../components/cockpit/cockpit-routes/cockpit-unseen/cockpit-unseen.component').then(
      (c) => c.CockpitUnseenComponent,
    ),
  data: { page: 'unseen' },
};

const toAssignRoute: Route = {
  path: 'to-assign',
  loadComponent: () =>
    import('../components/cockpit/cockpit-routes/cockpit-to-assign/cockpit-to-assign.component').then(
      (c) => c.CockpitToAssignComponent,
    ),
  data: { page: 'to-assign' },
};

const toDoRoute: Route = {
  path: 'to-do',
  loadComponent: () =>
    import('../components/cockpit/cockpit-routes/cockpit-todo/cockpit-todo.component').then(
      (c) => c.CockpitTodoComponent,
    ),
  data: { page: 'to-do' },
};

const mentionsRoute: Route = {
  path: 'mentions',
  loadComponent: () =>
    import('../components/cockpit/cockpit-routes/cockpit-mentions/cockpit-mentions.component').then(
      (c) => c.CockpitMentionsComponent,
    ),
  data: { page: 'mentions' },
};

const completedRoute: Route = {
  path: 'completed',
  loadComponent: () =>
    import('../components/cockpit/cockpit-routes/cockpit-completed/cockpit-completed.component').then(
      (c) => c.CockpitCompletedComponent,
    ),
  data: { page: 'completed' },
};

const delegatedRoute: Route = {
  path: 'delegated',
  loadComponent: () =>
    import('../components/cockpit/cockpit-routes/cockpit-delegated/cockpit-delegated.component').then(
      (c) => c.CockpitDelegatedComponent,
    ),
  data: { page: 'delegated' },
};

const quotesRoute: Route = {
  path: 'quotes',
  loadComponent: () =>
    import('../components/cockpit/cockpit-routes/cockpit-quotes/cockpit-quotes.component').then(
      (c) => c.CockpitQuotesComponent,
    ),
  data: { page: 'quotes' },
};

const toRootRoute: Route = {
  path: '**',
  pathMatch: 'full',
  redirectTo: '',
};

export const COCKPIT_ROUTES: Routes = [
  {
    path: '',
    component: CockpitComponent,
    providers: [CockpitComponentService, CockpitActionComponentService, CockpitRouterComponentService],
    canActivate: [CanAccessCockpitGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        children: [],
        canActivate: [CanAccessCockpitGuard],
      },
      {
        path: 'collaborator/:collaboratorUserId',
        canActivate: [CanAccessCockpitCollaboratorGuard],
        children: [unseenRoute, toDoRoute, mentionsRoute, completedRoute, delegatedRoute, quotesRoute],
        data: { domain: 'collaborator' },
      },
      {
        path: 'team/:teamUserId',
        children: [toAssignRoute, toDoRoute, completedRoute, delegatedRoute, quotesRoute],
        data: { domain: 'team' },
      },
      {
        path: 'department/:departmentKey',
        canActivate: [CanAccessCockpitDepartmentGuard],
        children: [toAssignRoute, toDoRoute, completedRoute, delegatedRoute, quotesRoute],
        data: { domain: 'department' },
      },
      toRootRoute,
    ],
  },
];
