import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ButtonComponent,
  CopyToClipboardDirective,
  FilePillComponent,
  StepComponent,
  StepperComponent,
  StepStatus,
  StepStatusDirective,
  StepSubtitleDirective,
  StepTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { SlotDocuments, Task } from '@dougs/task/dto';
import { FilterSlotDocumentsPipe } from '../../../pipes/control-panel/slot-documents/filter-slot-documents.pipe';
import { MakeDocumentsAvailableComponentService } from '../../../services/modals/make-documents-available.component.service';

@Component({
  selector: 'dougs-available-documents',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CopyToClipboardDirective,
    TrackByPipe,
    FilterSlotDocumentsPipe,
    FilePillComponent,
    StepperComponent,
    StepComponent,
    StepStatusDirective,
    StepSubtitleDirective,
    StepTitleDirective,
  ],
  templateUrl: './available-documents.component.html',
  styleUrls: ['./available-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableDocumentsComponent {
  constructor(public readonly makeDocumentsAvailableModalService: MakeDocumentsAvailableComponentService) {}

  @Input() slotDocuments!: SlotDocuments[];
  @Input() task!: Task;
  protected readonly StepStatus = StepStatus;
}
