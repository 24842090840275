import { computed, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { CustomerConversation, Task } from '@dougs/task/dto';

@Injectable()
export class TaskCustomersConversationsComponentService {
  constructor(@Inject(Window) private readonly window: Window) {}

  private readonly _task$: WritableSignal<Task | null> = signal(null);
  get task$(): Signal<Task | null> {
    return this._task$.asReadonly();
  }

  setTask(task: Task) {
    this._task$.set(task);
  }

  customerConversations$: Signal<CustomerConversation[]> = computed(() => this.task$()?.customerConversations ?? []);
  hasUnSnoozedConversation$: Signal<boolean> = computed(() =>
    this.hasUnSnoozedConversations(this.customerConversations$()),
  );

  private hasUnSnoozedConversations(conversations: CustomerConversation[]): boolean {
    return !!conversations.find((conv) => !conv.snoozed);
  }

  openConversationNewTab(url: string): void {
    this.window.open(url, '_blank');
  }

  onIconClick($event: Event) {
    const conversations = this.customerConversations$();
    if (conversations.length === 1) {
      $event.preventDefault();
      $event.stopPropagation();
      this.openConversationNewTab(conversations[0].url);
    }
  }
}
