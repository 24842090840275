import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DividerComponent, DropdownComponent } from '@dougs/ds';
import { CockpitStateService } from '@dougs/task/shared';
import { CockpitMainMenuComponent } from './cockpit-main-menu/cockpit-main-menu.component';

@Component({
  selector: 'dougs-cockpit-header-menu',
  standalone: true,
  imports: [CommonModule, DividerComponent, DropdownComponent, CockpitMainMenuComponent],
  templateUrl: './cockpit-header-menu.component.html',
  styleUrls: ['./cockpit-header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitHeaderMenuComponent {
  constructor(public readonly cockpitStateService: CockpitStateService) {}
}
