@if (collaboratorCardComponentService.markSelectedItemAsTouched$ | async) {}
@if (collaboratorCardComponentService.disableFormGroup$ | async) {}
@if (collaboratorCardComponentService.cardFormGroup; as cardFormGroup) {
  <div class="collaborator-variable-card" [formGroup]="cardFormGroup">
    <div class="collaborator-variable-card__header py-8 px-16">
      <p class="small color-white">
        {{ collaboratorCardComponentService.selectedCatalogItem$()?.type | collaboratorVariableCardTitle }}
      </p>
      @if (!disabled) {
        <i
          (click)="collaboratorFormComponentService.removeVariableCard(formGroupIndex)"
          class="pointer fal fa-times color-white"
        ></i>
      }
    </div>
    <div class="collaborator-variable-card__body p-16">
      <dougs-catalog-item-select [disabled]="disabled" />
      @switch (collaboratorCardComponentService.cardFormGroup?.controls?.type?.value) {
        @case (CollaboratorVariableType.DEPOSIT) {
          <dougs-deposit-form
            [dougsDetectFormChanges]="collaboratorFormComponentService.formArrayHasBeenTouched$ | async"
          />
        }
        @case (CollaboratorVariableType.HOUR) {
          <dougs-hour-form
            [dougsDetectFormChanges]="collaboratorFormComponentService.formArrayHasBeenTouched$ | async"
          />
        }
        @case (CollaboratorVariableType.PRIME) {
          <dougs-prime-form
            [dougsDetectFormChanges]="collaboratorFormComponentService.formArrayHasBeenTouched$ | async"
          />
        }
        @case (CollaboratorVariableType.ABSENCE) {
          <dougs-absence-form
            [dougsDetectFormChanges]="collaboratorFormComponentService.formArrayHasBeenTouched$ | async"
          />
        }
      }
    </div>
  </div>
}
