import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { EsignCreationDocumentsModalComponent } from '../../modals/esign-creation-documents-modal/esign-creation-documents-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerEsignCreationDocumentsService extends TaskActionService {
  protected _label = 'Voir le rappel';
  protected _isAvailable = true;

  constructor(
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }
    this.modalService.open<boolean>(EsignCreationDocumentsModalComponent, {
      data: {
        task: task,
      },
    });
  }
}
