import { Injectable } from '@angular/core';
import { Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerSocialKickoffAppointmentService extends TaskActionService {
  protected _label = 'Prendre rendez-vous';
  protected _isAvailable = true;
  protected _tag = 'Rendez-vous';
  protected _tagColor: Pill = 'success';
  protected _tagIcon = 'fa-calendar-check';

  constructor(private readonly window: Window) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task?.metadata?.calendlyUrl) {
      return;
    }
    this.window.open(task.metadata.calendlyUrl, '_blank');
  }
}
