import { Pipe, PipeTransform } from '@angular/core';
import { Task, TaskBillingInvoiceItem } from '@dougs/task/dto';

@Pipe({
  name: 'billingInvoiceIconColor',
  standalone: true,
})
export class BillingInvoiceIconColorPipe implements PipeTransform {
  transform(task: Task): string | null {
    if (!task || !task.billingInvoiceItems?.length) {
      return null;
    }

    const taskBillingInvoiceItem: TaskBillingInvoiceItem = task.billingInvoiceItems[0];

    if (taskBillingInvoiceItem.metadata?.paidAt) {
      return 'color-success';
    }

    if (!taskBillingInvoiceItem.isDraft) {
      return 'color-error';
    }

    return null;
  }
}
