import { Pipe, PipeTransform } from '@angular/core';
import { BillingInvoiceItem } from '@dougs/subscription/dto';
import { BillableItemAmountPipe } from './billable-item-amount.pipe';

@Pipe({
  name: 'billableItemAmountIncludingTaxes',
  standalone: true,
})
export class BillableItemAmountIncludingTaxesPipe implements PipeTransform {
  constructor(private readonly billableItemAmountPipe: BillableItemAmountPipe) {}

  transform(billingInvoiceItem: BillingInvoiceItem): number {
    return Math.round(this.billableItemAmountPipe.transform(billingInvoiceItem) * 1.2 * 100) / 100;
  }
}
