import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { replaceCompanyIdInUrlAsCommand } from '@dougs/core/routing';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionAccountingMonitoringCashPaymentService extends TaskActionService {
  _label = 'Vérifier les opérations';

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userStateService: UserStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly router: Router,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }
    const activeAccountingYear: AccountingYear | null =
      await this.accountingYearStateService.refreshActiveAccountingYear(task.companyId);
    if (!activeAccountingYear) {
      return;
    }
    await this.router.navigate(replaceCompanyIdInUrlAsCommand(task.companyId.toString(), this.router.url));
    const { VerifyOperationListModalComponent } = await import('@dougs/operations/ui');
    const success: boolean | undefined | null = (
      await lastValueFrom(
        this.modalService.open<boolean>(VerifyOperationListModalComponent, {
          data: {
            search: {
              customAmount: JSON.stringify({
                $gte: 500,
              }),
              date: `${format(new Date(activeAccountingYear.openingDate), 'dd/MM/yyyy')}-${format(
                new Date(activeAccountingYear.effectiveClosingDate),
                'dd/MM/yyyy',
              )}`,
              autogenerated: false,
              type: 'cashPayment',
            },
            title: 'Recettes en espèce de + de 500€',
          },
        }).afterClosed$,
      )
    )?.data;
    if (success) {
      await this.controlPanelTasksStateService.completeTask(task);
    }
  }
}
