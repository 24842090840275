import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskActionModalPayslipTitle',
  standalone: true,
})
export class TaskActionModalPayslipTitlePipe implements PipeTransform {
  transform(month: string): string {
    if (!month) {
      return 'Vos bulletins de paie sont prêts';
    }
    return `Vos bulletins de paie du mois de ${month} sont prêts`;
  }
}
