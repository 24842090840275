import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { BillingInvoice } from '@dougs/subscription/dto';

@Pipe({
  name: 'invoiceAlreadSelected',
  standalone: true,
})
export class InvoiceAlreadySelectedPipe implements PipeTransform {
  transform(billingInvoice: BillingInvoice, billableItems: FormArray, index: number): boolean {
    return billableItems.controls.some(
      (formControl, i) =>
        formControl.get('metadata')?.get('creditedBillingInvoiceNumber')?.value === billingInvoice.number &&
        i !== index,
    );
  }
}
