<div dougsModalTitle>
  <h6>Activité de la tâche - {{ task?.title }}</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <div class="line">
    <p class="inline-block tiny">({{ task.createdAt | date: 'dd/MM/yyyy' }})&nbsp;</p>
    <p class="inline-block small">Créée&nbsp;</p>
    <ng-container *ngIf="task.creator">
      <p class="inline-block small">par&nbsp;</p>
      <dougs-avatar
        *ngIf="task.creator.profile"
        [image]="task.creator.profile.avatarUrl || task.creator.profile.alternateAvatarUrl"
        class="avatar"
        size="small"
      ></dougs-avatar>
      <p class="inline-block small">&nbsp;{{ task.creator.profile?.fullName }}</p>
    </ng-container>
  </div>
  <div *ngIf="task.completedAt" class="line">
    <p class="inline-block tiny">({{ task.completedAt | date: 'dd/MM/yyyy' }})&nbsp;</p>
    <p class="inline-block small">Complétée&nbsp;</p>
    <ng-container *ngIf="task.completer">
      <p class="inline-block small">par&nbsp;</p>
      <dougs-avatar
        *ngIf="task.completer.profile"
        [image]="task.completer.profile.avatarUrl || task.completer.profile.alternateAvatarUrl"
        class="avatar"
        size="small"
      ></dougs-avatar>
      <p class="inline-block small">&nbsp;{{ task.completer.profile?.fullName }}</p>
    </ng-container>
  </div>
  <div *ngIf="task.abortedAt" class="line">
    <p class="inline-block tiny">({{ task.abortedAt | date: 'dd/MM/yyyy' }})&nbsp;</p>
    <p class="inline-block small">Abandonnée&nbsp;</p>
    <ng-container *ngIf="task.aborter">
      <p class="inline-block small">par&nbsp;</p>
      <dougs-avatar
        *ngIf="task.aborter.profile"
        [image]="task.aborter.profile.avatarUrl || task.aborter.profile.alternateAvatarUrl"
        class="avatar"
        size="small"
      ></dougs-avatar>
      <p class="inline-block small">&nbsp;{{ task.aborter.profile?.fullName }}</p>
    </ng-container>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
</div>
