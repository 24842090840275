import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { SalesViaPlatformDocumentUploadModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerSalesViaPlatformDocumentUploadService extends TaskActionService {
  protected _label = 'Attacher mes documents';
  protected _isAvailable = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const taskUpdated: Task | undefined | null = (
      await lastValueFrom(
        this.modalService.open<Task>(SalesViaPlatformDocumentUploadModalComponent, {
          data: {
            task: task,
          },
        }).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      await this.taskService.completeTask(taskUpdated);
    }
  }
}
