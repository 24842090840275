<ng-container *ngIf="task">
  <div dougsModalTitle>
    <h6>Vous devez changer de régime de TVA au plus vite !</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info type="error" class="mb-16" *ngIf="!isUploadedFile">
      <p class="small">
        Vous devez nous transmettre une copie de l'e-mail que vous avez envoyé aux impôts pour pouvoir valider cette
        tâche.
      </p>
    </dougs-panel-info>
    <dougs-avatar-message
      class="mb-16"
      size="medium"
      fullName="Sarah Jaouani, Responsable Comptabilité"
      avatarUrl="https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg"
    >
      <p class="small">
        Bonjour,
        <br />
        <br />
        Vous avez validé une opération d’achat hors Union Européenne ou au sein de l’Union Européenne.
        <br />
        <br />
        Vous devez passer au régime de TVA mensuel à partir du 1er jour de votre exercice comptable.
        <br />
        <br />
        ⚠️ Nous vous invitons dès à présent à :
        <br />
        <br />
      </p>
      <ol class="pl-24">
        <li>
          <b>contacter votre centre des impôts</b>
          pour demander ce changement de régime,
        </li>
        <li>
          <b>nous joindre une copie de l'e-mail ou du courrier</b>
          que vous avez envoyé aux impôts pour effectuer cette demande.
        </li>
      </ol>
      <br />
      <p class="small">
        Nos équipes restent évidemment disponibles par tchat ou téléphone pour vous accompagner dans cette évolution !
      </p>
    </dougs-avatar-message>
    <div class="ml-40 mt-16">
      <h6 class="mb-8">Copie de l'e-mail à nous joindre :</h6>
      <div class="file-list">
        <dougs-file-input [square]="true" (uploadFiles)="onUploadTaskFiles($event)"></dougs-file-input>
        <dougs-file-pill
          [square]="true"
          (deleteFile)="controlPanelTasksStateService.removeTaskAttachment(task, $event)"
          *ngFor="let attachment of task.attachments; trackBy: 'id' | trackBy"
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
    <dougs-button type="button" color="primary" (click)="confirm(task)">
      <i class="fal fa-check mr-4"></i>
      Valider
    </dougs-button>
  </div>
</ng-container>
