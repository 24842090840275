import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'billingInvoiceTotalItems',
  standalone: true,
})
export class BillingInvoiceTotalItemsPipe implements PipeTransform {
  transform(task: Task): {
    totalAmount: number;
    totalAmountIncludingTaxes: number;
  } | null {
    if (task.billingInvoiceItems?.length > 0) {
      return task.billingInvoiceItems.reduce(
        (
          acc: {
            totalAmount: number;
            totalAmountIncludingTaxes: number;
          },
          item,
        ) => {
          return {
            totalAmount: acc.totalAmount + item.totalAmount,
            totalAmountIncludingTaxes: acc.totalAmountIncludingTaxes + item.totalAmountIncludingTaxes,
          };
        },
        {
          totalAmount: 0,
          totalAmountIncludingTaxes: 0,
        },
      );
    }
    return null;
  }
}
