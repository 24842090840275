<div
  *ngIf="postIts?.length"
  class="post-its p-4"
  (mouseenter)="postItsComponentService.onMouseEnterPostIts()"
  (click)="postItsComponentService.onOpenPostItsDrawer()"
>
  <div class="post-its__pills">
    <dougs-pill
      [class.no-limit-width]="postIts?.length < 3"
      (mouseenter)="postItsComponentService.onMouseEnterPostIt(postIt)"
      (click)="postItsComponentService.onPostItClick(postIt)"
      [ellipsis]="true"
      [isSelected]="!postIt?.isGlobal"
      [dougsTooltip]="postIt | postItTooltip"
      *ngFor="let postIt of postIts; trackBy: 'id' | trackBy"
      type="admin-dark"
    >
      {{ postIt.title }}
    </dougs-pill>
  </div>
  <div class="post-its__cta">
    <p class="xtiny color-admin">Voir les post-its</p>
  </div>
</div>
<p
  *ngIf="!postIts?.length"
  class="post-its-blankslate pointer xtiny color-admin"
  (click)="postItsComponentService.onOpenPostItsDrawer()"
>
  <i class="fal fa-plus"></i> Ajouter un post-it
</p>
