import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import {
  AvatarMessageComponent,
  ButtonComponent,
  DougsDatePipe,
  FilePillComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-need-informations-modal',
  templateUrl: './need-informations-modal.component.html',
  styleUrls: ['./need-informations-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    NgIf,
    NgFor,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
    DougsDatePipe,
  ],
})
export class NeedInformationsModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task<ClosingMetadata>;
      attachments: Attachment[] | null;
    },
    private readonly userStateService: UserStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public isClosingRequest = false;
  public customerFirstName = '';
  public avatarUrl = '';
  public accountantName = '';

  async ngOnInit(): Promise<void> {
    this.isClosingRequest = this.data.task.metadata?.isClosing ?? false;
    this.customerFirstName = this.data.task.target.profile?.firstName ?? '';

    const userInfo = await firstValueFrom(this.userStateService.usersInfo$);
    const creatorInfo =
      userInfo?.find((user) => user.id === this.data.task.creatorId) ?? this.data.task.creator?.profile;

    if (creatorInfo) {
      this.avatarUrl = creatorInfo.avatarUrl || '';
      this.accountantName = creatorInfo.fullName || '';
      this.cdr.markForCheck();
    }
  }
}

type ClosingMetadata = {
  isClosing?: boolean;
};
