<div class="task-search p-8" [formGroup]="controlPanelSearchComponentService.formGroup">
  <div class="search-bar mb-8">
    <input placeholder="Rechercher..." type="text" formControlName="search" dougsFormFieldControl />
    <dougs-button size="small" [round]="true" (click)="createTaskComponentService.addTask()" color="admin">
      <i class="fal fa-plus"></i>
    </dougs-button>
  </div>
  <div class="task-search__selects">
    <dougs-select formControlName="status" dougsFormFieldControl>
      <dougs-select-option [value]="TASK_STATUS_FILTER.ACTIVE_OR_DELEGATED">Actives</dougs-select-option>
      <dougs-select-option [value]="TASK_STATUS_FILTER.OVERDUE">En retard</dougs-select-option>
      <dougs-select-option [value]="TASK_STATUS_FILTER.COMPLETED">Complétées</dougs-select-option>
      <dougs-select-option [value]="TASK_STATUS_FILTER.ABORTED">Abandonnées</dougs-select-option>
      <dougs-select-option [value]="TASK_STATUS_FILTER.FORECAST_IN_10_MONTHS">À venir</dougs-select-option>
      <dougs-select-option [value]="TASK_STATUS_FILTER.ALL">Toutes</dougs-select-option>
    </dougs-select>
    <dougs-select formControlName="scope" dougsFormFieldControl>
      <dougs-select-option [value]="TaskScope.ALL_BUT_CUSTOMER">Tâches Dougs</dougs-select-option>
      <dougs-select-option [value]="TaskScope.OWN">Mes tâches</dougs-select-option>
      <dougs-select-option [value]="TaskScope.ADMINISTRATIVE">Administratif</dougs-select-option>
      <dougs-select-option [value]="TaskScope.ACCOUNTING">Comptable</dougs-select-option>
      <dougs-select-option [value]="TaskScope.CARE">Care</dougs-select-option>
      <dougs-select-option [value]="TaskScope.CUSTOMER">Client</dougs-select-option>
      <dougs-select-option [value]="TaskScope.FISCAL">Fiscal</dougs-select-option>
      <dougs-select-option [value]="TaskScope.INTERNAL_FINANCE">Finance interne</dougs-select-option>
      <dougs-select-option [value]="TaskScope.LEGAL">Légal</dougs-select-option>
      <dougs-select-option [value]="TaskScope.SALES">Sales</dougs-select-option>
      <dougs-select-option [value]="TaskScope.SOCIAL">Social</dougs-select-option>
      <dougs-select-option [value]="TaskScope.TECH">Tech</dougs-select-option>
      <dougs-select-option [value]="TaskScope.OTHER">Autres</dougs-select-option>
    </dougs-select>
  </div>
</div>
