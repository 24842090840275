import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, withLatestFrom } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { CockpitFilter, CockpitPage, CockpitSort } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import {
  ActionAssignationPayload,
  ActionTaskStartDateUpdatePayload,
  CockpitActionComponentService,
} from './cockpit-action.component.service';
import { CockpitStatsComponentService } from './cockpit-stats/cockpit-stats.component.service';

@Injectable()
export class CockpitMetricsComponentService {
  private readonly PAGE_MIXPANEL_MAP: { [page in CockpitPage]: string } = {
    completed: 'complété',
    'to-assign': 'à assigner',
    'to-do': 'en cours',
    unseen: 'nouveautés',
    mentions: 'mentions',
    delegated: 'déléguées',
    quotes: 'devis',
  };

  readonly pushMixpanelAfterBulkAssign$: Observable<void> = this.cockpitActionComponentService.actionAssignation$.pipe(
    filter((payload: ActionAssignationPayload | null): payload is ActionAssignationPayload => !!payload),
    map((payload) => {
      if (payload.count === 1) {
        this.metricsService.pushMixpanelEvent('Task Cockpit Assigned ');
      } else {
        this.metricsService.pushMixpanelEvent('Task Cockpit Bulk Assigned', {
          'Change StartDate': payload.hasUpdatedStartDate,
        });
      }
    }),
  );

  readonly pushMixpanelAfterFilterUpdate$: Observable<void> = combineLatest([
    this.cockpitStateService.filter$,
    this.cockpitStateService.sort$,
  ]).pipe(
    filter(([filter, sort]) => !!filter && !!sort),
    withLatestFrom(this.cockpitStateService.page$),
    map(([[filter, sort], page]) => this.pushFilterMetrics(filter, sort, page)),
  );

  private pushFilterMetrics(filter: CockpitFilter, sort: CockpitSort, page: CockpitPage): void {
    const serializedStatusFilters = this.serializeSelectedStatusFilters(filter);
    const serializedFilters = this.serializeSelectedFilters(filter, serializedStatusFilters);

    this.metricsService.pushMixpanelEvent('Task Cockpit Task Filter Selected', {
      'Subtab Viewed': page === 'to-assign' ? 'à assigner' : 'à faire',
      'Sorted by': 'by ' + sort.split('-').join(' '),
      'Filter Name': serializedFilters,
    });
  }

  private serializeSelectedStatusFilters(filter: CockpitFilter): string {
    const statusFiltersSelected: string[] = [];

    if (filter.isPriority) {
      statusFiltersSelected.push('Prioritaire');
    }
    if (filter.isLate) {
      statusFiltersSelected.push('En retard');
    }

    return statusFiltersSelected.join(', ');
  }

  private serializeSelectedFilters(filter: CockpitFilter, serializedStatusFilters: string): string {
    const filtersSelected: string[] = [];

    if (serializedStatusFilters.length) {
      filtersSelected.push(`Statut : ${serializedStatusFilters}`);
    }
    if (filter.legalForm) {
      filtersSelected.push(`Forme sociale : ${filter.legalForm}`);
    }
    if (filter.taxRegime) {
      filtersSelected.push(`Régime : ${filter.taxRegime}`);
    }
    if (filter.vatType) {
      filtersSelected.push(`TVA : ${filter.vatType}`);
    }

    return filtersSelected.join('; ');
  }

  readonly pushMixpanelAfterPageChange$: Observable<void> = this.cockpitStateService.page$.pipe(
    map((page) =>
      this.metricsService.pushMixpanelEvent('Task Cockpit Tab Changed', {
        tabName: this.PAGE_MIXPANEL_MAP[page],
      }),
    ),
  );

  readonly pushMixpanelAfterStartDateUpdate$: Observable<void> =
    this.cockpitActionComponentService.actionTaskStartDateUpdate$.pipe(
      filter(
        (payload: ActionTaskStartDateUpdatePayload | null): payload is ActionTaskStartDateUpdatePayload => !!payload,
      ),
      withLatestFrom(this.cockpitStateService.page$),
      map(([{ previousStartDate, currentStartDate }, page]) =>
        this.metricsService.pushMixpanelEvent('Task StartDate Changed', {
          Location: page === 'to-assign' ? 'Cockpit onglet à assigner' : 'Cockpit onglet à faire',
          'Previous Date': previousStartDate,
          'New Date': currentStartDate,
          'Change Date': new Date(),
        }),
      ),
    );

  readonly pushMixpanelAfterWeekChange$: Observable<void> = this.cockpitStatsComponentService.referenceDate$.pipe(
    map((referenceDate) =>
      this.metricsService.pushMixpanelEvent('Task Cockpit Week Selected', {
        'Week navigation': referenceDate > new Date() ? 'Future' : 'Past',
      }),
    ),
  );

  constructor(
    private readonly cockpitActionComponentService: CockpitActionComponentService,
    private readonly cockpitStatsComponentService: CockpitStatsComponentService,
    private readonly cockpitStateService: CockpitStateService,
    private readonly metricsService: MetricsService,
  ) {}
}
