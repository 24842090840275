import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';
import { TaskActionFacadeService } from '../task-actions/task-action-facade.service';

@Pipe({
  name: 'taskActionTag',
  standalone: true,
})
export class TaskActionTagPipe implements PipeTransform {
  constructor(private readonly taskActionFacadeService: TaskActionFacadeService) {}

  transform(task: Task): string {
    return this.taskActionFacadeService.getTaskActionTag(task);
  }
}
