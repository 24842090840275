import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollaboratorVariable, CollaboratorVariableFormGroup, CollaboratorVariableType } from '@dougs/task/dto';
import { CollaboratorCardFormUtils } from './collaborator-card-form.utils';
import { CollaboratorFormComponentService } from './collaborator-form.component.service';
import { CollaboratorPayslipModalComponentService } from './collaborator-payslip-modal.component.service';

@Injectable()
export class CollaboratorCardComponentService {
  constructor(
    private readonly collaboratorFormComponentService: CollaboratorFormComponentService,
    private readonly collaboratorPayslipModalComponentService: CollaboratorPayslipModalComponentService,
  ) {}

  cardFormGroup?: FormGroup<CollaboratorVariableFormGroup>;

  private readonly selectedCatalogItem: WritableSignal<CollaboratorVariable | null> = signal(null);
  selectedCatalogItem$: Signal<CollaboratorVariable | null> = this.selectedCatalogItem.asReadonly();

  private readonly absenceFormReady: WritableSignal<boolean> = signal(true);
  absenceFormReady$: Signal<boolean> = this.absenceFormReady.asReadonly();

  selectedCatalogItemOnError$: Signal<boolean> = computed(() => !this.selectedCatalogItem());

  private readonly enableSelectedItemError: WritableSignal<boolean> = signal(false);
  enableSelectedItemError$: Signal<boolean> = this.enableSelectedItemError.asReadonly();

  markSelectedItemAsTouched$: Observable<void> = this.collaboratorFormComponentService.markSelectedItemAsTouched$.pipe(
    map((touched) => this.enableSelectedItemError.set(touched)),
  );

  disableFormGroup$: Observable<void> =
    this.collaboratorPayslipModalComponentService.selectedPayslipLineIsCompleted$.pipe(
      map((disabled) =>
        disabled ? this.cardFormGroup?.disable({ emitEvent: false }) : this.cardFormGroup?.enable({ emitEvent: false }),
      ),
    );

  onSelectCatalogItem(selectedCatalogItem: CollaboratorVariable): void {
    if (this.selectedCatalogItem()?.code !== selectedCatalogItem.code) {
      this.selectedCatalogItem.set(selectedCatalogItem);
      this.handleFormGroupModification(selectedCatalogItem);
      this.cardFormGroup?.patchValue({
        code: selectedCatalogItem.code,
        description: selectedCatalogItem.description,
        type: selectedCatalogItem.type,
      });
      if (selectedCatalogItem?.isNative !== undefined) {
        this.cardFormGroup?.addControl(
          'isNative',
          new FormControl<boolean>(selectedCatalogItem.isNative, { nonNullable: true }),
          { emitEvent: false },
        );
      }
      if (selectedCatalogItem?.isCustom !== undefined) {
        this.cardFormGroup?.addControl(
          'isCustom',
          new FormControl<boolean>(selectedCatalogItem.isCustom, { nonNullable: true }),
          { emitEvent: false },
        );
      }
    }
  }

  setFormGroup(formGroup: FormGroup<CollaboratorVariableFormGroup>): void {
    this.cardFormGroup = formGroup;
    if (this.cardFormGroup.controls?.code?.value) {
      const correspondingCatalogItem: CollaboratorVariable | undefined =
        this.collaboratorFormComponentService.getItemFromCatalogByCode(this.cardFormGroup.controls.code.value);
      if (correspondingCatalogItem) {
        this.selectedCatalogItem.set(correspondingCatalogItem);
      } else {
        this.clearFormGroup();
        this.cardFormGroup.patchValue(
          {
            code: '',
            description: '',
            type: CollaboratorVariableType.UNKNOWN,
          },
          { emitEvent: false },
        );
      }
    }
  }

  private clearFormGroup(): void {
    for (const key in this.cardFormGroup?.controls ?? []) {
      if (key !== 'code' && key !== 'description' && key !== 'type') {
        (this.cardFormGroup as FormGroup)?.removeControl(key, { emitEvent: false });
      }
    }
    this.cardFormGroup?.markAsPristine();
  }

  private clearAbsenceFormGroup(): void {
    for (const key in this.cardFormGroup?.controls ?? []) {
      if (key !== 'code' && key !== 'description' && key !== 'type' && key !== 'isOneDay') {
        (this.cardFormGroup as FormGroup)?.removeControl(key, { emitEvent: false });
      }
    }
    this.cardFormGroup?.markAsPristine();
  }

  private handleFormGroupModification(selectedCatalogItem: CollaboratorVariable): void {
    this.clearFormGroup();
    switch (selectedCatalogItem.type) {
      case CollaboratorVariableType.DEPOSIT:
        CollaboratorCardFormUtils.addDepositControls(this.cardFormGroup as FormGroup);
        break;
      case CollaboratorVariableType.HOUR:
        CollaboratorCardFormUtils.addHourControls(this.cardFormGroup as FormGroup);
        break;
      case CollaboratorVariableType.PRIME:
        CollaboratorCardFormUtils.addPrimeControls(
          this.cardFormGroup as FormGroup,
          {
            label: selectedCatalogItem?.description,
            date: this.collaboratorPayslipModalComponentService.task.metadata.payslipDate,
          } as CollaboratorVariable,
        );
        break;
      case CollaboratorVariableType.ABSENCE:
        CollaboratorCardFormUtils.addAbsenceControls(this.cardFormGroup as FormGroup);
        break;
      default:
        break;
    }
  }

  handleAbsenceTypeChange(isOneDay: boolean): void {
    this.absenceFormReady.set(false);
    this.clearAbsenceFormGroup();
    if (isOneDay) {
      CollaboratorCardFormUtils.addAbsenceOneDayControls(this.cardFormGroup as FormGroup);
    } else {
      CollaboratorCardFormUtils.addAbsencePeriodControls(this.cardFormGroup as FormGroup);
    }
    this.absenceFormReady.set(true);
  }
}
