import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

@Injectable()
export class CockpitAccountingStatsResponsiveComponentService {
  private readonly accountingStatsUnderWidth: WritableSignal<boolean> = signal(false);
  public accountingStatsUnderWidth$: Observable<boolean> = toObservable(this.accountingStatsUnderWidth);

  private readonly isActivityMode: WritableSignal<boolean> = signal(true);
  public isActivityMode$: Signal<boolean> = computed(() => this.isActivityMode());

  private readonly isBilanMode: WritableSignal<boolean> = signal(false);
  public isBilanMode$: Signal<boolean> = computed(() => this.isBilanMode());

  public setAccountingStatsUnderWidth(isUnderWidth: boolean): void {
    this.accountingStatsUnderWidth.set(isUnderWidth);
  }

  public setActivityMode(): void {
    this.isActivityMode.set(true);
    this.isBilanMode.set(false);
  }

  public setBilanMode(): void {
    this.isActivityMode.set(false);
    this.isBilanMode.set(true);
  }
}
