<div class="file-pill">
  <a class="file-pill__content" [href]="lastAttachment.file?.url ?? lastAttachment.url" target="_blank">
    <i class="self-center fal fa-paperclip"></i>
    <span class="filename">{{ label }}</span>
  </a>
  <i [dougsDropdownTrigger]="documentsDropdown" class="fal fa-chevron-down ml-4"></i>
</div>
<dougs-dropdown #documentsDropdown [widthToRefElement]="false">
  <dougs-dropdown-option *ngFor="let attachment of attachments; trackBy: 'id' | trackBy" [closeOnClick]="true">
    <a [href]="attachment.file?.url ?? attachment.url" target="_blank">
      {{ label }} {{ attachment.createdAt | date: 'dd/MM/yyyy' }}
    </a>
  </dougs-dropdown-option>
</dougs-dropdown>
