import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { LegalLetterEngagementModalComponent } from '../../modals/legal-letter-engagement-modal/legal-letter-engagement-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerLegalLetterEngagementService extends TaskActionService {
  protected _label = 'Signer';
  protected _icon = 'fal fa-pen';
  protected _isAvailable = true;
  protected _tag = 'Lettre de mission';
  protected _tagColor: Pill = 'primary';
  protected _tagIcon = 'fa-envelope';

  constructor(
    private readonly metricsService: MetricsService,
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const taskUpdated: boolean | null | undefined = (
      await lastValueFrom(
        this.modalService.open<boolean>(LegalLetterEngagementModalComponent, {
          disableClose: true,
          data: {
            task: task,
          },
        }).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      const taskCompleted: boolean = await this.taskService.completeTask(task); // Onboarding Creation Engagement letter Confirmation Page Viewed
      if (taskCompleted) {
        this.metricsService.pushGAEvent({
          category: 'Onboarding Creation',
          label: 'Engagement letter',
          action: 'Signed',
        });
      }
    }
  }
}
