<div class="form-container" [formGroup]="collaboratorCardComponentService.cardFormGroup">
  <div class="form-layout__two-columns">
    <dougs-input-datepicker
      [noMargin]="true"
      label="Date d'application"
      formControlName="date"
    ></dougs-input-datepicker>
    <dougs-form-field [noMargin]="true">
      <label dougsFormFieldLabel>Nombre d’heures</label>
      <input dougsFormFieldControl type="number" formControlName="hours" />
      @if (formService.isControlInvalid(collaboratorCardComponentService.cardFormGroup?.controls?.hours)) {
        <span dougsFormFieldError> Ce champ est requis </span>
      }
    </dougs-form-field>
  </div>
  <dougs-panel-info type="default-light" [small]="true">
    Si régularisation d’un mois précédent, mettre le mois concerné sinon mois courant.
  </dougs-panel-info>
</div>
