import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent, DividerComponent, DougsDatePipe, TrackByPipe } from '@dougs/ds';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';
import { SubscriptionComponentService } from '../../../services/subscription.component.service';

@Component({
  selector: 'dougs-term-of-use',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, ButtonComponent, DividerComponent, NgFor, AsyncPipe, TrackByPipe, DougsDatePipe],
})
export class TermOfUseComponent {
  openedSignedTermsList = false;

  constructor(
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly subscriptionComponentService: SubscriptionComponentService,
    public readonly subscriptionStateService: SubscriptionStateService,
  ) {}
}
