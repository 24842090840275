import { Inject, Injectable } from '@angular/core';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { concatMap, Observable, of } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { MODAL_DATA } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { FourretoutTaskStateService } from '@dougs/task/shared';

@Injectable()
export class DsnAvailableModalComponentService {
  avatarUrl!: string;
  collaboratorName!: string;
  modalTitle = 'Votre DSN est prête';

  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
    private readonly companyStateService: CompanyStateService,
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
  ) {
    this.collaboratorName = this.getCollaboratorName(task);
    this.avatarUrl = this.getAvatarUrl(task);

    if (task.metadata?.dsnDate) {
      this.modalTitle = `Votre DSN du mois de ${format(new Date(task.metadata.dsnDate), 'MMMM yyyy', {
        locale: fr,
      })} est prête`;
    }
  }

  attachments$: Observable<Attachment[]> = this.companyStateService.activeCompany$.pipe(
    concatMap(() =>
      this.task.metadata.dsnAttachmentId
        ? this.fourretoutTaskStateService.getAttachmentsByIds(this.task, [this.task.metadata.dsnAttachmentId])
        : of([]),
    ),
  );

  private getAvatarUrl(task: Task): string {
    if (task.metadata.collaborator?.avatarUrl) {
      return task.metadata.collaborator.avatarUrl;
    }
    return 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  }

  private getCollaboratorName(task: Task): string {
    if (task.metadata.collaborator?.fullName) {
      return task.metadata.collaborator.fullName;
    }
    return 'Sarah Jaouani';
  }
}
