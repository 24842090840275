import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfirmationModalComponent, FlashMessage, FlashMessagesService, ModalService } from '@dougs/ds';
import { ServiceTemplate } from '@dougs/revenue/services/dto';
import { BillingInvoice, BillingInvoiceItem } from '@dougs/subscription/dto';
import { BillingInvoiceStateService, SubscriptionStateService } from '@dougs/subscription/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { AddBillingInvoiceItemModalComponent } from '../modals/add-billing-invoice-item-modal/add-billing-invoice-item-modal.component';

@Injectable({
  providedIn: 'root',
})
export class BillingInvoiceService {
  constructor(
    private readonly billingInvoiceStateService: BillingInvoiceStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  async addInvoice(): Promise<void> {
    const billingItems: BillingInvoiceItem[] | undefined | null = await this.getBillingInvoiceItems();

    if (billingItems?.length) {
      const flashMessage: FlashMessage = this.flashMessagesService.show('Ajout de la facture...', {
        type: 'pending',
        timeout: 0,
      });

      const isCreated: boolean = await this.billingInvoiceStateService.createBillingInvoice(
        this.companyStateService.activeCompany,
        billingItems,
      );

      this.flashMessagesService.close(flashMessage);

      if (isCreated) {
        this.flashMessagesService.show('Facture créée.', {
          timeout: 5000,
          type: 'success',
        });

        await this.subscriptionStateService.refreshBalance(this.companyStateService.activeCompany.id);
      } else {
        this.flashMessagesService.show('Mmmm. Erreur lors la création de la facture.', {
          timeout: 5000,
          type: 'error',
        });
      }
    }
  }

  async addServiceInBillingInvoice(billingInvoice: BillingInvoice): Promise<void> {
    const billingInvoiceItems: BillingInvoiceItem[] | undefined | null = await this.getBillingInvoiceItems(true);
    if (billingInvoiceItems?.length) {
      const flashMessage: FlashMessage = this.flashMessagesService.show('Ajout de la prestation...', {
        type: 'pending',
        timeout: 0,
      });

      const itemAdded: boolean = await this.billingInvoiceStateService.addServiceInBillingInvoice(
        this.companyStateService.activeCompany,
        billingInvoice,
        billingInvoiceItems,
      );

      this.flashMessagesService.close(flashMessage);

      if (itemAdded) {
        this.flashMessagesService.show('Prestation ajoutée.', {
          timeout: 5000,
          type: 'success',
        });

        await this.billingInvoiceStateService.refreshPendingBillingInvoice(this.companyStateService.activeCompany.id);
      } else {
        this.flashMessagesService.show("Mmmm. Erreur lors de l'ajout de la prestation.", {
          timeout: 5000,
          type: 'error',
        });
      }
    }
  }

  async removeServiceInBillingInvoice(
    billingInvoice: BillingInvoice,
    billingInvoiceItem: BillingInvoiceItem,
  ): Promise<void> {
    const confirmDelete: boolean | null | undefined = (
      await lastValueFrom(
        this.modalService.open<boolean | null | undefined>(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Êtes-vous sûr de vouloir supprimer cette prestation ?`,
            noText: 'Non',
            yesText: 'Oui',
          },
        }).afterClosed$,
      )
    )?.data;
    if (!confirmDelete) {
      return;
    }
    const flashMessage: FlashMessage = this.flashMessagesService.show('Suppression de la prestation...', {
      type: 'pending',
      timeout: 0,
    });

    const itemDeleted: boolean = await this.billingInvoiceStateService.removeServiceInBillingInvoice(
      billingInvoice,
      billingInvoiceItem,
    );

    this.flashMessagesService.close(flashMessage);

    if (itemDeleted) {
      this.flashMessagesService.show('Prestation supprimée.', {
        timeout: 5000,
        type: 'success',
      });

      await this.billingInvoiceStateService.refreshPendingBillingInvoice(this.companyStateService.activeCompany.id);
    } else {
      this.flashMessagesService.show('Mmmm. Erreur lors de la supression de la prestation.', {
        timeout: 5000,
        type: 'error',
      });
    }
  }

  async addServiceInTaskBillingInvoice(task: Task): Promise<void> {
    const billingInvoiceItems: BillingInvoiceItem[] | undefined | null = await this.getBillingInvoiceItems(true);
    if (billingInvoiceItems?.length) {
      const flashMessage: FlashMessage = this.flashMessagesService.show('Ajout de la prestation...', {
        type: 'pending',
        timeout: 0,
      });

      const itemAdded: boolean = await this.controlPanelTasksStateService.addService(task, billingInvoiceItems);

      this.flashMessagesService.close(flashMessage);

      if (itemAdded) {
        this.flashMessagesService.show('Prestation ajoutée.', {
          timeout: 5000,
          type: 'success',
        });

        await this.billingInvoiceStateService.refreshPendingBillingInvoice(this.companyStateService.activeCompany.id);
      } else {
        this.flashMessagesService.show("Mmmm. Erreur lors de l'ajout de la prestation.", {
          timeout: 5000,
          type: 'error',
        });
      }
    }
  }

  private async getBillingInvoiceItems(updateBillingInvoice = false): Promise<BillingInvoiceItem[] | undefined | null> {
    const { ServicesModalComponent } = await import('../modals/services-modal/services-modal.component');
    const services: ServiceTemplate[] | null | undefined = (
      await lastValueFrom(
        this.modalService.open<ServiceTemplate[]>(ServicesModalComponent, {
          data: {
            isSelectable: true,
            isInUserMenu: false,
          },
        }).afterClosed$,
      )
    ).data;

    if (services?.length) {
      return (
        await lastValueFrom(
          this.modalService.open<BillingInvoiceItem[]>(AddBillingInvoiceItemModalComponent, {
            data: {
              services,
              addBillingInvoiceItem: updateBillingInvoice,
            },
          }).afterClosed$,
        )
      ).data;
    }
    return [];
  }
}
