import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { BillingInvoiceItem } from '@dougs/subscription/dto';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { OpenTaskModalService } from './modals/open-task-modal.service';

@Injectable()
export class CreateTaskComponentService {
  constructor(
    private readonly modalService: ModalService,
    private readonly openTaskModalService: OpenTaskModalService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  async addTask(): Promise<void> {
    const { CreateTaskModalComponent } = await import('../modals/create-task-modal/create-task-modal.component');
    const taskCreated: Task | undefined | null = (
      await lastValueFrom(this.modalService.open<Task>(CreateTaskModalComponent).afterClosed$)
    ).data;

    if (taskCreated?.billableServiceIds) {
      // eslint-disable-next-line @nx/enforce-module-boundaries
      const { AddBillingInvoiceItemModalComponent } = await import('@dougs/subscription/ui');
      const billableServiceItems: BillingInvoiceItem[] | null | undefined = (
        await lastValueFrom(
          this.modalService.open<BillingInvoiceItem[]>(AddBillingInvoiceItemModalComponent, {
            data: {
              billableServiceIds: taskCreated.billableServiceIds,
              addBillingInvoiceItem: true,
            },
          }).afterClosed$,
        )
      ).data;

      if (billableServiceItems?.length) {
        await this.controlPanelTasksStateService.addService(taskCreated, billableServiceItems);
      }
    }

    if (taskCreated?.hasForm) {
      await this.openTaskModalService.openTaskModal(taskCreated.id);
    }
  }
}
