<ng-container *ngIf="(companyStateService.activeCompany$ | async).subscription?.nextBillingAt as nextBillingAt">
  <div class="form-layout mb-16">
    <div class="subscription-line">
      <div class="line-title">Prochaine échéance</div>
      <div class="line-content">
        {{ nextBillingAt | date: 'PPP' }}
      </div>
    </div>
  </div>
  <div class="mb-16">
    <dougs-divider></dougs-divider>
  </div>
</ng-container>
