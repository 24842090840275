<div dougsModalTitle>
  <h6>Paramètres devis/facturation</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<form #ngForm="ngForm" (submit)="updateTask()" *ngIf="!isLoading">
  <div dougsModalContent>
    <div>
      <dougs-checkbox
        [(ngModel)]="task.metadata.skipCustomerAcceptation"
        [dougsFormFieldLabel]="true"
        label="Ne pas présenter le devis au client"
        name="skipCustomerAcceptation"
      ></dougs-checkbox>
      <p class="tiny">
        Si cette option est cochée, le devis ne sera pas présenté au client et sera, après finalisation du devis,
        considéré comme accepté.
      </p>
    </div>
    <div class="mt-16">
      <label dougsFormFieldLabel>Facturer lorsque :</label>
      <dougs-radio-group
        (ngModelChange)="updateBillOnPendingInvoiceIfNecessary($event)"
        [(ngModel)]="task.metadata.billOnCompletion"
        name="billOnCompletion"
      >
        <dougs-radio [value]="false" name="billOnCompletionOff">le devis est validé</dougs-radio>
        <dougs-radio [value]="true" name="billOnCompletionOn">la tâche est complétée</dougs-radio>
      </dougs-radio-group>
    </div>
    <div *ngIf="task.metadata.skipCustomerAcceptation && task.metadata.billOnCompletion" class="mt-16">
      <label dougsFormFieldLabel>Facturer via :</label>
      <dougs-radio-group [(ngModel)]="task.metadata.billOnPendingInvoice" name="billOnPendingInvoice">
        <dougs-radio [value]="false" name="billOnPendingInvoiceOff">une nouvelle facture</dougs-radio>
        <dougs-radio [value]="true" name="billOnPendingInvoiceOn">la prochaine facture mensuelle</dougs-radio>
      </dougs-radio-group>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
    <dougs-button [disabled]="!ngForm.dirty" color="primary" type="submit">Enregistrer</dougs-button>
  </div>
</form>
<ng-container *ngIf="isLoading">
  <dougs-loader></dougs-loader>
</ng-container>
