import { Pipe, PipeTransform } from '@angular/core';
import { Comment } from '@dougs/task/dto';

@Pipe({
  name: 'commentIsDeleted',
  standalone: true,
})
export class CommentIsDeletedPipe implements PipeTransform {
  transform(comment: Comment): boolean {
    return comment.tags.some((tag) => !tag.hidden && tag.type === 'deleted');
  }
}
