import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  CheckboxComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { PartnerStateService } from '@dougs/partners/shared';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { FileCardSelectionComponent } from './file-card-selection/file-card-selection.component';
import { PartnerCardSelectionComponent } from './partner-card-selection/partner-card-selection.component';
import { PartnerGroup, YousignTaskModalService } from './yousign-task-modal.service';

@Component({
  templateUrl: './yousign-task-modal.component.html',
  styleUrls: ['./yousign-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [YousignTaskModalService],
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    ModalContentDirective,
    CheckboxComponent,
    NgFor,
    PartnerCardSelectionComponent,
    FileCardSelectionComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class YousignTaskModalComponent implements OnInit {
  constructor(
    public readonly partnerStateService: PartnerStateService,
    @Inject(MODAL_DATA)
    public data: { task: Task; taskTemplate: TaskTemplate },
    public readonly yousignTaskModalService: YousignTaskModalService,
    public readonly modalRef: ModalRef,
  ) {}

  ngOnInit(): void {
    void this.partnerStateService.refreshPartners(this.data.task.companyId);
  }

  trackByPartnerFormGroup(index: number, item: PartnerGroup): number {
    return item.controls.partner.getRawValue()?.id ?? index;
  }
}
