import { Injectable } from '@angular/core';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { AccountingInitialAppointmentModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerAccountingInitialAppointmentService extends TaskActionService {
  protected _label = 'Prendre rendez-vous';
  protected _isAvailable = true;
  protected _tag = 'Rendez-vous';
  protected _tagColor: Pill = 'success';
  protected _tagIcon = 'fa-calendar-check';

  constructor(
    private readonly modalService: ModalService,
    private readonly window: Window,
  ) {
    super();
  }

  async automaticallyExecute(task: Task | null): Promise<unknown> {
    if (!task) {
      return;
    }
    this.modalService.open(AccountingInitialAppointmentModalComponent, {
      data: {
        task: task,
      },
    });
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    if (task.metadata.calendlyUrl) {
      this.window.open(task.metadata.calendlyUrl, '_blank');
    }
  }
}
