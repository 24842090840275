import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarComponent, ButtonComponent, DefaultAvatarUrlPipe } from '@dougs/ds';
import { PayslipInfo, PayslipInfoStatus } from '@dougs/task/dto';
import { PayslipLineCtaLabelPipe } from '../../../../pipes/payslip-line-cta-label.pipe';
import { CollaboratorPayslipModalComponentService } from '../../../../services/modals/collaborator-payslip/collaborator-payslip-modal.component.service';

@Component({
  selector: 'dougs-payslip-line',
  standalone: true,
  imports: [CommonModule, AvatarComponent, DefaultAvatarUrlPipe, ButtonComponent, PayslipLineCtaLabelPipe],
  templateUrl: './payslip-line.component.html',
  styleUrl: './payslip-line.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayslipLineComponent {
  constructor(public readonly collaboratorPayslipModalComponentService: CollaboratorPayslipModalComponentService) {}

  @Input({ required: true }) payslipLine!: PayslipInfo;

  protected PayslipInfoStatus = PayslipInfoStatus;
}
