import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AvatarComponent,
  AvatarMessageComponent,
  ButtonComponent,
  DougsDatePipe,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { CollaboratorPayslipMetricsComponentService } from '../../services/modals/collaborator-payslip/collaborator-payslip-metrics.component.service';
import { CollaboratorPayslipModalComponentService } from '../../services/modals/collaborator-payslip/collaborator-payslip-modal.component.service';
import { PayrollVariablesComponent } from '../payroll-survey/payroll-variables/payroll-variables.component';
import { FormFooterComponent } from './footer/form-footer/form-footer.component';
import { ListFooterComponent } from './footer/list-footer/list-footer.component';
import { PayslipFormComponent } from './payslip-form/payslip-form.component';
import { PayslipLineComponent } from './payslip-list/payslip-line/payslip-line.component';
import { PayslipListComponent } from './payslip-list/payslip-list.component';

@Component({
  selector: 'dougs-collaborator-payslip-modal',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    AvatarMessageComponent,
    ButtonComponent,
    DougsDatePipe,
    FormsModule,
    LoaderComponent,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    PayrollVariablesComponent,
    ReactiveFormsModule,
    PanelInfoComponent,
    PayslipLineComponent,
    PayslipListComponent,
    PayslipFormComponent,
    FormFooterComponent,
    ListFooterComponent,
  ],
  providers: [CollaboratorPayslipModalComponentService, CollaboratorPayslipMetricsComponentService],
  templateUrl: './collaborator-payslip-modal.component.html',
  styleUrls: ['./collaborator-payslip-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorPayslipModalComponent {
  constructor(
    @Inject(MODAL_DATA) public task: Task,
    public readonly collaboratorPayslipModalComponentService: CollaboratorPayslipModalComponentService,
  ) {}
}
