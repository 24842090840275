import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  DougsDatePipe,
  FileDetailsComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { TaskActionAvatarUrlPipe } from '../../pipes/task-action-avatar-url.pipe';
import { TaskActionCollaboratorNamePipe } from '../../pipes/task-action-collaborator-name.pipe';
import { TaskActionModalPayslipTextPipe } from '../../pipes/task-action-modal-payslip-text.pipe';
import { TaskActionModalPayslipTitlePipe } from '../../pipes/task-action-modal-payslip-title.pipe';
import { PayslipAvailableModalComponentService } from '../../services/modals/payslip-available-modal.component.service';

@Component({
  selector: 'dougs-payslip-available-modal',
  templateUrl: './payslip-available-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    AvatarMessageComponent,
    NgFor,
    FileDetailsComponent,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
    AsyncPipe,
    TaskActionCollaboratorNamePipe,
    DougsDatePipe,
    TaskActionAvatarUrlPipe,
    TaskActionModalPayslipTitlePipe,
    TaskActionModalPayslipTextPipe,
    TaskActionCollaboratorNamePipe,
    TaskActionAvatarUrlPipe,
    TaskActionModalPayslipTitlePipe,
  ],
  providers: [PayslipAvailableModalComponentService],
})
export class PayslipAvailableModalComponent {
  constructor(public readonly payslipAvailableModalComponentService: PayslipAvailableModalComponentService) {}
}
