<div dougsModalTitle>
  <h6>Aperçu de l’e-mail envoyé</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent="large" class="modal-container">
  @if (emailPreviewModalComponentService.mailPreview$()) {
    <dougs-email-preview
      [subject]="emailPreviewModalComponentService.mailPreview$().subject"
      [content]="emailPreviewModalComponentService.mailPreview$().content"
    />
  } @else {
    @if (emailPreviewModalComponentService.hasError$()) {
      <dougs-email-error-blankslate />
    } @else {
      <dougs-email-skeleton-loading />
    }
  }
</div>
<div dougsModalFooter>
  <dougs-button dougsModalClose color="secondary"> Fermer </dougs-button>
</div>
