import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { differenceInDays, endOfDay } from 'date-fns';
import { Company } from '@dougs/company/dto';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { AccountingSurveyClosingComponent } from './accounting-survey-closing/accounting-survey-closing.component';
import { AccountingSurveyLiquidatingComponent } from './accounting-survey-liquidating/accounting-survey-liquidating.component';
import { AccountingSurveyReopeningComponent } from './accounting-survey-reopening/accounting-survey-reopening.component';

@Component({
  selector: 'dougs-accounting-survey-modal',
  templateUrl: './accounting-survey-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    AccountingSurveyReopeningComponent,
    AccountingSurveyLiquidatingComponent,
    AccountingSurveyClosingComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class AccountingSurveyModalComponent implements OnInit {
  firstName: string | undefined;
  avatarUrl: string | undefined;
  message!: string;
  statusAccountingYear!: 'reopening' | 'liquidating' | 'closing';
  allowDismiss = false;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
      company: Company;
      user: User;
    },
    private readonly modalRef: ModalRef,
    public readonly userStateService: UserStateService,
  ) {}

  ngOnInit(): void {
    const task: Task = this.data.task;

    if (task.metadata.reopeningData) {
      this.statusAccountingYear = 'reopening';
    } else if (task.metadata.isLiquidation) {
      this.statusAccountingYear = 'liquidating';
    } else {
      this.statusAccountingYear = 'closing';
    }

    const closingDate = endOfDay(new Date(this.data.task.metadata.closingDate));
    this.allowDismiss =
      differenceInDays(new Date(), closingDate) < 0 && !!this.data.task.metadata.accountingSurveySeenAtLeastOnce;
  }

  goToAccountingSurvey() {
    this.modalRef.close(true);
  }
}
