import { Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { User } from '@dougs/user/dto';

export abstract class TaskActionService {
  protected _label = 'Marquer comme fait';
  protected _icon = 'fal fa-link';
  protected _isAvailable = false;
  protected _closeNotificationOnExecute = false;
  protected _tag = 'À faire';
  protected _tagColor: Pill = 'secondary';
  protected _tagIcon = 'fa-list-ul';

  getLabel(task?: Task): string {
    return this._label;
  }

  getIcon(task?: Task): string {
    return this._icon;
  }

  getTag(): string {
    return this._tag;
  }

  getTagColor(): Pill {
    return this._tagColor;
  }

  getTagIcon(): string {
    return this._tagIcon;
  }

  getIsAvailable(task?: Task): boolean {
    return this._isAvailable;
  }

  getCloseNotificationOnExecute(task?: Task): boolean {
    return this._closeNotificationOnExecute;
  }

  shouldExecuteAutomatically(loggedInUser: User, hasAutomaticallyExecutedActionableTasks: boolean): boolean {
    if (loggedInUser.flags.includes('doUserChecks')) {
      return true;
    }
    return !hasAutomaticallyExecutedActionableTasks;
  }

  // This method is used for automatic actionable task. In case it differs from the classic execution, can be overwrite
  async automaticallyExecute(task: Task | null): Promise<unknown> {
    return await this.execute(task);
  }

  abstract execute(task: Task | null): Promise<unknown>;
}
