import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Attachment } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { AskCustomerInformationConfiguration, Task, TaskTemplate } from '@dougs/task/dto';
import {
  ControlPanelTasksStateService,
  FourretoutTaskStateService,
  TemplateTaskStateService,
} from '@dougs/task/shared';

@Component({
  selector: 'dougs-ask-customer-information-modal',
  templateUrl: './ask-customer-information-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    PanelInfoComponent,
    FormsModule,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    AutosizeModule,
    FileInputComponent,
    NgFor,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class AskCustomerInformationModalComponent implements OnInit {
  public daysToReminder!: number;
  public daysToDueDate!: number;
  public configurations!: AskCustomerInformationConfiguration[];
  public subject!: string;
  public question!: string;
  public fileList!: Attachment[];
  public taskRefresh$!: Observable<Task[]>;
  public task!: Task | null;

  constructor(
    @Inject(MODAL_DATA) public taskInput: Task,
    private readonly templateTaskStateService: TemplateTaskStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
    private readonly metricsService: MetricsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.taskRefresh$ = this.controlPanelTasksStateService.tasks$.pipe(
      tap((tasks: Task[]) => {
        this.task = tasks.find((t) => t.id === this.taskInput.id) ?? null;
        this.fileList = this.task?.attachments?.filter((file) => file.type === 'fileForCustomerInformation') ?? [];
      }),
    );
    if (this.taskInput?.code) {
      const taskTemplate: TaskTemplate | null = await this.templateTaskStateService.getTaskTemplate(
        this.taskInput.code,
        this.taskInput.version,
      );

      const configurations: AskCustomerInformationConfiguration[] =
        taskTemplate?.config?.askCustomerForInformationConfiguration ?? [];

      this.configurations = configurations.map((config) => {
        if (config.question.startsWith('Bonjour,')) {
          return {
            ...config,
            question: config.question.replace('Bonjour,', `Bonjour ${this.taskInput.target?.profile?.firstName},`),
          };
        }
        return config;
      });
      this.subject = this.configurations[0].subject;
      this.question = this.configurations[0].question;
      this.daysToDueDate = this.configurations[0].daysToDueDate;
      this.daysToReminder = this.configurations[0].daysToReminder;
      this.cdr.markForCheck();
    }
  }

  async uploadTaskAttachment(files: FileList): Promise<void> {
    if (this.task) {
      await this.controlPanelTasksStateService.uploadTaskAttachments(this.task, Array.from(files), {
        fileType: 'fileForCustomerInformation',
      });
    }
  }

  async onDeleteFile(attachment: Attachment): Promise<void> {
    if (this.task) {
      await this.controlPanelTasksStateService.removeTaskAttachment(this.task, attachment);
    }
  }

  async onSubmit(): Promise<void> {
    if (this.task) {
      this.metricsService.pushMixpanelEvent('Task Information Request Sent');
      await this.fourretoutTaskStateService.askCustomerForInformation(
        this.task,
        this.subject,
        this.question,
        this.daysToReminder,
        this.daysToDueDate,
      );
      this.modalRef.close(true);
    }
  }
}
