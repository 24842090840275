<div dougsModalTitle>
  <h6>Factures impayées</h6>
  <i *ngIf="canCancel" dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="mb-16 small">Vous avez une ou plusieurs factures impayées</p>

  <div class="mb-16" *ngIf="billingInvoiceStateService.unpaidBillingInvoices$ | async as unpaidBillingInvoices">
    <div class="billing-invoice__list">
      <table>
        <tbody *ngFor="let billingInvoice of unpaidBillingInvoices; trackBy: trackById">
          <td class="px-16 py-8">
            <p class="small">
              {{ billingInvoice.date | date: 'MMMM yyyy' | titlecase }}
            </p>
          </td>
          <td *ngIf="userStateService.loggedInUser.isAccountantOrAdmin">
            <p class="color-admin tiny">
              #{{ billingInvoice.number || (billingInvoice.subscriptionId ? 'upcoming' : 'draft') }}
            </p>
          </td>
          <td class="center avatar">
            <dougs-avatar
              *ngIf="billingInvoice.creatorId"
              size="small"
              [dougsTooltip]="
                'Créée par ' +
                billingInvoice.creator.profile.fullName +
                ' le ' +
                (billingInvoice.createdAt | date: 'dd/MM/yyyy')
              "
              [image]="
                billingInvoice.creator.profile.avatarUrl
                  ? billingInvoice.creator.profile.avatarUrl
                  : billingInvoice.creator.profile.alternateAvatarUrl
              "
            ></dougs-avatar>
          </td>
          <td class="right">
            <p class="small">
              {{ billingInvoice.amount | currency: 'EUR' }}
            </p>
          </td>
          <td class="center">
            <p class="small color-error">En retard</p>
          </td>
          <td class="pr-16">
            <p class="small pointer">
              <i (click)="downloadFile(billingInvoice)" class="fal fa-file-pdf"></i>
            </p>
          </td>
        </tbody>
      </table>
    </div>
  </div>

  <div>
    <dougs-panel-info>
      Il est possible que le règlement de la facture ait échoué suite à un changement de votre carte bancaire. Mettez-la
      à jour pour relancer le règlement automatique des factures !
      <dougs-button class="mt-8" color="secondary" (click)="openCheckoutModal()">
        Mettre à jour la carte bancaire
      </dougs-button>
    </dougs-panel-info>
    <p class="my-16 small">
      Nous vous invitons à régulariser la situation dans les plus brefs délais. Sans action de votre part, et
      conformément à notre lettre de mission, nous serons dans l'obligation de résilier votre abonnement.
    </p>
    <p class="my-16 small">Trouvons la solution ensemble : contactez-nous au plus vite !</p>
  </div>
</div>

<div dougsModalFooter>
  <dougs-button *ngIf="canCancel" color="secondary" dougsModalClose>Fermer</dougs-button>
  <dougs-button type="button" (click)="openIntercom()" dougsModalClose>Contactez-nous</dougs-button>
</div>
