import { Injectable } from '@angular/core';
import { Task, TaskForm } from '@dougs/task/dto';
import { ControlPanelTasksStateService, TemplateTaskStateService } from '@dougs/task/shared';

@Injectable()
export class TaskFormComponentService {
  constructor(
    private readonly templateTaskStateService: TemplateTaskStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  async changeTaskForm(task: Task, form: TaskForm | null | undefined): Promise<TaskForm | null> {
    if (form) {
      const taskFormChanged: TaskForm | null = await this.templateTaskStateService.changeTaskForm(task, form);

      if (taskFormChanged) {
        await this.controlPanelTasksStateService.getTask(task);
        return taskFormChanged;
      }
      return await this.cancelTaskForm(task);
    }

    return null;
  }

  async updateTaskForm(task: Task, form: TaskForm | null | undefined): Promise<TaskForm | null> {
    if (form) {
      const taskFormUpdated: TaskForm | null = await this.templateTaskStateService.updateTaskForm(task, form);

      if (taskFormUpdated) {
        await this.controlPanelTasksStateService.getTask(task);
        return taskFormUpdated;
      }
      return await this.cancelTaskForm(task);
    }

    return null;
  }

  async cancelTaskForm(task: Task): Promise<TaskForm | null> {
    return await this.templateTaskStateService.getTaskForm(task);
  }
}
