import { Pipe, PipeTransform } from '@angular/core';
import { BillingInvoiceItem } from '@dougs/subscription/dto';

@Pipe({
  name: 'billableItemAmount',
  standalone: true,
})
export class BillableItemAmountPipe implements PipeTransform {
  transform(billingInvoiceItem: BillingInvoiceItem): number {
    return (
      billingInvoiceItem.quantity * (billingInvoiceItem.amount + this.calculateTotalAmountOption(billingInvoiceItem))
    );
  }

  calculateTotalAmountOption(billingInvoiceItem: BillingInvoiceItem): number {
    return (
      billingInvoiceItem.options?.reduce(
        (acc: number, cv: BillingInvoiceItem) =>
          cv?.quantity && cv.quantity > 0 ? acc + cv.amount * cv.quantity : acc,
        0,
      ) || 0
    );
  }
}
