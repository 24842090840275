import { Injectable } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { FlashMessagesService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionActivationService extends TaskActionService {
  protected _label = 'Relancer la vérification';
  protected _isAvailable = true;
  private _isExecuting = false;

  constructor(
    private readonly flashMessagesService: FlashMessagesService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (this._isExecuting || !task) {
      return;
    }

    this._isExecuting = true;

    const activationHasBeenRestarted: boolean = await this.companyStateService.automateActivationTask();

    this.flashMessagesService.show(
      activationHasBeenRestarted
        ? 'Le processus de vérification a bien été relancé.'
        : 'Une erreur est survenue lors du relancement de la vérification.',
      { type: activationHasBeenRestarted ? 'success' : 'error' },
    );

    if (activationHasBeenRestarted) {
      // TODO Voir avec Jérémie pour retourner une tâche
      await this.controlPanelTasksStateService.refreshTasks(this.companyStateService.activeCompany.id);
    }

    this._isExecuting = false;
  }
}
