import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { AccountingBankReconciliationModalComponent } from '../../modals/accounting-bank-reconciliation-modal/accounting-bank-reconciliation-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionAccountingBankReconciliationService extends TaskActionService {
  _label = 'Voir les relevés';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task || !task.formData?.accountId || !task?.companyId) {
      return;
    }
    await lastValueFrom(
      this.modalService.open<void>(AccountingBankReconciliationModalComponent, {
        data: {
          accountId: task.formData.accountId,
          companyId: task.companyId,
        },
      }).afterClosed$,
    );
  }
}
