<!-- Tasks -->
<ng-container *ngIf="cockpitTasksComponentService.getterParametersChanged$ | async"></ng-container>
<ng-container *ngIf="cockpitTasksComponentService.resetTasks$ | async"></ng-container>

<!-- Task selection -->
<ng-container *ngIf="cockpitTasksSelectionComponentService.resetSelection$ | async"></ng-container>
<ng-container *ngIf="cockpitTasksSelectionComponentService.computeSelectAllAfterTaskUpdate$ | async"></ng-container>

<!-- Partitions -->
<ng-container *ngIf="cockpitTasksPartitionComponentService.initializeDefaultPartition$ | async"></ng-container>

<!-- Filters -->
<ng-container *ngIf="cockpitFilterComponentService.initializeFormGroupFilter$ | async"></ng-container>

<!-- Categories (left menu) -->
<ng-container *ngIf="cockpitCategoriesComponentService.refreshCategories$ | async"></ng-container>
<ng-container *ngIf="cockpitCategoriesComponentService.resetSelectedCategoryAndCode$ | async"></ng-container>

<!-- Metrics -->
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterBulkAssign$ | async"></ng-container>
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterFilterUpdate$ | async"></ng-container>
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterPageChange$ | async"></ng-container>
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterStartDateUpdate$ | async"></ng-container>
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterWeekChange$ | async"></ng-container>

<!-- Launches everything 🚀 -->
<ng-container *ngIf="cockpitComponentService.inferContextThenStartCockpit$ | async"></ng-container>

<!-- Save current url -->
<ng-container *ngIf="cockpitComponentService.storeCurrentUrlOnNavigation$ | async"></ng-container>

<dougs-cockpit-header-menu></dougs-cockpit-header-menu>

<div class="header container-fluid">
  <dougs-cockpit-header></dougs-cockpit-header>
</div>
<div class="body container-fluid body__cockpit">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
