import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Payslip, Task, TaskFormInlineGroupValueItem } from '@dougs/task/dto';
import { PayslipFormComponentService } from './payslip-form.component.service';

@Injectable()
export class CustomerFormComponentService {
  constructor(private readonly payslipFormComponentService: PayslipFormComponentService) {}

  customerPartner$: Observable<TaskFormInlineGroupValueItem | undefined> =
    this.payslipFormComponentService.payslip$.pipe(
      filter((payslip): payslip is Payslip => !!payslip?.customerVariables?.variables),
      map((payslip) =>
        payslip?.customerVariables?.variables?.items?.find((item) => item.partnerId === payslip.partnerId),
      ),
    );

  customerVariablesNbr$: Observable<number | undefined> = this.customerPartner$.pipe(
    map((partner) => partner?.variables?.length),
  );

  customerTask$: Observable<Task> = this.payslipFormComponentService.payslip$.pipe(
    filter((payslip): payslip is Payslip => !!payslip?.customerVariables?.customerTask),
    map((payslip) => payslip?.customerVariables?.customerTask),
  );
}
