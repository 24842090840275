export interface ICustomerSurvey {
  collaborator: { firstName?: string; avatarUrl: string };
  steps: ICustomerSurveyStep[];
  completionStatus?: CustomerSurveyStatusEnum;
}

export enum CustomerSurveyStatusEnum {
  completed = 'completed',
  dismissed = 'dismissed',
  timeExceeded = 'timeExceeeded',
}

export interface ICustomerSurveyStep {
  message: string;
  type: CustomerSurveyStepTypeEnum | null;
  fieldName?: string;
  label?: string;
}

export enum CustomerSurveyStepTypeEnum {
  input = 'input',
  textarea = 'textarea',
  score = 'score',
}
