<ng-container *ngIf="subscriptionComponentService.populateDateFormControl$ | async"></ng-container>
<ng-container *ngIf="userStateService.loggedInUser?.flags?.includes('canManagePeriodEndsAtSubscription')">
  <div class="form-layout mb-16">
    <ng-container *ngIf="subscriptionComponentService.dateFormControlChanges$ | async"></ng-container>
    <div class="subscription-line">
      <div class="line-title">Date de fin de souscription</div>
      <dougs-input-datepicker
        [formControl]="subscriptionComponentService.dateFormControl"
        [noMargin]="true"
        label=""
      ></dougs-input-datepicker>
    </div>
  </div>
  <div class="mb-16">
    <dougs-divider></dougs-divider>
  </div>
</ng-container>
