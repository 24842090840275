<div dougsModalTitle>
  <h6>Lettre de mission</h6>
</div>
<div dougsModalContent="xlarge" [ngClass]="{ flex: (onMobile$ | async) === false }" class="mb-16">
  <div class="panel-info-container" [ngClass]="{ 'mr-24': (onMobile$ | async) === false }">
    <dougs-panel-info class="mb-8 default-light">
      <i class="fal fa-lightbulb-on color-primary-700 mb-8 light-icon"></i>
      <p class="small mb-16">
        Nous vous invitons à prendre connaissance et à
        <b>accepter la lettre de mission.</b>
      </p>
      <p class="small">
        Votre pack est sans engagement. Vous pouvez, à tout moment, arrêter votre abonnement sur simple demande.
      </p>
      <p class="small mt-4" *ngIf="canSignLater && data.task.dueDate">
        Vous avez jusqu'au
        <b>{{ data.task.dueDate | date: 'dd/MM/yyyy' }}</b>
        pour signer.
      </p>
    </dougs-panel-info>

    <dougs-panel-info class="warning mb-16" *ngIf="hasWarnings">
      <h6 *ngIf="data.task.metadata.regenerationReason !== regenCodeReactivation">
        Mise à jour de la lettre de mission
      </h6>
      <h6 *ngIf="data.task.metadata.regenerationReason === regenCodeReactivation">Nouvelle lettre de mission</h6>
      <p class="color-primary-700 small">
        <ng-container [ngSwitch]="data.task.metadata.regenerationReason">
          <ng-container *ngSwitchCase="regenCodeManual">
            Un membre de l’équipe Dougs a mis à jour la lettre de mission. Merci de bien vouloir la signer. N’hésitez
            pas à nous contacter si besoin de précisions.
          </ng-container>
          <ng-container *ngSwitchCase="regenCodeLegalProperty">
            La lettre de mission a été mise à jour suite à une modification de
            {{ data.task.metadata.changedPropertyLabel ? data.task.metadata.changedPropertyLabel : 'données' }}
            . Merci de bien vouloir la signer.
          </ng-container>
          <ng-container *ngSwitchCase="regenCodeReactivation">
            Votre abonnement a été réactivé. Merci de bien vouloir signer la nouvelle lettre de mission.
          </ng-container>
        </ng-container>
      </p>
    </dougs-panel-info>

    <dougs-panel-info class="warning mb-16" *ngIf="hasDirtyTerms">
      <p class="color-primary-700 small">
        En raison du démarrage de votre nouvel exercice comptable, nous vous invitons à accepter cette lettre de
        mission. N’hésitez pas à nous contacter si besoin de précisions.
      </p>
    </dougs-panel-info>

    <dougs-panel-info class="mb-16" *ngIf="termsChanges.length > 0">
      <h6>Synthèse des modifications&nbsp;:</h6>
      <ul class="mt-8 mb-0">
        <li *ngFor="let changes of termsChanges" [innerHTML]="changes"></li>
      </ul>
    </dougs-panel-info>

    <p *ngIf="(onMobile$ | async) === false" class="tiny mb-8 px-4">
      Si vous ne parvenez pas à visualiser le document, vous pouvez le
      <a [href]="previewUrl | trustRessourceUrl" target="_blank">télécharger ici.</a>
    </p>
  </div>

  <div class="policies-container">
    <iframe *ngIf="(onMobile$ | async) === false" class="mb-4" [src]="previewUrl | trustRessourceUrl"></iframe>

    <div *ngIf="onMobile$ | async" class="my-16">
      <dougs-button color="secondary" fullWidth="true">
        <a [href]="previewUrl | trustRessourceUrl" target="_blank">
          <div class="flex-container py-8">
            <i class="fal fa-file"></i>
            <span class="mx-16">Télécharger la lettre de mission</span>
            <i class="fal fa-chevron-right"></i>
          </div>
        </a>
      </dougs-button>
    </div>

    <p class="small color-gray mb-16">
      En acceptant la lettre de mission vous affirmez être le
      <b>représentant légal</b>
      de l’entreprise. Après signature, vous recevrez votre
      <b>lettre de mission par mail.</b>
    </p>

    <div class="confirmation p-8">
      <dougs-checkbox [formControl]="policies">
        <p class="ml-8 bold color-primary-700 small">
          Je confirme l’acceptation de la lettre de mission ainsi que
          <a rel="noopener" href="https://www.dougs.fr/cgu" class="color-primary" target="_blank">
            <span>les Conditions Générales d'Utilisation</span>
          </a>
        </p>
      </dougs-checkbox>
    </div>
  </div>
</div>

<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose *ngIf="canSignLater && (onMobile$ | async) === false">
    Signer plus tard
  </dougs-button>
  <a dougsModalClose *ngIf="canSignLater && (onMobile$ | async)" class="sign-later-policies">Signer plus tard</a>

  <dougs-button
    dougsModalClose
    color="secondary"
    *ngIf="canAccessService && (onMobile$ | async) === false"
    (click)="redirectService()"
  >
    Me le rappeler plus tard
  </dougs-button>
  <a
    dougsModalClose
    *ngIf="canAccessService && (onMobile$ | async)"
    class="sign-later-policies"
    (click)="redirectService()"
    >Me le rappeler plus tard</a
  >

  <dougs-button [disabled]="!policies.value" class="btn-mobile" type="submit" (click)="submit()">
    <i class="fal fa-check mr-8"></i>
    J'ai lu et j'accepte
  </dougs-button>
</div>
