<form
  (ngSubmit)="workloadTaskModalComponentService.onSubmit()"
  [formGroup]="workloadTaskModalComponentService.formGroup"
  class="form-abort-task"
>
  <div dougsModalTitle>
    <h6>Modifier la charte de travail</h6>
    <i class="fal fa-times" dougsModalClose></i>
  </div>
  <div dougsModalContent="small" class="workload-task-modal">
    <dougs-form-field [noMargin]="true">
      <input dougsFormFieldControl type="number" formControlName="workloadHours" />
      <span dougsFormFieldSuffix>h</span>
    </dougs-form-field>
    <dougs-form-field [noMargin]="true">
      <input dougsFormFieldControl type="number" formControlName="workloadMinutes" />
      <span dougsFormFieldSuffix>min</span>
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">Modifier</dougs-button>
  </div>
</form>
