import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import { lastValueFrom, take } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import {
  ButtonComponent,
  DougsDatePipe,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { BillingInvoice } from '@dougs/subscription/dto';
import { BillingInvoiceStateService } from '@dougs/subscription/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BillingInvoiceComponent } from '@dougs/subscription/ui';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OpenTaskModalService } from '@dougs/task/ui';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-payment-strong-customer-authentication-modal',
  templateUrl: './payment-strong-customer-authentication-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    BillingInvoiceComponent,
    NgSwitch,
    NgSwitchCase,
    PanelInfoComponent,
    ModalFooterDirective,
    ButtonComponent,
    DougsDatePipe,
  ],
})
export class PaymentStrongCustomerAuthenticationModalComponent implements OnInit {
  isPending = false;
  authenticationHasBeenAttempted = false;
  billingInvoice: BillingInvoice | null = null;
  status: string | null = null;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    public readonly billingInvoiceStateService: BillingInvoiceStateService,
    public readonly userStateService: UserStateService,
    public readonly openTaskModalService: OpenTaskModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly stripeService: StripeService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.status = this.data.task.completedAt !== null ? 'success' : null;

    if (this.data.task.metadata.billingInvoiceId) {
      this.billingInvoice = await this.billingInvoiceStateService.getBillingInvoice(
        this.companyStateService.activeCompany.id,
        this.data.task.metadata.billingInvoiceId,
      );
    }

    this.cdr.markForCheck();
  }

  async startConfirmation(): Promise<void> {
    if (this.isPending) {
      return;
    }

    this.isPending = true;
    const result: PaymentIntentResult = await lastValueFrom(
      this.stripeService
        .confirmCardPayment(this.data.task.metadata.paymentIntentClientSecret ?? '', {
          payment_method: this.data.task.metadata.paymentMethodId,
        })
        .pipe(take(1)),
    );

    this.handleStripeResponse(result);
    this.isPending = false;
    this.authenticationHasBeenAttempted = this.status !== 'error';
    this.cdr.markForCheck();
  }

  handleStripeResponse(result?: PaymentIntentResult): void {
    if (result === undefined) {
      this.status = 'error';
      return;
    }

    if (!result.error) {
      this.status = 'success';
      return;
    }

    const error = result.error;
    if (error.payment_intent && error.payment_intent.status === 'succeeded') {
      this.status = 'success';
    } else if (error.type === 'card_error') {
      this.status = 'cardError';
    } else {
      this.status = 'error';
    }
  }

  closeModal(): void {
    this.modalRef.close(this.authenticationHasBeenAttempted);
  }
}
