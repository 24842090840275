import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AvatarComponent,
  BadgeComponent,
  ButtonComponent,
  DateSmallestUnitOfTimeDifferencePipe,
  DefaultAvatarUrlPipe,
  LoaderComponent,
  MinInHourPipe,
  TooltipDirective,
  TrackByPipe,
} from '@dougs/ds';
import { CockpitStateService } from '@dougs/task/shared';
import { Collaborator, Team } from '@dougs/user/dto';
import {
  CockpitComputedDepartmentStats,
  CockpitComputedDepartmentStatsSummary,
  CockpitComputedTeamStats,
} from '../../../../services/cockpit/cockpit-stats/cockpit-stats-department.component.service';
import { CockpitComputedCollaboratorStats } from '../../../../services/cockpit/cockpit-stats/cockpit-stats-team.component.service';
import { CockpitStatsComponentService } from '../../../../services/cockpit/cockpit-stats/cockpit-stats.component.service';
import { CockpitTeamComponentService } from '../../../../services/cockpit/cockpit-team.component.service';
import { CockpitStatsPeriodComponent } from './cockpit-stats-period/cockpit-stats-period.component';
import { CockpitStatsProgressBarComponent } from './cockpit-stats-progress-bar/cockpit-stats-progress-bar.component';
import { CockpitStatsTaskTooltipRowComponent } from './cockpit-stats-task-tooltip-row/cockpit-stats-task-tooltip-row.component';

@Component({
  selector: 'dougs-cockpit-stats',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    BadgeComponent,
    ButtonComponent,
    CockpitStatsPeriodComponent,
    CockpitStatsProgressBarComponent,
    CockpitStatsTaskTooltipRowComponent,
    LoaderComponent,
    TooltipDirective,
    MinInHourPipe,
    BadgeComponent,
    DateSmallestUnitOfTimeDifferencePipe,
    DefaultAvatarUrlPipe,
    TrackByPipe,
  ],
  templateUrl: './cockpit-stats.component.html',
  styleUrls: ['./cockpit-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitStatsComponent {
  private _stats: CockpitComputedCollaboratorStats[] | CockpitComputedTeamStats[] = [];

  public anyAppointment = false;
  public anyClosedIntercom = false;
  public anySnoozedIntercom = false;
  public anyOpenIntercom = false;
  public departmentSummary: CockpitComputedDepartmentStatsSummary | null = null;

  @Input() isLoading = true;

  @Input()
  set departmentStats(value: CockpitComputedDepartmentStats) {
    this.departmentSummary = value?.summary;
    this._stats = value?.columns;
  }

  @Input()
  set collaboratorStats(value: CockpitComputedCollaboratorStats[]) {
    this._stats = value;

    if (this._stats) {
      this.anyAppointment = this.cockpitStatsComponentService.anyCollaboratorWithAnAppointment(this._stats);
      this.anyClosedIntercom = this.cockpitStatsComponentService.anyCollaboratorWithClosedIntercom(this._stats);
      this.anyOpenIntercom = this.cockpitStatsComponentService.anyCollaboratorWithOpenIntercom(this._stats);
      this.anySnoozedIntercom = this.cockpitStatsComponentService.anyCollaboratorWithSnoozedIntercom(this._stats);
    }
  }

  get stats(): CockpitComputedCollaboratorStats[] | CockpitComputedTeamStats[] | null {
    return this._stats;
  }

  constructor(
    public readonly cockpitStatsComponentService: CockpitStatsComponentService,
    public readonly cockpitTeamComponentService: CockpitTeamComponentService,
    public readonly cockpitStateService: CockpitStateService,
  ) {}

  statTrackBy(_: number, stat: CockpitComputedCollaboratorStats | CockpitComputedTeamStats): string {
    if ('team' in stat) {
      return `team-${(stat.team as Team).id}`;
    }
    return `collaborator-${(stat.collaborator as Collaborator).id}`;
  }
}
