import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AvatarComponent } from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';

@Component({
  selector: 'dougs-partner-card-selection',
  templateUrl: './partner-card-selection.component.html',
  styleUrls: ['./partner-card-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PartnerCardSelectionComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgClass, AvatarComponent, NgIf],
})
export class PartnerCardSelectionComponent implements ControlValueAccessor {
  protected _value = false;

  get value() {
    return this._value;
  }

  set value(input: boolean) {
    this._value = input;
    this.onChange(this._value);
    this.cdr.markForCheck();
  }

  isDisabled = false;

  private _partner!: Partner;
  partnerImageUrl!: string;

  @Input()
  set partner(input: Partner) {
    this._partner = input;
    this.partnerImageUrl = this.getPartnerImageUrl(this._partner);
  }

  get partner() {
    return this._partner;
  }

  @Input()
  errors: string[] = [];

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  onChange: (value: boolean) => void = () => true;
  onTouched: () => void = () => true;

  toggleSelect() {
    if (this.isDisabled) {
      return;
    }
    this.value = !this.value;
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  private getPartnerImageUrl(partner: Partner): string {
    if (partner.naturalPerson) {
      return (
        partner.naturalPerson.avatarUrl ||
        partner.naturalPerson.alternateAvatarUrl ||
        'https://www.gravatar.com/avatar/null?s=256&d=mm'
      );
    }
    return partner.legalPerson?.id && partner.legalPerson?.avatar?.name
      ? `/companies/${partner.legalPerson.id}/avatar?filename=${partner.legalPerson?.avatar?.name}`
      : 'https://www.gravatar.com/avatar/null?s=256&d=mm';
  }
}
