<div class="task-item px-8 pt-4 pb-8">
  <div
    class="task-item__title"
    [dougsTooltip]="
      task.completedAt && task.metadata?.workflowBypass?.bypassed
        ? 'Sous-tâche automatique en panne. ' +
          task.completer?.profile?.fullName +
          ' a continué en manuel le ' +
          (task.completedAt | date: 'dd/MM/yyyy HH:mm')
        : task.completedAt
          ? (task.completer?.profile?.fullName || 'Auto complétée') +
            ',' +
            (task.completedAt | date: 'dd/MM/yyyy HH:mm')
          : ''
    "
    (click)="taskItemComponentService.toggleCompleted(task)"
  >
    <i class="fal mr-4" [ngClass]="task | toggleIcon"></i>
    <span [ngClass]="{ 'task-item__title__active': task.isActive }">{{ task.title }}</span>
  </div>
  <div class="pl-24 mt-4" *ngIf="task.fileAttachmentSlots">
    <ng-container *ngFor="let slot of task.fileAttachmentSlots; trackBy: trackByIndex">
      <div class="file-list">
        <dougs-file-input
          *ngIf="!task.completedAt"
          [multiple]="true"
          [fileInputText]="slot.label"
          (uploadFiles)="taskItemComponentService.onUploadFiles(task, $event, slot.type)"
        ></dougs-file-input>
        <dougs-file-pill
          (deleteFile)="taskItemComponentService.onDeleteFile(task, $event)"
          [canDelete]="!task.completedAt"
          *ngFor="let attachment of getAttachmentsByType(slot.type); trackBy: trackById"
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </ng-container>
  </div>
  <p class="xtiny color-error pl-16" *ngIf="!task.completedAt && task?.metadata?.workflowBypass?.firstErrorAt">
    Oups ! La sous-tâche automatique n'a pas fonctionné.
  </p>
  <p class="xtiny color-admin pl-16" *ngIf="task.completedAt && task?.metadata?.workflowBypass?.bypassed">
    Complétée manuellement
  </p>
  <ng-container *ngIf="!task.completedAt && task?.metadata?.workflowBypass?.firstErrorAt">
    <dougs-button
      [fullWidth]="true"
      size="small"
      color="secondary"
      (click)="taskItemComponentService.completeTask(task, false)"
      [disabled]="taskItemComponentService.isLoading$()"
    >
      <i class="fal fa-spinner-third fa-spin mr-4" *ngIf="taskItemComponentService.isLoading$()"></i>
      Réessayer l'action de façon automatique
    </dougs-button>
    <dougs-button
      class="mt-4"
      [fullWidth]="true"
      size="small"
      color="admin"
      [disabled]="taskItemComponentService.isLoading$()"
      (click)="taskItemComponentService.completeTask(task, true)"
    >
      <i class="fal fa-spinner-third fa-spin mr-4" *ngIf="taskItemComponentService.isLoading$()"></i>
      {{ buttonText }}
    </dougs-button>
  </ng-container>
  <div class="pl-8">
    <dougs-task-item *ngFor="let taskItem of task.tasks; trackBy: 'id' | trackBy" [task]="taskItem"></dougs-task-item>
  </div>
  @if (task.isConfigurator && task.completedAt) {
    <p class="color-gray underline tiny pl-16" (click)="taskItemComponentService.openAnsweredConfiguratorModal(task)">
      Voir les réponses du configurateur
    </p>
  }
  @if (taskItemComponentService.canPreviewMail$()) {
    <p class="color-gray underline tiny pl-16" (click)="taskItemComponentService.openMailPreviewModal(task.rootId)">
      Voir l’aperçu de l’e-mail envoyé
    </p>
  }
</div>
