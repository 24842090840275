<div class="customer-form p-8">
  <h5>Variables de paie renseignées par le client</h5>
  @if (
    (customerFormComponentService.customerPartner$ | async) && (customerFormComponentService.customerTask$ | async)
  ) {
    <dougs-payroll-variables
      [month]="(customerFormComponentService.customerTask$ | async)?.metadata?.payslipDate"
      [partner]="customerFormComponentService.customerPartner$ | async"
      [task]="customerFormComponentService.customerTask$ | async"
      [variableDescriptors]="payslipStateService.variableDescriptors$ | async"
      [readOnly]="true"
      [small]="true"
    ></dougs-payroll-variables>
  }
</div>
