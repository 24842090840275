import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { AccountBalances, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { BankReconciliationService } from './bank-reconciliation.service';
import {
  BankReconciliationFirstStepComponent,
  BankReconciliationSecondStepComponent,
  BankReconciliationThirdStepComponent,
} from './steps';

@Component({
  selector: 'dougs-bank-reconciliation-modal',
  templateUrl: './bank-reconciliation-modal.component.html',
  styleUrls: ['./bank-reconciliation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    NgIf,
    ModalContentDirective,
    LoaderComponent,
    BankReconciliationFirstStepComponent,
    BankReconciliationSecondStepComponent,
    BankReconciliationThirdStepComponent,
  ],
})
export class BankReconciliationModalComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();
  synchronizedAccounts!: SynchronizedAccount[];
  task!: Task | null;

  isLoading = false;
  step = 1;

  selectedAccount: SynchronizedAccount | undefined = undefined;
  selectedAccountBalances: AccountBalances[] = [];
  reconciliationCompletedAccountsIds: { [accountId: number]: boolean } = {};
  bankReconciliationNeeded = false;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly bankReconciliationService: BankReconciliationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.task = await this.controlPanelTasksStateService.getTask(this.data.task);

    if (this.task) {
      this.reconciliationCompletedAccountsIds = this.task?.tasks.reduce(
        (reconciliationCompletedAccountsIds: { [accountId: number]: boolean }, subTask) => {
          if (subTask.metadata.accountId) {
            return {
              ...reconciliationCompletedAccountsIds,
              [subTask.metadata.accountId]: !!subTask.completedAt,
            };
          }
          return { ...reconciliationCompletedAccountsIds };
        },
        {},
      );
    }

    const synchronizedAccountsSubscription: Subscription =
      this.synchronizedAccountStateService.synchronizedAccounts$.subscribe((synchronizedAccounts) => {
        this.synchronizedAccounts = synchronizedAccounts.filter((synchronizedAccount) => {
          return synchronizedAccount.id in this.reconciliationCompletedAccountsIds;
        });

        if (this.selectedAccount) {
          this.refreshSelectedAccount();
        }

        this.cdr.markForCheck();
      });

    this.subscription.add(synchronizedAccountsSubscription);

    this.cdr.markForCheck();
  }

  private async saveAndRefresh(): Promise<void> {
    if (!this.selectedAccount) {
      return;
    }

    await this.synchronizedAccountStateService.updateAccount(this.selectedAccount, {
      updateReconciliation: true,
    });

    this.refreshSelectedAccount();
  }

  private refreshSelectedAccount(): void {
    const selectedAccount: SynchronizedAccount | undefined = this.synchronizedAccounts.find(
      (synchronizedAccount) => this.selectedAccount?.id === synchronizedAccount.id,
    );

    if (selectedAccount?.metadata.balances) {
      this.selectedAccount = { ...selectedAccount };

      this.selectedAccountBalances = [...this.filterAndSortBalances(selectedAccount.metadata.balances)];
    }
  }

  private filterAndSortBalances(selectedAccountBalances: AccountBalances[]): AccountBalances[] {
    const balances: AccountBalances[] = selectedAccountBalances.filter((balance) =>
      this.bankReconciliationService.shouldBalanceBeChecked(balance, this.task),
    );

    balances.sort((a, b) => new Date(b.maxDate).getTime() - new Date(a.maxDate).getTime());

    return balances;
  }

  getCurrentCardTitle(form: UntypedFormArray, index: number): string {
    if (index === 0) {
      return '';
    }

    const months: string[] = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];

    const previousFormDate = form.at(index - 1).get('date')?.value;

    if (!previousFormDate) {
      return 'Relevé bancaire du mois précédent';
    }

    const currentFormDate = form.at(index).get('date')?.value;
    const bankStatementMonth: number = currentFormDate
      ? new Date(currentFormDate).getMonth()
      : (new Date(previousFormDate).getMonth() + 11) % 12;

    return `Relevé bancaire du mois : ${months[bankStatementMonth]}`;
  }

  async selectAccount(account: SynchronizedAccount): Promise<void> {
    this.isLoading = true;

    this.selectedAccount = account;
    await this.saveAndRefresh();
    this.nextStep();

    this.isLoading = false;
    this.cdr.markForCheck();
  }

  previousStep(): void {
    if (this.step === 1) {
      return;
    }

    this.selectedAccount = undefined;
    this.step = 1;
  }

  nextStep(): void {
    if (!this.selectedAccount) {
      return;
    }

    this.step = this.reconciliationCompletedAccountsIds[this.selectedAccount.id]
      ? 1
      : this.selectedAccount.metadata.reconciliation?.step || 2;

    if (this.step === 1) {
      this.selectedAccount = undefined;
      this.selectedAccountBalances = [];
    }
  }

  async saveAndGoToNextStep(): Promise<void> {
    if (!this.selectedAccount || !this.task) {
      return;
    }
    this.isLoading = true;

    await this.saveAndRefresh();

    const selectedAccountTaskId: number | undefined = this.task.tasks.find(
      (subTask) => subTask.metadata.accountId === this.selectedAccount?.id,
    )?.id;

    const selectedAccountTask: Task | null = selectedAccountTaskId
      ? await this.controlPanelTasksStateService.getTask(selectedAccountTaskId)
      : null;

    if (selectedAccountTask && selectedAccountTask.metadata.accountId) {
      this.task = await this.controlPanelTasksStateService.getTask(this.data.task);

      this.reconciliationCompletedAccountsIds = {
        ...this.reconciliationCompletedAccountsIds,
        [selectedAccountTask.metadata.accountId]: !!selectedAccountTask.completedAt,
      };

      this.bankReconciliationNeeded =
        this.bankReconciliationNeeded ||
        !!(
          this.task?.tasks.some((subTask) => !!subTask.completedAt) &&
          this.synchronizedAccounts.some((synchronizedAccount) =>
            synchronizedAccount.metadata.balances?.some(
              (balance) =>
                balance.hasAttachment && this.bankReconciliationService.shouldBalanceBeChecked(balance, this.task),
            ),
          )
        );
    }
    this.nextStep();

    this.isLoading = false;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
