<div dougsModalTitle>
  <h5>Transmettre les projets de statuts au client</h5>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <div class="mb-24">
    <dougs-form-field>
      <label dougsFormFieldLabel>Objet</label>
      <input dougsFormFieldControl type="text" [required]="true" #ngSubject="ngModel" [(ngModel)]="subject" />
      <span *ngIf="formService.isNgModelInvalid(ngSubject)" dougsFormFieldError>
        <span>Ce champ est requis</span>
      </span>
    </dougs-form-field>
  </div>
  <div class="mb-24">
    <dougs-form-field>
      <label dougsFormFieldLabel>Message</label>
      <textarea autosize [required]="true" #ngMessage="ngModel" [(ngModel)]="message" dougsFormFieldControl></textarea>
      <span *ngIf="formService.isNgModelInvalid(ngMessage)" dougsFormFieldError>
        <span>Ce champ est requis</span>
      </span>
    </dougs-form-field>
  </div>
  <div>
    <label dougsFormFieldLabel>Pièces jointes</label>
    <p class="tiny" *ngIf="!projectAttachments || projectAttachments?.length === 0">Aucun fichier</p>
    <div class="file-list">
      <dougs-file-pill
        *ngFor="let attachment of projectAttachments; trackBy: trackById"
        [attachment]="attachment"
        (deleteFile)="onDeleteProjectAttachment($event)"
      ></dougs-file-pill>
    </div>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button color="primary" (click)="resendProjectStatus()">
    Transmettre les projets de statuts au client
  </dougs-button>
</div>
