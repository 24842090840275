import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarMessageComponent } from '@dougs/ds';
import { ReopeningData, Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-accounting-survey-reopening',
  templateUrl: './accounting-survey-reopening.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AvatarMessageComponent],
})
export class AccountingSurveyReopeningComponent {
  @Input()
  set task(task: Task) {
    if (task.metadata.reopeningData) {
      const reopeningDatas: ReopeningData[] = task.metadata.reopeningData;
      const lastReopeningData = reopeningDatas[reopeningDatas.length - 1];
      this.message = lastReopeningData.emailContent;
    }
  }

  message!: string;
}
