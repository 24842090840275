import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { toPromise } from '@dougs/core/utils';
import { ModalService } from '@dougs/ds';

@Injectable()
export class ControlPanelLabelsComponentService {
  constructor(
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
  ) {}

  kbisDocuments$: Observable<Attachment[]> = this.companyStateService.activeCompany$.pipe(
    map((company) => company?.kbis ?? []),
  );

  showKbisDocuments$: Observable<boolean> = this.kbisDocuments$.pipe(map((kbis) => kbis.length > 0));
  lastKbisDocument$: Observable<Attachment> = this.kbisDocuments$.pipe(
    map((kbisDocuments) => kbisDocuments[kbisDocuments.length - 1]),
  );

  statusDocuments$: Observable<Attachment[]> = this.companyStateService.activeCompany$.pipe(
    map((company) => company?.bylaws ?? []),
  );

  showStatusDocuments$: Observable<boolean> = this.statusDocuments$.pipe(map((status) => status.length > 0));
  laststatusDocument$: Observable<Attachment> = this.statusDocuments$.pipe(
    map((statusDocuments) => statusDocuments[statusDocuments.length - 1]),
  );

  inpiDocuments$: Observable<Attachment[]> = this.companyStateService.activeCompany$.pipe(
    map((company) => company?.inpiStatement ?? []),
  );

  showInpiDocuments$: Observable<boolean> = this.inpiDocuments$.pipe(map((inpi) => inpi.length > 0));
  lastInpiDocument$: Observable<Attachment> = this.inpiDocuments$.pipe(
    map((inpiDocuments) => inpiDocuments[inpiDocuments.length - 1]),
  );

  showDocuments$: Observable<boolean> = combineLatest([
    this.kbisDocuments$,
    this.statusDocuments$,
    this.inpiDocuments$,
  ]).pipe(map(([kbis, status, inpi]) => kbis.length > 0 || status.length > 0 || inpi.length > 0));

  async openCompanySummaryModal(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Control Panel Information Icon Clicked');
    const { CompanySummaryModalComponent } = await import('@dougs/company/ui');
    await toPromise(this.modalService.open(CompanySummaryModalComponent).afterClosed$);
  }

  trackUserSummariesModal(): void {
    this.metricsService.pushMixpanelEvent('Control Panel Conversations Icon Clicked');
  }
}
