import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, concatMap, filter, Observable, startWith, Subject, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { TASK_STATUS_FILTER, TaskScope } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { SelectedTabComponentService } from './selected-tab.component.service';

@Injectable()
export class ControlPanelSearchComponentService {
  formGroup = new FormGroup({
    search: new FormControl('', { nonNullable: true }),
    status: new FormControl<TASK_STATUS_FILTER>(TASK_STATUS_FILTER.ACTIVE_OR_DELEGATED, { nonNullable: true }),
    scope: new FormControl<TaskScope>(TaskScope.ALL_BUT_CUSTOMER, { nonNullable: true }),
  });

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly queueScroll: Subject<void> = new Subject<void>();
  private readonly queueScroll$ = this.queueScroll.asObservable();

  refreshTasks$: Observable<void> = combineLatest([
    this.companyStateService.activeCompanyIdChanged$,
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
    this.selectedTabComponentService.selectedTab$,
  ]).pipe(
    tap(() => this.isLoading.next(true)),
    filter(([, , selectedTab]) => selectedTab === 'tasks'),
    concatMap(([activeCompany, _]) =>
      this.controlPanelTasksStateService.refreshTasks(
        activeCompany.id,
        this.formGroup.value.scope,
        this.formGroup.value.status,
        this.formGroup.value.search,
      ),
    ),
    tap(() => this.isLoading.next(false)),
  );

  loadMoreTasks$: Observable<void> = this.queueScroll$.pipe(
    debounceTime(10),
    concatMap(() => this.controlPanelTasksStateService.loadMoreTasks()),
  );

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly selectedTabComponentService: SelectedTabComponentService,
  ) {}

  onScroll(): void {
    if (!this.isLoading.value) {
      this.queueScroll.next();
    }
  }
}
