import { Pipe, PipeTransform } from '@angular/core';

const MINIMUM_WIDTH = 10;
const DIGIT_WIDTH = 5;

@Pipe({
  name: 'showBarLabel',
  standalone: true,
})
export class ShowBarLabelPipe implements PipeTransform {
  transform(barWidth: number, widthPercentage: number, barNumber: number): boolean {
    if (!barWidth && widthPercentage && barNumber) {
      return true;
    }
    const barWidthInPx: number = (barWidth * widthPercentage) / 100;
    // 15px minimum for label and 5px per additional digit
    const limitWidth: number = MINIMUM_WIDTH + (barNumber.toString()?.length ?? 1) * DIGIT_WIDTH;
    return barWidthInPx >= limitWidth;
  }
}
