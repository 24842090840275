import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
import { PayrollSurveyComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerPayrollSurveyService extends TaskActionService {
  protected _label = 'Compléter';
  protected _isAvailable = true;
  protected _tag = 'Paramètres';
  protected _tagColor: Pill = 'admin';
  protected _tagIcon = 'fa-cog';

  constructor(
    private readonly modalService: ModalService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly companyStateService: CompanyStateService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const isUpdated: boolean | null | undefined = (
      await lastValueFrom(
        this.modalService.open<boolean>(PayrollSurveyComponent, {
          data: task,
        }).afterClosed$,
      )
    ).data;

    if (isUpdated) {
      await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
    }
  }
}
