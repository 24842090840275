<label dougsFormFieldLabel>Facture</label>
<div class="search-modal">
  <div class="form-field-line">
    <dougs-form-field>
      <input
        dougsFormFieldControl
        type="text"
        placeholder="Rechercher une facture..."
        [formControl]="searchInvoiceComponentService.searchInvoiceControl"
      />
      <i class="fal fa-search" dougsFormFieldSuffix></i>
    </dougs-form-field>
  </div>
  <div class="results" *ngIf="searchInvoiceComponentService.searchInvoiceValueChanges$ | async as invoices">
    <ul class="search-results">
      <li
        *ngFor="let invoice of invoices; trackBy: trackById"
        (click)="searchInvoiceComponentService.invoiceSearchResultClicked(invoice, index)"
        [ngClass]="{
          disable:
            (invoice | invoiceAlreadSelected: addBillingInvoiceItemModalComponentService.formBillableItem : index) ||
            !(
              invoice
              | invoiceHasCompliantPaidState: addBillingInvoiceItemModalComponentService.formBillableItem
              | async
            ) ||
            invoice.isDraft
        }"
      >
        <div>
          <div class="choice-result">
            <p class="small">
              {{ invoice.date | date: 'MMMM yyyy' | titlecase }}
            </p>
            <p class="small" [ngClass]="invoice.isPaid ? 'color-success' : 'color-error'">#{{ invoice.number }}</p>
            <p class="small">{{ invoice.amountExcludingTaxes }}&nbsp;€ HT</p>
            <p class="small">{{ invoice.amount }}&nbsp;€ TTC</p>
          </div>
          <div>
            <p class="tiny" *ngIf="invoice.isDraft">Facture brouillon</p>
            <p
              class="tiny"
              *ngIf="
                invoice | invoiceAlreadSelected: addBillingInvoiceItemModalComponentService.formBillableItem : index
              "
            >
              Facture déjà sélectionnée
            </p>
            <p
              class="tiny"
              *ngIf="
                !(
                  invoice
                  | invoiceHasCompliantPaidState: addBillingInvoiceItemModalComponentService.formBillableItem
                  | async
                )
              "
            >
              Impossible de choisir cette facture
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
