import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-accounting-initial-appointment-modal',
  templateUrl: './accounting-initial-appointment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    ModalFooterDirective,
    ButtonComponent,
    ModalCloseDirective,
  ],
})
export class AccountingInitialAppointmentModalComponent implements OnInit {
  link!: string;
  avatarUrl = 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  avatarFullName = 'Sarah Jaouani';

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    public readonly window: Window,
  ) {}

  ngOnInit(): void {
    this.link = this.data.task.metadata.calendlyUrl ? this.data.task.metadata.calendlyUrl : '';
  }

  redirectionCalendly(): void {
    this.window.open(this.link, '_blank');
  }
}
