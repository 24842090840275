import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent, DrawerCloseDirective } from '@dougs/ds';
import { MakeDocumentsAvailableComponentService } from '../../services/modals/make-documents-available.component.service';
import { AvailableDocumentsComponent } from './available-documents/available-documents.component';
import { SlotDocumentsSelectorComponent } from './slot-documents-selector/slot-documents-selector.component';
import { TaskHeaderSummaryComponent } from './task-header-summary/task-header-summary.component';

@Component({
  selector: 'dougs-make-documents-available',
  standalone: true,
  imports: [
    CommonModule,
    TaskHeaderSummaryComponent,
    ButtonComponent,
    DrawerCloseDirective,
    SlotDocumentsSelectorComponent,
    AvailableDocumentsComponent,
  ],
  providers: [MakeDocumentsAvailableComponentService],
  templateUrl: './make-documents-available.component.html',
  styleUrls: ['./make-documents-available.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakeDocumentsAvailableComponent {
  constructor(public readonly makeDocumentsAvailableComponentService: MakeDocumentsAvailableComponentService) {}
}
