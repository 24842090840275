import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { combineLatest, first, lastValueFrom, map, Observable } from 'rxjs';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import {
  CockpitComputableTabLink,
  CockpitComputedTabLink,
  CockpitDomain,
  CockpitPage,
  CockpitRouteToPayload,
  CockpitUnseenStats,
  TaskDepartment,
} from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Collaborator, Team, User } from '@dougs/user/dto';
import { TeamStateService, UserStateService } from '@dougs/user/shared';

@Injectable()
export class CockpitRouterComponentService {
  private readonly TAB_LINKS: CockpitComputableTabLink[] = [
    {
      page: 'unseen',
      title: 'Nouveautés',
      predicate: (domain: CockpitDomain): boolean => domain === 'collaborator',
      badgePredicate: (domain: CockpitDomain, unseenStats: CockpitUnseenStats): { show: boolean; value: number } => ({
        show: domain === 'collaborator' && unseenStats.tasks + unseenStats.mentions > 0,
        value: unseenStats.tasks + unseenStats.mentions,
      }),
    },
    {
      page: 'mentions',
      title: 'Mentions',
      predicate: (domain: CockpitDomain): boolean => domain === 'collaborator',
      badgePredicate: (domain: CockpitDomain, unseenStats: CockpitUnseenStats): { show: boolean } => ({
        show: domain === 'collaborator' && !!unseenStats.mentions,
      }),
    },
    {
      page: 'to-assign',
      title: 'À assigner',
      predicate: (domain: CockpitDomain): boolean => domain !== 'collaborator',
    },
    {
      page: 'to-do',
      title: 'À faire',
      badgePredicate: (domain: CockpitDomain, unseenStats: CockpitUnseenStats): { show: boolean } => ({
        show: domain === 'collaborator' && !!unseenStats.tasks,
      }),
    },
    {
      page: 'completed',
      title: 'Complétées',
    },
    {
      page: 'delegated',
      title: 'Déléguées',
      displayInMore: true,
    },
    {
      page: 'quotes',
      title: 'Devis',
      displayInMore: true,
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly cockpitStateService: CockpitStateService,
    private readonly userStateService: UserStateService,
    private readonly teamStateService: TeamStateService,
    private readonly routingService: RoutingService,
  ) {}

  readonly computedTabLinks$: Observable<CockpitComputedTabLink[]> = combineLatest([
    this.cockpitStateService.collaborator$,
    this.cockpitStateService.team$,
    this.cockpitStateService.department$,
    this.cockpitStateService.domain$,
    this.cockpitStateService.unseenStats$,
  ]).pipe(
    map(([collaborator, team, department, domain, unseenStats]) =>
      this.computeTabLinks(collaborator, team, department, domain, unseenStats),
    ),
  );

  private computeTabLinks(
    collaborator: Readonly<Collaborator> | null,
    team: Readonly<Team> | null,
    department: TaskDepartment | null,
    domain: CockpitDomain,
    unseenStats: CockpitUnseenStats,
  ): CockpitComputedTabLink[] {
    return this.TAB_LINKS.map<CockpitComputedTabLink | null>((tabLink: CockpitComputableTabLink) => {
      if (!domain || (tabLink.predicate && !tabLink.predicate(domain))) {
        return null;
      }

      let segments: string[] = [];

      switch (domain) {
        case 'collaborator':
          segments = this.createCollaboratorUrlSegments(collaborator?.userId ?? null, tabLink.page);
          break;
        case 'team':
          segments = this.createTeamUrlSegments(team?.userId ?? null, tabLink.page);
          break;
        case 'department':
          segments = this.createDepartmentUrlSegments(department ?? null, tabLink.page);
          break;
      }

      if (!segments.length) {
        return null;
      }

      const computedBadgePredicate: { show: boolean; value?: number } =
        tabLink.badgePredicate && !!unseenStats ? tabLink.badgePredicate(domain, unseenStats) : { show: false };

      return {
        segments,
        title: tabLink.title,
        displayInMore: tabLink.displayInMore,
        page: tabLink.page,
        shouldShowBadge: computedBadgePredicate.show,
        badgeValue: computedBadgePredicate.value,
      };
    }).filter(
      (computedTabLink: CockpitComputedTabLink | null): computedTabLink is CockpitComputedTabLink => !!computedTabLink,
    );
  }

  readonly isCollaboratorDomain$: Observable<boolean> = this.cockpitStateService.domain$.pipe(
    map((domain: CockpitDomain) => domain === 'collaborator'),
  );

  readonly isTeamDomain$: Observable<boolean> = this.cockpitStateService.domain$.pipe(
    map((domain: CockpitDomain) => domain === 'team'),
  );

  readonly isTeamOrDepartmentDomain$: Observable<boolean> = this.cockpitStateService.domain$.pipe(
    map((domain: CockpitDomain) => domain === 'team' || domain === 'department'),
  );

  readonly loggedInUserTeam$: Observable<Readonly<Team> | null> = combineLatest([
    this.userStateService.loggedInUser$,
    this.teamStateService.teams$,
  ]).pipe(map(([loggedInUser, teams]) => teams?.get(loggedInUser.teamId ?? 0) ?? null));

  readonly isCurrentTeamTheLoggedInUserTeam$: Observable<boolean> = combineLatest([
    this.cockpitStateService.team$,
    this.userStateService.loggedInUser$,
    this.teamStateService.teams$,
  ]).pipe(
    map(([currentTeam, loggedInUser, teams]) =>
      this.isCurrentTeamTheLoggedInUserTeam(currentTeam, loggedInUser, teams),
    ),
  );

  private isCurrentTeamTheLoggedInUserTeam(
    currentTeam: Readonly<Team> | null,
    loggedInuser: User,
    teams: ReadonlyMap<number, Readonly<Team>> | undefined,
  ): boolean {
    if (!currentTeam) {
      return false;
    }

    const loggedInUserTeam: Readonly<Team> | undefined = teams?.get(loggedInuser.teamId ?? 0);

    if (!loggedInUserTeam) {
      return false;
    }

    return loggedInUserTeam.id === currentTeam.id;
  }

  async routeToLoggedInCollaborator(page?: CockpitPage): Promise<void> {
    const loggedInUserId: number | undefined = (await lastValueFrom(this.userStateService.loggedInUser$.pipe(first())))
      ?.id;
    if (loggedInUserId) {
      await this.routeTo({ domain: 'collaborator', id: loggedInUserId, page });
    }
  }

  async routeToLoggedInCollaboratorTeam(page: CockpitPage): Promise<void> {
    const loggedInUserTeam: Readonly<Team> | null = await toPromise(this.loggedInUserTeam$);

    if (!loggedInUserTeam) {
      return; // CockpitError
    }

    return this.routeToTeam(loggedInUserTeam.userId, page);
  }

  async routeToTeam(teamUserId: number, page: CockpitPage): Promise<void> {
    await this.routeTo({ domain: 'team', id: teamUserId, page });
  }

  async routeToDepartment(department: TaskDepartment, page: CockpitPage): Promise<void> {
    await this.routeTo({ domain: 'department', department, page });
  }

  async routeTo(payload: CockpitRouteToPayload): Promise<void> {
    const urlTree: UrlTree | null = await this.getUrlTree(payload);

    if (urlTree) {
      await this.router.navigateByUrl(urlTree);
    }
  }

  async getUrlTree(payload: CockpitRouteToPayload): Promise<UrlTree | null> {
    const domain: CockpitDomain | undefined = payload.domain ?? this.cockpitStateService.get('domain');

    const page: CockpitPage | undefined = payload.page ?? this.cockpitStateService.get('page');

    if (!domain || !page) {
      return null;
    }

    let segments: string[] = [];

    if (domain === 'collaborator') {
      const collaboratorUserId: number | undefined = payload.id ?? this.cockpitStateService.get('collaborator')?.userId;
      segments = this.createCollaboratorUrlSegments(collaboratorUserId ?? null, page);
    }

    if (domain === 'team') {
      const teamUserId: number | undefined = payload.id ?? this.cockpitStateService.get('team')?.userId;
      segments = this.createTeamUrlSegments(teamUserId ?? null, page);
    }

    if (domain === 'department') {
      const department: TaskDepartment | undefined | null =
        payload.department ?? this.cockpitStateService.get('department');
      segments = this.createDepartmentUrlSegments(department ?? null, page);
    }

    if (segments.length) {
      return this.routingService.createUrl([URL.COCKPIT, ...segments]);
    }

    return null;
  }

  createCollaboratorUrlSegments(collaboratorUserId: number | null, page: CockpitPage): string[] {
    return ['collaborator', `${collaboratorUserId ?? 0}`, page];
  }

  createTeamUrlSegments(teamUserId: number | null, page: CockpitPage): string[] {
    return ['team', `${teamUserId ?? 0}`, page];
  }

  createDepartmentUrlSegments(department: TaskDepartment | null, page: CockpitPage): string[] {
    return ['department', department ?? '', page];
  }
}
