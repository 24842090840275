<dougs-form-field [noMargin]="true">
  <label for="packedLunchCount" dougsFormFieldLabel>Nombre de paniers repas</label>
  <input
    [name]="'packedLunchCount' + uuid"
    #packedLunchCount="ngModel"
    dougsFormFieldControl
    min="0"
    step="1"
    [disabled]="!isEditable"
    [(ngModel)]="variableData.packedLunchCount"
    [required]="true"
    type="number"
    id="packedLunchCount"
  />
  <span dougsFormFieldError *ngIf="formService.isNgModelInvalid(packedLunchCount)">
    <ng-container *ngIf="packedLunchCount.errors.required">Ce champ est requis</ng-container>
    <ng-container *ngIf="packedLunchCount.errors.min">Le montant doit être un nombre positif</ng-container>
  </span>
</dougs-form-field>
