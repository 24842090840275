import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { concatMap, distinctUntilKeyChanged, filter, Observable, of, Subject, switchMap, withLatestFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { toPromise } from '@dougs/core/utils';
import { ConfirmationModalComponent, FlashMessagesService, ModalService } from '@dougs/ds';
import {
  CollaboratorVariable,
  CollaboratorVariableFormGroup,
  CollaboratorVariableType,
  Payslip,
  PayslipInfo,
  PayslipInfoStatus,
} from '@dougs/task/dto';
import { PayslipStateService } from '@dougs/task/shared';
import { CollaboratorCardFormUtils } from './collaborator-card-form.utils';
import { CollaboratorPayslipMetricsComponentService } from './collaborator-payslip-metrics.component.service';
import { CollaboratorPayslipModalComponentService } from './collaborator-payslip-modal.component.service';
import { CustomerFormComponentService } from './customer-form.component.service';
import { PayslipFormComponentService } from './payslip-form.component.service';

@Injectable()
export class CollaboratorFormComponentService {
  constructor(
    private readonly collaboratorPayslipModalComponentService: CollaboratorPayslipModalComponentService,
    private readonly payslipFormComponentService: PayslipFormComponentService,
    private readonly customerFormComponentService: CustomerFormComponentService,
    private readonly collaboratorPayslipMetricsComponentService: CollaboratorPayslipMetricsComponentService,
    private readonly payslipStateService: PayslipStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  formArray: FormArray<FormGroup<CollaboratorVariableFormGroup>> = new FormArray<
    FormGroup<CollaboratorVariableFormGroup>
  >([]);

  private readonly catalogLoading: WritableSignal<boolean> = signal(true);
  catalogLoading$: Signal<boolean> = this.catalogLoading.asReadonly();

  catalog: CollaboratorVariable[] = [];

  refreshCatalog$: Observable<CollaboratorVariable[]> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.catalogLoading.set(true)),
    concatMap((company) => this.payslipStateService.refreshVariablesCatalog(company.id)),
    tap((catalog) => (this.catalog = catalog)),
    tap(() => this.catalogLoading.set(false)),
  );

  saveCollaboratorVariables$: Observable<void> = this.formArray.valueChanges.pipe(
    withLatestFrom(this.payslipFormComponentService.payslip$),
    filter((object): object is [CollaboratorVariable[], Payslip] => !!object?.[1]),
    switchMap(([collaboratorVariables, payslip]) =>
      of(this.payslipStateService.saveCollaboratorVariables(payslip.companyId, payslip.id, collaboratorVariables)).pipe(
        map(() => collaboratorVariables),
      ),
    ),
    map((collaboratorVariables) =>
      this.payslipStateService.updatePayslipLineStatus(
        this.collaboratorPayslipModalComponentService.selectedPayslipLineValue as PayslipInfo,
        this.getPayslipInfosStatus(collaboratorVariables),
      ),
    ),
  );

  populateFormArray$: Observable<void> = this.payslipFormComponentService.payslip$.pipe(
    filter((payslip): payslip is Payslip => !!payslip),
    distinctUntilKeyChanged('id'),
    map((payslip) => this.populateFormArray(payslip)),
  );

  groupedVariablesCatalog$ = this.payslipStateService.catalog$.pipe(map((catalog) => _.groupBy(catalog, 'type')));

  finalizeEmployeeForm$: Observable<void> = this.collaboratorPayslipModalComponentService.finalizeEmployeeForm$.pipe(
    concatMap(() => this.finalizeEmployeeForm()),
  );

  formArrayHasBeenTouched: Subject<number> = new Subject<number>();
  formArrayHasBeenTouched$: Observable<number> = this.formArrayHasBeenTouched.asObservable();

  markSelectedItemAsTouched: Subject<boolean> = new Subject<boolean>();
  markSelectedItemAsTouched$: Observable<boolean> = this.markSelectedItemAsTouched.asObservable();

  keepOriginalOrder(): number {
    return 0;
  }

  async addNewVariable(): Promise<void> {
    const newFormGroup: FormGroup<CollaboratorVariableFormGroup> = new FormGroup<CollaboratorVariableFormGroup>({
      type: new FormControl<CollaboratorVariableType>(CollaboratorVariableType.UNKNOWN, { nonNullable: true }),
      code: new FormControl<string | undefined>(undefined, { validators: Validators.required, nonNullable: true }),
      description: new FormControl<string>('', { nonNullable: true }),
    });
    this.formArray.push(newFormGroup);
    await this.trackAddCollaboratorVariable();
  }

  populateFormArray(payslip: Payslip): void {
    this.formArray.clear({ emitEvent: false });
    payslip?.collaboratorVariables?.forEach((collaboratorVariable) =>
      this.formArray.push(this.createCollaboratorVariableFormGroup(collaboratorVariable), { emitEvent: false }),
    );
  }

  createCollaboratorVariableFormGroup(
    collaboratorVariable: CollaboratorVariable,
  ): FormGroup<CollaboratorVariableFormGroup> {
    const newFormGroup: FormGroup<CollaboratorVariableFormGroup> = new FormGroup<CollaboratorVariableFormGroup>({
      type: new FormControl<CollaboratorVariableType>(collaboratorVariable.type, { nonNullable: true }),
      code: new FormControl<string>(collaboratorVariable.code, { validators: Validators.required, nonNullable: true }),
      description: new FormControl<string>(collaboratorVariable.description, { nonNullable: true }),
    });
    if (collaboratorVariable.isNative !== undefined) {
      newFormGroup.addControl(
        'isNative',
        new FormControl<boolean>(collaboratorVariable.isNative, { nonNullable: true }),
      );
    }
    if (collaboratorVariable.isCustom !== undefined) {
      newFormGroup.addControl(
        'isCustom',
        new FormControl<boolean>(collaboratorVariable.isCustom, { nonNullable: true }),
      );
    }
    switch (collaboratorVariable.type) {
      case CollaboratorVariableType.ABSENCE:
        CollaboratorCardFormUtils.addAbsenceControls(newFormGroup, collaboratorVariable);
        break;
      case CollaboratorVariableType.DEPOSIT:
        CollaboratorCardFormUtils.addDepositControls(newFormGroup, collaboratorVariable);
        break;
      case CollaboratorVariableType.HOUR:
        CollaboratorCardFormUtils.addHourControls(newFormGroup, collaboratorVariable);
        break;
      case CollaboratorVariableType.PRIME:
        CollaboratorCardFormUtils.addPrimeControls(newFormGroup, collaboratorVariable);
        break;
      default:
        break;
    }
    return newFormGroup;
  }

  async finalizeEmployeeForm(): Promise<void> {
    this.formArray.markAllAsTouched();
    this.formArrayHasBeenTouched.next(new Date().getTime());
    this.markSelectedItemAsTouched.next(true);
    if (this.formArray.invalid) {
      this.flashMessagesService.show('Les informations saisies ne sont pas conformes.', { type: 'error' });
    } else if (this.collaboratorPayslipModalComponentService.selectedPayslipLineValue) {
      await this.trackFinalizeEmployeeForm();
      await this.payslipStateService.validateCollaboratorVariables(
        this.collaboratorPayslipModalComponentService.task.companyId,
        this.collaboratorPayslipModalComponentService.selectedPayslipLineValue.id,
      );
      this.payslipStateService.updatePayslipLineStatus(
        this.collaboratorPayslipModalComponentService.selectedPayslipLineValue,
        PayslipInfoStatus.COMPLETED,
      );
    }
  }

  async removeVariableCard(formGroupIndex: number): Promise<void> {
    const toDelete = !!(
      await toPromise(
        this.modalService.open(ConfirmationModalComponent, {
          data: {
            title: 'Suppression d’une variable',
            body: 'Voulez-vous vraiment supprimer la variable ?',
            yesText: 'Supprimer la variable',
            noText: 'Annuler',
          },
        }).afterClosed$,
      )
    ).data;
    if (toDelete) {
      this.formArray.removeAt(formGroupIndex);
      this.formArrayHasBeenTouched.next(new Date().getTime());
      await this.trackRemoveCollaboratorVariable();
    }
  }

  private async trackFinalizeEmployeeForm(): Promise<void> {
    const payslip: Payslip | null = await toPromise(this.payslipFormComponentService.payslip$);
    const customerVariablesNumber: number | undefined = await toPromise(
      this.customerFormComponentService.customerVariablesNbr$,
    );
    const collaboratorVariablesNbr: number | undefined = this.formArray?.controls?.length;
    this.collaboratorPayslipMetricsComponentService.trackFinalizeEmployeeForm(
      payslip,
      customerVariablesNumber,
      collaboratorVariablesNbr,
    );
  }

  private async trackAddCollaboratorVariable(): Promise<void> {
    const payslip: Payslip | null = await toPromise(this.payslipFormComponentService.payslip$);
    const customerVariablesNumber: number | undefined = await toPromise(
      this.customerFormComponentService.customerVariablesNbr$,
    );
    const collaboratorVariablesNbr: number | undefined = this.formArray?.controls?.length;
    this.collaboratorPayslipMetricsComponentService.trackAddCollaboratorVariable(
      payslip,
      customerVariablesNumber,
      collaboratorVariablesNbr,
    );
  }

  private async trackRemoveCollaboratorVariable(): Promise<void> {
    const payslip: Payslip | null = await toPromise(this.payslipFormComponentService.payslip$);
    const customerVariablesNumber: number | undefined = await toPromise(
      this.customerFormComponentService.customerVariablesNbr$,
    );
    const collaboratorVariablesNbr: number | undefined = this.formArray?.controls?.length;
    this.collaboratorPayslipMetricsComponentService.trackRemoveCollaboratorVariable(
      payslip,
      customerVariablesNumber,
      collaboratorVariablesNbr,
    );
  }

  getItemFromCatalogByCode(code: string): CollaboratorVariable | undefined {
    return this.catalog.find((item) => item.code === code);
  }

  private getPayslipInfosStatus(collaboratorVariables: CollaboratorVariable[]): PayslipInfoStatus {
    return collaboratorVariables?.length ? PayslipInfoStatus.STARTED : PayslipInfoStatus.NOT_STARTED;
  }
}
