import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@dougs/task/dto';

@Pipe({
  name: 'activitiesDivider',
  standalone: true,
})
export class ActivitiesDividerPipe implements PipeTransform {
  transform(activities: Activity[], index: number): boolean {
    if (index === 0) {
      return true;
    }

    const previousDate: Date = new Date(activities[index - 1].date);
    const currentDate: Date = new Date(activities[index].date);

    return (
      previousDate.getFullYear() !== currentDate.getFullYear() || previousDate.getMonth() !== currentDate.getMonth()
    );
  }
}
