import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { FormService } from '@dougs/core/form';
import { MetricsService } from '@dougs/core/metrics';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FilePillComponent,
  FlashMessagesService,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-legal-creation-modal',
  templateUrl: './legal-creation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    FormsModule,
    ControlFormFieldDirective,
    NgIf,
    ErrorFormFieldDirective,
    AutosizeModule,
    NgFor,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class LegalCreationModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA) public task: Task,
    public readonly formService: FormService,
    private readonly companyStateService: CompanyStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly metricsService: MetricsService,
    private readonly modalRef: ModalRef,
  ) {}

  public subject!: string;
  public message!: string;
  public projectAttachments!: Attachment[];

  ngOnInit(): void {
    this.subject = `Vos projets de statuts modifiés`;
    this.message = `Bonjour ${this.task?.target?.profile?.firstName},
    \n\nVoici vos projets de statuts modifiés comme convenu ensemble.
    \n\nJe reste disponible pour toute question complémentaire.
    \n\nTrès bonne journée et à bientôt !`;
    this.projectAttachments =
      this.task?.attachments?.filter((attachment) => attachment.type === 'legal:creationProject') ?? [];
  }

  onDeleteProjectAttachment(attachment: Attachment): void {
    this.projectAttachments = this.projectAttachments.filter((a) => a.id !== attachment.id);
  }

  async resendProjectStatus(): Promise<void> {
    if (!this.message || !this.subject) {
      return;
    }
    const companyId: number = this.task?.companyId;
    const attachmentIds: number[] = this.projectAttachments?.map((a) => Number(a.id));
    const transmitSuccess: boolean = await this.companyStateService.resendCompanyStatusProject(
      companyId,
      attachmentIds,
      this.message,
      this.subject,
    );
    if (!transmitSuccess) {
      this.flashMessagesService.show(`Erreur lors de l'envoi de l'e-mail.`, { type: 'error' });
    } else {
      this.metricsService.pushMixpanelEvent('Legal Creation Task Status Project Sent');
      this.flashMessagesService.show(`E-mail envoyé avec succès.`, { type: 'success' });
      this.modalRef.close(true);
    }
  }

  trackById(index: number, item: Attachment): number | string {
    return item.id;
  }
}
