import { Injectable } from '@angular/core';
import { Pill } from '@dougs/ds';
import { AccountMergeTaskService } from '@dougs/synchronized-accounts/ui';
import { AccountMergeTask } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerUpdateBankInformationsService extends TaskActionService {
  protected _isAvailable = true;
  protected _tag = 'Banque';
  protected _tagColor: Pill = 'success';
  protected _tagIcon = 'fa-university';

  constructor(private readonly accountMergeTaskService: AccountMergeTaskService) {
    super();
  }

  getLabel(task?: AccountMergeTask): string {
    if (task) {
      return this.accountMergeTaskService.getActionWording(task);
    }

    return super.getLabel(task);
  }

  async execute(task: AccountMergeTask): Promise<void> {
    return this.accountMergeTaskService.handleAccountMergeTask(task);
  }
}
