import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService, OverlayCloseEvent, Pill } from '@dougs/ds';
import { SocialMissingFieldsModalComponent } from '@dougs/fields/ui';
import { UserTasksStateService } from '@dougs/task/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerPayrollByDougsService extends TaskActionService {
  protected _label = 'Compléter';
  protected _isAvailable = true;
  protected _tag = 'Paramètres';
  protected _tagColor: Pill = 'admin';
  protected _tagIcon = 'fa-cog';

  constructor(
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {
    super();
  }

  async execute(): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService.open(SocialMissingFieldsModalComponent, {
        data: {
          company: this.companyStateService.activeCompany,
        },
      }).afterClosed$,
    );
    const hasBeenCompleted = !!modalResult?.data;
    if (hasBeenCompleted) {
      await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
    }
  }
}
