import { Inject, Injectable } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { MODAL_DATA } from '@dougs/ds';
import { Payslip, PayslipInfo, Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class CollaboratorPayslipMetricsComponentService {
  constructor(
    @Inject(MODAL_DATA) public readonly task: Task,
    private readonly metricsService: MetricsService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
  ) {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Viewed', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
    });
  }

  trackOpenEmployeeForm(payslipLine: PayslipInfo | null): void {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Employee Form Selected', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
      'Payslip Line Id': payslipLine?.id,
      'Payslip Status': payslipLine?.status,
    });
  }

  trackBackToPayslipList(selectedPayslipLine: PayslipInfo | null): void {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Back To Payslip List', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
      'Payslip Line Id': selectedPayslipLine?.id,
      'Payslip Status': selectedPayslipLine?.status,
    });
  }

  trackFinalizeEmployeeForm(
    payslip: Payslip | null,
    customerVariablesNbr: number | undefined,
    collaboratorVariablesNbr: number | undefined,
  ): void {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Employee Form Finalized', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
      'Payslip Line Id': payslip?.id,
      'Number Of Customer Variables': customerVariablesNbr,
      'Number Of Collaborator Variables': collaboratorVariablesNbr,
    });
  }

  trackUncompletePayslipLine(selectedPayslipLineValue: PayslipInfo): void {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Employee Form Uncompleted', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
      'Payslip Line Id': selectedPayslipLineValue?.id,
      'Payslip Status': selectedPayslipLineValue?.status,
    });
  }

  trackFinalizePayslipModal(payslipLines: PayslipInfo[]): void {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Finalized', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
      'Number Of Employee Lines': payslipLines.length,
    });
  }

  trackAddCollaboratorVariable(
    payslip: Payslip | null,
    customerVariablesNumber: number | undefined,
    collaboratorVariablesNbr: number,
  ): void {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Add Collaborator Variable', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
      'Payslip Id': payslip?.id,
      'Number Of Customer Variables': customerVariablesNumber,
      'Number Of Collaborator Variables': collaboratorVariablesNbr,
    });
  }

  trackRemoveCollaboratorVariable(
    payslip: Payslip | null,
    customerVariablesNumber: number | undefined,
    collaboratorVariablesNbr: number,
  ): void {
    this.metricsService.pushMixpanelEvent('Social Collaborator Payslip Modal Remove Collaborator Variable', {
      'Payslip Date': this.task.metadata.payslipDate,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
      'Company Id': this.task.companyId,
      'Root Task Id': this.task.rootId,
      'Payslip Id': payslip?.id,
      'Number Of Customer Variables': customerVariablesNumber,
      'Number Of Collaborator Variables': collaboratorVariablesNbr,
    });
  }
}
