import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerRemoteDeclarationPaymentRejectedService extends TaskActionService {
  protected _label = 'Modifier les infos';
  protected _isAvailable = true;
  protected _tag = 'Déclarations';
  protected _tagColor: Pill = 'primary';
  protected _tagIcon = 'fa-scroll';

  constructor(
    private readonly router: Router,
    private readonly routingService: RoutingService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    await this.router.navigateByUrl(
      this.routingService.createUrl([URL.TASKS], true, {
        queryParams: {
          taskWithActionId: task?.id,
        },
      }),
    );
  }
}
