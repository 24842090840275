@if (appearance === 'icon') {
  <div class="icon-layout">
    @if (taskCustomersConversationsComponentService.customerConversations$().length > 0) {
      <div
        class="p-8"
        [ngClass]="
          taskCustomersConversationsComponentService.hasUnSnoozedConversation$()
            ? 'active-conversation'
            : 'snoozed-conversation-icon'
        "
        [dougsDropdownTrigger]="conversationsDropdown"
        [dougsDropdownDisabled]="taskCustomersConversationsComponentService.customerConversations$().length === 1"
        (click)="taskCustomersConversationsComponentService.onIconClick($event)"
      >
        <i class="fa-brands fa-intercom"></i>
      </div>
    }
  </div>
} @else {
  <div class="detail-layout p-8">
    @for (conv of taskCustomersConversationsComponentService.customerConversations$(); track conv.id) {
      <span
        [class.active-conversation]="!conv.snoozed"
        (click)="taskCustomersConversationsComponentService.openConversationNewTab(conv.url)"
      >
        <i class="fa-brands fa-intercom"></i>
        Conv. ouverte le {{ conv.firstOpenedAt | date: 'dd/MM/yy' }}
      </span>
    }
  </div>
}
<dougs-dropdown #conversationsDropdown [widthToRefElement]="false">
  @for (conv of taskCustomersConversationsComponentService.customerConversations$(); track conv.id) {
    <dougs-dropdown-option
      (click)="taskCustomersConversationsComponentService.openConversationNewTab(conv.url)"
      [class.active-conversation]="!conv.snoozed"
    >
      <i class="fal fa-arrow-up-right-from-square mr-8"></i>
      Conv. ouverte le {{ conv.firstOpenedAt | date: 'dd/MM/yy' }}
    </dougs-dropdown-option>
  }
</dougs-dropdown>
