import { AsyncPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  SelectComponent,
  SelectOptionComponent,
  TrackByPipe,
} from '@dougs/ds';
import { FieldComponent } from '@dougs/fields/ui';
import { SuggestProductModalService } from '../../services/modals/suggest-product-modal.service';

@Component({
  selector: 'suggest-product-modal',
  templateUrl: './suggest-product-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SuggestProductModalService],
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgFor,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    FormsModule,
    FormFieldComponent,
    ReactiveFormsModule,
    ControlFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
    FieldComponent,
    TrackByPipe,
    LoaderComponent,
    TitleCasePipe,
  ],
})
export class SuggestProductModalComponent {
  constructor(
    public readonly suggestProductModalService: SuggestProductModalService,
    public readonly companyStateService: CompanyStateService,
  ) {}
}
