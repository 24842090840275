import { Routes } from '@angular/router';

export const SUBSCRIPTION_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/referrer/referrer-dashboard/referrer-dashboard.component').then(
        (c) => c.ReferrerDashboardComponent,
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
