<div dougsModalTitle>
  <h6>Prenez votre rendez-vous comptable initial</h6>
</div>
<div dougsModalContent>
  <dougs-avatar-message [avatarUrl]="avatarUrl" [fullName]="avatarFullName" size="medium">
    <p class="small mb-8">
      Au début de votre période d'essai Dougs, vous bénéficiez d'un rendez-vous comptable gratuit pour faire un point
      complet sur votre projet et paramétrer votre espace Dougs.
    </p>
    <p class="small mb-8">
      Je vous laisse prendre rendez-vous directement
      <a rel="noopener" [href]="link" target="_blank">dans le calendrier de votre comptable.</a>
    </p>
    <p class="small mb-8">
      Ce rendez-vous est très important pour que vous puissiez tirer parti de toutes les possibilités de Dougs, je
      compte sur vous pour en profiter !
    </p>
  </dougs-avatar-message>
</div>

<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Me le rappeler une prochaine fois</dougs-button>
  <dougs-button (click)="redirectionCalendly()" dougsModalClose>
    Choisir mon créneau de rendez-vous&nbsp;&rarr;
  </dougs-button>
</div>
