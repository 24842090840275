import { Routes } from '@angular/router';
import { CanAccessTasksGuard } from '../guards/tasks.guard';

export const TASKS_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('../components/tasks/tasks.component').then((c) => c.TasksComponent),
    canActivate: [CanAccessTasksGuard],
  },
];
