import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { ModalService } from '@dougs/ds';
import { PayslipSocialInfos } from '@dougs/task/dto';
import { Profile } from '@dougs/user/dto';
import { CollaboratorPayslipModalComponentService } from './collaborator-payslip-modal.component.service';
import { PayslipFormComponentService } from './payslip-form.component.service';

@Injectable()
export class EmployeeInfosComponentService {
  constructor(
    private readonly payslipFormComponentService: PayslipFormComponentService,
    private readonly collaboratorPayslipModalComponentService: CollaboratorPayslipModalComponentService,
    private readonly modalService: ModalService,
  ) {}

  partnerProfile$: Signal<Profile | undefined> = toSignal(
    this.payslipFormComponentService.payslipInfo$.pipe(map((payslipInfo) => payslipInfo?.profile)),
  );

  partnerSocialInfos$: Signal<PayslipSocialInfos | undefined> = toSignal(
    this.payslipFormComponentService.payslipInfo$.pipe(map((payslipInfo) => payslipInfo?.socialInfo)),
  );

  partnerId$: Signal<number | undefined> = toSignal(
    this.payslipFormComponentService.payslipInfo$.pipe(map((payslipInfo) => payslipInfo?.partnerId)),
  );

  async openPartnerModal(): Promise<void> {
    if (this.partnerId$()) {
      const { PartnerModalComponent } = await import('@dougs/partners/ui');
      this.modalService.open(PartnerModalComponent, {
        data: {
          partnerId: this.partnerId$(),
          companyId: this.collaboratorPayslipModalComponentService.task.targetId,
        },
      });
    }
  }
}
