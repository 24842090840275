<div class="title-container">
  <h5>Variables de paie à saisir</h5>
  <p class="small color-primary-700">
    &nbsp;({{ collaboratorPayslipModalComponentService.payslipLinesNumber$ | async }})
  </p>
</div>
<div class="payslip-lines mb-24 mt-16">
  @for (payslipLine of payslipStateService.payslipLines$ | async; track 'id') {
    <dougs-payslip-line [payslipLine]="payslipLine" />
  }
</div>
