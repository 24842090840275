import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CockpitStateService } from '@dougs/task/shared';
import { CockpitAccountingStatsResponsiveComponentService } from '../../../services/cockpit/cockpit-stats/cockpit-accounting-stats-responsive.component.service';
import { CockpitAccountingStatsComponentService } from '../../../services/cockpit/cockpit-stats/cockpit-accounting-stats.component.service';
import { CockpitStatsCollaboratorComponentService } from '../../../services/cockpit/cockpit-stats/cockpit-stats-collaborator.component.service';
import { CockpitStatsDepartmentComponentService } from '../../../services/cockpit/cockpit-stats/cockpit-stats-department.component.service';
import { CockpitStatsTeamComponentService } from '../../../services/cockpit/cockpit-stats/cockpit-stats-team.component.service';
import { CockpitStatsComponentService } from '../../../services/cockpit/cockpit-stats/cockpit-stats.component.service';
import { CockpitComponentService } from '../../../services/cockpit/cockpit.component.service';
import { CockpitAccountingStatsComponent } from './cockpit-accounting-stats/cockpit-accounting-stats.component';
import { CockpitStatsComponent } from './cockpit-stats/cockpit-stats.component';
import { CockpitTabsComponent } from './cockpit-tabs/cockpit-tabs.component';

@Component({
  selector: 'dougs-cockpit-header',
  standalone: true,
  imports: [CommonModule, CockpitStatsComponent, CockpitTabsComponent, CockpitAccountingStatsComponent],
  providers: [CockpitAccountingStatsComponentService, CockpitAccountingStatsResponsiveComponentService],
  templateUrl: './cockpit-header.component.html',
  styleUrls: ['./cockpit-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0', visibility: 'hidden' }),
        animate('200ms ease-in-out', style({ height: '*', visibility: 'visible' })),
      ]),
      transition(':leave', [
        style({ height: '*', visibility: 'visible', opacity: 0 }),
        animate('200ms ease-in-out', style({ height: '0', visibility: 'hidden' })),
      ]),
    ]),
  ],
})
export class CockpitHeaderComponent {
  constructor(
    public readonly cockpitStatsComponentService: CockpitStatsComponentService,
    public readonly cockpitStatsCollaboratorComponentService: CockpitStatsCollaboratorComponentService,
    public readonly cockpitStatsTeamComponentService: CockpitStatsTeamComponentService,
    public readonly cockpitStatsDepartmentComponentService: CockpitStatsDepartmentComponentService,
    public readonly cockpitStateService: CockpitStateService,
    public readonly cockpitComponentService: CockpitComponentService,
    public readonly accountingStatsComponentService: CockpitAccountingStatsComponentService,
  ) {}

  public showCockpitStats = true;
}
