import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'taskActionAvatarUrl',
  standalone: true,
})
export class TaskActionAvatarUrlPipe implements PipeTransform {
  transform(task: Task): string {
    if (task.metadata.collaborator?.avatarUrl) {
      return task.metadata.collaborator.avatarUrl;
    }
    return 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  }
}
