import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'activeSubtaskTitle',
  standalone: true,
})
export class ActiveSubtaskTitlePipe implements PipeTransform {
  transform(task: Task): string {
    return task.activeSubTaskTitle
      ? task.activeSubTaskTitle
      : task.subTaskCount > 0
        ? 'Toutes les sous-tâches sont complétées'
        : '';
  }
}
