import { Pipe, PipeTransform } from '@angular/core';
import { SlotDocuments } from '@dougs/task/dto';

@Pipe({
  name: 'allSlotsSelected',
  standalone: true,
})
export class AllSlotsSelectedPipe implements PipeTransform {
  transform(slotDocuments: SlotDocuments[], selectedAttachments: (number | string)[]): boolean {
    return slotDocuments.every((slotDocuments) =>
      slotDocuments.attachments.every((attachment) => selectedAttachments.includes(attachment.id)),
    );
  }
}
