import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { endOfDay, format, isBefore, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { AvatarMessageComponent } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { User } from '@dougs/user/dto';

@Component({
  selector: 'dougs-accounting-survey-liquidating',
  templateUrl: './accounting-survey-liquidating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AvatarMessageComponent, NgIf],
})
export class AccountingSurveyLiquidatingComponent {
  @Input()
  set task(task: Task) {
    const closingDate = endOfDay(new Date(task.metadata.closingDate));

    this.isSameOrBeforeClosingDate = isBefore(new Date(), closingDate) || isSameDay(new Date(), closingDate);
    this.closingDate = format(closingDate, 'dd MMMM yyyy', { locale: fr });
  }

  @Input() user!: User;

  closingDate!: string;
  isSameOrBeforeClosingDate = true;
}
