import { Injectable } from '@angular/core';
import { AccountBalances, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { Task } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class BankReconciliationService {
  shouldBalanceBeChecked(balance: AccountBalances, accountReconciliationTask: Task | null) {
    return (
      accountReconciliationTask?.metadata.reconciliationDate &&
      accountReconciliationTask?.metadata.reconciliationDate.startsWith(balance.triggerDate)
    );
  }

  getResumeReconciliationAccountsIds(
    synchronizedAccounts: SynchronizedAccount[],
    task: Task,
  ): { [accountId: number]: boolean } {
    return synchronizedAccounts.reduce(
      (resumeReconciliationAccountsIds: { [accountId: number]: boolean }, synchronizedAccount) => {
        return {
          ...resumeReconciliationAccountsIds,
          [synchronizedAccount.id]:
            (synchronizedAccount.metadata.reconciliation?.step || 1) >= 2 &&
            !!synchronizedAccount.metadata.balances?.some((balance) => this.shouldBalanceBeChecked(balance, task)),
        };
      },
      {},
    );
  }
}
