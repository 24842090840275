import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeComponent, MinInHourPipe } from '@dougs/ds';

@Component({
  selector: 'dougs-cockpit-stats-task-tooltip-row',
  standalone: true,
  imports: [CommonModule, BadgeComponent, MinInHourPipe],
  templateUrl: './cockpit-stats-task-tooltip-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitStatsTaskTooltipRowComponent {
  @Input() badgeType = 'error';
  @Input() title = '';
  @Input() shouldShowHours = false;
  @Input() workload = 0;
  @Input() count = 0;
}
