import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AutosizeModule } from 'ngx-autosize';
import {
  AvatarComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  ConvertMentionPipe,
  DividerComponent,
  DougsDatePipe,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TargetBlankInHrefPipe,
  TooltipDirective,
  TrackByPipe,
  WrapUrlsInHtmlPipe,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ModalTaskStateService, QualityControlRemarksStateService } from '@dougs/task/shared';
import { TaskFormFieldsComponent } from '../../components/control-panel/task/task-form-fields/task-form-fields.component';
import { TaskComponent } from '../../components/control-panel/task/task.component';
import { CommentIsDeletedPipe } from '../../pipes/control-panel/task/comment-is-deleted.pipe';
import { QualityControlComponentService } from './quality-control.component.service';

@Component({
  selector: 'dougs-quality-control-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    TaskFormFieldsComponent,
    TaskComponent,
    AvatarComponent,
    TrackByPipe,
    TooltipDirective,
    CommentIsDeletedPipe,
    ConvertMentionPipe,
    TargetBlankInHrefPipe,
    WrapUrlsInHtmlPipe,
    DividerComponent,
    AutosizeModule,
    ControlFormFieldDirective,
    LoaderComponent,
    DougsDatePipe,
  ],
  providers: [QualityControlComponentService],
  templateUrl: './quality-control-modal.component.html',
  styleUrls: ['./quality-control-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualityControlModalComponent {
  constructor(
    @Inject(MODAL_DATA) public readonly task: Task,
    public readonly qualityControlComponentService: QualityControlComponentService,
    public readonly modalTaskStateService: ModalTaskStateService,
    public readonly qualityControlRemarksStateService: QualityControlRemarksStateService,
  ) {}
}
