<div dougsModalTitle>
  <h6>J'ai besoin d'informations {{ isClosingRequest ? 'pour votre bilan' : '' }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <div>
    <dougs-avatar-message size="medium" [avatarUrl]="avatarUrl" [fullName]="accountantName">
      <div>
        <p>
          Bonjour {{ customerFirstName }},
          <br />
          <br />
          Je vous ai envoyé un e-mail de demande d’informations le
          {{ data.task.createdAt | date: 'd MMMM yyyy' }}.
          <br />
          J’ai besoin d’éléments supplémentaires concernant votre dossier pour pouvoir le traiter rapidement. Merci de
          m’envoyer les informations demandées par e-mail
          <strong>
            (et uniquement par e-mail
            <img class="emoji-envelope" src="images/emoji/envelope.png" width="24" height="24" alt="envelope" />
            ).
          </strong>
          <br />
          <br />
          À bientôt !
        </p>
      </div>
    </dougs-avatar-message>
  </div>
  <div class="mt-8" *ngIf="data.attachments?.length">
    <div class="file-list">
      <dougs-file-pill
        *ngFor="let attachment of data.attachments; trackBy: 'id' | trackBy"
        [attachment]="attachment"
        [canDelete]="false"
      ></dougs-file-pill>
    </div>
  </div>
</div>

<div dougsModalFooter>
  <dougs-button type="button" dougsModalClose>J'ai compris</dougs-button>
</div>
