import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs';
import { concatMap, debounceTime } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { Activity, CommentPost, Task } from '@dougs/task/dto';
import { ActivityStateService, ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class ActivitiesComponentService {
  formGroup = new FormGroup({
    search: new FormControl<string>('', { nonNullable: true }),
    onlyPermanent: new FormControl<boolean>(false, { nonNullable: true }),
  });

  commentFormControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private noteTask: Task | null = null;

  constructor(
    private readonly activityStateService: ActivityStateService,
    private readonly userStateService: UserStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  filteredActivities$: Observable<Activity[]> = combineLatest([
    this.search.valueChanges.pipe(startWith('')),
    this.activityStateService.activities$,
  ]).pipe(
    debounceTime(500),
    map(([search, activities]) => {
      if (search) {
        return activities.filter(
          (activity) => activity.data?.title?.includes(search) || activity.data?.taskTitle?.includes(search),
        );
      }

      return activities;
    }),
  );

  refreshActivities$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.next(true)),
    tap(() => this.resetFormGroup()),
    withLatestFrom(this.userStateService.activeUser$),
    concatMap(([activeCompany, activeUser]) =>
      Promise.all([
        this.activityStateService.refreshActivities(activeCompany, this.onlyPermanent.value),
        this.controlPanelTasksStateService.getTaskNote(activeUser.id),
      ]),
    ),
    map(([_, noteTask]) => {
      this.noteTask = noteTask;
    }),
    tap(() => this.isLoading.next(false)),
  );

  onlyPermanentValueChanges$: Observable<void> = this.onlyPermanent.valueChanges.pipe(
    distinctUntilChanged(),
    withLatestFrom(this.companyStateService.activeCompany$),
    tap(() => this.isLoading.next(true)),
    concatMap(([onlyPermanent, activeCompany]) =>
      this.activityStateService.refreshActivities(activeCompany, onlyPermanent),
    ),
    tap(() => this.isLoading.next(false)),
  );

  get search(): FormControl {
    return this.formGroup.get('search') as FormControl;
  }

  get onlyPermanent(): FormControl {
    return this.formGroup.get('onlyPermanent') as FormControl;
  }

  resetFormGroup(): void {
    this.formGroup.setValue(
      {
        search: '',
        onlyPermanent: false,
      },
      { emitEvent: false },
    );
  }

  async addComment(e: KeyboardEvent): Promise<void> {
    e.preventDefault();

    if (this.commentFormControl.value) {
      const comment: CommentPost = {
        body: this.commentFormControl.value,
        commenter: this.userStateService.loggedInUser,
        tags: this.onlyPermanent.value
          ? [
              {
                label: 'Dossier perm.',
                type: 'permanent',
                hidden: false,
                readOnly: false,
              },
            ]
          : [],
      };
      this.commentFormControl.setValue('', { emitEvent: false });
      if (this.noteTask) {
        await this.controlPanelTasksStateService.commentTask(this.noteTask, comment);
        this.isLoading.next(true);
        await this.activityStateService.refreshActivities(
          this.companyStateService.activeCompany,
          this.formGroup.value.onlyPermanent,
        );
        this.isLoading.next(false);
        this.noteTask = await this.controlPanelTasksStateService.getTaskNote(this.userStateService.activeUser.id);
      }
    }
  }
}
