import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedTabComponentService {
  private readonly selectedTabSubject: BehaviorSubject<'tasks' | 'activities'> = new BehaviorSubject<
    'tasks' | 'activities'
  >('tasks');
  selectedTab$: Observable<'tasks' | 'activities'> = this.selectedTabSubject.asObservable();

  selectTab(tab: 'tasks' | 'activities'): void {
    this.selectedTabSubject.next(tab);
  }
}
