import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  BadgeComponent,
  ButtonComponent,
  DougsDatePipe,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionFacadeService } from '../../task-actions';

@Component({
  selector: 'dougs-unseen-tasks-modal',
  templateUrl: './unseen-tasks-modal.component.html',
  styleUrls: ['./unseen-tasks-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    NgClass,
    BadgeComponent,
    NgIf,
    ModalFooterDirective,
    ButtonComponent,
    DougsDatePipe,
  ],
})
export class UnseenTasksModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      unseenTasks: Task[];
    },
    private readonly modalRef: ModalRef,
    public taskActionFacadeService: TaskActionFacadeService,
    public userTasksStateService: UserTasksStateService,
    public userStateService: UserStateService,
  ) {}

  trackById(index: number, item: Task): number {
    return item.id;
  }

  async markAsSeen(): Promise<void> {
    const markAsSeen: boolean = await this.userTasksStateService.markUserTasksAsSeen(
      this.userStateService.activeUser?.id,
      this.data.unseenTasks,
    );
    if (markAsSeen) {
      this.modalRef.close();
    }
  }
}
