import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerDeclarationNotificationService extends TaskActionService {
  protected _tag = 'Déclarations';
  protected _tagColor: Pill = 'primary';
  protected _tagIcon = 'fa-scroll';

  constructor(private readonly modalService: ModalService) {
    super();
  }

  getLabel(task?: Task): string {
    return task?.code.includes(':reconfirm') ? 'Éditer la déclaration' : 'Voir la déclaration';
  }

  getIsAvailable(task?: Task): boolean {
    return !!task?.metadata?.declarationId;
  }

  async execute(task: Task): Promise<void> {
    if (task.metadata?.declarationId) {
      // eslint-disable-next-line @nx/enforce-module-boundaries
      const { DeclarationModalComponent } = await import('@dougs/declaration/ui');

      await lastValueFrom(
        this.modalService.open(DeclarationModalComponent, {
          data: task.metadata.declarationId,
        }).afterClosed$,
      );
    }
  }
}
