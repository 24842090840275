import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AvatarComponent,
  ConvertMentionPipe,
  DougsDatePipe,
  DragNDropDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FilePillComponent,
  PillComponent,
  TargetBlankInHrefPipe,
  TooltipDirective,
  TrackByPipe,
  WrapUrlsInHtmlPipe,
} from '@dougs/ds';
import { Activity, Tag } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { CommentIsDeletedPipe } from '../../../../pipes/control-panel/task/comment-is-deleted.pipe';
import { TagClassPipe } from '../../../../pipes/tag-class.pipe';
import { ActivityComponentService } from '../../../../services/control-panel/activity/activity.component.service';

@Component({
  selector: 'dougs-activity-comment',
  templateUrl: './activity-comment.component.html',
  styleUrls: ['./activity-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DragNDropDirective,
    AvatarComponent,
    TooltipDirective,
    NgClass,
    DropdownTriggerDirective,
    NgFor,
    PillComponent,
    FilePillComponent,
    DropdownComponent,
    DropdownOptionComponent,
    AsyncPipe,
    ConvertMentionPipe,
    TargetBlankInHrefPipe,
    WrapUrlsInHtmlPipe,
    TrackByPipe,
    CommentIsDeletedPipe,
    TagClassPipe,
    NgIf,
    DougsDatePipe,
  ],
})
export class ActivityCommentComponent {
  @Input() activity!: Activity;

  constructor(
    public readonly userStateService: UserStateService,
    public readonly activityComponentService: ActivityComponentService,
  ) {}

  async onAddTag(tag: Tag): Promise<void> {
    if (!this.activity.data.comment) {
      return;
    }

    this.activity.data.comment = {
      ...this.activity.data.comment,
      tags: [...this.activity.data.comment.tags, tag],
    };

    await this.activityComponentService.updateComment(this.activity.data.comment, this.activity);
  }

  async onRemoveTag(index: number): Promise<void> {
    if (!this.activity.data.comment) {
      return;
    }

    this.activity.data.comment = {
      ...this.activity.data.comment,
      tags: this.activity.data.comment.tags.slice(0, index).concat(this.activity.data.comment.tags.slice(index + 1)),
    };

    await this.activityComponentService.updateComment(this.activity.data.comment, this.activity);
  }
}
