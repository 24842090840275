import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'creationDateTooltipText',
  standalone: true,
})
export class CreationDateTooltipTextPipe implements PipeTransform {
  transform(task: Task): string {
    return `Tâche créée le ${format(new Date(task.createdAt), 'dd/MM/yyyy')}${
      task.dueDate ? `, date limite le ${format(new Date(task.dueDate), 'dd/MM/yyyy')}` : ''
    }`;
  }
}
