import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isAfter } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { OwnershipService } from '@dougs/core/ownership';
import { RoutingService, URL } from '@dougs/core/routing';
import { SessionStorageService } from '@dougs/core/storage';
import { FlashMessagesService, ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { AccountingSurveyModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerAccountingSurveyService extends TaskActionService {
  protected _label = 'Voir le questionnaire';
  protected _isAvailable = true;
  protected _tag = 'Bilan';
  protected _tagColor: Pill = 'admin';
  protected _tagIcon = 'fa-balance-scale';

  constructor(
    private readonly modalService: ModalService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly ownershipService: OwnershipService,
  ) {
    super();
  }

  shouldExecuteAutomatically(): boolean {
    return true;
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    await this.router.navigateByUrl(this.routingService.createUrl([URL.ACCOUNTING_SURVEY]).toString());
  }

  async automaticallyExecute(task: Task | null): Promise<void> {
    if (
      !task ||
      this.userStateService.activeUser.isAccountantOrAdmin ||
      !this.companyStateService.activeCompany.isCreated
    ) {
      return;
    }
    if (!task.metadata.closingDate || (task.metadata.reopeningData && task.metadata.reopeningData.length < 1)) {
      this.flashMessagesService.show('Oups... une erreur est survenue. Nous sommes prévenus.', {
        type: 'error',
        timeout: 5000,
      });
      return;
    }
    if (
      task &&
      !task.metadata?.preventNotifications &&
      (!task.metadata?.accountingSurveySeenAtLeastOnce || isAfter(new Date(), new Date(task.metadata.closingDate))) &&
      !this.sessionStorageService.retrieve('hasSeenAccountingSurveyModal')
    ) {
      const succeeded = (
        await lastValueFrom(
          this.modalService.open<boolean>(AccountingSurveyModalComponent, {
            disableClose: !this.userStateService.loggedInUser.isAccountantOrAdmin,
            disableCloseOnEscape: !this.userStateService.loggedInUser.isAccountantOrAdmin,
            data: {
              task: task,
              company: this.companyStateService.activeCompany,
              user: this.userStateService.activeUser,
            },
          }).afterClosed$,
        )
      ).data;

      if (succeeded) {
        if (this.ownershipService.isMyCompany() && !task.metadata?.accountingSurveySeenAtLeastOnce) {
          task.metadata.accountingSurveySeenAtLeastOnce = true;
          await this.controlPanelTasksStateService.updateTask(task);
        }
        this.sessionStorageService.store('hasSeenAccountingSurveyModal', true);
        await this.router.navigateByUrl(this.routingService.createUrl([URL.ACCOUNTING_SURVEY]).toString());
      }
    }
  }
}
