import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { format } from 'date-fns';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TooltipDirective,
} from '@dougs/ds';
import { Activity } from '@dougs/task/dto';

interface EmailEventMessage {
  [key: string]: {
    label: string;
    dates: string[];
  };
}

@Component({
  selector: 'dougs-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    NgFor,
    TooltipDirective,
    ModalFooterDirective,
    ButtonComponent,
    KeyValuePipe,
  ],
})
export class EmailModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA) public activity: Activity,
    private readonly cdr: ChangeDetectorRef,
  ) {}
  public emailsEventsMessage!: string | EmailEventMessage;
  public dataText?: string;

  ngOnInit(): void {
    this.dataText = `${this.activity.data.text ? this.activity.data.text : 'Contenu non récupéré'}`
      .replace(/\n/g, '<br>')
      .replace(/\s+/g, ' ')
      .trim();

    this.emailsEventsMessage = this.computeEmailsEventsMessage();
    this.cdr.markForCheck();
  }

  computeEmailsEventsMessage(): EmailEventMessage | string {
    const events = this.activity.data.events;
    if (events && events.length > 0) {
      const emailRelevantEvents: {
        [key: string]: {
          label: string;
          dates: string[];
        };
      } = {
        open: {
          label: 'Ouvert',
          dates: [],
        },
        click: {
          label: 'Cliqué',
          dates: [],
        },
        hard_bounce: {
          label: 'Hard bounce',
          dates: [],
        },
        soft_bounce: {
          label: 'Soft bounce',
          dates: [],
        },
        send: {
          label: 'Envoyé',
          dates: [],
        },
        unsub: {
          label: 'Désabonné',
          dates: [],
        },
        reject: {
          label: 'Refusé',
          dates: [],
        },
        spam: {
          label: 'Spam',
          dates: [],
        },
      };

      events.forEach((event) => {
        const emailRelevantEvent = emailRelevantEvents[event.eventName as keyof typeof emailRelevantEvents];
        if (!emailRelevantEvent) {
          return;
        }

        emailRelevantEvent.dates.push(format(new Date(event.occurredAt), 'yyyy-MM-dd HH:mm:ss'));
      });

      return emailRelevantEvents;
    }

    return '';
  }
}
