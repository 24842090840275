import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AvatarComponent,
  ConvertMentionPipe,
  DougsDatePipe,
  DragNDropDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FilePillComponent,
  PillComponent,
  TargetBlankInHrefPipe,
  TooltipDirective,
  TrackByPipe,
  WrapUrlsInHtmlPipe,
} from '@dougs/ds';
import { Comment, Tag, Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { CommentIsDeletedPipe } from '../../../../../pipes/control-panel/task/comment-is-deleted.pipe';
import { TagClassPipe } from '../../../../../pipes/tag-class.pipe';
import { TaskConversationComponentService } from '../../../../../services/control-panel/task/task-conversation.component.service';

@Component({
  selector: 'dougs-task-comment',
  templateUrl: './task-comment.component.html',
  styleUrls: ['./task-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DragNDropDirective,
    AvatarComponent,
    TooltipDirective,
    NgClass,
    DropdownTriggerDirective,
    NgFor,
    PillComponent,
    FilePillComponent,
    DropdownComponent,
    DropdownOptionComponent,
    AsyncPipe,
    ConvertMentionPipe,
    TargetBlankInHrefPipe,
    WrapUrlsInHtmlPipe,
    TrackByPipe,
    CommentIsDeletedPipe,
    TagClassPipe,
    DougsDatePipe,
  ],
  providers: [TaskConversationComponentService],
})
export class TaskCommentComponent {
  @Input() size: 'small' | 'tiny' = 'small';
  @Input() task!: Task;
  @Input() comment!: Comment;

  constructor(
    public readonly userStateService: UserStateService,
    public readonly taskConversationComponentService: TaskConversationComponentService,
  ) {}

  async onAddTag(tag: Tag): Promise<void> {
    this.comment = {
      ...this.comment,
      tags: [...this.comment.tags, tag],
    };

    await this.taskConversationComponentService.updateComment(this.task, this.comment);
  }

  async onRemoveTag(index: number): Promise<void> {
    this.comment = {
      ...this.comment,
      tags: this.comment.tags.slice(0, index).concat(this.comment.tags.slice(index + 1)),
    };

    await this.taskConversationComponentService.updateComment(this.task, this.comment);
  }
}
