import { Pipe, PipeTransform } from '@angular/core';
import { CollaboratorVariableType } from '@dougs/task/dto';

@Pipe({
  name: 'collaboratorVariableCardTitle',
  standalone: true,
})
export class CollaboratorVariableCardTitlePipe implements PipeTransform {
  transform(typeValue?: CollaboratorVariableType): string {
    switch (typeValue) {
      case CollaboratorVariableType.UNKNOWN:
        return 'Nouvelle variable de paie';
      case CollaboratorVariableType.ABSENCE:
        return 'Absence';
      case CollaboratorVariableType.DEPOSIT:
        return 'Accompte';
      case CollaboratorVariableType.HOUR:
        return 'Heure';
      case CollaboratorVariableType.PRIME:
        return 'Prime';
      default:
        return 'Nouvelle variable de paie';
    }
  }
}
