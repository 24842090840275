import { Injectable, OnDestroy, Signal, signal, WritableSignal } from '@angular/core';
import { distinctUntilKeyChanged, filter, Observable, ReplaySubject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { Payslip, PayslipInfo } from '@dougs/task/dto';
import { PayslipStateService } from '@dougs/task/shared';

@Injectable()
export class PayslipFormComponentService implements OnDestroy {
  constructor(
    private readonly payslipStateService: PayslipStateService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  private readonly isLoading: WritableSignal<boolean> = signal(true);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  private readonly payslipInfo: ReplaySubject<PayslipInfo> = new ReplaySubject<PayslipInfo>(1);
  payslipInfo$: Observable<PayslipInfo> = this.payslipInfo.asObservable();

  private readonly payslip: ReplaySubject<Payslip | null> = new ReplaySubject<Payslip | null>(1);
  payslip$: Observable<Payslip | null> = this.payslip.asObservable();

  refreshPayslip$: Observable<void> = this.payslipInfo$.pipe(
    filter((payslipInfo) => !!payslipInfo),
    distinctUntilKeyChanged('id'),
    tap(() => this.isLoading.set(true)),
    concatMap((payslipInfo) =>
      this.payslipStateService.refreshPayslipById(this.companyStateService.activeCompany.id, payslipInfo.id),
    ),
    tap(() => this.isLoading.set(false)),
  );

  updateSelectedPayslip$: Observable<void> = this.payslipStateService.selectedPayslip$.pipe(
    map((payslip) => this.payslip.next(payslip)),
  );

  setPayslipLine(payslipInfo: PayslipInfo): void {
    this.payslipInfo.next(payslipInfo);
  }

  ngOnDestroy(): void {
    this.payslip.next(null);
  }
}
