import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
import { BankReconciliationModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerBankReconciliationService extends TaskActionService {
  protected _label = 'Commencer';
  protected _isAvailable = true;
  protected _tag = 'Banque';
  protected _tagColor: Pill = 'success';
  protected _tagIcon = 'fa-university';

  constructor(
    private readonly modalService: ModalService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    this.metricsService.pushMixpanelEvent('Todo Module Bank Reconciliation Clicked');

    const isCompleted: boolean | undefined | null = (
      await lastValueFrom(
        this.modalService.open<boolean>(BankReconciliationModalComponent, {
          data: { task },
          disableBackdropClose: true,
        }).afterClosed$,
      )
    ).data;

    if (isCompleted) {
      await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
    }
  }
}
