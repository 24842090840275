<div dougsModalTitle>
  Votre chiffre d'affaires pour l'année {{ data.task.metadata.year ?? 0 }} est actuellement de
  {{ data.task.metadata.thresholdReachedAmounts ?? 0 | currency: 'EUR' : 'symbol' : '1.0-2' }}
</div>
<div dougsModalContent>
  Vous allez bientôt atteindre le seuil limite du régime de franchise en base de
  {{ data.task.metadata.threshold ?? 0 | currency: 'EUR' : 'symbol' : '1.0-2' }} pour l'année en cours. Si vous pensez
  dépasser cette limite prochainement, vous devez
  <a
    href="https://aide.dougs.fr/fr/articles/8181506-comment-contacter-votre-sie-service-des-impots-des-entreprises"
    target="_blank"
    >demander à votre SIE</a
  >
  de vous assujettir à la TVA.
</div>
<div dougsModalFooter>
  <dougs-button type="button" [dougsModalClose]="true">Marquer comme vu</dougs-button>
</div>
