import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AccountingYear, BalanceSheetsEmailParts } from '@dougs/accounting-years/dto';
import { AccountingYearService, AccountingYearStateService } from '@dougs/accounting-years/shared';
import { Attachment } from '@dougs/core/files';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileDetailsComponent,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-balance-sheet-available-modal',
  templateUrl: './balance-sheet-available-modal.component.html',
  styleUrls: ['./balance-sheet-available-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    AvatarMessageComponent,
    NgFor,
    FileDetailsComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class BalanceSheetAvailableModalComponent implements OnInit {
  attachments: Attachment[] = [];
  accountingYear!: AccountingYear | null;
  balanceSheetsEmailParts!: BalanceSheetsEmailParts | null;
  avatarUrl!: string;
  accountantName!: string;
  isLoading = true;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
      accountingYear: AccountingYear;
    },
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly accountingYearService: AccountingYearService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.accountantName = this.getAccountantName();
    this.avatarUrl = this.getAvatarUrl();

    this.attachments = this.attachments.concat(
      this.accountingYearService.getMostRecentSignedBalanceSheet(this.data.accountingYear) || [],
      this.data.accountingYear.companyTaxes || [],
    );

    this.balanceSheetsEmailParts = await this.accountingYearStateService.getBalanceSheetsEmailPartsFromAccountingYear(
      this.data.accountingYear,
    );
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  trackById(index: number, attachment: Attachment) {
    return attachment.id;
  }

  getAvatarUrl(): string {
    if (this.data.task.metadata.accountant && this.data.task.metadata.accountant.avatarUrl) {
      return this.data.task.metadata.accountant.avatarUrl;
    }
    return 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  }

  getAccountantName(): string {
    if (this.data.task.metadata.accountant && this.data.task.metadata.accountant.fullName) {
      return this.data.task.metadata.accountant.fullName;
    }
    return 'Sarah Jaouani';
  }
}
