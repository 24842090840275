import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingPipe, URL } from '@dougs/core/routing';
import {
  AvatarMessageComponent,
  ButtonComponent,
  CompletionComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-completion-modal',
  templateUrl: './completion-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
    CompletionComponent,
    RoutingPipe,
  ],
})
export class CompletionModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public completionTask: Task,
    public companyStateService: CompanyStateService,
    public userStateService: UserStateService,
  ) {}

  protected readonly URL = URL;
}
