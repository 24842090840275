<div dougsModalTitle>
  <h6>Votre bilan est prêt !</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-loader *ngIf="isLoading"></dougs-loader>
  <ng-container *ngIf="!isLoading">
    <dougs-avatar-message
      *ngIf="balanceSheetsEmailParts"
      [avatarUrl]="avatarUrl"
      [fullName]="accountantName"
      size="medium"
    >
      <div [innerHtml]="balanceSheetsEmailParts?.content + balanceSheetsEmailParts?.subContent"></div>
      <br />
      <p>PS : Votre bilan et relevé de solde d'impôt sur les sociétés vous ont aussi été transmis par e-mail.</p>
    </dougs-avatar-message>
    <dougs-file-details
      class="mt-16"
      *ngFor="let attachment of attachments; trackBy: trackById"
      [attachment]="attachment"
    ></dougs-file-details>
  </ng-container>
</div>
<div dougsModalFooter>
  <dougs-button *ngIf="!isLoading" type="button" [dougsModalClose]="true">D'accord !</dougs-button>
</div>
