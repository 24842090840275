import { Directive, ElementRef, OnDestroy, OnInit, Signal, signal, WritableSignal } from '@angular/core';

@Directive({
  selector: '[dougsBarWidth]',
  exportAs: 'dougsBarWidth',
  standalone: true,
})
export class AccountingStatsBarWidthDirective implements OnInit, OnDestroy {
  private observer!: ResizeObserver;
  private elementToObserve: HTMLElement | null = null;

  private readonly _barWidth: WritableSignal<number> = signal<number>(0);
  barWidth$: Signal<number> = this._barWidth.asReadonly();

  constructor(private readonly host: ElementRef) {}

  ngOnInit(): void {
    this.observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        this._barWidth.set(entries[0].target.clientWidth);
      }
    });

    this.elementToObserve = this.host.nativeElement;
    if (this.elementToObserve) {
      this.observer.observe(this.elementToObserve);
    }
  }

  ngOnDestroy(): void {
    if (this.elementToObserve) {
      this.observer?.unobserve(this.elementToObserve);
    }
  }
}
