import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  BadgeComponent,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  TrackByPipe,
} from '@dougs/ds';
import { CockpitStateService } from '@dougs/task/shared';
import { CockpitRouterComponentService } from '../../../../services/cockpit/cockpit-router.component.service';

@Component({
  selector: 'dougs-cockpit-tabs',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BadgeComponent,
    DropdownComponent,
    DropdownOptionComponent,
    DropdownTriggerDirective,
    TrackByPipe,
  ],
  templateUrl: './cockpit-tabs.component.html',
  styleUrls: ['./cockpit-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitTabsComponent {
  isMoreDropdownOpenCaretStatus = false;

  constructor(
    public readonly cockpitRouterComponentService: CockpitRouterComponentService,
    public readonly cockpitStateService: CockpitStateService,
  ) {}

  toggleMoreDropdownCaretStatus(): void {
    this.isMoreDropdownOpenCaretStatus = !this.isMoreDropdownOpenCaretStatus;
  }
}
