import { Injectable } from '@angular/core';
import { format, isBefore } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { LocalStorageService } from '@dougs/core/storage';
import { FlashMessagesService, ModalService } from '@dougs/ds';
import { SERVICE_STATE, ServiceState } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCheckValidSubscription extends TaskActionService {
  constructor(
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly localStorageService: LocalStorageService,
  ) {
    super();
  }

  shouldExecuteAutomatically(): boolean {
    return true;
  }

  async execute(): Promise<void> {
    await this.automaticallyExecute();
  }

  async automaticallyExecute(): Promise<void> {
    const accountingServiceState: ServiceState = this.companyServicesStateService.services.accounting;

    if (accountingServiceState?.suspendedAt) {
      const dateSuspendedAt: Date = new Date(accountingServiceState.suspendedAt);

      if (this.companyStateService.activeCompany.metadata.resiliationReason) {
        const msg = `Votre abonnement à Dougs ${
          isBefore(new Date(), dateSuspendedAt) ? 'sera' : 'a été'
        } résilié le ${format(dateSuspendedAt, 'dd/MM/yyyy')}`;
        this.flashMessagesService.show(msg, {
          type: 'warning',
          timeout: 0,
        });
      } else {
        const hasFlashWarningForAccountingTrialEndBeenSeen = this.localStorageService.retrieve(
          'hasFlashWarningForAccountingTrialEndBeenSeen',
        );
        if (!hasFlashWarningForAccountingTrialEndBeenSeen) {
          const msg = `Votre période d'essai a pris fin le ${format(dateSuspendedAt, 'dd/MM/yyyy')}`;
          this.flashMessagesService.show(msg, {
            type: 'warning',
            timeout: 0,
          });
          this.localStorageService.store('hasFlashWarningForAccountingTrialEndBeenSeen', true);
        }
      }
    }

    if (
      [SERVICE_STATE.NOT_STARTED, SERVICE_STATE.WAITING, SERVICE_STATE.TERMINATED].includes(
        accountingServiceState.state,
      )
    ) {
      return;
    }

    if (this.userStateService.activeUser?.isEligibleToComptastart && !accountingServiceState?.serviceCode) {
      // eslint-disable-next-line @nx/enforce-module-boundaries
      const { ActivateComptastartModalComponent } = await import('@dougs/subscription/ui');
      await lastValueFrom(
        this.modalService.open<boolean | null>(ActivateComptastartModalComponent, {
          disableClose: !this.userStateService.loggedInUser.isAccountantOrAdmin,
          disableCloseOnEscape: !this.userStateService.loggedInUser.isAccountantOrAdmin,
          data: {
            company: this.companyStateService.activeCompany,
            activeUser: this.userStateService.activeUser,
            disableClose: true,
          },
        }).afterClosed$,
      );
    } else if (
      this.companyStateService.activeCompany.isCreated &&
      !accountingServiceState?.isValid &&
      !accountingServiceState?.suspendedAt
    ) {
      // eslint-disable-next-line @nx/enforce-module-boundaries
      const { PlansModalComponent } = await import('@dougs/subscription/ui');
      await lastValueFrom(
        this.modalService.open<boolean | null>(PlansModalComponent, {
          disableClose: !this.userStateService.loggedInUser.isAccountantOrAdmin,
          disableCloseOnEscape: !this.userStateService.loggedInUser.isAccountantOrAdmin,
          data: {
            company: this.companyStateService.activeCompany,
            activeUser: this.userStateService.activeUser,
            disableClose: true,
          },
        }).afterClosed$,
      );
    }
  }
}
