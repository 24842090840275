import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

@Injectable()
export class WorkloadTaskModalComponentService {
  formGroup = new FormGroup({
    workloadHours: new FormControl(0, { nonNullable: true }),
    workloadMinutes: new FormControl(0, { nonNullable: true }),
  });

  isSending = false;

  constructor(
    @Inject(MODAL_DATA) public readonly task: Task,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly modalRef: ModalRef,
  ) {}

  populateFormGroup(): void {
    this.formGroup.get('workloadHours')?.setValue(Math.floor((this.task.workload || 0) / 60));
    this.formGroup.get('workloadMinutes')?.setValue((this.task.workload || 0) % 60);
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid && !this.isSending) {
      this.isSending = true;
      const { workloadHours, workloadMinutes } = this.formGroup.value;
      const workload: number = (workloadHours || 0) * 60 + (workloadMinutes || 0);
      const hasBeenUpdated: boolean = await this.controlPanelTasksStateService.updateTaskWorkload(this.task, workload);
      if (hasBeenUpdated) {
        this.modalRef.close();
      }
      this.isSending = false;
    }
  }
}
