import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  RadioComponent,
  RadioGroupComponent,
} from '@dougs/ds';
import { CollaboratorCardComponentService } from '../../../../../../services/modals/collaborator-payslip/collaborator-card.component.service';

@Component({
  selector: 'dougs-absence-form',
  standalone: true,
  imports: [
    CommonModule,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    FormFieldComponent,
    InputDatepickerComponent,
    LabelFormFieldDirective,
    RadioComponent,
    RadioGroupComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './absence-form.component.html',
  styleUrl: './absence-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceFormComponent {
  constructor(
    public readonly collaboratorCardComponentService: CollaboratorCardComponentService,
    public readonly formService: FormService,
  ) {}
}
