<nav class="cockpit-tabs">
  <ul>
    <ng-container
      *ngFor="let link of cockpitRouterComponentService.computedTabLinks$ | async; trackBy: 'title' | trackBy"
    >
      <li *ngIf="!link.displayInMore">
        <a [routerLink]="link.segments" routerLinkActive="active" queryParamsHandling="preserve" class="py-16">
          {{ link.title }}
        </a>
        <dougs-badge
          type="primary"
          *ngIf="link.shouldShowBadge"
          [size]="link.badgeValue !== undefined ? 'medium' : 'small'"
        >
          {{ link.badgeValue }}
        </dougs-badge>
      </li>
    </ng-container>
    <li>
      <p class="small more" (click)="toggleMoreDropdownCaretStatus()" [dougsDropdownTrigger]="tabsMore">
        Plus&nbsp;
        <i class="fal fa-lg" [ngClass]="isMoreDropdownOpenCaretStatus ? 'fa-angle-up' : 'fa-angle-down'"></i>
      </p>
    </li>
  </ul>
</nav>
<dougs-dropdown
  #tabsMore
  (closed)="toggleMoreDropdownCaretStatus()"
  [widthToRefElement]="false"
  [displayArrow]="false"
  [isSelect]="true"
>
  <ng-container
    *ngFor="let link of cockpitRouterComponentService.computedTabLinks$ | async; trackBy: 'title' | trackBy"
  >
    <dougs-dropdown-option
      class="cockpit-tabs-more"
      *ngIf="link.displayInMore"
      (click)="cockpitRouterComponentService.routeTo({ page: link.page })"
      [closeOnClick]="true"
      [value]="link.page"
    >
      <p class="small">{{ link.title }}</p>
    </dougs-dropdown-option>
  </ng-container>
</dougs-dropdown>
