import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent, DividerComponent, DougsDatePipe } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { BankCardComponentService } from '../../../services/bank.card.component.service';
import { SubscriptionComponentService } from '../../../services/subscription.component.service';

@Component({
  selector: 'dougs-bank-card',
  templateUrl: './bank-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [BankCardComponentService],
  imports: [NgIf, ButtonComponent, DividerComponent, AsyncPipe, DougsDatePipe],
})
export class BankCardComponent {
  constructor(
    public readonly subscriptionComponentService: SubscriptionComponentService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly bankCardComponentService: BankCardComponentService,
  ) {}
}
