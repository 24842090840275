import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
  SelectOptionGroupComponent,
} from '@dougs/ds';
import { VariableTypeLabelPipe } from '../../../../../../pipes/variable-type-label.pipe';
import { CollaboratorCardComponentService } from '../../../../../../services/modals/collaborator-payslip/collaborator-card.component.service';
import { CollaboratorFormComponentService } from '../../../../../../services/modals/collaborator-payslip/collaborator-form.component.service';

@Component({
  selector: 'dougs-catalog-item-select',
  standalone: true,
  imports: [
    CommonModule,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
    SelectOptionGroupComponent,
    FormsModule,
    VariableTypeLabelPipe,
  ],
  templateUrl: './catalog-item-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogItemSelectComponent {
  constructor(
    public readonly collaboratorFormComponentService: CollaboratorFormComponentService,
    public readonly collaboratorCardComponentService: CollaboratorCardComponentService,
  ) {}

  @Input() disabled = false;
}
