import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { FlashMessagesService, ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { BalanceSheetAvailableModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerBalanceSheetsAvailableService extends TaskActionService {
  protected _label = 'Consulter mon bilan';
  protected _isAvailable = true;

  constructor(
    private readonly taskService: TaskService,
    private readonly modalService: ModalService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly accountingYearStateService: AccountingYearStateService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }
    if (!task.metadata.accountingYearId) {
      this.flashMessagesService.show('Oups... une erreur est survenue. Nous sommes prévenus.', {
        type: 'error',
        timeout: 5000,
      });
      return;
    }
    const accountingYear = await this.accountingYearStateService.getAccountingYear(
      task.companyId,
      task.metadata.accountingYearId,
    );
    if (!accountingYear) {
      return;
    }
    const succeeded = (
      await lastValueFrom(
        this.modalService.open(BalanceSheetAvailableModalComponent, {
          data: {
            task: task,
            accountingYear: accountingYear,
          },
        }).afterClosed$,
      )
    ).data;

    if (succeeded) {
      await this.taskService.completeTask(task);
    }
  }
}
