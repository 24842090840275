import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { replaceCompanyIdInUrlAsCommand } from '@dougs/core/routing';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionAccountingMonitoringInvestmentCategorizationService extends TaskActionService {
  _label = 'Vérifier les opérations';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly router: Router,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }
    const activeAccountingYear: AccountingYear | null =
      await this.accountingYearStateService.refreshActiveAccountingYear(task.companyId);
    if (!activeAccountingYear) {
      return;
    }
    await this.router.navigate(replaceCompanyIdInUrlAsCommand(task.companyId.toString(), this.router.url));
    const { VerifyOperationListModalComponent } = await import('@dougs/operations/ui');
    const success: boolean | undefined | null = (
      await lastValueFrom(
        this.modalService.open<boolean>(VerifyOperationListModalComponent, {
          data: {
            search: {
              accountNumberRanges: [
                { minAccountingNumber: 606300, maxAccountingNumber: 606499 },
                { minAccountingNumber: 615000, maxAccountingNumber: 615999 },
                { minAccountingNumber: 626100, maxAccountingNumber: 626199 },
              ],
              accountingLineAmount: '>50000',
              date: `>${format(new Date(activeAccountingYear.openingDate), 'dd/MM/yyyy')}`,
              autogenerated: false,
              accountingSurveyId: null,
            },
            panelInfo: `Ces opérations ont toutes un montant supérieur à 500€ mais ne sont pas classées en immobilisation ou cession d'immobilisation.
                        <b>Vérifiez</b> que la catégorisation est correcte puis <b>verrouillez</b> chaque opération. Si des précisions sont nécessaires, contactez le client.
                        Lorsque toutes les opérations sont verrouillées, vous pouvez terminer la vérification.`,
            title: 'Opérations + de 500€ non immobilisées',
          },
        }).afterClosed$,
      )
    )?.data;
    if (success) {
      await this.controlPanelTasksStateService.completeTask(task);
    }
  }
}
