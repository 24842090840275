import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LoaderComponent, TooltipDirective, TrackByPipe } from '@dougs/ds';
import { LabelStateService, USER_FLAG, UserStateService } from '@dougs/user/shared';
import { ControlPanelLabelsComponentService } from '../../../services/control-panel/labels/control-panel-labels.component.service';
import { UserLabelComponent } from '../user-label/user-label.component';
import { FilePillDropdownComponent } from './file-pill-dropdown/file-pill-dropdown.component';
import { PostItsComponent } from './post-its/post-its.component';

@Component({
  selector: 'dougs-control-panel-labels',
  templateUrl: './control-panel-labels.component.html',
  styleUrls: ['./control-panel-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    TooltipDirective,
    NgFor,
    UserLabelComponent,
    AsyncPipe,
    TrackByPipe,
    FilePillDropdownComponent,
    PostItsComponent,
    RouterLink,
  ],
  providers: [ControlPanelLabelsComponentService],
})
export class ControlPanelLabelsComponent {
  constructor(
    public readonly labelStateService: LabelStateService,
    public readonly controlPanelLabelsComponentService: ControlPanelLabelsComponentService,
    public readonly userStateService: UserStateService,
  ) {}

  protected readonly USER_FLAG = USER_FLAG;
}
