<div dougsModalTitle>
  <h6>Votre avis</h6>
  <i
    (click)="surveyAppointmentModalService.quitSurvey()"
    class="close-icon fal fa-times"
    data-cy="survey-appointment-modal-close-icon"
  ></i>
</div>
<ng-container *ngIf="surveyAppointmentModalService.survey as survey">
  <ng-container
    *ngIf="surveyAppointmentModalService.currentStep as currentStep"
    [formGroup]="surveyAppointmentModalService.formGroup"
  >
    <div dougsModalContent *ngIf="survey.collaborator as collaborator">
      <dougs-avatar-message
        class="pb-24"
        [avatarUrl]="collaborator.avatarUrl"
        fullWidth="true"
        size="medium"
        [fullName]="collaborator.firstName"
        [disableFullName]="!collaborator.firstName"
      >
        <p class="small" [innerHTML]="currentStep.message"></p>
      </dougs-avatar-message>
      <ng-container [ngSwitch]="currentStep.type">
        <ng-container *ngSwitchCase="'score'">
          <dougs-emoji-rating
            [formControlName]="currentStep.fieldName"
            (ngModelChange)="surveyAppointmentModalService.saveAndNextStep()"
          />
        </ng-container>
        <ng-container *ngSwitchCase="'input'">
          <div>
            <dougs-form-field>
              <label *ngIf="currentStep.label" [for]="currentStep.fieldName" dougsFormFieldLabel>{{
                currentStep.label
              }}</label>
              <input
                [formControlName]="currentStep.fieldName"
                [placeholder]="currentStep.placeholder || ''"
                [id]="currentStep.fieldName"
                dougsFormFieldControl
              />
            </dougs-form-field>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
          <div>
            <dougs-form-field>
              <label *ngIf="currentStep.label" [for]="currentStep.fieldName" dougsFormFieldLabel>{{
                currentStep.label
              }}</label>
              <textarea
                [formControlName]="currentStep.fieldName"
                [placeholder]="currentStep.placeholder || ''"
                autosize
                [id]="currentStep.fieldName"
                dougsFormFieldControl
              ></textarea>
            </dougs-form-field>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div dougsModalFooter *ngIf="surveyAppointmentModalService.shouldShowFooter">
      <dougs-button
        *ngIf="!surveyAppointmentModalService.isLastStep"
        (click)="surveyAppointmentModalService.saveAndNextStep()"
        color="primary"
        >{{ currentStep?.type !== null ? 'Envoyer' : 'Continuer' }}</dougs-button
      >
      <dougs-button
        *ngIf="surveyAppointmentModalService.isLastStep"
        (click)="surveyAppointmentModalService.quitSurvey()"
        color="secondary"
        >Fermer</dougs-button
      >
    </div>
  </ng-container>
</ng-container>
