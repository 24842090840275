import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { CcnAnalysisAvailableModalComponent } from '../../modals/ccn-analysis-available-modal/ccn-analysis-available-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCcnAnalysisAvailableService extends TaskActionService {
  protected _label = "Consulter l'analyse de la convention collective";
  protected _isAvailable = true;

  constructor(
    private readonly taskService: TaskService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const succeeded = !!(
      await lastValueFrom(
        this.modalService.open<boolean>(CcnAnalysisAvailableModalComponent, {
          data: task,
        }).afterClosed$,
      )
    ).data;

    if (succeeded) {
      await this.taskService.completeTask(task);
    }
  }
}
