import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { from, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { Attachment } from '@dougs/core/files';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileInputComponent,
  FilePillComponent,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { RequiredCertificateType, Task, TaskForm, TaskFormInlineGroupValue } from '@dougs/task/dto';
import { ControlPanelTasksStateService, TemplateTaskStateService, UserTasksStateService } from '@dougs/task/shared';

@Component({
  selector: 'dougs-madelin-certificates-modal',
  templateUrl: './madelin-certificates-modal.component.html',
  styleUrls: ['./madelin-certificates-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    NgIf,
    LoaderComponent,
    NgFor,
    NgClass,
    FileInputComponent,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class MadelinCertificatesModalComponent implements OnInit, OnDestroy {
  isLoading = true;
  form!: TaskFormInlineGroupValue;
  fullTask?: Task;
  attachments: { id?: number; attachment?: Attachment } = {};
  userTasksSubscription!: Subscription;

  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
    private readonly templateTaskStateService: TemplateTaskStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.controlPanelTasksStateService.getTask(this.task);

    this.userTasksSubscription = this.userTasksStateService.tasks$
      .pipe(
        tap((userTasks) => {
          this.fullTask = userTasks.find((task) => task.id === this.task.id);
          this.cdr.markForCheck();
        }),
        concatMap(() => from(this.getForm())),
        tap(() => {
          this.isLoading = false;

          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  async getForm(): Promise<void> {
    if (this.fullTask) {
      const taskForm: TaskForm | null = await this.templateTaskStateService.getTaskForm(this.fullTask);

      if (taskForm?.inlineGroup.value) {
        this.form = taskForm.inlineGroup.value;

        this.getAttachmentsFromSubTask();
      }
    }
  }

  getAttachmentsFromSubTask(): void {
    this.form.missingPartnersCertificateData.forEach((missingPartnersCertificate) => {
      missingPartnersCertificate.requiredCertificateTypes.forEach((missingCertificate) => {
        const subTask: Task | undefined = this.getSubTask(missingCertificate);
        subTask?.attachments
          .filter((attachment) => missingCertificate.taskFileAttachmentIds.includes(attachment.id))
          .forEach((attachment) => {
            this.attachments = {
              ...this.attachments,
              [attachment.id]: attachment,
            };
          });
      });
    });

    this.cdr.markForCheck();
  }

  async onUploadFiles(missingCertificate: RequiredCertificateType, files: FileList): Promise<void> {
    const subTask: Task | undefined = this.getSubTask(missingCertificate);

    if (subTask) {
      for (const file of Array.from(files)) {
        await this.controlPanelTasksStateService.uploadTaskAttachment(subTask, file, {
          fileType: 'madelin',
          metadata: { partnerId: missingCertificate.partnerId },
        });
      }

      // TODO Remove get task if just to have task with attachments
      await this.controlPanelTasksStateService.getTask(this.task);
    }
  }

  async onDeleteFile(missingCertificate: RequiredCertificateType, attachment: Attachment): Promise<void> {
    const subTask: Task | undefined = this.getSubTask(missingCertificate);
    if (subTask) {
      await this.controlPanelTasksStateService.removeTaskAttachment(subTask, attachment);
      // TODO Remove get task if just to have task withtout attachment
      await this.controlPanelTasksStateService.getTask(this.task);
    }
  }

  getSubTask(missingCertificate: RequiredCertificateType): Task | undefined {
    return this.fullTask?.tasks?.find(
      (task) =>
        task.metadata.certificate?.partnerId === missingCertificate.partnerId &&
        task.metadata.certificate?.type === missingCertificate.type,
    );
  }

  transmit(): void {
    if (this.form.canComplete) {
      this.modalRef.close(true);
    }
  }

  ngOnDestroy(): void {
    this.userTasksSubscription?.unsubscribe();
  }
}
