<div class="task-conversation p-8">
  <ng-container *ngFor="let comment of comments; trackBy: 'id' | trackBy">
    <dougs-task-comment *ngIf="!comment.metadata.isActivity" [task]="task" [comment]="comment"></dougs-task-comment>
    <div *ngIf="comment.metadata.isActivity" class="task-conversation__activity mb-8">
      <div class="task-conversation__activity__body" [innerHTML]="comment.body | wrapUrlsInHtml"></div>
      <div [dougsTooltip]="comment.createdAt | date: 'dd/MM/yyyy HH:mm'" class="task-conversation__activity__time">
        {{ comment.createdAt | formatDistanceToNow: true }}
      </div>
      <div class="file-list">
        <dougs-file-pill
          (deleteFile)="taskConversationComponentService.removeFile(task, $event)"
          *ngFor="let attachment of comment.attachments; trackBy: 'id' | trackBy"
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </div>
  </ng-container>
  <textarea
    #commentInput
    (keydown)="preventLineBreak($event)"
    (keydown.enter)="addComment($event)"
    (keypress)="handleOpenDropdownUser($event)"
    class="task-conversation__comment-textarea"
    autosize
    dougsFormFieldControl
    placeholder="Commenter..."
  ></textarea>
</div>
<dougs-dropdown
  (opened)="isMentionOpened = true"
  (closed)="isMentionOpened = false; focusSearchElement()"
  #dropdownUsers
  [reference]="commentInput"
  [widthToRefElement]="false"
  maxHeight="500"
>
  <dougs-task-user-dropdown
    *ngIf="isMentionOpened"
    (selectUser)="mentionUser($event)"
    [isMention]="true"
    [taskIds]="[task.id]"
  ></dougs-task-user-dropdown>
</dougs-dropdown>
