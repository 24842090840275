import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskActionModalPayslipText',
  standalone: true,
})
export class TaskActionModalPayslipTextPipe implements PipeTransform {
  transform(month: string): string {
    if (!month) {
      return 'Veuillez trouver ci-dessous votre ou vos bulletin(s) de paie';
    }
    return `Veuillez trouver ci-dessous votre ou vos bulletin(s) de paie pour le mois de ${month}`;
  }
}
