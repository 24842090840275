import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import {
  AutofocusDirective,
  AvatarComponent,
  ControlFormFieldDirective,
  DropdownOptionComponent,
  LoaderComponent,
  TrackByPipe,
} from '@dougs/ds';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-referrer-user-dropdown',
  templateUrl: './referrer-user-dropdown.component.html',
  styleUrls: ['./referrer-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    AutofocusDirective,
    ControlFormFieldDirective,
    ReactiveFormsModule,
    NgIf,
    LoaderComponent,
    DropdownOptionComponent,
    NgFor,
    AvatarComponent,
    AsyncPipe,
    TrackByPipe,
  ],
})
export class ReferrerUserDropdownComponent {
  users$!: Observable<User[]>;
  isLoading = false;
  searchUserControl: UntypedFormControl = new UntypedFormControl();

  @Input() shouldShowMe = false;
  @Output() selectUser: EventEmitter<User | null> = new EventEmitter<User | null>();

  @ViewChild('inputSearch') inputSearch!: ElementRef;

  @Input()
  set isOpened(isOpened: boolean) {
    if (isOpened && this.inputSearch) {
      this.inputSearch.nativeElement.focus();

      this.users$ = this.searchUserControl.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.isLoading = true;
          this.cdr.markForCheck();
        }),
        switchMap((value) => this.userStateService.getReferrerUsers(value)),
        tap(() => {
          this.isLoading = false;
        }),
      );
    }
  }

  constructor(
    public userStateService: UserStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  onSelectUser(user: User | null): void {
    this.selectUser.emit(user);
  }
}
