<div class="cockpit-stats-period">
  <p class="small">
    Semaine du
    <b class="color-primary-700">{{ cockpitStatsComponentService.startOfWeek$ | async | date: 'dd MMMM' }}</b>
    au
    <b class="color-primary-700">{{ cockpitStatsComponentService.endOfWeek$ | async | date: 'dd MMMM' }}</b>
  </p>
  <div class="cockpit-stats-period__week-picker">
    <dougs-icon-button
      iconClass="chevron-left"
      (click)="cockpitStatsComponentService.goToPreviousWeek()"
    ></dougs-icon-button>
    <dougs-icon-button
      [disabled]="disableFuture && (cockpitStatsComponentService.isReferenceWeekCurrentOrFuture$ | async)"
      iconClass="chevron-right"
      (click)="cockpitStatsComponentService.goToNextWeek()"
    ></dougs-icon-button>
    <dougs-button
      size="small"
      (click)="cockpitStatsComponentService.goToPresentDay()"
      color="secondary"
      [disabled]="cockpitStatsComponentService.isThisWeekTheReferenceWeek$ | async"
    >
      Aujourd'hui
    </dougs-button>
  </div>
</div>
