import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  CheckboxComponent,
  ControlFormFieldDirective,
  DetectFormChangesDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  PanelInfoComponent,
  RadioComponent,
  RadioGroupComponent,
  SelectComponent,
  SelectOptionComponent,
  SelectOptionGroupComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { CollaboratorVariableFormGroup, CollaboratorVariableType } from '@dougs/task/dto';
import { CollaboratorVariableCardTitlePipe } from '../../../../../pipes/collaborator-variable-card-title.pipe';
import { CollaboratorCardComponentService } from '../../../../../services/modals/collaborator-payslip/collaborator-card.component.service';
import { CollaboratorFormComponentService } from '../../../../../services/modals/collaborator-payslip/collaborator-form.component.service';
import { AbsenceFormComponent } from './absence-form/absence-form.component';
import { CatalogItemSelectComponent } from './catalog-item-select/catalog-item-select.component';
import { DepositFormComponent } from './deposit-form/deposit-form.component';
import { HourFormComponent } from './hour-form/hour-form.component';
import { PrimeFormComponent } from './prime-form/prime-form.component';

@Component({
  selector: 'dougs-collaborator-variable-card',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CollaboratorVariableCardTitlePipe,
    FormFieldComponent,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    LabelFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
    SelectOptionGroupComponent,
    FormsModule,
    InputDatepickerComponent,
    SuffixFormFieldDirective,
    PanelInfoComponent,
    CheckboxComponent,
    RadioComponent,
    RadioGroupComponent,
    CatalogItemSelectComponent,
    DepositFormComponent,
    HourFormComponent,
    PrimeFormComponent,
    AbsenceFormComponent,
    DetectFormChangesDirective,
  ],
  providers: [CollaboratorCardComponentService],
  templateUrl: './collaborator-variable-card.component.html',
  styleUrl: './collaborator-variable-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorVariableCardComponent {
  @Input() set variableFormGroup(formGroup: FormGroup<CollaboratorVariableFormGroup>) {
    this.collaboratorCardComponentService.setFormGroup(formGroup);
  }

  @Input() formGroupIndex!: number;
  @Input() disabled = false;

  constructor(
    public readonly collaboratorFormComponentService: CollaboratorFormComponentService,
    public readonly collaboratorCardComponentService: CollaboratorCardComponentService,
    public readonly formService: FormService,
  ) {}

  protected readonly CollaboratorVariableType = CollaboratorVariableType;
}
