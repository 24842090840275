import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'toggleIcon',
  standalone: true,
})
export class ToggleIconPipe implements PipeTransform {
  transform(task: Task): string {
    if (task.metadata?.workflowBypass?.firstErrorAt && !task.completedAt) {
      return 'fa-exclamation-circle error';
    }

    if (task.completedAt) {
      return 'fa-check-square';
    }

    if (!task.isActive) {
      return 'fa-square';
    }

    return task.isDelegated ? 'fa-hourglass' : 'fa-square';
  }
}
