import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, ModalCloseDirective, ModalFooterDirective } from '@dougs/ds';

@Component({
  selector: 'dougs-form-footer',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ModalCloseDirective, ModalFooterDirective],
  templateUrl: './form-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFooterComponent {
  @Input() disabled = false;
  @Input() completed = false;
  @Output() backToList: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Output() modify: EventEmitter<void> = new EventEmitter<void>();
}
