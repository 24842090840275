<div dougsModalTitle>
  <h6>Cockpit (debug)</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p>
    Vous rencontrez un problème ? Cliquez sur le gros bouton. Il va automatiquement mettre des données utiles pour les
    développeurs dans votre presse-papier. Rendez-vous sur le canal Slack #product-cockpit !
  </p>
  <p class="container p-8">
    <dougs-button [dougsCopyToClipboard]="debugData">Cliquez !</dougs-button>
  </p>
</div>
