<div class="cockpit-title mb-16">
  <div class="cockpit-title__info">
    <ng-container
      *ngIf="
        (cockpitStateService.domain$ | async) === 'collaborator' &&
        (cockpitStateService.collaborator$ | async) as collaborator
      "
    >
      <img [src]="collaborator.avatarUrl | defaultAvatarUrl" [alt]="collaborator.id" />
      <h5>{{ collaborator.fullName | titlecase }}</h5>
    </ng-container>
    <ng-container *ngIf="(cockpitStateService.domain$ | async) === 'team' && cockpitStateService.team$ | async as team">
      <img [src]="team.avatarUrl | defaultAvatarUrl" [alt]="team.id" />
      <h5>{{ team.name | titlecase }}</h5>
    </ng-container>
    <ng-container
      *ngIf="
        (cockpitStateService.domain$ | async) === 'department' && cockpitStateService.department$ | async as department
      "
    >
      <i class="fal" [ngClass]="cockpitTeamComponentService.DOMAIN_ICON_MAPPER[department]"></i>
      <h5>{{ cockpitTeamComponentService.DOMAIN_NAME_MAPPER[department] | titlecase }}</h5>
    </ng-container>
  </div>
  <h5 class="cockpit-title__activity pl-16">Suivi d'activité</h5>
  <dougs-cockpit-stats-period></dougs-cockpit-stats-period>
</div>
<div class="cockpit-stats">
  <div class="cockpit-stats__container" *ngIf="!isLoading && stats; else loader">
    <!-- Résumé à gauche -->
    <div class="cockpit-stats__summary">
      <ng-container *ngIf="(cockpitStateService.domain$ | async) === 'department'">
        <div class="cockpit-stats__summary__completed">
          <p class="tiny color-primary-700 mb-4">Complété</p>
          <div class="cockpit-stats__summary__item">
            <p class="color-primary-700">RDV</p>
            <h3 class="color-success">{{ departmentSummary.appointments.completedThisWeek.count }}</h3>
            <p class="tiny" *ngIf="cockpitStatsComponentService.shouldShowHours$ | async">
              {{ departmentSummary.appointments.completedThisWeek.workload | minInHour }}
            </p>
          </div>
          <div class="cockpit-stats__summary__item">
            <p class="color-primary-700">Tâches</p>
            <h3 class="color-success">{{ departmentSummary.tasks.completedThisWeek.count }}</h3>
            <p class="tiny" *ngIf="cockpitStatsComponentService.shouldShowHours$ | async">
              {{ departmentSummary.tasks.completedThisWeek.workload | minInHour }}
            </p>
          </div>
          <div class="cockpit-stats__summary__item">
            <p class="color-primary-700">Intercom</p>
            <h3 class="color-success">{{ departmentSummary.intercom.closed }}</h3>
          </div>
        </div>
        <div class="cockpit-stats__summary__capacity">
          <p class="tiny color-primary-700 mb-4">Capacité</p>
          <div class="cockpit-stats__summary__item">
            <p class="color-primary-700">ETP</p>
            <h3 class="color-primary-700">{{ departmentSummary.capacity | number: '1.0-2' }}</h3>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- La table en elle-même -->
    <div class="cockpit-stats__table pl-16">
      <!-- La légende -->
      <div class="cockpit-stats__legend">
        <div
          *ngIf="(cockpitStateService.domain$ | async) !== 'collaborator'"
          class="cockpit-stats__legend__container cockpit-stats__size-team"
        >
          <p class="tiny">Équipe{{ (cockpitStateService.domain$ | async) === 'department' ? 's' : '' }}</p>
        </div>
        <div
          *ngIf="(cockpitStateService.domain$ | async) === 'department'"
          class="cockpit-stats__legend__container cockpit-stats__size-capacity"
        >
          <p class="tiny">Capacité</p>
        </div>
        <div class="cockpit-stats__legend__container cockpit-stats__size-tasks-stamp">
          <p class="tiny">Tâches</p>
        </div>
        <div class="cockpit-stats__legend__container-tasks cockpit-stats__size-tasks-bar">
          <p class="tiny">
            <dougs-badge size="small" type="success"></dougs-badge>
            Réalisé
          </p>
          <p class="tiny">
            <dougs-badge size="small" type="admin"></dougs-badge>
            À faire cette semaine
          </p>
          <p class="tiny">
            <dougs-badge size="small"></dougs-badge>
            À faire avant cette semaine
          </p>
        </div>
        <div class="cockpit-stats__legend__container cockpit-stats__size-appointments" *ngIf="anyAppointment">
          <p class="tiny">RDV</p>
        </div>
        <div
          class="cockpit-stats__legend__container cockpit-stats__size-intercom"
          *ngIf="anyClosedIntercom || anyOpenIntercom || anySnoozedIntercom"
        >
          <p class="tiny">Intercom</p>
        </div>
      </div>

      <!-- Le contenu de la table -->
      <div *ngFor="let stat of stats; trackBy: statTrackBy" class="cockpit-stats__line">
        <!-- Colonne de nom -->
        <div
          *ngIf="(cockpitStateService.domain$ | async) === 'team'"
          class="cockpit-stats__line__name cockpit-stats__size-team"
        >
          <dougs-avatar size="small" [image]="stat.collaborator.avatarUrl | defaultAvatarUrl"></dougs-avatar>
          <p class="ml-8 small">{{ stat.collaborator.fullName }}</p>
        </div>
        <div
          *ngIf="(cockpitStateService.domain$ | async) === 'department'"
          class="cockpit-stats__line__name cockpit-stats__size-team"
        >
          <dougs-avatar size="small" [image]="stat.team.avatarUrl | defaultAvatarUrl"></dougs-avatar>
          <p class="ml-8 small">{{ stat.team.name }}</p>
        </div>

        <!-- Colonne de la capacité -->
        <div
          *ngIf="(cockpitStateService.domain$ | async) === 'department'"
          class="cockpit-stats__line__capacity cockpit-stats__size-capacity"
        >
          <p class="cockpit-stats__line__capacity__total color-primary-700 mx-4">
            <i class="fa fa-users"></i>
            {{ stat.capacity | number: '1.0-2' }}
          </p>
        </div>

        <!-- Colonne des tâches -->
        <ng-template #taskTooltip>
          <div class="cockpit__tooltip__stats">
            <dougs-cockpit-stats-task-tooltip-row
              badgeType="gray"
              title="Total"
              [shouldShowHours]="cockpitStatsComponentService.shouldShowHours$ | async"
              [workload]="stat.tasks.total.workload"
              [count]="stat.tasks.total.count"
            ></dougs-cockpit-stats-task-tooltip-row>
            <dougs-cockpit-stats-task-tooltip-row
              badgeType="success"
              title="Réalisé"
              [shouldShowHours]="cockpitStatsComponentService.shouldShowHours$ | async"
              [workload]="stat.tasks.completedThisWeek.workload"
              [count]="stat.tasks.completedThisWeek.count"
            ></dougs-cockpit-stats-task-tooltip-row>
            <dougs-cockpit-stats-task-tooltip-row
              badgeType="admin"
              title="À faire cette semaine"
              [shouldShowHours]="cockpitStatsComponentService.shouldShowHours$ | async"
              [workload]="stat.tasks.todo.workload"
              [count]="stat.tasks.todo.count"
            ></dougs-cockpit-stats-task-tooltip-row>
            <dougs-cockpit-stats-task-tooltip-row
              badgeType="error"
              title="À faire avant cette semaine"
              [shouldShowHours]="cockpitStatsComponentService.shouldShowHours$ | async"
              [workload]="stat.tasks.remaining.workload"
              [count]="stat.tasks.remaining.count"
            ></dougs-cockpit-stats-task-tooltip-row>
          </div>
        </ng-template>

        <div
          class="cockpit-stats__line__workload cockpit-stats__size-tasks-bar"
          [dougsTooltip]="taskTooltip"
          [dougsTooltipSize]="big"
          [dougsTooltipDelay]="200"
        >
          <p
            *ngIf="(cockpitStateService.domain$ | async) !== 'department'"
            class="cockpit-stats__line__workload__stamp color-primary-700"
          >
            <ng-container *ngIf="cockpitStatsComponentService.shouldShowHours$ | async">
              {{ stat.tasks.total.workload | minInHour }}
            </ng-container>
            <ng-container *ngIf="!(cockpitStatsComponentService.shouldShowHours$ | async)">
              {{ stat.tasks.total.count }}
            </ng-container>
          </p>
          <p
            *ngIf="(cockpitStateService.domain$ | async) === 'department'"
            class="cockpit-stats__line__workload__stamp color-primary-700"
          >
            <ng-container *ngIf="stat.capacity">
              <ng-container *ngIf="cockpitStatsComponentService.shouldShowHours$ | async">
                {{ stat.tasks.total.workload / stat.capacity | minInHour }}
              </ng-container>
              <ng-container *ngIf="!(cockpitStatsComponentService.shouldShowHours$ | async)">
                {{ stat.tasks.total.count / stat.capacity | number: '1.0-2' }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!stat.capacity">-</ng-container>
          </p>
          <dougs-cockpit-stats-progress-bar [tasks]="stat.tasks"></dougs-cockpit-stats-progress-bar>
        </div>

        <!-- Colonne des rendez-vous -->
        <ng-container *ngIf="anyAppointment">
          <ng-template #appointmentTooltip>
            <div class="cockpit__tooltip__stats">
              <dougs-cockpit-stats-task-tooltip-row
                badgeType="gray"
                title="Total"
                [shouldShowHours]="cockpitStatsComponentService.shouldShowHours$ | async"
                [workload]="stat.appointments.todo.workload"
                [count]="stat.appointments.todo.count"
              ></dougs-cockpit-stats-task-tooltip-row>

              <dougs-cockpit-stats-task-tooltip-row
                badgeType="success"
                title="Réalisé"
                [shouldShowHours]="cockpitStatsComponentService.shouldShowHours$ | async"
                [workload]="stat.appointments.completedThisWeek.workload"
                [count]="stat.appointments.completedThisWeek.count"
              ></dougs-cockpit-stats-task-tooltip-row>
            </div>
          </ng-template>

          <div
            class="cockpit-stats__line__appointments cockpit-stats__size-appointments px-4"
            [dougsTooltip]="appointmentTooltip"
            [dougsTooltipDelay]="200"
          >
            <p class="cockpit-stats__line__appointments__total color-primary-700">
              <i class="fa fa-calendar-alt mr-4"></i>
              <ng-container *ngIf="cockpitStatsComponentService.shouldShowHours$ | async">
                {{ stat.appointments.todo.workload | minInHour }}
              </ng-container>
              <ng-container *ngIf="!(cockpitStatsComponentService.shouldShowHours$ | async)">
                {{ stat.appointments.todo.count }}
              </ng-container>
            </p>
            <p class="cockpit-stats__line__appointments__completed tiny color-gray">
              <i class="fa fa-check-circle mr-4 color-success"></i>
              <ng-container *ngIf="cockpitStatsComponentService.shouldShowHours$ | async">
                {{ stat.appointments.completedThisWeek.workload | minInHour }}
              </ng-container>
              <ng-container *ngIf="!(cockpitStatsComponentService.shouldShowHours$ | async)">
                {{ stat.appointments.completedThisWeek.count }}
              </ng-container>
            </p>
          </div>
        </ng-container>

        <!-- Colonne Intercom -->
        <ng-container *ngIf="anyClosedIntercom || anyOpenIntercom || anySnoozedIntercom">
          <ng-template #intercomTooltip>
            <div class="cockpit__tooltip__stats">
              <ng-container *ngIf="cockpitStatsComponentService.isThisWeekTheReferenceWeek$ | async">
                <dougs-badge type="admin" size="small"></dougs-badge>
                <p class="bold tiny">Ouvert</p>
                <div>
                  {{ stat.intercom.open ?? 0 }}
                </div>
              </ng-container>

              <ng-container *ngIf="cockpitStatsComponentService.isThisWeekTheReferenceWeek$ | async">
                <dougs-badge type="gray" size="small"></dougs-badge>
                <p class="bold tiny">Snoozé</p>
                <div>
                  {{ stat.intercom.snoozed ?? 0 }}
                </div>
              </ng-container>

              <dougs-badge type="success" size="small"></dougs-badge>
              <p class="bold tiny">Clôturé</p>
              <div>
                {{ stat.intercom.closed ?? 0 }}
              </div>
              <div
                *ngIf="
                  (cockpitStatsComponentService.isThisWeekTheReferenceWeek$ | async) && stat.intercom.openUpdatedAt
                "
                class="cockpit__tooltip__stats__plain"
              >
                <em>Mis à jour {{ stat.intercom.openUpdatedAt | dateSmallestUnitOfTimeDifference }}</em>
              </div>
            </div>
          </ng-template>
          <div
            class="cockpit-stats__line__intercom cockpit-stats__size-intercom px-4"
            [dougsTooltip]="intercomTooltip"
            [dougsTooltipDelay]="200"
          >
            <p *ngIf="anyOpenIntercom" class="cockpit-stats__line__appointments__total color-primary-700">
              <i class="fa fa-envelope-open color-admin mr-4"></i>
              {{ stat.intercom.open ?? 0 }}
            </p>
            <p *ngIf="anySnoozedIntercom" class="cockpit-stats__line__appointments__total tiny color-gray">
              <i class="fa fa-snooze color-gray mr-4"></i>
              {{ stat.intercom.snoozed ?? 0 }}
            </p>
            <p *ngIf="anyClosedIntercom" class="cockpit-stats__line__appointments__completed tiny color-gray">
              <i class="fa fa-check-circle mr-4 color-success"></i>
              {{ stat.intercom.closed ?? 0 }}
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
