import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';

@Component({
  selector: 'dougs-paid-up-capital-advice',
  templateUrl: './paid-up-capital-advice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, ModalFooterDirective, ButtonComponent],
})
export class PaidUpCapitalAdviceComponent {}
