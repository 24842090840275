import { Params } from '@angular/router';

export const filterQueryParams: (queryParams: Params) => Params = (queryParams: Params): Params => {
  const filteredParams: Params = { ...queryParams };
  if (filteredParams.taskId) {
    delete filteredParams.taskId;
  }
  if (filteredParams?.['user-conversation']) {
    delete filteredParams['user-conversation'];
  }
  return filteredParams;
};
