import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AvatarComponent,
  ButtonComponent,
  DougsDatePipe,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-activity-task-modal',
  templateUrl: './activity-task-modal.component.html',
  styleUrls: ['./activity-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    AvatarComponent,
    ModalFooterDirective,
    ButtonComponent,
    DougsDatePipe,
  ],
})
export class ActivityTaskModal {
  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
  ) {}
}
