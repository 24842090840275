import { Injectable } from '@angular/core';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerNegativeAssociateAccountService extends TaskActionService {
  protected _label = 'Régulariser';
  protected _isAvailable = true;

  constructor(private readonly window: Window) {
    super();
  }

  async execute(): Promise<void> {
    this.window.open(
      'https://aide.dougs.fr/comptabilite-et-obligations-comptables/que-dois-je-faire-lorsquun-compte-courant-dassocie-est-debiteur',
      '_blank',
      'noopener',
    );
  }
}
