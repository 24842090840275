<div class="modal-container">
  <div dougsModalTitle>
    <h6>Signer électroniquement vos documents de création d'entreprise</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>

  <div dougsModalContent>
    <dougs-loader *ngIf="!data.task"></dougs-loader>
    <ng-container *ngIf="data.task">
      <dougs-avatar-message size="medium" [avatarUrl]="avatarUrl" [fullName]="avatarFullName">
        <p class="mb-8 small">Bonjour {{ data.task.target.profile.firstName }}</p>
        <p class="mb-8 small">
          Vous et chacun des associés,
          <em>le cas échéant</em>
          , avez récemment reçu un e-mail vous invitant à signer électroniquement les documents de création de votre
          entreprise.
        </p>

        <p class="small">
          Suivez les indications reçues dans cet email pour
          <b>procéder à la signature</b>
          . Nous serons automatiquement notifiés une fois toutes les signatures reçues, et je pourrai transmettre votre
          dossier aux institutions compétentes.
        </p>
      </dougs-avatar-message>
    </ng-container>
  </div>
  <div dougsModalFooter>
    <dougs-button dougsModalClose color="secondary">Fermer</dougs-button>
  </div>
</div>
