import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BadgeComponent } from '@dougs/ds';
import { CockpitAccountingStatsLegendComponentService } from '../../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats-legend.component.service';
import { CockpitAccountingStatsComponentService } from '../../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats.component.service';

@Component({
  selector: 'dougs-legend',
  standalone: true,
  imports: [CommonModule, BadgeComponent],
  providers: [CockpitAccountingStatsLegendComponentService],
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComponent {
  constructor(
    public readonly legendComponentService: CockpitAccountingStatsLegendComponentService,
    public readonly cockpitAccountingStatsComponentService: CockpitAccountingStatsComponentService,
  ) {}
}
