import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DefaultAvatarUrlPipe } from '@dougs/ds';
import { CockpitAccountingTeamStats } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { CockpitStatsTeamComponentService } from '../../../../../services/cockpit/cockpit-stats/cockpit-stats-team.component.service';
import { CockpitStatsPeriodComponent } from '../../cockpit-stats/cockpit-stats-period/cockpit-stats-period.component';

@Component({
  selector: 'dougs-header',
  standalone: true,
  imports: [CommonModule, CockpitStatsPeriodComponent, DefaultAvatarUrlPipe],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(
    public readonly cockpitStateService: CockpitStateService,
    public readonly cockpitStatsTeamComponentService: CockpitStatsTeamComponentService,
  ) {}

  @Input() stats!: CockpitAccountingTeamStats | null;
}
