import { Injectable } from '@angular/core';
import { addDays, isAfter } from 'date-fns';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
import { UnseenTasksModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionUnseenTasks extends TaskActionService {
  constructor(
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {
    super();
  }

  async execute(): Promise<void> {
    await this.automaticallyExecute();
  }

  async automaticallyExecute(): Promise<void> {
    if (
      !this.companyStateService.activeCompany.isAccountingSignupCompleted ||
      !this.companyStateService.activeCompany.completedAt ||
      isAfter(addDays(new Date(this.companyStateService.activeCompany.completedAt), 2), new Date())
    ) {
      return;
    }
    const unseenTasks: Task[] = await this.userTasksStateService.getUnseenUserTasks(
      this.companyStateService.activeCompany,
    );
    if (unseenTasks?.length) {
      await lastValueFrom(
        this.modalService.open(UnseenTasksModalComponent, {
          data: {
            unseenTasks: unseenTasks,
          },
        }).afterClosed$,
      );
    }
  }
}
