import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  ButtonComponent,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LoaderComponent,
  ModalContentDirective,
  ModalFooterDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { AccountBalances, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { BankStatementCardComponent } from '../../bank-statement-card/bank-statement-card.component';

@Component({
  selector: 'dougs-bank-reconciliation-third-step',
  templateUrl: './bank-reconciliation-third-step.component.html',
  styleUrls: ['./bank-reconciliation-third-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ModalContentDirective,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    PanelInfoComponent,
    NgFor,
    BankStatementCardComponent,
    InputDatepickerComponent,
    FormFieldComponent,
    FileInputComponent,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class BankReconciliationThirdStepComponent {
  @Input() selectedAccount!: SynchronizedAccount;
  @Input()
  set selectedAccountBalances(selectedAccountBalances: AccountBalances[]) {
    this._selectedAccountBalances = selectedAccountBalances;
    this.clearAndGenerateBankStatementForm();
    this.isLoading = !this.bankStatementForm.controls.length;
    this.cdr.markForCheck();
  }
  get selectedAccountBalances() {
    return this._selectedAccountBalances;
  }
  @Input() getCurrentCardTitle!: (form: UntypedFormArray, index: number) => string;

  @Output() selectedAccountChange = new EventEmitter<SynchronizedAccount>();
  @Output() submitFormEvent = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();

  private _selectedAccountBalances!: AccountBalances[];
  isLoading = false;
  bankStatementForm: UntypedFormArray = new UntypedFormArray([]);
  bankStatements: { [key: string]: File } = {};
  isBankStatementFormSubmitDisabled = true;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
  ) {}

  private clearAndGenerateBankStatementForm(): void {
    const bankStatementRequiredBalances = this.selectedAccountBalances.filter(
      (balance) => balance.needsAttachment && !balance.hasAttachment,
    );

    this.bankStatementForm = new UntypedFormArray(
      bankStatementRequiredBalances.map((balance) => {
        return new UntypedFormGroup({
          balanceId: new UntypedFormControl(balance.id),
          date: new UntypedFormControl({ value: balance.date, disabled: true }),
        });
      }),
    );
  }

  async submitBankStatements() {
    this.isLoading = true;

    if (this.bankStatementForm.invalid || !this.selectedAccountBalances) {
      this.isLoading = false;
      return;
    }

    let uploadFailed = false;

    for (const balance of this.selectedAccountBalances) {
      if (balance.id in this.bankStatements) {
        const succeed = await this.synchronizedAccountStateService.uploadAttachment(
          this.selectedAccount,
          this.bankStatements[balance.id],
          {
            type: 'bankStatements',
            fileType: 'bankStatement',
            metadata: {
              bankStatementId: balance.id,
            },
          },
        );

        if (!succeed) {
          uploadFailed = true;
          return;
        }

        delete this.bankStatements[balance.id];
      }
    }

    if (uploadFailed) {
      this.isLoading = false;
      return;
    }

    this.submitFormEvent.emit();
  }

  previousStep() {
    this.previousStepEvent.emit();
  }

  onUploadFile(file: { balanceIndex: number; bankStatement: File }): void {
    const balanceId = this.bankStatementForm.at(file.balanceIndex)?.get('balanceId')?.value;
    if (!balanceId) {
      return;
    }

    this.bankStatements[balanceId] = file.bankStatement;

    this.isBankStatementFormSubmitDisabled =
      Object.keys(this.bankStatements).length < this.bankStatementForm.controls.length;
  }

  onDeleteFile(file: { balanceIndex: number; bankStatement: File }): void {
    const balanceId = this.bankStatementForm.at(file.balanceIndex)?.get('balanceId')?.value;
    if (!balanceId) {
      return;
    }
    delete this.bankStatements?.[balanceId];

    this.isBankStatementFormSubmitDisabled =
      Object.keys(this.bankStatements).length < this.bankStatementForm.controls.length;
  }

  getCurrentBalance(balanceIndex: number): AccountBalances | undefined {
    return this.selectedAccountBalances?.find(
      (balance) => balance.id === this.bankStatementForm.at(balanceIndex).get('balanceId')?.value,
    );
  }

  trackByIndex(index: number): number {
    return index;
  }
}
