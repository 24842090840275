import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { add, differenceInDays, endOfDay, format, isAfter, isBefore, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Company } from '@dougs/company/dto';
import { AvatarMessageComponent } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { User } from '@dougs/user/dto';

@Component({
  selector: 'dougs-accounting-survey-closing',
  templateUrl: './accounting-survey-closing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AvatarMessageComponent, NgIf],
})
export class AccountingSurveyClosingComponent {
  @Input()
  set task(task: Task) {
    const closingDate = endOfDay(new Date(task.metadata.closingDate));
    const dueDate = endOfDay(new Date(task.dueDate));

    this.setDates(closingDate, dueDate);
    this.setAccountant(closingDate);
    this.setSentencesConditions(closingDate, dueDate);
    this.remainingDaysSentence = this.getRemainingDaysSentence(dueDate);

    this.hasExtraDaysForSurvey = !!task.metadata['accountingSurveyNotification-extraDays'];
  }

  @Input()
  set company(company: Company) {
    this.hasGeneralAssembly = this.hasAgoMadeByDougs(company);
    this.isAGOIncluded = !!company.subscription?.isAGOIncluded;
  }

  @Input()
  set user(user: User) {
    this.firstName = user.profile?.firstName;
  }

  closingDate!: string;
  dueDate!: string;
  deadlineDate!: string;
  bonusDate!: string;
  hasGeneralAssembly!: boolean;
  isAGOIncluded!: boolean;
  firstName!: string | undefined;
  accountantFullName!: string;
  avatarUrl!: string;

  isBeforeClosingDate!: boolean;
  isFirst31DaysAfterClosingDate!: boolean;
  isAfterFirst31DaysAndBeforeDueDate!: boolean;
  isFirst15DaysLate!: boolean;
  isTooLate!: boolean;
  hasExtraDaysForSurvey!: boolean;

  remainingDaysSentence!: string;

  setDates(closingDate: Date, dueDate: Date) {
    this.closingDate = format(closingDate, 'dd MMMM yyyy', { locale: fr });
    this.dueDate = format(dueDate, 'dd MMMM yyyy', { locale: fr });
    this.bonusDate = format(add(closingDate, { days: new Date() > new Date('2022-12-03') ? 31 : 15 }), 'dd MMMM yyyy', {
      locale: fr,
    });
    this.deadlineDate = format(add(dueDate, { days: 15 }), 'dd MMMM yyyy', { locale: fr });
  }

  setAccountant(closingDate: Date) {
    const elapsedDays = differenceInDays(new Date(), closingDate);
    this.accountantFullName = elapsedDays <= 31 ? 'Romain, Votre comptable Dougs' : 'Sarah, Responsable comptabilité';
    this.avatarUrl =
      elapsedDays <= 31
        ? 'https://images.prismic.io/test-dougs/58a190f4-a09e-494d-96a1-ea4c885bd1ef_romain5.jpeg'
        : 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg';
  }

  setSentencesConditions(closingDate: Date, dueDate: Date) {
    const now = new Date();
    this.isBeforeClosingDate = isBefore(now, closingDate);
    this.isFirst31DaysAfterClosingDate =
      isAfter(now, closingDate) &&
      this.isSameOrBefore(now, add(closingDate, { days: new Date() > new Date('2022-12-03') ? 31 : 15 }));
    this.isAfterFirst31DaysAndBeforeDueDate =
      isAfter(now, add(closingDate, { days: new Date() > new Date('2022-12-03') ? 31 : 15 })) &&
      this.isSameOrBefore(now, dueDate);
    this.isFirst15DaysLate = isAfter(now, dueDate) && this.isSameOrBefore(now, add(dueDate, { days: 15 }));
    this.isTooLate = isAfter(now, add(dueDate, { days: 15 }));
  }

  isSameOrBefore(date1: Date, date2: Date) {
    return isBefore(date1, date2) || isSameDay(date1, date2);
  }

  getRemainingDaysSentence(dueDate: Date) {
    const remainingDays = differenceInDays(dueDate, endOfDay(new Date()));
    if (remainingDays === 0) {
      return "jusqu'à ce soir minuit";
    }
    if (remainingDays === 1) {
      return "jusqu'à demain soir";
    }
    return `${remainingDays} jours`;
  }

  private hasAgoMadeByDougs(company: Company) {
    return company.legalFormConfig.hasGeneralAssembly && company.legalFormConfig.value !== 'ass';
  }
}
