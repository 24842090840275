import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CockpitAccountingStatsComponentService } from './cockpit-accounting-stats.component.service';

@Injectable()
export class CockpitAccountingStatsLegendComponentService {
  constructor(private readonly accountingStatsComponentService: CockpitAccountingStatsComponentService) {}

  public readonly oneColumnLegend$: Observable<boolean> = combineLatest([
    this.accountingStatsComponentService.showClassicBilan$,
    this.accountingStatsComponentService.isCompletedStats$,
    this.accountingStatsComponentService.activityBilanToggleMode$,
  ]).pipe(
    map(
      ([showClassicBilan, isCompletedStats, activityBilanToggleMode]: [boolean, boolean, boolean]) =>
        !showClassicBilan || isCompletedStats || activityBilanToggleMode,
    ),
  );

  public readonly showBilanLegend$: Observable<boolean> = combineLatest([
    this.accountingStatsComponentService.showClassicBilan$,
    this.accountingStatsComponentService.isBilanModeToggle$,
  ]).pipe(map(([showClassicBilan, isBilanModeToggle]: [boolean, boolean]) => showClassicBilan || isBilanModeToggle));
}
