<div dougsModalTitle>
  <h6>L'analyse de votre convention collective est prête !</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-avatar-message
    [avatarUrl]="ccnAnalysisAvailableModalComponentService.task$ | async | taskActionAvatarUrl"
    [fullName]="ccnAnalysisAvailableModalComponentService.task$ | async | taskActionCollaboratorName"
    size="medium"
  >
    <p>N’hésitez pas à vous rapprocher de nous pour toutes questions via le tchat dans l’application.</p>

    <p>A bientôt chez Dougs !</p>
  </dougs-avatar-message>
  <dougs-file-details
    class="mt-16"
    *ngFor="let attachment of ccnAnalysisAvailableModalComponentService.attachments$ | async; trackBy: 'id' | trackBy"
    [attachment]="attachment"
  ></dougs-file-details>
</div>
<div dougsModalFooter>
  <dougs-button type="button" [dougsModalClose]="true">D'accord !</dougs-button>
</div>
