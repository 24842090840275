import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { LegalCreationModalComponent } from '../../modals/legal-creation-modal/legal-creation-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionLegalCreationService extends TaskActionService {
  protected _label = 'Renvoyer les projets de statuts';

  constructor(private readonly modalService: ModalService) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return !!(
      (task?.tasks?.length &&
        task?.tasks.find(
          (subTask) =>
            subTask.code === 'legal:48hCreation.transmitProject' ||
            subTask.code === 'legal:standardCreation.transmitProject' ||
            subTask.code === 'legal:new48hCreation.transmitProject' ||
            subTask.code === 'legal:newStandardCreation.transmitProject' ||
            subTask.code === 'legal:creationSASAutomatic.sendDocumentsToCustomer' ||
            subTask.code === 'legal:creationEURLAutomatic.sendDocumentsToCustomer' ||
            subTask.code === 'legal:creationSciAutomatic.beforeCapitalDepositSendDocumentToCustomer' ||
            subTask.code === 'legal:creationSciAutomatic.afterCapitalDepositSendDocumentToCustomer',
        )?.completedAt) ??
      false
    );
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }
    this.modalService.open(LegalCreationModalComponent, {
      data: task,
    });
  }
}
