import { Injectable } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { toPromise } from '@dougs/core/utils';
import { DrawerService } from '@dougs/ds';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

@Injectable()
export class TaskFilesComponentService {
  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly drawerService: DrawerService,
  ) {}

  getAttachmentByType(task: Task, taskTemplate: TaskTemplate | null): { [key: string]: Attachment[] } {
    let attachmentByType: { [key: string]: Attachment[] } = {};
    if (task && taskTemplate) {
      attachmentByType = taskTemplate.fileAttachmentSlots.reduce(
        (acc: { [key: string]: Attachment[] }, fileAttachmentSlot) => {
          acc[fileAttachmentSlot.type] =
            task.attachments?.filter((attachment) => attachment.type === fileAttachmentSlot.type) || [];

          return acc;
        },
        {},
      );

      attachmentByType.attachment = task.attachments?.filter((attachment) => attachment.type === 'attachment') ?? [];
    }

    return attachmentByType;
  }

  async onRemoveTaskFile(task: Task, attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(task, attachment);
  }

  async onUploadTaskFiles(task: Task, files: FileList, type?: string): Promise<void> {
    const { TaskFilesValidationComponentDrawer } = await import(
      '../../../modals/task-files-validation/task-files-validation-drawer.component'
    );
    const filesToUpload: File[] | null | undefined = (
      await toPromise(
        this.drawerService.open<File[] | null>(TaskFilesValidationComponentDrawer, {
          data: {
            task,
            files,
          },
        }).afterClosed$,
      )
    )?.data;

    if (filesToUpload) {
      await this.controlPanelTasksStateService.uploadTaskAttachments(task, filesToUpload, {
        fileType: type,
      });
    }
  }
}
