import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrustResourceHtmlPipe,
} from '@dougs/ds';
import { EmailPreviewModalComponentService } from '../../services/tasks/task/email-preview-modal.component.service';
import { EmailErrorBlankslateComponent } from './email-error-blankslate/email-error-blankslate.component';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { EmailSkeletonLoadingComponent } from './email-skeleton-loading/email-skeleton-loading.component';

@Component({
  selector: 'dougs-email-preview-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    TrustResourceHtmlPipe,
    EmailPreviewComponent,
    EmailSkeletonLoadingComponent,
    EmailErrorBlankslateComponent,
  ],
  templateUrl: './email-preview-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmailPreviewModalComponentService],
})
export class EmailPreviewModalComponent {
  constructor(public readonly emailPreviewModalComponentService: EmailPreviewModalComponentService) {}
}
