import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { SaleServiceDesComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerSaleServicesDESService extends TaskActionService {
  protected _label = "Plus d'informations";
  protected _isAvailable = true;
  protected _tag = 'Déclarations';
  protected _tagColor: Pill = 'primary';
  protected _tagIcon = 'fa-scroll';

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const succeeded: boolean | null | undefined = (
      await lastValueFrom(this.modalService.open<boolean>(SaleServiceDesComponent).afterClosed$)
    ).data;

    if (succeeded) {
      await this.taskService.completeTask(task);
    }
  }
}
