import { Injectable, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CockpitAccountingStatsCollaborator, CockpitAccountingStatsCollaboratorBalanceSheets } from '@dougs/task/dto';
import { CockpitAccountingStatsComponentService } from './cockpit-accounting-stats.component.service';

@Injectable()
export class CockpitBilanBarsComponentService {
  constructor(private readonly accountingStatsComponentService: CockpitAccountingStatsComponentService) {}

  public completedWidth = 0;
  public preparedWidth = 0;
  public verifiedWidth = 0;
  public revisionWidth = 0;

  private readonly collaboratorStat: WritableSignal<CockpitAccountingStatsCollaborator | null> =
    signal<CockpitAccountingStatsCollaborator | null>(null);

  computeCollaboratorStat$: Observable<void> = combineLatest([
    toObservable(this.collaboratorStat),
    this.accountingStatsComponentService.collaboratorBilanMaxValue$,
  ]).pipe(
    map(([collaboratorStat, maxValue]: [CockpitAccountingStatsCollaborator | null, number]) =>
      this.computeCollaboratorBilanStats(collaboratorStat?.balanceSheets, maxValue),
    ),
  );

  setCollaboratorStat(collaboratorStat: CockpitAccountingStatsCollaborator): void {
    this.collaboratorStat.set(collaboratorStat);
  }

  public computeCollaboratorBilanStats(
    collaboratorBilanStats: CockpitAccountingStatsCollaboratorBalanceSheets | undefined,
    maxValue: number,
  ): void {
    if (collaboratorBilanStats) {
      this.completedWidth = this.getWidth(collaboratorBilanStats.accountingSurveyCompleted, maxValue);
      this.preparedWidth = this.getWidth(collaboratorBilanStats.prepared, maxValue);
      this.verifiedWidth = this.getWidth(collaboratorBilanStats.accountingSurveyVerified, maxValue);
      this.revisionWidth = this.getWidth(collaboratorBilanStats.revisionCompleted, maxValue);
    }
  }

  private getWidth(value: number, maxValue: number): number {
    if (!value || !maxValue) {
      return 0;
    }
    return Math.round((value / maxValue) * 100);
  }
}
