import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionAccountingQualityControlService extends TaskActionService {
  _label = 'Contrôler la tâche';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task || !task.metadata.taskToControlId) {
      return;
    }
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { QualityControlModalComponent } = await import('@dougs/task/ui');
    this.modalService.open(QualityControlModalComponent, { data: task });
  }
}
