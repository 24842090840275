import { AsyncPipe, CurrencyPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarComponent, DougsDatePipe, TooltipDirective } from '@dougs/ds';
import { Activity } from '@dougs/task/dto';
import { FormatAuthorFromHubspotActivityPipe } from '../../../pipes/control-panel/format-author-from-hubspot-activity.pipe';
import { UserInfoPipe } from '../../../pipes/control-panel/user-info.pipe';
import { ActivityComponentService } from '../../../services/control-panel/activity/activity.component.service';
import { TaskCommentComponent } from '../task/task-conversation/task-comment/task-comment.component';
import { ActivityCommentComponent } from './activity-comment/activity-comment.component';

@Component({
  selector: 'dougs-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgIf,
    AvatarComponent,
    TooltipDirective,
    TaskCommentComponent,
    NgFor,
    AsyncPipe,
    CurrencyPipe,
    UserInfoPipe,
    FormatAuthorFromHubspotActivityPipe,
    ActivityCommentComponent,
    DougsDatePipe,
  ],
  providers: [ActivityComponentService],
})
export class ActivityComponent {
  @Input() activity!: Activity;

  constructor(public readonly activityComponentService: ActivityComponentService) {}

  getEmojiForRating(rating: number): string {
    switch (rating) {
      case 1:
        return 'pouting-face';
      case 2:
        return 'white-frowning-face';
      case 3:
        return 'neutral-face';
      case 4:
        return 'grinning-face';
      case 5:
        return 'star-struck-face';
      default:
        return 'neutral-face';
    }
  }
}
