<div dougsModalTitle>
  <h6>Vérification des soldes bancaires</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>

<div dougsModalContent *ngIf="isLoading || !synchronizedAccounts" class="modal-content-loader-container">
  <dougs-loader></dougs-loader>
</div>

<dougs-bank-reconciliation-first-step
  *ngIf="!isLoading && step === 1 && synchronizedAccounts?.length"
  [synchronizedAccounts]="synchronizedAccounts"
  [bankReconciliationNeeded]="bankReconciliationNeeded"
  [task]="task"
  [reconciliationCompletedAccountsIds]="reconciliationCompletedAccountsIds"
  (selectAccountEvent)="selectAccount($event)"
></dougs-bank-reconciliation-first-step>

<dougs-bank-reconciliation-second-step
  *ngIf="!isLoading && selectedAccountBalances.length && step === 2"
  [(selectedAccount)]="selectedAccount"
  [selectedAccountBalances]="selectedAccountBalances"
  [getCurrentCardTitle]="getCurrentCardTitle"
  (previousStepEvent)="previousStep($event)"
  (submitFormEvent)="saveAndGoToNextStep($event)"
></dougs-bank-reconciliation-second-step>

<dougs-bank-reconciliation-third-step
  *ngIf="!isLoading && selectedAccount && selectedAccountBalances.length && step === 3"
  [(selectedAccount)]="selectedAccount"
  [selectedAccountBalances]="selectedAccountBalances"
  [getCurrentCardTitle]="getCurrentCardTitle"
  (previousStepEvent)="previousStep($event)"
  (submitFormEvent)="saveAndGoToNextStep($event)"
></dougs-bank-reconciliation-third-step>
