<dougs-form-field [noMargin]="true">
  <label for="other" dougsFormFieldLabel>Indiquez les autres éléments variables</label>
  <textarea
    autosize
    rows="3"
    [name]="'other' + uuid"
    #other="ngModel"
    dougsFormFieldControl
    [disabled]="!isEditable"
    [(ngModel)]="variableData.other"
    id="other"
  ></textarea>
</dougs-form-field>
