import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'taskActionCollaboratorName',
  standalone: true,
})
export class TaskActionCollaboratorNamePipe implements PipeTransform {
  transform(task: Task): string {
    if (task.metadata.collaborator?.fullName) {
      return task.metadata.collaborator.fullName;
    }
    return 'Sarah Jaouani';
  }
}
