<ng-container *ngIf="suggestProductModalService.plans$ | async"></ng-container>
<form
  [formGroup]="suggestProductModalService.formGroup"
  (ngSubmit)="suggestProductModalService.sendProductSuggestion()"
>
  <div dougsModalTitle>
    <h6>Recommander un produit pour le client</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <ng-container *ngIf="!suggestProductModalService.isLoading$()">
      <p>Pack actuel : {{ (companyStateService.activeCompany$ | async).subscription?.plan | titlecase }}</p>
      <p>Vers quel produit le client souhaiterait évoluer ?</p>
      <dougs-form-field [noMargin]="true">
        <dougs-select dougsFormFieldControl formControlName="desiredProduct" placeholder="Produit">
          <dougs-select-option
            *ngFor="let plan of suggestProductModalService.plans$ | async; trackBy: 'id' | trackBy"
            [value]="plan.id"
          >
            Pack {{ plan.name }}
          </dougs-select-option>
        </dougs-select>
      </dougs-form-field>
    </ng-container>
    <dougs-loader *ngIf="suggestProductModalService.isLoading$()"></dougs-loader>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">Envoyer</dougs-button>
  </div>
</form>
