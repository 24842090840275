import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileDetailsComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { TaskActionAvatarUrlPipe } from '../../pipes/task-action-avatar-url.pipe';
import { TaskActionCollaboratorNamePipe } from '../../pipes/task-action-collaborator-name.pipe';
import { CcnAnalysisAvailableModalComponentService } from '../../services/modals/ccn-analysis-available-modal.component.service';

@Component({
  selector: 'dougs-ccn-analysis-available-modal',
  templateUrl: './ccn-analysis-available-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    NgFor,
    FileDetailsComponent,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
    AsyncPipe,
    TaskActionCollaboratorNamePipe,
    TaskActionAvatarUrlPipe,
  ],
  providers: [CcnAnalysisAvailableModalComponentService],
})
export class CcnAnalysisAvailableModalComponent {
  constructor(public readonly ccnAnalysisAvailableModalComponentService: CcnAnalysisAvailableModalComponentService) {}
}
