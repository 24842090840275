import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent, DougsDatePipe, IconButtonComponent, TrackByPipe } from '@dougs/ds';
import { CockpitStatsComponentService } from '../../../../../services/cockpit/cockpit-stats/cockpit-stats.component.service';

@Component({
  selector: 'dougs-cockpit-stats-period',
  standalone: true,
  imports: [CommonModule, TrackByPipe, ButtonComponent, IconButtonComponent, DougsDatePipe],
  templateUrl: './cockpit-stats-period.component.html',
  styleUrls: ['./cockpit-stats-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CockpitStatsPeriodComponent {
  @Input() disableFuture = false;

  constructor(public readonly cockpitStatsComponentService: CockpitStatsComponentService) {}
}
