import { Pipe, PipeTransform } from '@angular/core';
import { SlotDocuments } from '@dougs/task/dto';

@Pipe({
  name: 'isSlotSelected',
  standalone: true,
})
export class IsSlotSelectedPipe implements PipeTransform {
  transform(slotDocument: SlotDocuments, selectedAttachments: (number | string)[]): boolean {
    return slotDocument.attachments.every((attachment) => selectedAttachments.includes(attachment.id));
  }
}
