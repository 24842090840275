import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, withLatestFrom } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DougsDatePipe } from '@dougs/ds';
import { BillingInvoice } from '@dougs/subscription/dto';
import { BillingInvoiceStateService } from '@dougs/subscription/shared';
import { AddBillingInvoiceItemModalComponentService } from '../add-billing-invoice-item-modal.component.service';

@Injectable()
export class SearchInvoiceComponentService {
  searchInvoiceControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  searchInvoiceValueChanges$: Observable<BillingInvoice[]> = this.searchInvoiceControl.valueChanges.pipe(
    distinctUntilChanged(),
    withLatestFrom(this.billingInvoiceStateService.billingInvoices$),
    map(([searchedText, billingInvoices]) => {
      return billingInvoices.filter((billingInvoice) => {
        const searchableData = [
          this.dougsDatePipe.transform(billingInvoice.date, 'MMMM yyyy'),
          billingInvoice.number,
        ].join(' ');
        return `${searchedText}`
          .toUpperCase()
          .split(' ')
          .every((searchWord) => searchableData.toUpperCase().indexOf(searchWord) > -1);
      });
    }),
  );

  constructor(
    private readonly billingInvoiceStateService: BillingInvoiceStateService,
    private readonly dougsDatePipe: DougsDatePipe,
    private readonly addBillingInvoiceItemModalComponentService: AddBillingInvoiceItemModalComponentService,
  ) {}

  invoiceSearchResultClicked(invoice: BillingInvoice, index: number): void {
    this.searchInvoiceControl.setValue((invoice.number || 0).toString());
    this.addBillingInvoiceItemModalComponentService.invoiceSearchResultClicked(invoice, index);
  }
}
