<div [ngClass]="{ 'mb-8': !isLastItem }" class="invoice-line">
  <div [ngClass]="{ 'long-line': forceLongLine }" class="invoice-line__description">
    {{ invoiceItem?.fullDescription }}
  </div>
  <div class="invoice-line__divider">
    <dougs-divider></dougs-divider>
  </div>
  <div class="invoice-line__amount">
    {{ invoiceItem?.totalAmount | currency: 'EUR' }}
    <i (click)="redirectToTask()" *ngIf="showRedirectToTask" class="fal fa-tasks ml-4 color-admin"></i>
    <i (click)="deleteInvoiceItem()" *ngIf="showDeleteInvoiceItem" class="fal fa-times ml-4 color-error"></i>
  </div>
</div>
