import { Injectable } from '@angular/core';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionDefaultService extends TaskActionService {
  constructor(private readonly taskService: TaskService) {
    super();
  }

  async execute(task: Task | null): Promise<void> {
    if (task) {
      await this.taskService.completeTask(task);
    }
  }
}
