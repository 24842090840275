import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserInfo } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Pipe({
  name: 'userInfo',
  standalone: true,
})
export class UserInfoPipe implements PipeTransform {
  constructor(private readonly userStateService: UserStateService) {}

  transform(userId: number): Observable<UserInfo | undefined> {
    return this.userStateService.usersInfo$.pipe(
      map((usersInfo) => usersInfo.find((userInfo) => userInfo.id === userId)),
    );
  }
}
