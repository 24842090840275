import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent, DetectFormChangesDirective, LoaderComponent } from '@dougs/ds';
import { CollaboratorFormComponentService } from '../../../../services/modals/collaborator-payslip/collaborator-form.component.service';
import { CollaboratorPayslipModalComponentService } from '../../../../services/modals/collaborator-payslip/collaborator-payslip-modal.component.service';
import { CollaboratorVariableCardComponent } from './collaborator-variable-card/collaborator-variable-card.component';

@Component({
  selector: 'dougs-collaborator-form',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CollaboratorVariableCardComponent,
    DetectFormChangesDirective,
    LoaderComponent,
  ],
  templateUrl: './collaborator-form.component.html',
  styleUrl: './collaborator-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorFormComponent {
  constructor(
    public readonly collaboratorFormComponentService: CollaboratorFormComponentService,
    public readonly collaboratorPayslipModalComponentService: CollaboratorPayslipModalComponentService,
  ) {}
}
