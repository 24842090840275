import { Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { FlashMessagesService, ModalService } from '@dougs/ds';
import { CapitalDeposit, CapitalDepositStateService, OnboardingStateService } from '@dougs/legal/shared';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerCapitalDepositService extends TaskActionService {
  protected _label = 'Attacher mon attestation de dépôt de capital';
  protected _isAvailable = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
    private readonly companyStateService: CompanyStateService,
    private readonly capitalDepositStateService: CapitalDepositStateService,
    private readonly onboardingStateService: OnboardingStateService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    await this.onboardingStateService.refreshOnboarding();
    await this.capitalDepositStateService.refreshCapitalDeposit(this.companyStateService.activeCompany);

    const capitalDeposit: CapitalDeposit = await lastValueFrom(
      this.capitalDepositStateService.capitalDeposit$.pipe(take(1)),
    );
    if (!capitalDeposit) {
      return;
    }

    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { CapitalDepositModalComponent, PartnershipCapitalDepositModalComponent } = await import('@dougs/legal/ui');

    const taskUpdated: Task | undefined | null = (
      await lastValueFrom(
        this.modalService.open<Task>(
          capitalDeposit.isEligible || capitalDeposit.isInProcess
            ? PartnershipCapitalDepositModalComponent
            : CapitalDepositModalComponent,
          {
            data: {
              task: task,
              personInCharge: this.onboardingStateService.onboarding.finalStage.personInCharge,
            },
          },
        ).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      const completed: boolean = await this.taskService.completeTask(taskUpdated);

      if (completed) {
        this.flashMessagesService.show('Votre dépôt de capital a été déposé 🎉', {
          type: 'success',
          timeout: 5000,
        });
      }
    }
  }
}
