import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@dougs/task/dto';

@Pipe({
  name: 'formatAuthorFromHubspotActivity',
  standalone: true,
})
export class FormatAuthorFromHubspotActivityPipe implements PipeTransform {
  transform(activity: Activity, field = 'emailCreatedBy'): string {
    if (!activity.data.metadata?.[field]) {
      return 'un workflow HubSpot';
    }
    return activity.data.metadata[field]
      .split('@')[0]
      .split('.')
      .map((namePart: string) => namePart[0].toUpperCase() + namePart.slice(1).toLowerCase())
      .join(' ');
  }
}
