import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AvatarMessageComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { SurveyAppointmentModalComponentService } from '../../services/modals/survey-appointment-modal.component.service';
import { EmojiRatingComponent } from './emoji-rating/emoji-rating.component';

@Component({
  selector: 'dougs-survey-appointment-modal.component',
  templateUrl: './survey-appointment-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    AvatarMessageComponent,
    ControlFormFieldDirective,
    FormsModule,
    ReactiveFormsModule,
    EmojiRatingComponent,
  ],
  providers: [SurveyAppointmentModalComponentService],
})
export class SurveyAppointmentModalComponent {
  constructor(public readonly surveyAppointmentModalService: SurveyAppointmentModalComponentService) {}
}
