import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class SidebarCompactComponentService {
  constructor(private readonly userStateService: UserStateService) {}

  private readonly forceReducedSidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public forceReducedSidebar$: Observable<boolean> = this.forceReducedSidebarSubject.asObservable();

  compactSidebar$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.forceReducedSidebar$,
  ]).pipe(
    map(([loggedInUser, reducedSidebar]: [User, boolean]) => loggedInUser?.metadata?.compactAppAside || reducedSidebar),
  );

  setForceReducedSidebar(reduced: boolean): void {
    this.forceReducedSidebarSubject.next(reduced);
  }
}
