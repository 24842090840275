<input
  #inputSearch
  dougsAutofocus
  dougsFormFieldControl
  class="input-search py-4 px-8"
  [formControl]="searchUserControl"
  placeholder="Rechercher..."
/>
<dougs-loader *ngIf="isLoading"></dougs-loader>
<ng-container *ngIf="users$ | async as users">
  <p class="small p-8" *ngIf="!isLoading && users.length === 0">Aucun résultat</p>
  <ng-container *ngIf="!isLoading">
    <dougs-dropdown-option *ngIf="shouldShowMe" (click)="onSelectUser(null)">
      <i class="far fa-times-circle mr-8"></i>
      Assigner à personne
    </dougs-dropdown-option>
    <dougs-dropdown-option *ngFor="let user of users; trackBy: 'id' | trackBy" (click)="onSelectUser(user)">
      <div class="option-container">
        <dougs-avatar
          size="small"
          class="mr-8 avatar"
          [image]="user.profile?.avatarUrl || user.profile?.alternateAvatarUrl"
        ></dougs-avatar>
        <div>
          <div class="referrer-name">{{ user.profile?.fullName }}</div>
          <div class="referrer-legal mt-4">{{ user.company?.legalName }}</div>
        </div>
      </div>
    </dougs-dropdown-option>
  </ng-container>
</ng-container>
