import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ButtonComponent,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { CockpitTask, Task } from '@dougs/task/dto';
import { CockpitStateService, ModalTaskStateService } from '@dougs/task/shared';
import { TaskComponent } from '../../components/control-panel/task/task.component';

@Component({
  selector: 'dougs-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    TaskComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class TaskModalComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public task?: Task;
  private modalTaskSubscription!: Subscription;

  constructor(
    @Inject(MODAL_DATA)
    public taskId: number,
    private readonly cockpitStateService: CockpitStateService,
    private readonly modalTaskStateService: ModalTaskStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.modalTaskStateService.refreshTasks(this.taskId, true);

    this.modalTaskSubscription = this.modalTaskStateService.modalTask$
      .pipe(
        tap((task: Task) => {
          if (task && task?.id === this.taskId) {
            this.task = task;
            this.isLoading = false;
            this.cdr.markForCheck();
          }
        }),
      )
      .subscribe();

    // Note: there would be a way to avoid to do this by maintaining current state...
    await this.cockpitStateService.refreshUnseenStatsOfCurrentUser();

    const taskInCockpitState: CockpitTask | undefined = this.cockpitStateService.get('tasks')?.get(this.taskId);

    if (taskInCockpitState) {
      await this.cockpitStateService.updateTasksState([
        { id: this.taskId, taskHasBeenSeenAfterCreation: true, allMentionsAreSeen: true },
      ]);
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.modalTaskSubscription.unsubscribe();
  }

  closeTaskModal(): void {
    this.modalRef.close({ modalHasBeenClosedAutomatically: true });
  }
}
