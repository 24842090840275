import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlankStateComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-vip',
  templateUrl: './vip.component.html',
  styleUrls: ['./vip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BlankStateComponent],
})
export class VipComponent {}
