import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CockpitAccountingStatsCollaborator } from '@dougs/task/dto';
import { AccountingStatsBarWidthDirective } from '../../../../../../directives/accounting-stats-bar-width.directive';
import { ShowBarLabelPipe } from '../../../../../../pipes/cockpit/show-bar-label.pipe';
import { CockpitAccountingStatsComponentService } from '../../../../../../services/cockpit/cockpit-stats/cockpit-accounting-stats.component.service';
import { CockpitBilanBarsComponentService } from '../../../../../../services/cockpit/cockpit-stats/cockpit-bilan-bars.component.service';

@Component({
  selector: 'dougs-collaborator-bilan-bars',
  standalone: true,
  imports: [CommonModule, AccountingStatsBarWidthDirective, ShowBarLabelPipe],
  providers: [CockpitBilanBarsComponentService],
  templateUrl: './collaborator-bilan-bars.component.html',
  styleUrls: ['./collaborator-bilan-bars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorBilanBarsComponent {
  constructor(
    public readonly cockpitBilanBarsComponentService: CockpitBilanBarsComponentService,
    public readonly accountingStatsComponentService: CockpitAccountingStatsComponentService,
  ) {}

  private _collaboratorStat!: CockpitAccountingStatsCollaborator;

  get collaboratorStat(): CockpitAccountingStatsCollaborator {
    return this._collaboratorStat;
  }

  @Input()
  set collaboratorStat(value: CockpitAccountingStatsCollaborator) {
    this._collaboratorStat = value;
    this.cockpitBilanBarsComponentService.setCollaboratorStat(this.collaboratorStat);
  }

  @Input() hovered = false;
}
