import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PillComponent, TooltipDirective, UserLabelPillPipe } from '@dougs/ds';
import { CompanyPill } from '@dougs/user/dto';

@Component({
  selector: 'dougs-user-label',
  templateUrl: './user-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PillComponent, TooltipDirective, NgClass, UserLabelPillPipe],
})
export class UserLabelComponent {
  @Input() pill!: CompanyPill;
}
