import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalRef } from '@dougs/ds';
import { ProductsService } from '@dougs/revenue/products/shared';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { SubscriptionStateService } from '@dougs/subscription/shared';

@Injectable()
export class SuggestProductModalService {
  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  formGroup: FormGroup = new FormGroup({
    desiredProduct: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly productsService: ProductsService,
    public readonly modalRef: ModalRef,
  ) {}

  plans$: Observable<SubscriptionPlan[] | void> = combineLatest([
    this.subscriptionStateService.plans$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(([plans, activeCompany]: [plans: SubscriptionPlan[] | void, activeCompany: Company]) => {
      return plans ? plans.filter((plan) => plan.id !== activeCompany.subscription?.plan) : [];
    }),
  );

  get desiredProduct(): FormControl {
    return this.formGroup.get('desiredProduct') as FormControl;
  }

  async sendProductSuggestion(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (this.desiredProduct.valid) {
      this.isLoading.set(true);

      await this.productsService.suggestProductForCompany(
        this.companyStateService.activeCompany.id,
        this.desiredProduct.value,
      );

      this.modalRef.close();
      this.isLoading.set(false);
    }
  }
}
