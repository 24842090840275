<div dougsModalTitle>
  <h6>Questionnaire de bilan</h6>
  <i *ngIf="userStateService.loggedInUser?.isAccountantOrAdmin" dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-accounting-survey-reopening
    *ngIf="statusAccountingYear === 'reopening'"
    [task]="data.task"
  ></dougs-accounting-survey-reopening>
  <dougs-accounting-survey-liquidating
    *ngIf="statusAccountingYear === 'liquidating'"
    [task]="data.task"
    [user]="data.user"
  ></dougs-accounting-survey-liquidating>
  <dougs-accounting-survey-closing
    *ngIf="statusAccountingYear === 'closing'"
    [task]="data.task"
    [user]="data.user"
    [company]="data.company"
  ></dougs-accounting-survey-closing>
</div>
<div dougsModalFooter>
  <dougs-button *ngIf="allowDismiss" color="secondary" dougsModalClose>Plus tard</dougs-button>
  <dougs-button color="primary" (click)="goToAccountingSurvey()">Voir mon questionnaire bilan&nbsp;&rarr;</dougs-button>
</div>
