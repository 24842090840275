import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResponsiveService } from '@dougs/core/responsive';

@Component({
  selector: 'dougs-emoji-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './emoji-rating.component.html',
  styleUrls: ['./emoji-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmojiRatingComponent),
      multi: true,
    },
  ],
})
export class EmojiRatingComponent implements ControlValueAccessor {
  constructor(public readonly responsiveService: ResponsiveService) {}

  rating!: number;

  onTouched!: () => void;

  propagateChange!: (value: number) => void;

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (value: number) => void): void {
    this.propagateChange = fn;
  }

  writeValue(rating: number): void {
    this.rating = rating;
  }

  setRating(rating: number): void {
    this.rating = rating;
    this.propagateChange(rating);
    this.onTouched();
  }
}
