import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent, PillComponent, TrackByPipe } from '@dougs/ds';
import { SlotDocuments } from '@dougs/task/dto';
import { AllSlotsSelectedPipe } from '../../../pipes/control-panel/slot-documents/all-slots-selected.pipe';
import { IsIncludedPipe } from '../../../pipes/control-panel/slot-documents/is-included.pipe';
import { IsSlotSelectedPipe } from '../../../pipes/control-panel/slot-documents/is-slot-selected.pipe';
import { MakeDocumentsAvailableComponentService } from '../../../services/modals/make-documents-available.component.service';

@Component({
  selector: 'dougs-slot-documents-selector',
  standalone: true,
  imports: [
    CommonModule,
    CheckboxComponent,
    TrackByPipe,
    FormsModule,
    PillComponent,
    IsIncludedPipe,
    IsSlotSelectedPipe,
    AllSlotsSelectedPipe,
  ],
  templateUrl: './slot-documents-selector.component.html',
  styleUrls: ['./slot-documents-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlotDocumentsSelectorComponent {
  @Input() slotDocuments!: SlotDocuments[];

  constructor(public readonly makeDocumentsAvailableComponentService: MakeDocumentsAvailableComponentService) {}
}
