import { Routes } from '@angular/router';
import { RedirectToDefaultAccountingYearGuard } from '@dougs/accounting-review/shared';

export const ACCOUNTING_REVIEW_ROUTES: Routes = [
  {
    path: '',
    canActivate: [RedirectToDefaultAccountingYearGuard],
    children: [
      {
        path: ':accountingYear',
        loadComponent: () => import('./accounting-review.component').then((c) => c.AccountingReviewComponent),
        children: [
          {
            path: 'pending-items',
            loadComponent: () => import('./pending-items/pending-items.component').then((c) => c.PendingItemsComponent),
          },
          {
            path: 'supervision-preparation',
            loadComponent: () =>
              import('./supervision-preparation/supervision-preparation.component').then(
                (c) => c.SupervisionPreparationComponent,
              ),
          },
          {
            path: 'supervision',
            loadComponent: () => import('./supervision/supervision.component').then((c) => c.SupervisionComponent),
          },
          {
            path: 'send-balance-sheets',
            loadComponent: () =>
              import('./send-balance-sheets/send-balance-sheets.component').then((c) => c.SendBalanceSheetsComponent),
          },
          {
            path: 'tax-summary',
            loadComponent: () => import('./tax-summary/tax-summary.component').then((c) => c.TaxSummaryComponent),
          },
          {
            path: '',
            loadComponent: () =>
              import('./cycles-balances/cycles-balances.component').then((c) => c.CyclesBalancesComponent),
            children: [
              {
                path: 'cycles',
                redirectTo: 'cycles/',
              },
              {
                path: 'balances',
                redirectTo: 'balances/',
              },
              {
                path: 'cycles/:ledgerId',
                loadComponent: () => import('./cycles/cycles.component').then((c) => c.CyclesComponent),
              },
              {
                path: 'cycles/:ledgerId/lines',
                loadComponent: () =>
                  import('./accounting-lines/accounting-lines.component').then((c) => c.AccountingLinesComponent),
              },
              {
                path: 'balances/:ledgerId',
                loadComponent: () => import('./balances/balances.component').then((c) => c.BalancesComponent),
              },
              {
                path: 'balances/:ledgerId/lines',
                loadComponent: () =>
                  import('./accounting-lines/accounting-lines.component').then((c) => c.AccountingLinesComponent),
              },
              {
                path: '**',
                redirectTo: 'cycles',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
