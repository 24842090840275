<ng-container *ngIf="referrerComponentService.refreshReferralStats$ | async"></ng-container>
<ng-container *ngIf="userStateService.activeUser$ | async as activeUser">
  <div class="form-layout mb-16">
    <div class="referrer-link-container py-16 px-24">
      <div class="referrer-image-container">
        <img class="referrer-image" src="images/illustrations/referrer.png" />
      </div>
      <div class="referrer-link-text">
        <h5 class="title mb-8">Parrainez vos proches</h5>
        <div class="subtitle mb-8">
          {{
            !activeUser.isEligibleToComptastart
              ? "Partagez votre lien de parrainage, nous offrons un mois de comptabilité à vos filleuls et vous bénéficiez d'un \nmois de comptabilité offert pour chaque inscription activée."
              : 'Partagez votre lien de parrainage, nous offrons un mois de comptabilité à vos filleuls.'
          }}
        </div>
        <div class="referral-link mb-8">
          <div class="referral-link__input">
            <label dougsFormFieldLabel class="mb-4">Lien de parrainage</label>
            <input
              dougsFormFieldControl
              type="text"
              disabled
              [value]="'https://www.dougs.fr?r=' + activeUser.referralHash"
            />
          </div>
          <dougs-button
            color="admin"
            [dougsCopyToClipboard]="'https://www.dougs.fr?r=' + activeUser.referralHash"
            (click)="handleCopyLinkButtonClick()"
          >
            Copier mon lien
          </dougs-button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin">
    <div class="selected-referrer form-layout mb-16" (click)="openUsersDropdown()" #referer>
      <dougs-form-field [noMargin]="true">
        <label dougsFormFieldLabel [isAdmin]="true">Parrain</label>
        <div dougsFormFieldControl class="p-8">
          {{ activeUser.referrer?.profile?.fullName ?? 'Pas de parrain' }}
        </div>
        <i dougsFormFieldSuffix *ngIf="!activeUser.referrer" class="fal fa-user"></i>
        <dougs-avatar
          dougsFormFieldSuffix
          *ngIf="activeUser.referrer"
          size="small"
          class="avatar"
          [image]="activeUser.referrer.profile?.avatarUrl || activeUser.referrer.profile?.alternateAvatarUrl"
        ></dougs-avatar>
      </dougs-form-field>
    </div>
    <dougs-dropdown
      class="select-dropdown"
      #dropdownUsers
      [reference]="referer"
      [widthToRefElement]="true"
      (closed)="onDropdownClose()"
    >
      <dougs-referrer-user-dropdown
        [isOpened]="dropdownOpened"
        [shouldShowMe]="true"
        (selectUser)="onUpdateSelectedReferrer($event)"
      ></dougs-referrer-user-dropdown>
    </dougs-dropdown>
  </ng-container>
  <div class="form-layout" *ngIf="referrerStateService.referralStats$ | async as referralStats">
    <div>
      <h6 class="stats-title">Filleuls</h6>
      <div class="stats-text">
        <span *ngIf="referralStats?.totalRefereeCount > 0">
          Vous avez {{ referralStats?.totalRefereeCount ?? 0 }} filleuls dont
          {{ referralStats?.trialRefereeCount ?? 0 }} en période d'essai.
          <span *ngIf="!activeUser.isEligibleToComptastart">
            À ce jour, vous avez donc bénéficié de {{ referralStats?.activeRefereeCount ?? 0 }} mois offerts.
          </span>
        </span>
        <span *ngIf="!referralStats?.totalRefereeCount">Vous n'avez aucun filleul pour le moment.</span>
      </div>
    </div>
  </div>

  <dougs-referrer-partnership
    *ngIf="
      (userStateService.loggedInUser$ | async)?.isAdmin &&
      (companyStateService.activeCompany$ | async).flags?.includes('enablePartnership')
    "
  />
</ng-container>
