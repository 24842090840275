<div dougsModalTitle>
  <h6>Relevé de situation</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent="xlarge">
  <dougs-loader *ngIf="isLoading"></dougs-loader>
  <div class="table" *ngIf="!isLoading">
    <table>
      <thead>
        <tr>
          <th>Jnl</th>
          <th>Date</th>
          <th>Compte</th>
          <th>Libellé</th>
          <th>Montant</th>
          <th>Solde</th>
          <th>Lettrage</th>
          <ng-container
            *ngIf="(userStateService.loggedInUser$ | async).flags.includes('allowOperationModalSituationReport')"
          >
            <th></th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let subscriptionDetail of subscriptionDetails">
          <td>{{ subscriptionDetail.journal }}</td>
          <td>{{ subscriptionDetail.date | date: 'dd/MM/yyyy' }}</td>
          <td>{{ subscriptionDetail.accountingNumber }}</td>
          <td>{{ subscriptionDetail.wording }}</td>
          <td>{{ (subscriptionDetail.isCredit ? -subscriptionDetail.amount : subscriptionDetail.amount) / 100 }}</td>
          <td>{{ subscriptionDetail.cumulative / 100 }}</td>
          <td>{{ subscriptionDetail.lettering }}</td>
          <td *ngIf="(userStateService.loggedInUser$ | async).flags.includes('allowOperationModalSituationReport')">
            <dougs-button
              color="admin"
              size="small"
              (click)="openOperationModal(subscriptionDetail.operationId, subscriptionDetail.companyId)"
            >
              Voir l'opération
            </dougs-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button dougsModalClose>Fermer</dougs-button>
</div>
