import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { DividerComponent, DougsDatePipe } from '@dougs/ds';

@Component({
  selector: 'dougs-next-billing',
  templateUrl: './next-billing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, DividerComponent, AsyncPipe, DougsDatePipe],
})
export class NextBillingComponent {
  constructor(public readonly companyStateService: CompanyStateService) {}
}
