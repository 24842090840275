<div dougsModalContent>
  @if (!(synchronizedAccounts.length - countAccountsChecked)) {
    <h5 class="mb-4">{{ countAccountsChecked > 1 ? 'Comptes vérifiés' : 'Compte vérifié' }} avec succès</h5>
    <p class="small color-primary-700 mb-32">
      {{ countAccountsChecked > 1 ? 'Vos comptes ont été vérifiés' : 'Votre compte a été vérifié' }} avec succès : tout
      est en ordre ! Merci de nous avoir aidé à réaliser cette action
      <img class="thumbs-up" width="16" src="images/emoji/thumbs-up.png" alt="Bravo" />
    </p>
  } @else {
    <h5 class="mb-4">Vérifiez vos comptes bancaires</h5>
    <p class="small color-primary-700 mb-32">
      Pour garantir une comptabilité juste sur Dougs, vérifiez régulièrement la correspondance entre vos relevés
      bancaires et le solde sur votre compte Dougs. Cela ne prend que quelques minutes.
    </p>
  }

  <ul *ngFor="let synchronizedAccount of synchronizedAccounts; trackBy: trackById" class="accounts-container pl-0">
    <li class="accounts-container__account-item">
      <div class="account-container py-8 pr-16" [ngClass]="'m-' + synchronizedAccount.color">
        <div class="account-title account-detail-border pl-8">
          <h6 class="account-title__bank-name">
            {{ synchronizedAccount.bankName }} ·
            <ng-container *ngIf="synchronizedAccount.memo">
              <span class="account-name color-gray">{{ synchronizedAccount.memo }}</span>
              <span> · </span>
            </ng-container>
            <span class="account-name color-gray">{{ synchronizedAccount.accountName }}</span>
          </h6>
        </div>
      </div>
      <dougs-button
        class="check-button"
        [fullWidth]="true"
        (click)="selectAccount(synchronizedAccount)"
        *ngIf="!reconciliationCompletedAccountsIds[synchronizedAccount.id]"
      >
        {{
          resumeReconciliationAccountsIds[synchronizedAccount.id]
            ? 'Reprendre la vérification'
            : 'Démarrer la vérification'
        }}
      </dougs-button>
      <dougs-pill type="success" *ngIf="reconciliationCompletedAccountsIds[synchronizedAccount.id]">
        <i class="fal fal fa-circle-check mr-4"></i>Vérifié
      </dougs-pill>
    </li>
  </ul>
</div>

<div dougsModalFooter *ngIf="countAccountsChecked && countAccountsChecked === synchronizedAccounts.length">
  <dougs-button [dougsModalClose]="task">Terminer</dougs-button>
</div>
