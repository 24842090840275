import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileDetailsComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { CorrectedDsnAvailableModalComponentService } from '../../services/modals/corrected-dsn-available-modal.component.service';

@Component({
  selector: 'dougs-dsn-available-modal',
  templateUrl: './corrected-dsn-available-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    AvatarMessageComponent,
    NgFor,
    FileDetailsComponent,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
    AsyncPipe,
  ],
  providers: [CorrectedDsnAvailableModalComponentService],
})
export class CorrectedDsnAvailableModalComponent {
  constructor(public readonly correctedDsnAvailableModalComponentService: CorrectedDsnAvailableModalComponentService) {}
}
