import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import { DividerComponent, InputDatepickerComponent } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { SubscriptionComponentService } from '../../../services/subscription.component.service';

@Component({
  selector: 'dougs-subscription-period',
  templateUrl: './subscription-period.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, InputDatepickerComponent, FormsModule, ReactiveFormsModule, DividerComponent, AsyncPipe],
})
export class SubscriptionPeriodComponent {
  constructor(
    public readonly userStateService: UserStateService,
    public readonly formService: FormService,
    public readonly subscriptionComponentService: SubscriptionComponentService,
  ) {}
}
