<div dougsModalTitle>
  <h6>
    {{
      payslipAvailableModalComponentService.taskPayslipDate$ | async | date: 'MMMM yyyy' | taskActionModalPayslipTitle
    }}
  </h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-avatar-message
    [avatarUrl]="payslipAvailableModalComponentService.task$ | async | taskActionAvatarUrl"
    [fullName]="payslipAvailableModalComponentService.task$ | async | taskActionCollaboratorName"
    size="medium"
  >
    <p>
      {{
        payslipAvailableModalComponentService.taskPayslipDate$ | async | date: 'MMMM yyyy' | taskActionModalPayslipText
      }}. Vous pouvez également le retrouver à tout moment dans votre onglet Prestations.
    </p>

    <p>N’hésitez pas à vous rapprocher de nous pour toutes questions via le tchat dans l’application.</p>

    <p>A bientôt chez Dougs !</p>
  </dougs-avatar-message>
  <dougs-file-details
    class="mt-16"
    *ngFor="let attachment of payslipAvailableModalComponentService.attachments$ | async; trackBy: 'id' | trackBy"
    [attachment]="attachment"
  ></dougs-file-details>
</div>
<div dougsModalFooter>
  <dougs-button type="button" [dougsModalClose]="true">D'accord !</dougs-button>
</div>
