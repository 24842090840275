import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService } from '@dougs/ds';
import { CapitalDepositStateService, OnboardingStateService } from '@dougs/legal/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProjectValidationModalComponent } from '@dougs/legal/ui';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerCreationProjectValidationService extends TaskActionService {
  protected _label = 'Consulter mon projet de statuts';
  protected _icon = 'fal fa-pen';
  protected _isAvailable = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
    private readonly capitalDepositStateService: CapitalDepositStateService,
    private readonly onboardingStateService: OnboardingStateService,
    private readonly companyStateService: CompanyStateService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    await this.capitalDepositStateService.refreshCapitalDeposit(this.companyStateService.activeCompany);
    await this.onboardingStateService.refreshOnboarding();

    const taskUpdated = (
      await lastValueFrom(
        this.modalService.open<boolean>(ProjectValidationModalComponent, {
          data: {
            task: task,
            personInCharge: this.onboardingStateService.onboarding.finalStage.personInCharge,
          },
        }).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      await this.taskService.completeTask(task);
    }
  }
}
