import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { replaceCompanyIdInUrlAsCommand } from '@dougs/core/routing';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionSalesEntrySurveyService extends TaskActionService {
  _label = "Voir le questionnaire d'entrée";

  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly router: Router,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task || !task?.targetId) {
      return;
    }

    const { EntrySurveyModalComponent } = await import('@dougs/surveys/ui');

    await this.router.navigate(replaceCompanyIdInUrlAsCommand(task.companyId.toString(), this.router.url));
    const success: boolean | undefined | null = (
      await lastValueFrom(this.modalService.open<boolean>(EntrySurveyModalComponent).afterClosed$)
    )?.data;
    // TODO Voir si on peut pas avoir une socket ou récupérer la tâche updated
    if (success) {
      await this.controlPanelTasksStateService.refreshTasks(this.companyStateService.activeCompany.id);
    }
  }
}
