import { AsyncPipe, CurrencyPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { differenceInDays } from 'date-fns';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AvatarComponent,
  ButtonComponent,
  DougsDatePipe,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
  TooltipDirective,
} from '@dougs/ds';
import { BillingInvoice } from '@dougs/subscription/dto';
import { BillingInvoiceStateService } from '@dougs/subscription/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PaymentService } from '@dougs/subscription/ui';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-past-due-billing-invoice-modal',
  styleUrls: ['./past-due-billing-invoice-modal.component.scss'],
  templateUrl: './past-due-billing-invoice-modal.component.html',
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    AvatarComponent,
    TooltipDirective,
    PanelInfoComponent,
    ButtonComponent,
    ModalFooterDirective,
    AsyncPipe,
    TitleCasePipe,
    CurrencyPipe,
    DougsDatePipe,
  ],
})
export class PastDueBillingInvoiceModalComponent implements OnInit {
  canCancel = true;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
      cantCancelBeforeDelay: boolean;
    },
    private readonly intercom: Intercom,
    private readonly companyStateService: CompanyStateService,
    public readonly billingInvoiceStateService: BillingInvoiceStateService,
    public readonly userStateService: UserStateService,
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly paymentService: PaymentService,
    private readonly window: Window,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.billingInvoiceStateService.refreshBillingInvoices(this.companyStateService.activeCompany.id);

    if (!this.companyStateService.activeCompany.subscription?.isUnpaidSince) {
      return;
    }

    const unpaidDays = differenceInDays(
      new Date(),
      new Date(this.companyStateService.activeCompany?.subscription?.isUnpaidSince),
    );

    if (this.data.cantCancelBeforeDelay && unpaidDays >= 40) {
      this.canCancel = false;
      setTimeout(() => {
        this.canCancel = true;
        this.cdr.markForCheck();
      }, 60_000);
    }
  }

  async openCheckoutModal(): Promise<void> {
    const cardIsUpdated = await this.paymentService.updatePaymentCard(this.data.task);
    if (cardIsUpdated) {
      this.modalRef.close(true);
    }
  }

  downloadFile(billingInvoice: BillingInvoice): void {
    this.window.open(
      `/companies/${billingInvoice.billedCompanyId}/billing-invoices/${billingInvoice.id}/actions/download`,
      '_blank',
    );
  }

  openIntercom() {
    this.intercom.showNewMessage(`Bonjour,\nJe vous contacte car j'ai une ou plusieurs factures impayées.`);
  }

  trackById(_index: number, billingInvoice: BillingInvoice) {
    return billingInvoice.id;
  }
}
