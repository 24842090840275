<div dougsModalFooter>
  <dougs-button color="secondary" (click)="backToList.emit()">Revenir aux salariés</dougs-button>
  @if (!completed) {
    @if (!disabled) {
      <dougs-button (click)="save.emit()">Valider la saisie du salarié</dougs-button>
    } @else {
      <dougs-button color="secondary" (click)="modify.emit()"
        ><i class="fal fa-pen mr-8"></i> Modifier la saisie du salarié
      </dougs-button>
    }
  }
</div>
