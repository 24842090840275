<div class="line">
  <div class="line__left">
    <dougs-avatar [image]="payslipLine?.profile?.avatarUrl | defaultAvatarUrl" />
    <div class="line__left__label">
      <p class="small bold color-primary-700 text-ellipsis">
        {{ payslipLine.profile.firstName }} {{ payslipLine.profile.lastName }}
      </p>
      <p class="tiny">
        {{ payslipLine.socialInfo.salary | currency: 'EUR' : 'symbol' : '0.0-0' }}
        ({{ payslipLine.socialInfo.isNet ? 'net' : 'brut' }})
      </p>
    </div>
  </div>
  <div class="line__cta">
    <dougs-button
      [disabled]="collaboratorPayslipModalComponentService.isValidating$()"
      (click)="collaboratorPayslipModalComponentService.setSelectedLine(payslipLine)"
      [color]="payslipLine.status === PayslipInfoStatus.COMPLETED ? 'secondary' : 'primary'"
      >{{ payslipLine.status | payslipLineCtaLabel }}
      @if (payslipLine.status === PayslipInfoStatus.STARTED) {
        <i class="fal fa-arrow-right color-white ml-4"></i>
      }
    </dougs-button>
  </div>
</div>
