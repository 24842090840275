<dougs-form-field [noMargin]="true">
  <label for="transportSubscriptionAmount" dougsFormFieldLabel>Montant total de l'abonnement (en € TTC)</label>
  <input
    [name]="'transportSubscriptionAmount' + uuid"
    #transportSubscriptionAmount="ngModel"
    dougsFormFieldControl
    min="0"
    step="0.01"
    [disabled]="!isEditable"
    [(ngModel)]="variableData.transportSubscriptionAmount"
    [required]="true"
    type="number"
    id="transportSubscriptionAmount"
  />
  <i class="fal fa-euro-sign" dougsFormFieldSuffix></i>
  <span dougsFormFieldError *ngIf="formService.isNgModelInvalid(transportSubscriptionAmount)">
    <ng-container *ngIf="transportSubscriptionAmount.errors.required">Ce champ est requis</ng-container>
    <ng-container *ngIf="transportSubscriptionAmount.errors.min">Le montant doit être un nombre positif</ng-container>
  </span>
</dougs-form-field>
