<div class="header-summary p-8">
  <h6>{{ task.title }}</h6>
  <div class="header-summary__infos mt-8">
    <div class="header-summary__infos__line">
      <p class="tiny">
        <i class="fal fa-folder mr-4" dougsTooltip="Dossier"></i>
        <span class="color-admin">{{ task | targetIdentifier }}</span>
      </p>
      <p class="tiny">
        <i class="fal fa-user-secret mr-4" dougsTooltip="Assignée à"></i>
        <span>{{ task.assignee?.profile?.fullName || 'Assignée à personne' }}</span>
      </p>
    </div>
  </div>
</div>
