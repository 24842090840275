import { Pipe, PipeTransform } from '@angular/core';
import { PostIt } from '@dougs/user/dto';

@Pipe({
  name: 'postItTooltip',
  standalone: true,
})
export class PostItTooltipPipe implements PipeTransform {
  transform(postIt: PostIt): string {
    return `${postIt.title}<br/><br/>${postIt.content}<br/><br/> ${postIt.attachments
      ?.map((attachment) => attachment.name)
      .join('<br/>')}`;
  }
}
