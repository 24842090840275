@if (collaboratorPayslipModalComponentService.refreshPayslipModalInfo$ | async) {}
@if (collaboratorPayslipModalComponentService.updateSelectedPayslipLine$ | async) {}
<div dougsModalTitle>
  <h6>Variables de paie pour {{ task.metadata?.payslipDate | date: 'MMMM yyyy' }}</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div
  [dougsModalContent]="(collaboratorPayslipModalComponentService.selectedPayslipLine$ | async) ? 'xlarge' : 'medium'"
  [class.no-overflow]="collaboratorPayslipModalComponentService.selectedPayslipLine$ | async"
>
  @if (collaboratorPayslipModalComponentService.selectedPayslipLine$ | async) {
    <dougs-payslip-form [payslipLine]="collaboratorPayslipModalComponentService.selectedPayslipLine$ | async" />
  } @else {
    <dougs-payslip-list />
  }
</div>
@if (collaboratorPayslipModalComponentService.selectedPayslipLine$ | async) {
  <dougs-form-footer
    [disabled]="collaboratorPayslipModalComponentService.selectedPayslipLineIsCompleted$ | async"
    [completed]="collaboratorPayslipModalComponentService.payslipDisabled$ | async"
    (backToList)="collaboratorPayslipModalComponentService.goBackToPayslipList()"
    (save)="collaboratorPayslipModalComponentService.onFinalizeEmployeeForm()"
    (modify)="collaboratorPayslipModalComponentService.uncompletePayslipLine()"
  />
} @else {
  <dougs-list-footer
    (finalize)="collaboratorPayslipModalComponentService.finalizePayslip()"
    [disabled]="collaboratorPayslipModalComponentService.disabledFinalize$ | async"
    [completed]="collaboratorPayslipModalComponentService.payslipDisabled$ | async"
    [isLoading]="collaboratorPayslipModalComponentService.isValidating$()"
  />
}
