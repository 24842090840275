<div dougsModalTitle>
  <h6>Prenez rendez-vous pour votre création d'entreprise</h6>
</div>
<div dougsModalContent>
  <dougs-avatar-message [avatarUrl]="avatarUrl" size="medium" [fullName]="avatarFullName">
    <p class="small mb-8">Bonjour {{ targetFirstName }},</p>
    <p class="small mb-8">
      Merci de nous faire confiance pour vous accompagner dans la création de votre entreprise. Pour lancer votre
      projet, et répondre à toutes vos questions, nous allons échanger par téléphone. Je vous laisse donc
      <strong>prendre rendez-vous</strong>
      directement avec votre juriste.
    </p>
    <p class="small mb-8">Pour rappel, ce rendez-vous est essentiel au lancement de la procédure.</p>
  </dougs-avatar-message>
</div>

<div dougsModalFooter class="appointment-modal-footer">
  <dougs-button color="secondary" dougsModalClose>Me le rappeler une prochaine fois</dougs-button>
  <dougs-button (click)="redirectionCalendly()" dougsModalClose>
    Choisir mon créneau de rendez-vous&nbsp;&rarr;
  </dougs-button>
</div>
