<div class="form-layout mb-16" *ngIf="companyStateService.activeCompany$ | async as activeCompany">
  <div class="subscription-line">
    <div class="line-title">Pack choisi</div>
    <div class="line-content">
      {{ activeCompany.subscription?.planDescriptor.name }}
      <span
        *ngIf="
          !activeCompany.subscription?.isAnnuallyPaid && !(userStateService.activeUser$ | async).isEligibleToComptastart
        "
      >
        {{ activeCompany.subscription?.planDescriptor.amount * activeCompany.subscription?.quantity }} €/mois
      </span>
      <span *ngIf="activeCompany.subscription?.isAnnuallyPaid">(paiement annuel)</span>
      <span *ngIf="activeCompany.subscription?.suspended">Abonnement résilié</span>
    </div>
  </div>

  <div class="form-layout__no-grow self-end button-container" *ngIf="shouldShowPlansModal$ | async">
    <dougs-button
      *ngIf="(userStateService.loggedInUser$ | async).isAccountantOrAdmin"
      [fullWidth]="true"
      size="small"
      color="admin"
      (click)="productsService.openSuggestProductModal()"
    >
      Recommander pour l'upsell
    </dougs-button>
    <dougs-button [fullWidth]="true" size="small" color="secondary" (click)="openPlansModal()">
      Voir les packs
    </dougs-button>
  </div>
</div>
