<div *ngIf="billingInvoice?.items" class="pending-billing-invoice__container p-16">
  <h6 *ngIf="displayTitle" class="mb-8">
    Facture n°{{ billingInvoice?.number }}
    <span class="pending-billing-invoice__title__date ml-4 color-gray">
      {{ billingInvoice?.date | date: 'dd MMMM yyyy' }}
    </span>
  </h6>

  <ng-container *ngFor="let invoiceItem of billingInvoice.items; trackBy: trackById; let last = last">
    <dougs-billing-invoice-line
      (openTaskModal)="openTaskModal.emit($event)"
      (removeBillingInvoiceItem)="removeBillingInvoiceItem.emit($event)"
      [forceLongLine]="forceLongLine"
      [invoiceItem]="invoiceItem"
      [isLastItem]="last"
    ></dougs-billing-invoice-line>
  </ng-container>
  <ng-container *ngIf="userStateService.loggedInUser$ | async as loggedInUser">
    <div
      *ngIf="
        loggedInUser?.isAccountantOrAdmin && loggedInUser.flags.includes('canAddPendingInvoiceItems') && canAddService
      "
      class="mt-8 pending-billing-invoice__add-service"
    >
      <p (click)="addService.emit(billingInvoice)" class="add-service color-gray-325 small">+ Ajouter une prestation</p>
    </div>
  </ng-container>
  <div class="pending-billing-invoice__total mt-16">
    <div class="pending-billing-invoice__total__ht">
      <div class="pending-billing-invoice__total__ht__title">
        <h5>Total HT</h5>
      </div>
      <div class="pending-billing-invoice__total__ht__amount">
        <h5>{{ billingInvoice?.amountExcludingTaxes | currency: 'EUR' }}</h5>
      </div>
    </div>
    <div class="pending-billing-invoice__total__ttc">
      <div class="pending-billing-invoice__total__ttc__title">Total TTC</div>
      <div class="pending-billing-invoice__total__ttc__amount">
        {{ billingInvoice?.amount | currency: 'EUR' }}
      </div>
    </div>
  </div>
</div>
