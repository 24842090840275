import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dougs-email-error-blankslate',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './email-error-blankslate.component.html',
  styleUrl: './email-error-blankslate.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class EmailErrorBlankslateComponent {}
