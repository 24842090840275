import { Injectable, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CockpitAccountingStatsCollaborator, CockpitAccountingStatsCollaboratorActivity } from '@dougs/task/dto';
import { CockpitAccountingStatsComponentService } from './cockpit-accounting-stats.component.service';

@Injectable()
export class CockpitActivityBarsComponentService {
  constructor(private readonly accountingStatsComponentService: CockpitAccountingStatsComponentService) {}

  public assignedThisWeekWidth = 0;
  public assignedBeforeWeekWidth = 0;
  public completedWidth = 0;
  public productivityWidth = 0;
  public totalActivity = 0;

  private readonly collaboratorStat: WritableSignal<CockpitAccountingStatsCollaborator | null> =
    signal<CockpitAccountingStatsCollaborator | null>(null);

  computeCollaboratorStat$: Observable<void> = combineLatest([
    toObservable(this.collaboratorStat),
    this.accountingStatsComponentService.collaboratorActivityMaxValue$,
  ]).pipe(
    map(([collaboratorStat, maxValue]: [CockpitAccountingStatsCollaborator | null, number]) =>
      this.computeCollaboratorActivityStats(collaboratorStat?.activity, maxValue),
    ),
  );

  setCollaboratorStat(collaboratorStat: CockpitAccountingStatsCollaborator): void {
    this.collaboratorStat.set(collaboratorStat);
  }

  public computeCollaboratorActivityStats(
    collaboratorActivityStats: CockpitAccountingStatsCollaboratorActivity | undefined,
    maxValue: number,
  ): void {
    if (collaboratorActivityStats) {
      this.totalActivity =
        (collaboratorActivityStats.pointsAssignedThisWeek ?? 0) +
        (collaboratorActivityStats.pointsAssignedBeforeThisWeek ?? 0) +
        (collaboratorActivityStats.pointsCompletedThisWeek ?? 0);
      this.assignedThisWeekWidth = this.getWidth(collaboratorActivityStats.pointsAssignedThisWeek ?? 0, maxValue);
      this.assignedBeforeWeekWidth = this.getWidth(
        collaboratorActivityStats.pointsAssignedBeforeThisWeek ?? 0,
        maxValue,
      );
      this.completedWidth = this.getWidth(collaboratorActivityStats.pointsCompletedThisWeek ?? 0, maxValue);
      this.productivityWidth = this.getWidth(collaboratorActivityStats.averageProductivity ?? 0, maxValue);
    }
  }

  private getWidth(value: number, maxValue: number): number {
    if (!value || !maxValue) {
      return 0;
    }
    return Math.round((value / maxValue) * 100);
  }
}
