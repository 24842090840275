import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { lastValueFrom, take } from 'rxjs';
import { RoutingService, URL } from '@dougs/core/routing';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class CanAccessCockpitCollaboratorGuard {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly routingService: RoutingService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const loggedInUser: User = await lastValueFrom(this.userStateService.loggedInUser$.pipe(take(1)));

    if (
      loggedInUser.flags?.includes('cockpitDebug') ||
      loggedInUser.id === Number(route.paramMap.get('collaboratorUserId'))
    ) {
      return true;
    }

    return this.routingService.createUrl([URL.COCKPIT], true, { queryParams: route.queryParams });
  }
}
