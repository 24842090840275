import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CharteredAccountantComponentService } from '@dougs/accounting-review/ui';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCharteredAccountantClosingVisaService extends TaskActionService {
  _label = 'Accepter / refuser';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly charteredAccountantComponentService: CharteredAccountantComponentService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return (
      !task?.completedAt !== null &&
      this.userStateService.loggedInUser?.isAccountantOrAdmin &&
      this.userStateService.loggedInUser?.flags.includes('role:charteredAccountant')
    );
  }

  async execute(task: Task): Promise<void> {
    await this.accountingYearStateService.refreshAccountingYears(task.companyId);
    const accountingYears: AccountingYear[] = await lastValueFrom(
      this.accountingYearStateService.accountingYears$.pipe(take(1)),
    );

    if (!task.metadata.accountingYearId) {
      return;
    }

    const accountingYear: AccountingYear | undefined = accountingYears.find(
      (accountingYear) => accountingYear.id === task.metadata.accountingYearId,
    );

    if (!accountingYear) {
      return;
    }
    await this.charteredAccountantComponentService.applyVisa(accountingYear);

    await this.controlPanelTasksStateService.getTask(task);
  }
}
