@if (payslipFormComponentService.refreshPayslip$ | async) {}
@if (payslipFormComponentService.updateSelectedPayslip$ | async) {}
<div class="payslip">
  @if (payslipFormComponentService.isLoading$()) {
    <dougs-loader />
  } @else {
    <dougs-employee-infos />
    <dougs-customer-variables />
    <dougs-collaborator-form />
  }
</div>
