import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isIncluded',
  standalone: true,
})
export class IsIncludedPipe implements PipeTransform {
  transform(array: (number | string)[], item?: number | string): boolean {
    return !!item && array.includes(item);
  }
}
