import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  ButtonComponent,
  CopyToClipboardDirective,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { CockpitStateService } from '@dougs/task/shared';

@Component({
  selector: 'dougs-cockpit-debug-modal',
  templateUrl: './cockpit-debug-modal.component.html',
  styleUrls: ['./cockpit-debug-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, ModalTitleDirective, ModalCloseDirective, ModalContentDirective, CopyToClipboardDirective],
})
export class CockpitDebugModalComponent implements OnInit {
  public debugData = '';

  constructor(public readonly cockpitStateService: CockpitStateService) {}

  ngOnInit(): void {
    this.debugData = JSON.stringify(this.cockpitStateService.getStateHistory());
  }
}
