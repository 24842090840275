import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MetricsService } from '@dougs/core/metrics';
import { OwnershipService } from '@dougs/core/ownership';
import { DividerComponent, MinInHourPipe, ModalService } from '@dougs/ds';
import { CockpitDebugModalComponent } from '../../modals/cockpit-debug-modal/cockpit-debug-modal.component';
import { CockpitCategoriesComponentService } from '../../services/cockpit/cockpit-categories.component.service';
import { CockpitFilterComponentService } from '../../services/cockpit/cockpit-filter.component.service';
import { CockpitMetricsComponentService } from '../../services/cockpit/cockpit-metrics.component.service';
import { CockpitStatsCollaboratorComponentService } from '../../services/cockpit/cockpit-stats/cockpit-stats-collaborator.component.service';
import { CockpitStatsDepartmentComponentService } from '../../services/cockpit/cockpit-stats/cockpit-stats-department.component.service';
import { CockpitStatsTeamComponentService } from '../../services/cockpit/cockpit-stats/cockpit-stats-team.component.service';
import { CockpitStatsComponentService } from '../../services/cockpit/cockpit-stats/cockpit-stats.component.service';
import { CockpitTasksAssignationComponentService } from '../../services/cockpit/cockpit-tasks/cockpit-tasks-assignation.component.service';
import { CockpitTasksPartitionComponentService } from '../../services/cockpit/cockpit-tasks/cockpit-tasks-partition.component.service';
import { CockpitTasksSelectionComponentService } from '../../services/cockpit/cockpit-tasks/cockpit-tasks-selection.component.service';
import { CockpitTasksComponentService } from '../../services/cockpit/cockpit-tasks/cockpit-tasks.component.service';
import { CockpitTeamComponentService } from '../../services/cockpit/cockpit-team.component.service';
import { CockpitComponentService } from '../../services/cockpit/cockpit.component.service';
import { CockpitHeaderComponent } from './cockpit-header/cockpit-header.component';
import { CockpitHeaderMenuComponent } from './cockpit-header-menu/cockpit-header-menu.component';
import { CockpitMainMenuComponent } from './cockpit-header-menu/cockpit-main-menu/cockpit-main-menu.component';

@Component({
  selector: 'dougs-cockpit',
  standalone: true,
  imports: [
    CommonModule,
    CockpitHeaderComponent,
    CockpitHeaderMenuComponent,
    DividerComponent,
    RouterOutlet,
    CockpitMainMenuComponent,
  ],
  templateUrl: './cockpit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CockpitCategoriesComponentService,
    CockpitFilterComponentService,
    CockpitMetricsComponentService,
    CockpitStatsComponentService,
    CockpitStatsCollaboratorComponentService,
    CockpitStatsTeamComponentService,
    CockpitStatsDepartmentComponentService,
    CockpitTasksComponentService,
    CockpitTasksAssignationComponentService,
    CockpitTasksSelectionComponentService,
    CockpitTasksPartitionComponentService,
    CockpitTeamComponentService,
    MinInHourPipe,
  ],
})
export class CockpitComponent implements OnInit, OnDestroy {
  private controlIsPressed = false;

  constructor(
    private readonly ownershipService: OwnershipService,
    private readonly metricsService: MetricsService,
    private readonly modalService: ModalService,
    public readonly cockpitComponentService: CockpitComponentService,
    public readonly cockpitFilterComponentService: CockpitFilterComponentService,
    public readonly cockpitMetricsComponentService: CockpitMetricsComponentService,
    public readonly cockpitStatsComponentService: CockpitStatsComponentService,
    public readonly cockpitTasksComponentService: CockpitTasksComponentService,
    public readonly cockpitTasksSelectionComponentService: CockpitTasksSelectionComponentService,
    public readonly cockpitTasksPartitionComponentService: CockpitTasksPartitionComponentService,
    public readonly cockpitCategoriesComponentService: CockpitCategoriesComponentService,
  ) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyDownEvent(event: KeyboardEvent): void {
    const eventKey: string = event.key.toLowerCase();

    if (eventKey === 'shift') {
      this.cockpitTasksSelectionComponentService.shiftIsPressed = true;
    }
    if (eventKey === 'control') {
      this.controlIsPressed = true;
    }

    if (this.controlIsPressed && eventKey === 'h') {
      this.modalService.open(CockpitDebugModalComponent);
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUpEvent(event: KeyboardEvent): void {
    const eventKey: string = event?.key?.toLowerCase();

    if (eventKey === 'shift') {
      this.cockpitTasksSelectionComponentService.shiftIsPressed = false;
    }
    if (eventKey === 'control') {
      this.controlIsPressed = false;
    }
  }

  @HostListener('window:beforeunload')
  onWindowUnload(): void {
    this.metricsService.pushMixpanelEvent('Task Cockpit Page Session Ended');
  }

  ngOnInit(): void {
    this.metricsService.loadClarity();

    if (this.ownershipService.isMyCompany()) {
      this.metricsService.pushMixpanelEvent('Task Cockpit Page Viewed');
    }

    this.metricsService.pushMixpanelTimeEvent('Task Cockpit Page Session Ended');
  }

  ngOnDestroy(): void {
    this.metricsService.pushMixpanelEvent('Task Cockpit Page Session Ended');
    this.metricsService.unloadClarity();
  }
}
