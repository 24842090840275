import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { DEPARTMENTS, TaskDepartment } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class CanAccessCockpitDepartmentGuard {
  constructor(private readonly routingService: RoutingService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (DEPARTMENTS.includes(route.paramMap.get('departmentKey') as TaskDepartment)) {
      return true;
    }

    return this.routingService.createUrl([URL.COCKPIT], true, { queryParams: route.queryParams });
  }
}
