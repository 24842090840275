import { Injectable } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerDownloadKbisService extends TaskActionService {
  protected _isAvailable = true;

  protected readonly legacyApiServerUrl = AppConfig.settings.legacyApiServerUrl;

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly window: Window,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    const company: Company | null = await this.companyStateService.getCompanyById(task.companyId);
    if (company?.kbis) {
      this.window.open(`${this.legacyApiServerUrl}${company.kbis[company.kbis.length - 1].file.url}`, '_blank');
      await this.taskService.completeTask(task);
    }
  }
}
