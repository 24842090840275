import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';

@Component({
  selector: 'dougs-sale-good-deb',
  templateUrl: './sale-good-deb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, ModalFooterDirective, ButtonComponent],
})
export class SaleGoodDebComponent {}
