import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { FlashMessagesService } from '@dougs/ds';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { Subscription } from '@dougs/subscription/dto';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCheckValidCard extends TaskActionService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {
    super();
  }

  shouldExecuteAutomatically(): boolean {
    return true;
  }

  async execute(): Promise<void> {
    await this.automaticallyExecute();
  }

  async automaticallyExecute(): Promise<void> {
    if (
      [SERVICE_STATE.NOT_STARTED, SERVICE_STATE.PREQUALIFIED, SERVICE_STATE.WAITING].includes(
        this.companyServicesStateService.services?.accounting.state,
      )
    ) {
      return;
    }

    const subscription: Subscription | undefined = (
      await lastValueFrom(this.companyStateService.activeCompany$.pipe(take(1)))
    )?.subscription;
    if (!subscription) {
      return;
    }

    if (subscription.isCardExpired || subscription.isCardAlmostExpired) {
      this.flashMessagesService.show(
        subscription.isCardExpired
          ? 'Votre carte de paiement est expirée. Rendez-vous dans vos paramètres pour la mettre à jour.'
          : 'Votre carte de paiement expire dans moins de 10 jours. Rendez-vous dans vos paramètres pour la mettre à jour.',
        {
          type: 'warning',
          timeout: 0,
        },
      );
    }
  }
}
