import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { SuggestProductModalComponent } from '../modals/suggest-product-modal/suggest-product-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private readonly modalService: ModalService) {}

  openSuggestProductModal(): void {
    this.modalService.open<boolean | null>(SuggestProductModalComponent);
  }
}
