<ng-container [ngSwitch]="activity.type">
  <div *ngSwitchCase="'company.signup'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/chequered-flag.png" />
    <div class="activity__content">
      <span class="activity__content__title">Inscription</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'company.completedSignup'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/taco.png" />
    <div class="activity__content">
      <span class="activity__content__title">Synchronisation d'une banque</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'subscription.trialEnded'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/raised-hand-with-part-between-middle-and-ring-fingers.png" />
    <div class="activity__content">
      <span class="activity__content__title">Fin de période d'essai</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'subscription.activated'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/beer-mug.png" />
    <div class="activity__content">
      <span class="activity__content__title">Activation d'abonnement pack {{ activity.data?.plan }}</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'subscription.suspended'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/no-entry-sign.png" />
    <div class="activity__content">
      <span class="activity__content__title">Résiliation d'abonnement pack {{ activity.data?.plan }}</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'task.created'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/logo/dougs-logo-light-compact.svg" />
    <div class="activity__content">
      <span class="activity__content__title">
        Création de tâche
        <a (click)="activityComponentService.openTaskModal($event, activity)" href="#">
          &laquo;&nbsp;{{ activity.data.title }} {{ activity.data.abortedAt ? '(abandonnée)' : '' }}&nbsp;&raquo;
        </a>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'task.completed'" class="activity mb-4">
    <img *ngIf="!activity.data.completer" alt="Images" class="mr-8" src="images/logo/dougs-logo-light-compact.svg" />
    <dougs-avatar
      *ngIf="activity.data.completer"
      [dougsTooltip]="activity.data.completer?.profile.fullName"
      [image]="activity.data.completer?.profile.avatarUrl || activity.data.completer?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        Tâche complétée
        <a (click)="activityComponentService.openTaskModal($event, activity)" href="#">
          &laquo;&nbsp;{{ activity.data.title }} {{ activity.data.abortedAt ? '(abandonnée)' : '' }}&nbsp;&raquo;
        </a>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'billingInvoice.created'" class="activity mb-4">
    <img *ngIf="!activity.data.creator" alt="Images" class="mr-8" src="images/logo/dougs-logo-light-compact.svg" />
    <dougs-avatar
      *ngIf="activity.data.creator"
      [dougsTooltip]="activity.data.creator?.profile.fullName"
      [image]="activity.data.creator?.profile.avatarUrl || activity.data.creator?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        Facture manuelle #{{ activity.data.number }} de {{ activity.data.amount | currency: 'EUR' }} créée
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'billingInvoice.chargeRetried'" class="activity mb-4">
    <img *ngIf="!activity.data.retrier" alt="Images" class="mr-8" src="images/logo/dougs-logo-light-compact.svg" />
    <dougs-avatar
      *ngIf="activity.data.retrier"
      [dougsTooltip]="activity.data.retrier?.profile.fullName"
      [image]="activity.data.retrier?.profile.avatarUrl || activity.data.retrier?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        {{ activity.data.success ? 'Débit réussi' : 'Tentative de débit' }} sur facture #{{
          activity.data.billingInvoice?.number
        }}
        de {{ activity.data.billingInvoice?.amount | currency: 'EUR' }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'paymentStrongCustomerAuthentication.completed'" class="activity mb-4">
    <img *ngIf="!activity.data.completer" alt="Images" class="mr-8" src="images/logo/dougs-logo-light-compact.svg" />
    <dougs-avatar
      *ngIf="activity.data.completer"
      [dougsTooltip]="activity.data.completer?.profile.fullName"
      [image]="activity.data.completer?.profile.avatarUrl || activity.data.completer?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        {{
          activity.data.outcome === 'succeeded'
            ? 'A complété son authentification forte avec débit réussi'
            : 'A complété son authentification forte avec paiement échoué'
        }}
        pour la facture #{{ activity.data.billingInvoice?.number }} de
        {{ activity.data.billingInvoice?.amount | currency: 'EUR' }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'paymentStrongCustomerAuthentication.aborted'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/thinking-face.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        La tâche d'authentification forte à été abandonnée pour la facture #{{
          activity.data.billingInvoice?.number
        }}
        de {{ activity.data.billingInvoice?.amount | currency: 'EUR' }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'paymentStrongCustomerAuthentication'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/thinking-face.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Tâche d'authentification forte en cours pour la facture #{{ activity.data.billingInvoice?.number }} de
        {{ activity.data.billingInvoice?.amount | currency: 'EUR' }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'accountingYear.closed'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/blue-book.png" />
    <div class="activity__content">
      <span class="activity__content__title">Clôture d'exercice au {{ activity.date | date: 'dd/MM/yyyy' }}</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'email.sent'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/love-letter.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Email envoyé
        <a (click)="activityComponentService.showEmailContentFromActivity($event, activity)" href="#">
          &laquo;&nbsp;{{ activity.data.subject }}&nbsp;&raquo;
        </a>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
      <span *ngIf="activity.data.hasBeenOpened" class="activity__content__date ml-4">(Ouvert)</span>
    </div>
  </div>

  <div *ngSwitchCase="'intercom.email.sent'" class="activity mb-4">
    <dougs-avatar
      *ngIf="activity.data.senderId | userInfo | async as userInfo"
      [dougsTooltip]="userInfo?.fullName || ''"
      [image]="userInfo?.avatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        Email Intercom envoyé &laquo;&nbsp;{{ activity.data.subject }}&nbsp;&raquo;
      </span>
      <span class="activity__content__date ml-4">
        ({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})
        <a *ngIf="activity.data.conversationUrl" [href]="activity.data.conversationUrl" target="_blank">
          Voir sur Intercom
        </a>
      </span>
    </div>
  </div>

  <div *ngSwitchCase="'webhook:intercom:conversation.rating.added'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/{{ getEmojiForRating(activity.data.rating) }}.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Conversation Intercom notée &laquo;&nbsp;{{ activity.data.rating }}&nbsp;&raquo;
      </span>
      <span class="activity__content__date ml-4">
        ({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})
        <a *ngIf="activity.data.conversationUrl" [href]="activity.data.conversationUrl" target="_blank">
          Voir sur Intercom
        </a>
      </span>
    </div>
  </div>

  <div *ngSwitchCase="'intercom.survey.answer'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/ballot-box.png" />
    <div class="activity__content">
      <span *ngIf="activity.data.score" class="activity__content__title">
        {{ activity.data.source }} a reçu la note de &laquo;&nbsp;{{ activity.data.score }}&nbsp;&raquo;
      </span>
      <span *ngIf="activity.data.comment" class="activity__content__title">
        {{ activity.data.source }} a reçu le commentaire &laquo;&nbsp;{{ activity.data.comment }}&nbsp;&raquo;
      </span>
      <span class="activity__content__date ml-4"> ({{ activity.date | date: 'dd/MM/yyyy HH:mm' }}) </span>
    </div>
  </div>

  <div *ngSwitchCase="'call'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/phone-filled.png" />
    <div class="activity__content">
      <span class="activity__content__title">{{ activity.data.description }}</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'sms'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/phone.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        {{ activity.data.sender.profile.fullName }} a envoyé un SMS&nbsp;: &laquo;&nbsp;{{
          activity.data.message
        }}&nbsp;&raquo; au {{ activity.data.phoneNumber }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'meeting.scheduled'" class="activity mb-4">
    <img *ngIf="!activity.data.assignee" alt="Images" class="mr-8" src="images/emoji/popcorn.png" />
    <dougs-avatar
      *ngIf="activity.data.assignee"
      [dougsTooltip]="activity.data.assignee?.profile.fullName"
      [image]="activity.data.assignee?.profile.avatarUrl || activity.data.assignee?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">Rendez-vous &laquo;&nbsp;{{ activity.data.name }}&nbsp;&raquo;</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'task.comment.created'" class="activity mb-4">
    <dougs-activity-comment [activity]="activity">
      <a (click)="activityComponentService.openTaskModal($event, activity)" href="#">
        &laquo;&nbsp;{{ activity.data.taskTitle }} {{ activity.data.abortedAt ? '(abandonnée)' : '' }}&nbsp;&raquo;
      </a>
    </dougs-activity-comment>
  </div>

  <div *ngSwitchCase="'accountingYear.comment.created'" class="activity mb-4">
    <dougs-activity-comment [activity]="activity">
      <a [href]="activity.data.ledgerLink">révision compte {{ activity.data.accountingNumber }}</a>
    </dougs-activity-comment>
  </div>

  <div *ngSwitchCase="'accountingSurvey.dueDateUpdate'" class="activity mb-4">
    <img *ngIf="!activity.data.updater" alt="Images" class="mr-8" src="images/logo/dougs-logo-light-compact.svg" />
    <dougs-avatar
      *ngIf="activity.data.updater"
      [dougsTooltip]="activity.data.updater?.profile.fullName"
      [image]="activity.data.updater?.profile.avatarUrl || activity.data.updater?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        {{ activity.data.updater?.profile.firstName }} a modifié la date limite du QB au {{ activity.data.dueDate }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'accountingSurvey.firstCompletedAt'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/clipboard.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        {{ activity.data.updater?.profile.fullName }} a complété le questionnaire de bilan
        {{ activity.data.closingYear }} pour la première fois dans les {{ activity.data.daysElapsed }} jours suivant la
        clôture.
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'intercom:conversation.admin.noted'" class="activity mb-4">
    <img *ngIf="!activity.data.noter" alt="Images" class="mr-8" src="images/logo/dougs-logo-light-compact.svg" />
    <dougs-avatar
      *ngIf="activity.data.noter"
      [dougsTooltip]="activity.data.noter?.profile.fullName"
      [image]="activity.data.noter?.profile.avatarUrl || activity.data.noter?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        {{ activity.data.body }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
      <span class="activity__content__date ml-4">
        <a [href]="activity.data.conversationLink" target="_blank">
          Note sur Intercom&nbsp;
          <i class="fal fa-external-link"></i>
        </a>
      </span>
    </div>
  </div>

  <div *ngSwitchCase="'social:sent'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/briefcase.png" />
    <div class="activity__content">
      <span class="activity__content__title">Le dossier a été transmis au service social.</span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'webinar:registered'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/television.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Inscription au webinar &laquo;&nbsp;{{ activity.data.title }}&nbsp;&raquo;
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'webinar:attended'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/television.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        {{ activity.data.attendanceRate > 10 ? 'Participation' : 'Non-Participation' }} au webinar
        <a (click)="activityComponentService.showWebinarEventFromActivity($event, activity)" href="#">
          &laquo;&nbsp;{{ activity.data.title }}&nbsp;&raquo;
        </a>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'hubspot.note_created'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/hubspot.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Note Hubspot : {{ activity.data.body }} par
        <b>{{ activity.data.nameCreatedBy }}</b>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'webhookV2_zapier:hubspot_note_created'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/hubspot.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Note : {{ activity.data.content }} par
        <b>{{ activity | formatAuthorFromHubspotActivity }}</b>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'webhookV2_zapier:hubspot_meeting_created'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/hubspot.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Meeting : {{ activity.data.content }} par
        <b>{{ activity | formatAuthorFromHubspotActivity }}</b>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'webhookV2_zapier:hubspot_call_created'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/hubspot.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Appel : {{ activity.data.content }} par
        <b>{{ activity | formatAuthorFromHubspotActivity }}</b>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'webhookV2_zapier:hubspot_dealstage_update'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/hubspot.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Phase de transaction : {{ activity.data.content }} par
        <b>{{ activity | formatAuthorFromHubspotActivity }}</b>
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'hubspot_owner_updated'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/hubspot.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Commercial :
        <b>{{ activity | formatAuthorFromHubspotActivity }}</b>
        <i *ngIf="activity.data?.metadata?.oldOwnerEmail"></i>
        (précédemment : {{ activity | formatAuthorFromHubspotActivity: 'oldOwnerEmail' }})
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'legal:onboardingProcess.survey'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/rocket.png" />
    <div class="activity__content">
      <span class="activity__content__title">
        Onboarding légal : {{ activity.data.title }}
        <ul>
          <li *ngFor="let line of activity.data?.lines">
            {{ line }}
          </li>
        </ul>
        <i *ngIf="activity.data?.metadata?.oldOwnerEmail"></i>
        (précédemment : {{ activity | formatAuthorFromHubspotActivity: 'oldOwnerEmail' }})
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'product.opportunity'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/beer-mug.png" />
    <dougs-avatar
      *ngIf="activity.data.updater"
      [dougsTooltip]="activity.data.collaborator?.profile.fullName"
      [image]="activity.data.collaborator?.profile.avatarUrl || activity.data.collaborator?.profile.alternateAvatarUrl"
      class="mr-8"
      size="tiny"
    ></dougs-avatar>
    <div class="activity__content">
      <span class="activity__content__title">
        Recommendation envoyée pour le produit : {{ activity.data.opportunityProductType }}
      </span>
      <span class="activity__content__date ml-4">({{ activity.date | date: 'dd/MM/yyyy HH:mm' }})</span>
    </div>
  </div>

  <div *ngSwitchCase="'accounting:planChanged'" class="activity mb-4">
    <img alt="Images" class="mr-8" src="images/emoji/package.png" />
    <div class="activity__content">
      <span *ngIf="activity.data.description" class="activity__content__title">
        {{ activity.data.description }}
      </span>
      <span class="activity__content__date ml-4"> ({{ activity.date | date: 'dd/MM/yyyy HH:mm' }}) </span>
    </div>
  </div>

  <div *ngSwitchCase="'payments'" class="activity mb-4">
    <img
      alt="Image {{ activity.data.isSuccess ? 'succès' : 'échec' }}"
      class="mr-8"
      src="images/emoji/{{ activity.data.isSuccess ? 'money-with-wings' : 'carrot' }}.png"
    />
    <div class="activity__content">
      <span *ngIf="activity.data.description" class="activity__content__title">
        {{ activity.data.description }}
      </span>
      <span class="activity__content__date ml-4"> ({{ activity.date | date: 'dd/MM/yyyy HH:mm' }}) </span>
    </div>
  </div>
</ng-container>
