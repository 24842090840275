import { Injectable } from '@angular/core';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCharteredAccountantNonRemunerationCertificateService extends TaskActionService {
  _label = 'Signer';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return (
      !task?.completedAt !== null &&
      this.userStateService.loggedInUser?.isAccountantOrAdmin &&
      this.userStateService.loggedInUser?.flags.includes('role:charteredAccountant')
    );
  }

  async execute(task: Task): Promise<void> {
    if (task) {
      await this.taskService.completeTask(task);
    }
  }
}
