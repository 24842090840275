<div class="form-container" [formGroup]="collaboratorCardComponentService.cardFormGroup">
  <dougs-input-datepicker [noMargin]="true" label="Date d'application" formControlName="date"></dougs-input-datepicker>
  <dougs-form-field [noMargin]="true">
    <label dougsFormFieldLabel>Valeur de la prime</label>
    <input dougsFormFieldControl type="number" formControlName="amount" />
    <i class="fal fa-euro-sign" dougsFormFieldSuffix></i>
    @if (formService.isControlInvalid(collaboratorCardComponentService.cardFormGroup?.controls?.amount)) {
      <span dougsFormFieldError> Ce champ est requis </span>
    }
  </dougs-form-field>
  <dougs-checkbox formControlName="isNet" label="Prime nette" [labelAfter]="true"></dougs-checkbox>
  <dougs-form-field [noMargin]="true">
    <label dougsFormFieldLabel>Intitulé bulletin</label>
    <input
      dougsFormFieldControl
      type="text"
      formControlName="label"
      placeholder="Ex. Prime d’activité, Prime d’objectif, ..."
    />
    <p dougsFormFieldCharacterCount>
      {{ collaboratorCardComponentService.cardFormGroup?.controls?.label?.value?.length ?? 0 }} / 45
    </p>
    @if (formService.isControlInvalid(collaboratorCardComponentService.cardFormGroup?.controls?.label ?? null)) {
      <span dougsFormFieldError>
        @if (collaboratorCardComponentService.cardFormGroup?.controls?.label?.errors?.maxlength) {
          Ce champ ne doit pas dépasser 45 caractères
        } @else {
          Ce champ est requis
        }
      </span>
    }
  </dougs-form-field>
</div>
