<dougs-badge [type]="badgeType" size="small"></dougs-badge>
<p class="bold tiny">{{ title }}</p>
<div>
  {{ shouldShowHours ? (workload | minInHour) + ' – ' : '' }}
  {{ count }}
  <span [ngPlural]="count">
    <ng-template ngPluralCase="=0">tâche</ng-template>
    <ng-template ngPluralCase="=1">tâche</ng-template>
    <ng-template ngPluralCase="other">tâches</ng-template>
  </span>
</div>
