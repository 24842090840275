import { Pipe, PipeTransform } from '@angular/core';
import { SlotDocuments } from '@dougs/task/dto';

@Pipe({
  name: 'filterSlotDocuments',
  standalone: true,
})
export class FilterSlotDocumentsPipe implements PipeTransform {
  transform(slotDocuments: SlotDocuments[], selectedIds: (number | string)[]): SlotDocuments[] {
    if (!slotDocuments || !selectedIds) {
      return [];
    }
    return slotDocuments
      .filter((slotDocument) => slotDocument.attachments.some((attachment) => selectedIds.includes(attachment.id)))
      .map((slotDocument) => ({
        ...slotDocument,
        attachments: slotDocument.attachments.filter((attachment) => selectedIds.includes(attachment.id)),
      }));
  }
}
