<div class="two-columns">
  <dougs-form-field [noMargin]="true">
    <label for="bonusAmount" dougsFormFieldLabel>Montant brut (en €)</label>
    <input
      [name]="'bonusAmount' + uuid"
      #bonusAmount="ngModel"
      dougsFormFieldControl
      min="0"
      step="0.01"
      [disabled]="!isEditable"
      [(ngModel)]="variableData.bonusAmount"
      [required]="true"
      type="number"
      id="bonusAmount"
    />
    <span dougsFormFieldError *ngIf="formService.isNgModelInvalid(bonusAmount)">
      <ng-container *ngIf="bonusAmount.errors.required">Ce champ est requis</ng-container>
      <ng-container *ngIf="bonusAmount.errors.min">Le montant doit être un nombre positif</ng-container>
    </span>
  </dougs-form-field>
  <dougs-form-field [noMargin]="true">
    <label for="bonusDescription" dougsFormFieldLabel>Description</label>
    <input
      placeholder="Ex: Prime d'activité, Prime d'objectif, ..."
      [name]="'bonusDescription' + uuid"
      #bonusDescription="ngModel"
      dougsFormFieldControl
      [disabled]="!isEditable"
      [(ngModel)]="variableData.bonusDescription"
      [required]="true"
      type="text"
      id="bonusDescription"
    />
    <span dougsFormFieldError *ngIf="formService.isNgModelInvalid(bonusDescription)">
      <ng-container *ngIf="bonusDescription.errors.required">Ce champ est requis</ng-container>
    </span>
  </dougs-form-field>
</div>
