import { Inject, Injectable, OnDestroy, signal, Signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, combineLatest, concatMap, filter, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { toPromise } from '@dougs/core/utils';
import { ConfirmationModalComponent, MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { PayslipInfo, PayslipInfoStatus, Task } from '@dougs/task/dto';
import { PayslipStateService } from '@dougs/task/shared';
import { CollaboratorPayslipMetricsComponentService } from './collaborator-payslip-metrics.component.service';

@Injectable()
export class CollaboratorPayslipModalComponentService implements OnDestroy {
  constructor(
    @Inject(MODAL_DATA) public readonly task: Task,
    private readonly payslipStateService: PayslipStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly collaboratorPayslipMetricsComponentService: CollaboratorPayslipMetricsComponentService,
    private readonly modalRef: ModalRef,
  ) {}

  private readonly isLoading: WritableSignal<boolean> = signal(true);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  private readonly isValidating: WritableSignal<boolean> = signal(false);
  isValidating$: Signal<boolean> = this.isValidating.asReadonly();

  refreshPayslipModalInfo$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    filter((company) => !!company?.id && !!this.task?.id),
    tap(() => this.isLoading.set(true)),
    concatMap((company) => this.payslipStateService.refreshPayslipModalInfos(company.id, this.task.id)),
    tap(() => this.isLoading.set(false)),
  );

  payslipLinesNumber$: Observable<number> = this.payslipStateService.payslipLines$.pipe(
    map((payslipLines) => payslipLines?.filter((line) => line.status !== PayslipInfoStatus.COMPLETED).length ?? 0),
  );

  selectedPayslipLineId: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  selectedPayslipLineId$: Observable<number | null> = this.selectedPayslipLineId.asObservable();

  payslipDisabled$: Observable<boolean> = this.payslipStateService.payslipModalInfo$.pipe(
    map((payslipModalInfo) => !payslipModalInfo?.isEditable),
  );

  updateSelectedPayslipLine$: Observable<void> = combineLatest([
    this.selectedPayslipLineId$,
    this.payslipStateService.payslipLines$,
  ]).pipe(
    map(([selectedPayslipLineId, payslipLines]) => {
      const selectedPayslipLine: PayslipInfo | null =
        payslipLines?.find((line) => line.id === selectedPayslipLineId) ?? null;
      this.selectedPayslipLine.next(selectedPayslipLine);
    }),
  );

  get selectedPayslipLineValue(): PayslipInfo | null {
    return this.selectedPayslipLine.value;
  }

  private readonly selectedPayslipLine: BehaviorSubject<PayslipInfo | null> = new BehaviorSubject<PayslipInfo | null>(
    null,
  );
  selectedPayslipLine$: Observable<PayslipInfo | null> = this.selectedPayslipLine.asObservable();

  private readonly finalizeEmployeeForm: Subject<void> = new Subject<void>();
  finalizeEmployeeForm$: Observable<void> = this.finalizeEmployeeForm.asObservable();

  selectedPayslipLineIsCompleted$: Observable<boolean> = this.selectedPayslipLine$.pipe(
    map((payslipLine) => payslipLine?.status === PayslipInfoStatus.COMPLETED),
  );

  disabledFinalize$: Observable<boolean> = this.payslipStateService.payslipModalInfo$.pipe(
    map(
      (payslipModalInfo) =>
        payslipModalInfo?.payslipInfos?.some((payslipLine) => payslipLine.status !== PayslipInfoStatus.COMPLETED) ??
        true,
    ),
  );

  setSelectedLine(payslipLine: PayslipInfo | null): void {
    this.selectedPayslipLineId.next(payslipLine?.id ?? null);
    if (!payslipLine) {
      this.payslipStateService.clearSelectedPayslip();
    } else {
      this.collaboratorPayslipMetricsComponentService.trackOpenEmployeeForm(payslipLine);
    }
  }

  goBackToPayslipList(): void {
    this.collaboratorPayslipMetricsComponentService.trackBackToPayslipList(this.selectedPayslipLineValue);
    this.setSelectedLine(null);
  }

  async uncompletePayslipLine(): Promise<void> {
    if (this.selectedPayslipLine.value) {
      this.collaboratorPayslipMetricsComponentService.trackUncompletePayslipLine(this.selectedPayslipLine.value);
      await this.payslipStateService.unvalidateCollaboratorVariables(
        this.task.companyId,
        this.selectedPayslipLine.value.id,
      );
      this.payslipStateService.updatePayslipLineStatus(this.selectedPayslipLine.value, PayslipInfoStatus.STARTED);
    }
  }

  ngOnDestroy(): void {
    this.payslipStateService.clearState();
  }

  onFinalizeEmployeeForm(): void {
    this.finalizeEmployeeForm.next();
  }

  async finalizePayslip(): Promise<void> {
    if (!this.isValidating$()) {
      const toFinalize = !!(
        await toPromise(
          this.modalService.open(ConfirmationModalComponent, {
            data: {
              title: 'Confirmation de la saisie des salariés',
              body:
                'Confirmez-vous la finalisation de la saisie des salariés ? \n' +
                'Cette action est définitive et aucune modification ne sera possible par la suite.',
              yesText: 'Confirmer la saisie',
              noText: 'Annuler',
              secondaryColor: 'primary',
            },
          }).afterClosed$,
        )
      ).data;
      if (toFinalize) {
        this.isValidating.set(true);
        await this.trackFinalizePayslipModal();
        const hasBeenValidated: boolean = await this.payslipStateService.validatePayslip(
          this.task.companyId,
          this.task.id,
        );
        if (hasBeenValidated) {
          this.modalRef.close();
        }
        this.isValidating.set(false);
      }
    }
  }

  private async trackFinalizePayslipModal(): Promise<void> {
    const payslipLines: PayslipInfo[] = await toPromise(this.payslipStateService.payslipLines$);
    this.collaboratorPayslipMetricsComponentService.trackFinalizePayslipModal(payslipLines);
  }
}
