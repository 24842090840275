import { Pipe, PipeTransform } from '@angular/core';
import { Task, TaskBillingInvoiceItem } from '@dougs/task/dto';

@Pipe({
  name: 'isEditableService',
  standalone: true,
})
export class IsEditableServicePipe implements PipeTransform {
  transform(task: Task, invoiceItem?: TaskBillingInvoiceItem): boolean {
    const isTaskEditable: boolean | undefined =
      !task.completedAt &&
      !task.abortedAt &&
      (task.quoteStatus === 'project' || (task.metadata.billOnCompletion && task.metadata.skipCustomerAcceptation));
    return !!(invoiceItem ? isTaskEditable && invoiceItem.id && task.id : isTaskEditable && task.id);
  }
}
