<div dougsModalTitle>
  <h6>Email</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-16">Objet: {{ activity.data.subject }}</p>
  <p class="small mb-16" *ngIf="dataText" [innerHTML]="dataText"></p>
  <div class="email-event-messages" *ngIf="emailsEventsMessage">
    <ng-container *ngFor="let emailEventMessage of emailsEventsMessage | keyvalue">
      <p
        class="tiny inline-block mb-8"
        [dougsTooltip]="emailEventMessage.value.dates.join(', ')"
        [dougsTooltipSize]="small"
        *ngIf="emailEventMessage.value.dates?.length"
      >
        {{ emailEventMessage.value.label }} {{ emailEventMessage.value.dates.length }} fois.
      </p>
    </ng-container>
  </div>
  <p class="tiny" *ngIf="activity.data.attachments?.length">
    Pièces jointes : {{ activity.data.attachments.join(', ') }}
  </p>
</div>
<div dougsModalFooter>
  <dougs-button [dougsModalClose]="true" color="secondary">J'ai compris</dougs-button>
</div>
