import { computed, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { DRAWER_DATA } from '@dougs/ds';
import { FileAttachmentSlot, SlotDocuments, Task, TaskTemplate } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

@Injectable()
export class MakeDocumentsAvailableComponentService {
  constructor(
    @Inject(DRAWER_DATA)
    public data: {
      task: Task;
      taskTemplate: TaskTemplate;
    },
    @Inject(Window) private readonly window: Window,
    private readonly tasksStateService: ControlPanelTasksStateService,
    private readonly metricsService: MetricsService,
  ) {
    this.setGroupedAttachments();
  }

  private readonly slotDocuments: WritableSignal<SlotDocuments[]> = signal([]);
  slotDocuments$: Signal<SlotDocuments[]> = this.slotDocuments.asReadonly();

  private readonly selectedAttachmentIds: WritableSignal<(number | string)[]> = signal([]);
  selectedAttachmentIds$: Signal<(number | string)[]> = this.selectedAttachmentIds.asReadonly();

  emptySelectedAttachments$: Signal<boolean> = computed(() => this.selectedAttachmentIds().length === 0);
  selectedAttachmentsNbr$: Signal<number> = computed(() => this.selectedAttachmentIds().length);

  private readonly documentsLoading: WritableSignal<boolean> = signal(false);
  documentsLoading$: Signal<boolean> = this.documentsLoading.asReadonly();

  private readonly documentsAvailable: WritableSignal<boolean> = signal(false);
  documentsAvailable$: Signal<boolean> = this.documentsAvailable.asReadonly();

  async makeDocumentsAvailable(): Promise<void> {
    if (!this.documentsLoading$()) {
      this.documentsLoading.set(true);
      const documentsProvided: boolean = await this.tasksStateService.makeDocumentsAvailable(
        this.data.task,
        this.selectedAttachmentIds().map((id: number | string) => Number(id)),
      );
      this.metricsService.pushMixpanelEvent('Task Document Provided', {
        'Documents count': this.selectedAttachmentIds().length,
      });
      this.documentsLoading.set(false);
      if (documentsProvided) {
        this.documentsAvailable.set(true);
        this.window.document.getElementById('drawer-content')?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }

  private setGroupedAttachments(): void {
    const fileAttachmentSlots: FileAttachmentSlot[] = this.data.taskTemplate.fileAttachmentSlots;
    if (fileAttachmentSlots.length) {
      const slotDocuments: SlotDocuments[] = fileAttachmentSlots.reduce(
        (slotDocs: SlotDocuments[], slot: FileAttachmentSlot): SlotDocuments[] => {
          const slotAttachments: Attachment[] = this.data.task.attachments.filter(
            (attachment) => attachment.type === slot.type,
          );
          if (slotAttachments.length) {
            slotDocs = [...slotDocs, { label: slot.label, type: slot.type, attachments: slotAttachments }];
          }
          return slotDocs;
        },
        [],
      );
      const otherAttachments: Attachment[] = this.data.task.attachments.filter(
        (attachment) => !attachment.type || !slotDocuments.map((slotDoc) => slotDoc.type).includes(attachment.type),
      );
      if (otherAttachments.length) {
        slotDocuments.push({ label: 'Autres', type: 'Autres', attachments: otherAttachments });
      }
      this.slotDocuments.set(slotDocuments);
    } else {
      const slotDocuments: SlotDocuments[] = [{ label: null, type: 'all', attachments: this.data.task.attachments }];
      this.slotDocuments.set(slotDocuments);
    }
  }

  toggleSelectAttachment(id: number | string): void {
    if (this.selectedAttachmentIds().includes(id)) {
      this.selectedAttachmentIds.update((ids) => ids.filter((attachmentId) => attachmentId !== id));
    } else {
      this.selectedAttachmentIds.update((ids) => [...ids, id]);
    }
  }

  toggleSelectSlot(slotDocument: SlotDocuments): void {
    if (slotDocument.attachments.every((attachment) => this.selectedAttachmentIds().includes(attachment.id))) {
      this.selectedAttachmentIds.update((ids) =>
        ids.filter(
          (attachmentId) => !slotDocument.attachments.map((attachment) => attachment.id).includes(attachmentId),
        ),
      );
    } else {
      this.selectedAttachmentIds.update((ids) => [
        ...ids.filter((id) => !slotDocument.attachments.map((attachment) => attachment.id).includes(id)),
        ...slotDocument.attachments.map((attachment) => attachment.id),
      ]);
    }
  }

  toggleAllAttachments(slotDocuments: SlotDocuments[]): void {
    if (
      slotDocuments.every((slotDocuments) =>
        slotDocuments.attachments.every((attachment) => this.selectedAttachmentIds().includes(attachment.id)),
      )
    ) {
      this.selectedAttachmentIds.set([]);
    } else {
      this.selectedAttachmentIds.update((ids) => [
        ...ids,
        ...this.data.task.attachments
          .filter((attachment) => !ids.includes(attachment.id))
          .map((attachment) => attachment.id),
      ]);
    }
  }
}
