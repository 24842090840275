import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  DatepickerComponent,
  DatepickerDirective,
  DougsDatePipe,
  FormatDistanceToNowRoundedToDayPipe,
  TooltipDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskSnoozeComponentService } from '../../../../../services/tasks/task/task-snooze.component.service';

@Component({
  selector: 'dougs-task-snooze',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatepickerDirective,
    DatepickerComponent,
    TooltipDirective,
    FormatDistanceToNowRoundedToDayPipe,
  ],
  templateUrl: './task-snooze.component.html',
  styleUrl: './task-snooze.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaskSnoozeComponentService],
})
export class TaskSnoozeComponent {
  constructor(
    public readonly taskSnoozeComponentService: TaskSnoozeComponentService,
    private readonly dougsDatePipe: DougsDatePipe,
  ) {}

  @Input() appearance: 'icon' | 'detail' = 'icon';

  @Input()
  set task(task: Task) {
    if (task) {
      this.taskSnoozeComponentService.setTask(task);
      this.taskSnoozeComponentService.snoozeDateControl.setValue(task.startDate, {
        emitEvent: false,
      });
    }
  }
}
