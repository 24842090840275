<ng-container *ngIf="companyStateService.activeCompany$ | async as activeCompany">
  <div class="settings-subscription" *ngIf="!activeCompany.subscription?.isFreeForever">
    <dougs-plan *ngIf="activeCompany.subscription?.plan" />
    <dougs-trial *ngIf="!activeCompany.subscription?.plan && activeCompany.isCreated" />
    <div class="mb-16">
      <dougs-divider></dougs-divider>
    </div>
    <dougs-next-billing />
    <dougs-subscription-period />
    <dougs-bank-card />
    <dougs-term-of-use />
  </div>
  <dougs-vip *ngIf="activeCompany.subscription?.isFreeForever"></dougs-vip>
</ng-container>
