import { Injectable } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';
import { DrawerService } from '@dougs/ds';
import { PostIt } from '@dougs/user/dto';

@Injectable()
export class PostItsComponentService {
  constructor(
    private readonly metricsService: MetricsService,
    private readonly drawerService: DrawerService,
  ) {}

  onMouseEnterPostIts(): void {
    this.metricsService.pushMixpanelEvent('Post-its Hovered');
  }

  onMouseEnterPostIt(postIt: PostIt): void {
    this.metricsService.pushMixpanelEvent('Post-it Hovered', { postItId: postIt.id, postItTitle: postIt.title });
  }

  async onOpenPostItsDrawer(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Post-its Drawer Opened');
    const { PostItsDrawerComponent } = await import('../../../modals/post-its-drawer/post-its-drawer.component');
    this.drawerService.open(PostItsDrawerComponent, { size: 'small' });
  }

  onPostItClick(postIt: PostIt): void {
    this.metricsService.pushMixpanelEvent('Post-it Clicked', { postItId: postIt.id, postItTitle: postIt.title });
  }
}
