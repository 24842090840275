import { CurrencyPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import {
  AvatarMessageComponent,
  ButtonComponent,
  FileInputComponent,
  FilePillComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
  TrackByPipe,
} from '@dougs/ds';
import { EcommerceStateService } from '@dougs/ecommerce/shared';
import { FileStateService } from '@dougs/settings/shared';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-sales-via-platform-document-upload-modal',
  templateUrl: './sales-via-platform-document-upload-modal.component.html',
  styleUrls: ['./sales-via-platform-document-upload-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    PanelInfoComponent,
    AvatarMessageComponent,
    NgClass,
    LabelFormFieldDirective,
    InputDatepickerComponent,
    FileInputComponent,
    NgFor,
    FilePillComponent,
    ModalFooterDirective,
    ButtonComponent,
    TrackByPipe,
  ],
})
export class SalesViaPlatformDocumentUploadModalComponent implements OnInit, OnDestroy {
  activeCompany!: Company;
  companySubscription!: Subscription;
  isUploadedFile: boolean | null = null;
  hasOss: boolean | null = false;
  hasIoss: boolean | null = false;
  errorMessage?: string;
  modalTitle = '';
  minDate: Date = new Date('2021-06-30');

  formGroup = new UntypedFormGroup({
    ossDate: new UntypedFormControl(''),
    iossDate: new UntypedFormControl(''),
  });

  get ossDate(): AbstractControl | null {
    return this.formGroup.get('ossDate');
  }
  get iossDate(): AbstractControl | null {
    return this.formGroup.get('iossDate');
  }

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly companyStateService: CompanyStateService,
    private readonly ecommerceStateService: EcommerceStateService,
    private readonly fileStateService: FileStateService,
  ) {}

  async ngOnInit() {
    this.companySubscription = this.companyStateService.activeCompany$.subscribe((company) => {
      this.activeCompany = company;
      this.hasOss = company.salesViaPlatformConfiguration.hasOss;
      this.hasIoss = company.salesViaPlatformConfiguration.hasIoss;

      if (this.hasOss && this.hasIoss) {
        this.modalTitle = `Attachez vos bordereaux d'inscription à l'OSS et à l'IOSS`;
        this.formGroup.get('ossDate')?.setValidators(Validators.required);
        this.formGroup.get('iossDate')?.setValidators(Validators.required);
        this.isUploadedFile = !!this.activeCompany.ossInscriptions && !!this.activeCompany.iossInscriptions;
      } else if (this.hasOss) {
        this.modalTitle = `Attachez votre bordereau d'inscription à l'OSS`;
        this.formGroup.get('ossDate')?.setValidators(Validators.required);
        this.isUploadedFile = !!this.activeCompany.ossInscriptions;
      } else {
        this.modalTitle = `Attachez votre bordereau d'inscription à l'IOSS`;
        this.formGroup.get('iossDate')?.setValidators(Validators.required);
        this.isUploadedFile = !!this.activeCompany.iossInscriptions;
      }

      this.cdr.markForCheck();
    });
  }

  getErrorMessage(): string {
    if (this.hasOss && this.activeCompany.ossInscriptions && this.hasIoss && this.activeCompany.iossInscriptions) {
      return `Vous devez nous transmettre vos dates et une copie de vos bordereaux d'inscription à l'OSS et à l'IOSS pour pouvoir valider cette tâche.`;
    } else if (this.hasOss && this.activeCompany.ossInscriptions) {
      return `Vous devez nous transmettre votre date et une copie de votre bordereau d'inscription à l'OSS pour pouvoir valider cette tâche.`;
    } else if (this.hasIoss && this.activeCompany.iossInscriptions) {
      return `Vous devez nous transmettre votre date et une copie de votre bordereau d'inscription à l'IOSS pour pouvoir valider cette tâche.`;
    }
    return `Vous devez nous transmettre une copie de votre bordereau d'inscription pour pouvoir valider cette tâche.`;
  }

  async uploadOssFiles(files: FileList): Promise<void> {
    await this.fileStateService.uploadAttachments(this.activeCompany, files, 'ossInscriptions', 'ossInscription');
  }

  async deleteOssFile(attachment: Attachment): Promise<void> {
    await this.fileStateService.deleteAttachments(this.activeCompany, attachment, 'ossInscriptions', true);
  }

  async uploadIOssFiles(files: FileList): Promise<void> {
    await this.fileStateService.uploadAttachments(this.activeCompany, files, 'iossInscriptions', 'iossInscription');
  }

  async deleteIOssFile(attachment: Attachment): Promise<void> {
    await this.fileStateService.deleteAttachments(this.activeCompany, attachment, 'iossInscriptions', true);
  }

  async onSubmit(): Promise<void> {
    this.isUploadedFile = false;
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.invalid) {
      if (this.hasOss && this.hasIoss && this.activeCompany.ossInscriptions && this.activeCompany.iossInscriptions) {
        this.isUploadedFile = true;
        await this.ecommerceStateService.updateOssActivationDate(this.activeCompany, this.ossDate?.value);
        await this.ecommerceStateService.updateIOssActivationDate(this.activeCompany, this.iossDate?.value);
      } else if (
        this.hasOss &&
        this.activeCompany.ossInscriptions &&
        !this.hasIoss &&
        !this.activeCompany.iossInscriptions
      ) {
        this.isUploadedFile = true;
        await this.ecommerceStateService.updateOssActivationDate(this.activeCompany, this.ossDate?.value);
      } else if (
        this.hasIoss &&
        this.activeCompany.iossInscriptions &&
        !this.hasOss &&
        !this.activeCompany.ossInscriptions
      ) {
        this.isUploadedFile = true;
        await this.ecommerceStateService.updateIOssActivationDate(this.activeCompany, this.iossDate?.value);
      } else {
        this.isUploadedFile = false;
      }

      if (this.isUploadedFile) {
        await this.ecommerceStateService.lockDateSalesViaPlatformConfiguration(this.activeCompany);
        this.modalRef.close(this.data.task);
      } else {
        this.errorMessage = this.getErrorMessage();
      }
    } else {
      this.errorMessage = this.getErrorMessage();
    }
  }

  ngOnDestroy() {
    this.companySubscription.unsubscribe();
  }
}
