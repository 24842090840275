import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormatDistanceToNowPipe, FormatDistanceToNowRoundedToDayPipe, TooltipDirective } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { CreationDateTooltipTextPipe } from '../../../pipes/control-panel/task/creation-date-tooltip-text.pipe';
import { TargetIdentifierPipe } from '../../../pipes/control-panel/task/target-identifier.pipe';

@Component({
  selector: 'dougs-task-header-summary',
  standalone: true,
  imports: [
    CommonModule,
    TargetIdentifierPipe,
    TooltipDirective,
    CreationDateTooltipTextPipe,
    FormatDistanceToNowPipe,
    FormatDistanceToNowRoundedToDayPipe,
  ],
  templateUrl: './task-header-summary.component.html',
  styleUrls: ['./task-header-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskHeaderSummaryComponent {
  @Input() task!: Task;
}
