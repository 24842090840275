import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';

@Component({
  selector: 'dougs-near-vat-exemption-threshold-modal',
  templateUrl: './near-vat-exemption-threshold-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    CurrencyPipe,
  ],
})
export class NearVatExemptionThresholdModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: { task: Task },
  ) {}
}
