import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent, DropdownComponent, DropdownOptionComponent, DropdownTriggerDirective } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { TaskCustomersConversationsComponentService } from '../../../../../services/tasks/task/task-customer-conversations.component.service';

@Component({
  selector: 'dougs-task-customer-conversations',
  standalone: true,
  imports: [CommonModule, DropdownComponent, DropdownOptionComponent, DropdownTriggerDirective, ButtonComponent],
  templateUrl: './task-customer-conversations.component.html',
  styleUrl: './task-customer-conversations.component.scss',
  providers: [TaskCustomersConversationsComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCustomerConversationsComponent {
  constructor(public readonly taskCustomersConversationsComponentService: TaskCustomersConversationsComponentService) {}

  @Input() appearance: 'icon' | 'detail' = 'icon';
  @Input({ required: true }) set task(task: Task) {
    this.taskCustomersConversationsComponentService.setTask(task);
  }
}
