import { Injectable } from '@angular/core';
import { toPromise } from '@dougs/core/utils';
import { ModalService } from '@dougs/ds';

@Injectable({ providedIn: 'root' })
export class UserConversationSummariesService {
  constructor(private readonly modalService: ModalService) {}

  async openUserSummariesModal(): Promise<void> {
    const { UserConversationSummaryModalComponent } = await import('@dougs/user/ui');
    await toPromise(this.modalService.open(UserConversationSummaryModalComponent).afterClosed$);
  }
}
