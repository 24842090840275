import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attachment } from '@dougs/core/files';
import {
  DougsDatePipe,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  TooltipDirective,
} from '@dougs/ds';
import { AccountBalances, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';

@Component({
  selector: 'dougs-accounting-bank-reconciliation-modal',
  templateUrl: './accounting-bank-reconciliation-modal.component.html',
  styleUrls: ['./accounting-bank-reconciliation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    LoaderComponent,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    TooltipDirective,
    DougsDatePipe,
  ],
})
export class AccountingBankReconciliationModalComponent implements OnInit, OnDestroy {
  public useIncludedDate = true;
  public hasValidatedBalances!: boolean;
  public validatedBalances!: AccountBalances[];
  public balanceDifferenceMapping: { [accountId: string]: { included: number; excluded: number } } = {};
  public balanceAttachmentMapping: { [accountId: string]: Attachment[] } = {};
  public refreshSelectAccountSubscription!: Subscription;
  public account?: SynchronizedAccount;

  constructor(
    @Inject(MODAL_DATA) public data: { accountId: number; companyId: number },
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly window: Window,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.synchronizedAccountStateService.refreshSynchronizedAccounts(this.data.companyId);
    this.refreshSelectAccountSubscription = this.synchronizedAccountStateService.synchronizedAccounts$
      .pipe(map((synchronizedAccounts) => this.initializeAccount(synchronizedAccounts)))
      .subscribe();
  }

  async updateAccount(account: SynchronizedAccount): Promise<void> {
    await this.synchronizedAccountStateService.updateAccount(account, { updateReconciliationExpectedBalances: true });
  }

  async deleteBalance(balance: AccountBalances): Promise<void> {
    if (this.account) {
      const updatedBalances: AccountBalances[] =
        this.account?.metadata.balances?.filter((b) => b.id !== balance.id) ?? [];
      const updatedAccount: SynchronizedAccount = {
        ...this.account,
        metadata: {
          ...this.account?.metadata,
          balances: updatedBalances,
        },
      };
      await this.updateAccount(updatedAccount);
    }
  }

  updateUseIncludedDate(e: MouseEvent): void {
    e.preventDefault();
    this.useIncludedDate = !this.useIncludedDate;
  }

  updateHasValidatedBalances(): void {
    this.hasValidatedBalances = !!this.validatedBalances?.length;
  }

  downloadAttachment(attachment: Attachment): void {
    this.window.open(attachment.file.url, '_blank');
  }

  trackById(index: number, item: AccountBalances | Attachment): string | number {
    return item.id;
  }

  ngOnDestroy(): void {
    this.refreshSelectAccountSubscription?.unsubscribe();
  }

  private initializeAccount(synchronizedAccounts: SynchronizedAccount[]): SynchronizedAccount | undefined {
    this.account = synchronizedAccounts?.find((acc) => acc.id === this.data.accountId);
    if (this.account) {
      this.onAccountUpdate();
    }
    return this.account;
  }

  private onAccountUpdate(): void {
    this.validatedBalances = this.account?.metadata.balances?.filter((balance) => balance.validated) ?? [];
    this.updateHasValidatedBalances();
    this.updateBalanceMapping();
    this.cdr.markForCheck();
  }

  private updateBalanceMapping(): void {
    this.balanceDifferenceMapping = {};
    this.balanceAttachmentMapping = {};
    for (const balance of this.account?.metadata?.balances ?? []) {
      const included: number = Math.round((balance.balance - balance.endOfDayBalance) * 100) / 100;
      const excluded: number = Math.round((balance.balance - balance.startOfDayBalance) * 100) / 100;
      this.balanceDifferenceMapping[balance.id] = { included, excluded };
      this.balanceAttachmentMapping[balance.id] =
        this.account?.bankStatements.filter((attachment) => attachment.metadata?.bankStatementId === balance.id) || [];
    }
  }
}
