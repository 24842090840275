import { Injectable } from '@angular/core';
import { startOfWeek } from 'date-fns';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { MinInHourPipe } from '@dougs/ds';
import { CockpitAccountingTeamStats, CockpitCollaboratorStats, CockpitStatsQuantityPair } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { CollaboratorWithPersonalData, Team } from '@dougs/user/dto';
import { CollaboratorStateService } from '@dougs/user/shared';
import { CockpitActionComponentService } from '../cockpit-action.component.service';
import { CockpitStatsComponentService } from './cockpit-stats.component.service';

type CockpitComputedTaskBarProperties = {
  relativeBarPartSize: number;
  barText: string;
  showBarText: boolean;
};

export type CockpitComputedCollaboratorStats = {
  collaborator: CollaboratorWithPersonalData | null;

  tasks: {
    todo: CockpitStatsQuantityPair & CockpitComputedTaskBarProperties;
    completedThisWeek: CockpitStatsQuantityPair & CockpitComputedTaskBarProperties;
    remaining: CockpitStatsQuantityPair & CockpitComputedTaskBarProperties;
    total: CockpitStatsQuantityPair;

    relativeBarSize: number;
  };

  appointments: {
    todo: CockpitStatsQuantityPair;
    completedThisWeek: CockpitStatsQuantityPair;
  };

  intercom: {
    closed: number;
    open: number | null;
    snoozed: number | null;
    openUpdatedAt: Date | null;
  };
};

@Injectable()
export class CockpitStatsTeamComponentService {
  private readonly BAR_TEXT_DISPLAY_MIN_PERCENTAGE = 3;

  private readonly isLoadingTeamStatsSource = new BehaviorSubject<boolean>(true);
  private readonly teamStatsSource = new BehaviorSubject<CockpitComputedCollaboratorStats[]>([]);

  readonly isLoadingTeamStats$: Observable<boolean> = this.isLoadingTeamStatsSource.asObservable();
  readonly teamStats$: Observable<CockpitComputedCollaboratorStats[]> = this.teamStatsSource.asObservable();

  constructor(
    private readonly cockpitActionComponentService: CockpitActionComponentService,
    private readonly cockpitStatsComponentService: CockpitStatsComponentService,
    private readonly collaboratorStateService: CollaboratorStateService,
    private readonly cockpitStateService: CockpitStateService,
    private readonly minInHourPipe: MinInHourPipe,
  ) {}

  readonly refreshAccountingTeamStats$: Observable<CockpitAccountingTeamStats | null> = combineLatest([
    this.cockpitStateService.team$,
    this.cockpitStatsComponentService.shouldShowStats$,
    this.cockpitStatsComponentService.referenceDate$,
    this.cockpitStatsComponentService.showAccountingDashboard$,
  ]).pipe(
    debounceTime(100),
    filter(([team, showStats, _d, showAccountingDashboard]) => showAccountingDashboard && !!team && showStats),
    tap(([_t, _s, referenceDate]) => {
      if (startOfWeek(referenceDate) > startOfWeek(new Date())) {
        this.cockpitStatsComponentService.goToPresentDay();
      }
    }),
    filter(([_t, _s, referenceDate]) => startOfWeek(referenceDate) <= startOfWeek(new Date())),
    tap(() => this.isLoadingTeamStatsSource.next(true)),
    switchMap(([team, _s, referenceDate]) =>
      this.cockpitStateService.refreshAccountingTeamStats(team as Team, referenceDate),
    ),
    tap(() => this.isLoadingTeamStatsSource.next(false)),
  );

  readonly refreshTeamStats$: Observable<void> = combineLatest([
    this.cockpitStatsComponentService.referenceDate$,
    this.cockpitStateService.team$,
    this.cockpitStateService.domain$,
    this.collaboratorStateService.collaborators$,
    this.cockpitStatsComponentService.shouldShowStats$,

    this.cockpitActionComponentService.actionAssignation$,
    this.cockpitActionComponentService.actionTaskStartDateUpdate$,
    this.cockpitActionComponentService.actionTaskWorkloadUpdate$,

    this.cockpitStatsComponentService.showAccountingDashboard$,
  ]).pipe(
    filter(
      ([_r, team, domain, collaborators, shouldShowStats, _aa, _ats, _atw, showAccountingDashboard]) =>
        domain === 'team' && !!team && !!collaborators && shouldShowStats && !showAccountingDashboard,
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    debounceTime(50),
    tap(() => this.isLoadingTeamStatsSource.next(true)),
    switchMap(([referenceDate, team]) => this.cockpitStateService.getTeamStats((team as Team).userId, referenceDate)),
    withLatestFrom(
      this.collaboratorStateService.collaborators$,
      this.cockpitStatsComponentService.shouldShowHours$,
      this.cockpitStatsComponentService.isThisWeekTheReferenceWeek$,
    ),
    map(([teamStats, collaborators, shouldShowHours, isThisWeekTheStatsReferenceWeek]) =>
      this.computeTeamStats(
        teamStats,
        collaborators as ReadonlyMap<number, CollaboratorWithPersonalData>,
        shouldShowHours,
        isThisWeekTheStatsReferenceWeek,
      ),
    ),
    map((teamStats: CockpitComputedCollaboratorStats[]) => this.teamStatsSource.next(teamStats)),
    map(() => this.isLoadingTeamStatsSource.next(false)),
  );

  computeTeamStats(
    teamStats: CockpitCollaboratorStats[],
    collaborators: ReadonlyMap<number, CollaboratorWithPersonalData>,
    shouldShowHours: boolean,
    isThisWeekTheStatsReferenceWeek: boolean,
  ): CockpitComputedCollaboratorStats[] {
    const maxWorkload: number = Math.max(
      ...teamStats.map((collaboratorStats) => collaboratorStats.tasksTotal.workload),
    );

    return teamStats.map((collaboratorStats) => {
      return {
        ...this.columnCollaborator(collaboratorStats, collaborators),
        ...this.columnTaskBars(collaboratorStats, maxWorkload, shouldShowHours),
        ...this.columnAppointments(collaboratorStats, shouldShowHours),
        ...this.columnIntercom(collaboratorStats, isThisWeekTheStatsReferenceWeek),
      };
    });
  }

  private columnCollaborator(
    collaboratorStats: CockpitCollaboratorStats,
    collaborators: ReadonlyMap<number, CollaboratorWithPersonalData>,
  ): Pick<CockpitComputedCollaboratorStats, 'collaborator'> {
    return { collaborator: collaborators.get(collaboratorStats.userId) ?? null };
  }

  private columnTaskBars(
    collaboratorStats: CockpitCollaboratorStats,
    maxWorkload: number,
    shouldShowHours: boolean,
  ): Pick<CockpitComputedCollaboratorStats, 'tasks'> {
    return {
      tasks: {
        completedThisWeek: {
          ...collaboratorStats.tasksCompletedThisWeek,
          ...this.computeTaskBarProperties(
            collaboratorStats.tasksCompletedThisWeek.workload,
            collaboratorStats.tasksTotal.workload,
            maxWorkload,
            shouldShowHours,
          ),
        },
        todo: {
          ...collaboratorStats.tasksTodo,
          ...this.computeTaskBarProperties(
            collaboratorStats.tasksTodo.workload,
            collaboratorStats.tasksTotal.workload,
            maxWorkload,
            shouldShowHours,
          ),
        },
        remaining: {
          ...collaboratorStats.tasksRemaining,
          ...this.computeTaskBarProperties(
            collaboratorStats.tasksRemaining.workload,
            collaboratorStats.tasksTotal.workload,
            maxWorkload,
            shouldShowHours,
          ),
        },
        total: collaboratorStats.tasksTotal,

        relativeBarSize: maxWorkload ? collaboratorStats.tasksTotal.workload / maxWorkload : 0,
      },
    };
  }

  private computeTaskBarProperties(
    workload: number,
    totalWorkload: number,
    maxWorkload: number,
    shouldShowHours: boolean,
  ): CockpitComputedTaskBarProperties {
    return {
      relativeBarPartSize: totalWorkload ? Math.ceil((workload / totalWorkload) * 100) : 0,
      barText: this.minInHourPipe.transform(workload),
      showBarText:
        shouldShowHours &&
        (maxWorkload && totalWorkload
          ? (totalWorkload / maxWorkload) * (workload / totalWorkload) * 100 > this.BAR_TEXT_DISPLAY_MIN_PERCENTAGE
          : true),
    };
  }

  private columnAppointments(
    collaboratorStats: CockpitCollaboratorStats,
    shouldShowHours: boolean,
  ): Pick<CockpitComputedCollaboratorStats, 'appointments'> {
    return {
      appointments: {
        completedThisWeek: collaboratorStats.appointmentsCompletedThisWeek,
        todo: collaboratorStats.appointmentsTodo,
      },
    };
  }

  private columnIntercom(
    collaboratorStats: CockpitCollaboratorStats,
    isThisWeekTheStatsReferenceWeek: boolean,
  ): Pick<CockpitComputedCollaboratorStats, 'intercom'> {
    return {
      intercom: {
        closed: collaboratorStats.intercom.closed,
        open: isThisWeekTheStatsReferenceWeek ? collaboratorStats.intercom.open : 0,
        snoozed: isThisWeekTheStatsReferenceWeek ? collaboratorStats.intercom.snoozed : 0,
        openUpdatedAt:
          collaboratorStats.intercom.openUpdatedAt && isThisWeekTheStatsReferenceWeek
            ? new Date(collaboratorStats.intercom.openUpdatedAt)
            : null,
      },
    };
  }
}
