import { Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CockpitAccountingStatsCollaborator,
  CockpitAccountingStatsCollaboratorBalanceSheets,
  CockpitAccountingTeamStats,
} from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { CockpitAccountingStatsResponsiveComponentService } from './cockpit-accounting-stats-responsive.component.service';
import { CockpitStatsTeamComponentService } from './cockpit-stats-team.component.service';

@Injectable()
export class CockpitAccountingStatsComponentService {
  constructor(
    private readonly cockpitStateService: CockpitStateService,
    private readonly cockpitStatsTeamComponentService: CockpitStatsTeamComponentService,
    private readonly accountingStatsResponsiveComponentService: CockpitAccountingStatsResponsiveComponentService,
  ) {}

  resetHoverOnChange$: Observable<void> = this.cockpitStatsTeamComponentService.isLoadingTeamStats$.pipe(
    map(() => this.hoveredCollaboratorLine.next(null)),
  );

  private readonly hoveredCollaboratorLine: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  hoveredCollaboratorLine$: Observable<number | null> = this.hoveredCollaboratorLine.asObservable();

  private readonly hasBilanSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasBilan$: Observable<boolean> = this.hasBilanSubject.asObservable();

  activityBilanToggleMode$: Observable<boolean> = combineLatest([
    this.accountingStatsResponsiveComponentService.accountingStatsUnderWidth$,
    this.hasBilan$,
  ]).pipe(map(([accountingStatsUnderWidth, hasBilan]: [boolean, boolean]) => accountingStatsUnderWidth && hasBilan));

  showClassicBilan$: Observable<boolean> = combineLatest([
    this.hasBilan$,
    this.accountingStatsResponsiveComponentService.accountingStatsUnderWidth$,
  ]).pipe(map(([hasBilan, underWidth]: [boolean, boolean]) => hasBilan && !underWidth));

  isActivityModeToggle$: Observable<boolean> = combineLatest([
    this.activityBilanToggleMode$,
    toObservable(this.accountingStatsResponsiveComponentService.isActivityMode$),
  ]).pipe(map(([toggleMode, isActivityMode]: [boolean, boolean]) => toggleMode && isActivityMode));

  isBilanModeToggle$: Observable<boolean> = combineLatest([
    this.activityBilanToggleMode$,
    toObservable(this.accountingStatsResponsiveComponentService.isBilanMode$),
  ]).pipe(map(([toggleMode, isBilanMode]: [boolean, boolean]) => toggleMode && isBilanMode));

  showActivityBar$: Observable<boolean> = combineLatest([
    this.activityBilanToggleMode$,
    this.isActivityModeToggle$,
  ]).pipe(map(([toggleMode, isActivityMode]: [boolean, boolean]) => !toggleMode || isActivityMode));

  hasCollaboratorStats$: Observable<boolean> = this.cockpitStateService.accountingTeamStats$.pipe(
    map((stats: CockpitAccountingTeamStats) => !!stats?.collaborators?.length),
  );

  isCompletedStats$: Observable<boolean> = this.cockpitStateService.accountingTeamStats$.pipe(
    map((stats: CockpitAccountingTeamStats) => stats.isCompletedStats),
  );

  collaboratorActivityMaxValue$: Observable<number> = this.cockpitStateService.accountingTeamStats$.pipe(
    map((stats: CockpitAccountingTeamStats) => this.getCollaboratorMaxActivty(stats)),
  );

  collaboratorBilanMaxValue$: Observable<number> = this.cockpitStateService.accountingTeamStats$.pipe(
    map((stats: CockpitAccountingTeamStats) => this.getCollaboratorMaxBilan(stats)),
  );

  handleShowAccountingStatsColumns$: Observable<void> = this.cockpitStateService.accountingTeamStats$.pipe(
    map((stats: CockpitAccountingTeamStats) => this.handleShowAccountingStatsColumns(stats)),
  );

  setHoveredLine(collaboratorId: number | null): void {
    this.hoveredCollaboratorLine.next(collaboratorId);
  }

  private getCollaboratorMaxActivty(stats: CockpitAccountingTeamStats): number {
    let max = 0;
    stats.collaborators.forEach((collaboratorStats: CockpitAccountingStatsCollaborator) => {
      const collaboratorTotal: number =
        (collaboratorStats.activity.pointsAssignedBeforeThisWeek ?? 0) +
        (collaboratorStats.activity.pointsAssignedThisWeek ?? 0) +
        (collaboratorStats.activity.pointsCompletedThisWeek ?? 0);
      const collaboratorMax: number = Math.max(collaboratorTotal, collaboratorStats.activity.averageProductivity);
      if (collaboratorMax > max) {
        max = collaboratorMax;
      }
    });
    return max;
  }

  private getCollaboratorMaxBilan(stats: CockpitAccountingTeamStats): number {
    let max = 0;
    stats.collaborators.forEach((collaboratorStats: CockpitAccountingStatsCollaborator) => {
      if (!collaboratorStats.balanceSheets) {
        return;
      }

      const collaboratorTotal: number =
        collaboratorStats.balanceSheets.accountingSurveyCompleted +
        collaboratorStats.balanceSheets.prepared +
        collaboratorStats.balanceSheets.accountingSurveyVerified +
        collaboratorStats.balanceSheets.revisionCompleted;
      if (collaboratorTotal > max) {
        max = collaboratorTotal;
      }
    });
    return max;
  }

  private handleShowAccountingStatsColumns(stats: CockpitAccountingTeamStats): void {
    const collaboratorStats: CockpitAccountingStatsCollaborator[] = stats.collaborators;

    const showBilan = collaboratorStats.some((collaboratorStat) => {
      const balanceSheetsStats: CockpitAccountingStatsCollaboratorBalanceSheets | undefined =
        collaboratorStat.balanceSheets;

      return (
        balanceSheetsStats?.accountingSurveyCompleted ||
        balanceSheetsStats?.accountingSurveyVerified ||
        balanceSheetsStats?.prepared ||
        balanceSheetsStats?.revisionCompleted
      );
    });

    this.hasBilanSubject.next(showBilan);
  }
}
