import { Injectable } from '@angular/core';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionTechSupportService extends TaskActionService {
  _label = 'Voir le dossier';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly window: Window,
  ) {
    super();
  }

  getIsAvailable(task?: Task): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task?.metadata?.activeUrl) {
      return;
    }
    this.window.open(task.metadata.activeUrl, '_blank');
  }
}
