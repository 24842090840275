import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import {
  DougsDatePipe,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  TrackByPipe,
} from '@dougs/ds';

@Component({
  selector: 'dougs-file-pill-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    DropdownComponent,
    DropdownOptionComponent,
    TrackByPipe,
    DropdownTriggerDirective,
    DougsDatePipe,
  ],
  templateUrl: './file-pill-dropdown.component.html',
  styleUrls: ['./file-pill-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePillDropdownComponent {
  @Input() attachments!: Attachment[];
  @Input() label!: string;
  @Input() lastAttachment!: Attachment;
}
