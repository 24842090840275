<div class="emoji-rating">
  <span *ngIf="(responsiveService.isMobile | async) === false">Peut mieux faire</span>
  <div class="emoji-button" (click)="setRating(1)">
    <img width="24" height="24" src="images/emoji/angry-face.png" />
  </div>
  <div class="emoji-button" (click)="setRating(2)">
    <img width="24" height="24" src="images/emoji/white-frowning-face.png" />
  </div>
  <div class="emoji-button" (click)="setRating(3)">
    <img width="24" height="24" src="images/emoji/neutral-face.png" />
  </div>
  <div class="emoji-button" (click)="setRating(4)">
    <img width="24" height="24" src="images/emoji/grinning-face.png" />
  </div>
  <div class="emoji-button" (click)="setRating(5)">
    <img width="24" height="24" src="images/emoji/star-struck-face.png" />
  </div>
  <span *ngIf="(responsiveService.isMobile | async) === false">Très satisfait</span>
</div>
