<ng-container
  *ngIf="
    (companyStateService.activeCompany$ | async)?.subscription?.plan ||
    (companyStateService.activeCompany$ | async)?.subscription.cardBrand
  "
>
  <div class="form-layout mb-16">
    <div class="subscription-line">
      <div class="line-title">Carte de paiement</div>
      <div class="line-content" *ngIf="(companyStateService.activeCompany$ | async)?.subscription?.cardBrand">
        <div>****-****-****-{{ (companyStateService.activeCompany$ | async)?.subscription?.cardLast4 }}</div>
        <div>
          (expire
          {{ (companyStateService.activeCompany$ | async).subscription.cardExpirationDate | date: 'MM-yyyy' }})
        </div>
      </div>
    </div>
    <div
      class="button-container form-layout__no-grow self-end"
      *ngIf="companyStateService.activeCompany$ | async as activeCompany"
    >
      <dougs-button
        [fullWidth]="true"
        size="small"
        color="secondary"
        (click)="subscriptionComponentService.updateCard(activeCompany)"
      >
        {{ activeCompany?.subscription?.cardBrand ? 'Mettre à jour' : 'Ajouter une carte' }}
      </dougs-button>
      <dougs-button
        size="small"
        [fullWidth]="true"
        *ngIf="
          !!activeCompany?.subscription?.cardBrand &&
          (userStateService.loggedInUser$ | async)?.flags?.includes('canDeletePaymentCard')
        "
        color="delete"
        (click)="subscriptionComponentService.deleteCard(activeCompany)"
      >
        Supprimer
      </dougs-button>
      <dougs-button
        size="small"
        [fullWidth]="true"
        *ngIf="bankCardComponentService.showStripeDeleteButton$ | async"
        color="delete"
        (click)="subscriptionComponentService.deleteCustomerOnStripe(company)"
      >
        Supprimer sur Stripe
      </dougs-button>
    </div>
  </div>
  <div class="mb-16">
    <dougs-divider></dougs-divider>
  </div>
</ng-container>
