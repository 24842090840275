import { Pipe } from '@angular/core';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'makeDocumentsAvailableTooltip',
  standalone: true,
})
export class MakeDocumentsAvailableTooltipPipe {
  transform(task: Task): string {
    if (!task) {
      return '';
    }
    if (task.target?.suspended) {
      return 'Le client a ses accès coupés et ne peut donc pas accéder aux documents dans Dougs.';
    }
    if (!task.isService) {
      return 'Cette tâche n’est pas visible du client dans Prestations';
    }
    if (task.completedAt) {
      return 'Cette tâche est complétée';
    }
    if (!task.attachments?.length) {
      return 'Aucun document n’est attaché à la tâche';
    }
    return '';
  }
}
