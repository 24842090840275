<div dougsModalTitle>
  <h6>Compléter vos paramètres comptables</h6>
  <i *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin" dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <div class="mb-24">
    <dougs-completion
      [smallSize]="true"
      [completionPercent]="(companyStateService.activeCompany$ | async)?.completion?.percent"
      [settingsLink]="URL.SETTINGS | createUrl | async"
      [missingFieldsLink]="false"
    >
      Complétez vos paramètres
    </dougs-completion>
  </div>
  <dougs-avatar-message
    avatarUrl="https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg"
    size="medium"
    fullName="Sarah Jaouani, Responsable Comptabilité"
  >
    <p class="small mb-16">{{ (userStateService.activeUserFirstName$ | async) || 'Bonjour ' }},</p>
    <p class="small mb-16">
      Pour réaliser notre mission d'expertise-comptable, nous avons besoin que vos paramètres Dougs soient complétés à
      100%{{
        completionTask.metadata?.hasPreviousAccountantMissingInformation
          ? ', notamment les ' + 'coordonnées de votre précédent expert-comptable pour récupérer votre dossier.'
          : '.'
      }}
      Si ce n'est pas le cas, ou s'ils sont incorrects, vos déclarations fiscales risqueraient d'être fausses ou en
      retard et Dougs ne pourrait en être tenu responsable.
    </p>
    <p class="small">Pouvez-vous les compléter dès maintenant ? Merci !</p>
  </dougs-avatar-message>
</div>
<div dougsModalFooter>
  <dougs-button *ngIf="completionTask.metadata?.shouldShowCancelButton" color="secondary" dougsModalClose>
    Annuler
  </dougs-button>
  <dougs-button [dougsModalClose]="true" color="primary">Remplir mes paramètres</dougs-button>
</div>
