import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class ControlPanelComponentService {
  shouldShowSearchUserTab$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map(
      (loggedInUser) => loggedInUser.isAccountantOrAdmin && !loggedInUser.flags.includes('restrictToAssignedCompanies'),
    ),
  );

  constructor(
    private readonly modalService: ModalService,
    private readonly userStateService: UserStateService,
  ) {}

  async openSearchUserModal(): Promise<void> {
    const { UserSearchModalComponent } = await import('@dougs/user/ui');
    await lastValueFrom(this.modalService.open(UserSearchModalComponent).afterClosed$);
  }
}
