import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ButtonComponent,
  CheckboxComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  RadioComponent,
  RadioGroupComponent,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService, FourretoutTaskStateService } from '@dougs/task/shared';

@Component({
  selector: 'dougs-activity-task-modal',
  templateUrl: './invoice-task-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    NgIf,
    FormsModule,
    ModalContentDirective,
    CheckboxComponent,
    LabelFormFieldDirective,
    RadioGroupComponent,
    RadioComponent,
    ModalFooterDirective,
    ButtonComponent,
    LoaderComponent,
  ],
})
export class InvoiceTaskSettingsModal implements OnInit {
  public isLoading = true;

  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.task) {
      const task: Task | null = await this.controlPanelTasksStateService.getTask(this.task);
      if (task) {
        this.task = task;
        if (!this.task.metadata.billOnCompletion) {
          this.task.metadata.billOnCompletion = false;
        }
        if (!this.task.metadata.billOnPendingInvoice) {
          this.task.metadata.billOnPendingInvoice = false;
        }
        this.isLoading = false;
        this.cdr.markForCheck();
      }
    }
  }

  async updateTask(): Promise<void> {
    await this.controlPanelTasksStateService.updateTask(this.task);
    await this.fourretoutTaskStateService.updateInvoiceTaskSettings(this.task);
    this.modalRef.close(true);
  }

  updateBillOnPendingInvoiceIfNecessary(billOnCompletion: boolean): void {
    if (!billOnCompletion && this.task.metadata.billOnPendingInvoice) {
      this.task.metadata.billOnPendingInvoice = false;
    }
  }
}
