import { Injectable } from '@angular/core';
import { toPromise } from '@dougs/core/utils';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { CollaboratorPayslipModalComponent } from '../../modals/collaborator-payslip-modal/collaborator-payslip-modal.component';
import { PayrollSurveyComponent } from '../../modals/payroll-survey/payroll-survey.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionSocialPayslipService extends TaskActionService {
  _label = 'Voir les variables';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    if (task.version === 2) {
      await toPromise(
        this.modalService.open(CollaboratorPayslipModalComponent, {
          data: task,
        }).afterClosed$,
      );
    } else {
      const fullTask: Task | null = await this.controlPanelTasksStateService.getTask(task);

      if (fullTask) {
        const payslipSurveySubTask: Task | undefined = fullTask.tasks.find(
          (task) => task.code === 'social:payslip.survey',
        );

        if (payslipSurveySubTask?.spawnedTaskId) {
          const customerTask: Task | null = await this.controlPanelTasksStateService.getTask(
            payslipSurveySubTask.spawnedTaskId,
          );

          this.modalService.open(PayrollSurveyComponent, {
            data: customerTask,
          });
        }
      }
    }
  }
}
