<div class="modal-container">
  <div dougsModalTitle>
    <h6>Lettre de mission</h6>
    <i dougsModalClose *ngIf="canCloseWithoutData" class="fal fa-times"></i>
  </div>
  <div dougsModalContent *ngIf="!ownerIsCeoOrDirector">
    <dougs-panel-info type="warning" class="mb-16">
      <p class="small mb-8">
        Le propriétaire du compte Dougs n’est pas dirigeant de l’entreprise, donc la lettre de mission ne peut pas être
        signée.
      </p>
      <p class="small mb-8">
        En effet, en tant qu’expert-comptable, Dougs ne peut traiter qu’avec le dirigeant de l’entreprise.
      </p>
      <p class="small mb-8">
        Veuillez donner la propriété du compte Dougs au dirigeant de l’entreprise afin de pouvoir procéder à la
        signature de la lettre de mission.
      </p>
      <p class="small mb-8">Vous ne savez pas comment faire ?</p>
      <p class="small">
        Vous pouvez lire cet article :
        <a
          rel="noopener"
          href="https://aide.dougs.fr/fr/articles/5631530-comment-changer-le-proprietaire-de-mon-compte-dougs"
          target="_blank"
        >
          &nbsp;Comment changer le propriétaire de mon compte Dougs&nbsp;?&nbsp;&rarr;
        </a>
      </p>
    </dougs-panel-info>
  </div>
  <div dougsModalContent>
    <dougs-panel-info type="info" class="mb-16">
      <p class="small mb-8">
        Nous vous invitons à prendre connaissance et à accepter la lettre de mission pour la création de votre
        entreprise. Dougs s’engage à mener à bien la prestation, dans le respect de la déontologie propre aux
        experts-comptables.
      </p>
      <p class="small">
        En cliquant sur
        <b>« Lu et approuvé »</b>
        vous affirmez être le représentant légal de l'entreprise. Après signature, vous recevrez votre lettre de mission
        par e-mail.
      </p>
    </dougs-panel-info>
    <iframe *ngIf="ownerIsCeoOrDirector" [src]="previewUri"></iframe>
    <p class="tiny color-gray-350 mb-8">
      Si vous ne parvenez pas à visualiser le document, vous pouvez le télécharger avant acceptation. Une fois accepté,
      une version signée par les deux parties vous sera automatiquement transmise par e-mail.
      <a [href]="previewUri" target="_blank">Télécharger le document</a>
    </p>
  </div>
  <div dougsModalFooter class="nowrap">
    <dougs-button color="secondary" dougsModalClose> Signer plus tard </dougs-button>
    <dougs-button type="submit" (click)="submit()" data-cy="legal-letter-engagement-modal-submit-button">
      <i class="fa fa-check"></i>
      Lu et approuvé
    </dougs-button>
  </div>
</div>
