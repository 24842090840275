import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import { generateUuidV4 } from '@dougs/core/utils';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { VariableData } from '@dougs/task/dto';
import { controlContainerFactory } from '../control-container-factory/control-container-factory';

@Component({
  selector: 'dougs-payroll-variable-kilometric-indemnity',
  templateUrl: './payroll-variable-kilometric-indemnity.component.html',
  styleUrls: ['./payroll-variable-kilometric-indemnity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: controlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
  standalone: true,
  imports: [
    FormFieldComponent,
    LabelFormFieldDirective,
    FormsModule,
    ControlFormFieldDirective,
    NgIf,
    ErrorFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
  ],
})
export class PayrollVariableKilometricIndemnityComponent {
  @Input() isEditable = true;
  @Input() variableData!: VariableData;

  uuid = generateUuidV4();

  constructor(public formService: FormService) {}
}
