import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PillComponent, TooltipDirective, TrackByPipe } from '@dougs/ds';
import { PostIt } from '@dougs/user/dto';
import { PostItTooltipPipe } from '../../../../pipes/control-panel/post-it-tooltip.pipe';
import { PostItsComponentService } from '../../../../services/control-panel/labels/post-its.component.service';

@Component({
  selector: 'dougs-post-its',
  templateUrl: './post-its.component.html',
  styleUrls: ['./post-its.component.scss'],
  standalone: true,
  providers: [PostItsComponentService],
  imports: [NgIf, AsyncPipe, TrackByPipe, NgForOf, PillComponent, TooltipDirective, PostItTooltipPipe],
})
export class PostItsComponent {
  @Input() postIts!: PostIt[];

  constructor(public readonly postItsComponentService: PostItsComponentService) {}
}
