import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CockpitCollaboratorStats } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Collaborator, CollaboratorWithPersonalData } from '@dougs/user/dto';
import { CollaboratorStateService } from '@dougs/user/shared';
import { CockpitActionComponentService } from '../cockpit-action.component.service';
import {
  CockpitComputedCollaboratorStats,
  CockpitStatsTeamComponentService,
} from './cockpit-stats-team.component.service';
import { CockpitStatsComponentService } from './cockpit-stats.component.service';

@Injectable()
export class CockpitStatsCollaboratorComponentService {
  private readonly isLoadingCollaboratorStatsSource = new BehaviorSubject<boolean>(true);
  private readonly collaboratorStatsSource = new BehaviorSubject<CockpitComputedCollaboratorStats[]>([]);

  readonly isLoadingCollaboratorStats$: Observable<boolean> = this.isLoadingCollaboratorStatsSource.asObservable();
  readonly collaboratorStats$: Observable<CockpitComputedCollaboratorStats[]> =
    this.collaboratorStatsSource.asObservable();

  readonly refreshCollaboratorStats$: Observable<unknown> = combineLatest([
    this.cockpitStatsComponentService.referenceDate$,
    this.cockpitStateService.collaborator$,
    this.cockpitStateService.domain$,
    this.collaboratorStateService.collaborators$,
    this.cockpitStatsComponentService.shouldShowStats$,

    this.cockpitActionComponentService.actionAssignation$,
    this.cockpitActionComponentService.actionTaskStartDateUpdate$,
    this.cockpitActionComponentService.actionTaskWorkloadUpdate$,
  ]).pipe(
    filter(
      ([_, collaborator, domain, collaborators, shouldShowStats]) =>
        domain === 'collaborator' && !!collaborator && !!collaborators && shouldShowStats,
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    tap(() => this.isLoadingCollaboratorStatsSource.next(true)),
    switchMap(([referenceDate, collaborator]) =>
      this.cockpitStateService.getCollaboratorStats((collaborator as Collaborator).id, referenceDate),
    ),
    withLatestFrom(
      this.collaboratorStateService.collaborators$,
      this.cockpitStatsComponentService.shouldShowHours$,
      this.cockpitStatsComponentService.isThisWeekTheReferenceWeek$,
    ),
    map(([collaboratorStats, collaborators, shouldShowHours, isThisWeekTheStatsReferenceWeek]) =>
      this.computeCollaboratorStats(
        collaboratorStats,
        collaborators as ReadonlyMap<number, CollaboratorWithPersonalData>,
        shouldShowHours,
        isThisWeekTheStatsReferenceWeek,
      ),
    ),
    map((collaboratorStats: CockpitComputedCollaboratorStats[]) =>
      this.collaboratorStatsSource.next(collaboratorStats),
    ),
    map(() => this.isLoadingCollaboratorStatsSource.next(false)),
  );

  computeCollaboratorStats(
    collaboratorStats: CockpitCollaboratorStats,
    collaborators: ReadonlyMap<number, CollaboratorWithPersonalData>,
    shouldShowHours: boolean,
    isThisWeekTheStatsReferenceWeek: boolean,
  ): CockpitComputedCollaboratorStats[] {
    return this.cockpitStatsTeamComponentService.computeTeamStats(
      [collaboratorStats],
      collaborators,
      shouldShowHours,
      isThisWeekTheStatsReferenceWeek,
    );
  }

  constructor(
    private readonly cockpitActionComponentService: CockpitActionComponentService,
    private readonly cockpitStatsComponentService: CockpitStatsComponentService,
    private readonly cockpitStatsTeamComponentService: CockpitStatsTeamComponentService,
    private readonly cockpitStateService: CockpitStateService,
    private readonly collaboratorStateService: CollaboratorStateService,
  ) {}
}
