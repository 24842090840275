<div dougsModalTitle>
  <h6 class="mr-16">Libérez votre capital pour garder le taux réduit d'imposition à 15%</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-16">
    Nous avons fait le point sur vos comptes et nous avons vu que votre capital n'est pas
    <b>entièrement libéré</b>
    . Cela signifie qu'une partie du capital n'a pas encore été versée sur le compte de l'entreprise.
  </p>
  <p class="small mb-16">
    Tous les entrepreneurs ne le savent pas, mais cela a pour conséquence de vous faire perdre le droit à une
    <b>réduction du taux de votre impôt sur les sociétés</b>
    . Si vous ne libérez pas la totalité de votre capital, vous serez imposé à
    <b>28 % au lieu de 15 %</b>
    . Nous vous conseillons donc de le faire au plus vite.
  </p>
  <p class="small">
    Si vous êtes dans l'incapacité de verser les fonds nécessaires à la libération de votre capital, je vous invite à
    lire l'article ci-dessous qui vous permettra d'avoir des solutions :
    <a
      rel="noopener"
      target="_blank"
      href="https://aide.dougs.fr/fr/articles/4280247-liberez-votre-capital-pour-beneficier-du-taux-reduit-d-impot-sur-les-societes-is"
    >
      → Libérez votre capital pour bénéficier du taux réduit d'impôt sur les sociétés (IS)
    </a>
  </p>
</div>

<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
  <dougs-button type="button" [dougsModalClose]="true">Ok, j'ai compris</dougs-button>
</div>
