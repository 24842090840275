import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { WorkloadTaskModalComponentService } from '../../services/modals/workload-task-modal.component.service';

@Component({
  selector: 'dougs-workload-task-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ModalCloseDirective,
    ModalTitleDirective,
    ModalContentDirective,
    ReactiveFormsModule,
    ControlFormFieldDirective,
    FormFieldComponent,
    ButtonComponent,
    ModalFooterDirective,
    SuffixFormFieldDirective,
  ],
  templateUrl: './workload-task-modal.component.html',
  styleUrls: ['./workload-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WorkloadTaskModalComponentService],
})
export class WorkloadTaskModalComponent implements OnInit {
  constructor(public readonly workloadTaskModalComponentService: WorkloadTaskModalComponentService) {}

  ngOnInit(): void {
    this.workloadTaskModalComponentService.populateFormGroup();
  }
}
