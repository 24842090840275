import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AutosizeModule } from 'ngx-autosize';
import {
  ControlFormFieldDirective,
  DougsDatePipe,
  DropdownComponent,
  FilePillComponent,
  FormatDistanceToNowPipe,
  TooltipDirective,
  TrackByPipe,
  WrapUrlsInHtmlPipe,
} from '@dougs/ds';
import { Comment, Task } from '@dougs/task/dto';
import { User } from '@dougs/user/dto';
import { TaskConversationComponentService } from '../../../../services/control-panel/task/task-conversation.component.service';
import { TaskUserDropdownComponent } from '../task-user-dropdown/task-user-dropdown.component';
import { TaskCommentComponent } from './task-comment/task-comment.component';

@Component({
  selector: 'dougs-task-conversation',
  templateUrl: './task-conversation.component.html',
  styleUrls: ['./task-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    TaskCommentComponent,
    TooltipDirective,
    FilePillComponent,
    AutosizeModule,
    ControlFormFieldDirective,
    DropdownComponent,
    TaskUserDropdownComponent,
    WrapUrlsInHtmlPipe,
    FormatDistanceToNowPipe,
    TrackByPipe,
    DougsDatePipe,
  ],
  providers: [TaskConversationComponentService],
})
export class TaskConversationComponent {
  @ViewChild('dropdownUsers') dropdownUsers!: DropdownComponent;
  @ViewChild('commentInput') commentInput!: ElementRef;
  isMentionOpened = false;
  comments: Comment[] = [];

  private _task!: Task;

  constructor(public readonly taskConversationComponentService: TaskConversationComponentService) {}

  get task(): Task {
    return this._task;
  }

  @Input()
  set task(task: Task) {
    this._task = task;
    if (task.comments?.length) {
      this.comments = [...task.comments].sort((a, b) => a.id - b.id);
    }
  }

  async addComment(e: KeyboardEvent): Promise<void> {
    e.stopPropagation();
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const value: string = target.value;
    target.blur();
    target.value = '';
    if (value !== '') {
      await this.taskConversationComponentService.commentTask(this.task, value);
    }
  }

  mentionUser(user: User): void {
    if (this.dropdownUsers) {
      this.dropdownUsers.hide();
    }

    if (this.commentInput) {
      const textareaHTMLElement: HTMLTextAreaElement = this.commentInput.nativeElement as HTMLTextAreaElement;
      textareaHTMLElement.value += user.profile?.firstName + '-' + user.profile?.lastName + '-' + user.id;
      textareaHTMLElement.focus();
    }
  }

  focusSearchElement(): void {
    if (this.commentInput) {
      const textareaHTMLElement: HTMLTextAreaElement = this.commentInput.nativeElement as HTMLTextAreaElement;
      textareaHTMLElement.focus();
    }
  }

  preventLineBreak(e: KeyboardEvent): void {
    if (e.shiftKey && e.code === 'Enter') {
      e.preventDefault();
    }
  }

  handleOpenDropdownUser(e: KeyboardEvent): void {
    if (e.key === '@' && this.dropdownUsers && !this.dropdownUsers.showing) {
      this.dropdownUsers.show();
    }
  }
}
