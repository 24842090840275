<div dougsModalTitle>
  <h6>Envoyer une demande au client</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <ng-container *ngIf="taskRefresh$ | async"></ng-container>
  <dougs-panel-info class="mb-16">
    <p class="small">
      Le client est contacté via Intercom (e-mail). La conversation vous est assignée mais snoozée. Votre tâche est
      déléguée au client. Il peut répondre via email ou à l'entrée dans Dougs. Sa réponse sera notée dans Intercom et en
      commentaire de votre tâche qui redeviendra active. Sans réponse, le client est relancé à J+{{ daysToReminder }} et
      votre tâche ré-activée à J+{{ daysToDueDate }}. Pour des raisons de sécurité, si vous attachez des documents, le
      client ne pourra les voir que sur Dougs et non dans l'e-mail. Il est automatiquement prévenu en fin d'e-mail.
    </p>
  </dougs-panel-info>
  <form #form="ngForm">
    <dougs-form-field>
      <label dougsFormFieldLabel>Objet</label>
      <input
        #subjectNgControl="ngModel"
        [(ngModel)]="subject"
        [required]="true"
        dougsFormFieldControl
        name="subject"
        type="text"
      />
      <span *ngIf="subjectNgControl.errors?.required" dougsFormFieldError>Ce champs est requis</span>
    </dougs-form-field>
    <dougs-form-field>
      <label dougsFormFieldLabel>Demande</label>
      <textarea
        #questionNgControl="ngModel"
        [(ngModel)]="question"
        [required]="true"
        autosize
        dougsFormFieldControl
        name="question"
        type="text"
      ></textarea>
      <span *ngIf="questionNgControl.errors?.required" dougsFormFieldError>Ce champs est requis</span>
    </dougs-form-field>
    <div>
      <div class="file-list">
        <dougs-file-input (uploadFiles)="uploadTaskAttachment($event)"></dougs-file-input>
        <dougs-file-pill
          (deleteFile)="onDeleteFile($event)"
          *ngFor="let file of fileList"
          [attachment]="file"
        ></dougs-file-pill>
      </div>
    </div>
  </form>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button (click)="onSubmit()" [disabled]="form.invalid">Envoyer le message</dougs-button>
</div>
