<div class="two-columns">
  <dougs-form-field [noMargin]="true">
    <label for="kilometricIndemnityDistance" dougsFormFieldLabel>Kilomètres parcourus (en km)</label>
    <input
      [name]="'kilometricIndemnityDistance' + uuid"
      #kilometricIndemnityDistance="ngModel"
      dougsFormFieldControl
      min="0"
      step="1"
      [disabled]="!isEditable"
      [(ngModel)]="variableData.kilometricIndemnityDistance"
      [required]="true"
      type="number"
      id="kilometricIndemnityDistance"
    />
    <span dougsFormFieldError *ngIf="formService.isNgModelInvalid(kilometricIndemnityDistance)">
      <ng-container *ngIf="kilometricIndemnityDistance.errors.required">Ce champ est requis</ng-container>
      <ng-container *ngIf="kilometricIndemnityDistance.errors.min">Le montant doit être un nombre positif</ng-container>
    </span>
  </dougs-form-field>
  <dougs-form-field [noMargin]="true">
    <label dougsFormFieldLabel>Puissance du véhicule</label>
    <dougs-select
      placeholder="Sélectionner une puissance"
      dougsFormFieldControl
      [name]="'kilometricIndemnityPower' + uuid"
      #kilometricIndemnityPower="ngModel"
      [(ngModel)]="variableData.kilometricIndemnityPower"
      [disabled]="!isEditable"
      [required]="true"
    >
      <dougs-select-option value="<3">3 CV et moins</dougs-select-option>
      <dougs-select-option value="4">4 CV</dougs-select-option>
      <dougs-select-option value="5">5 CV</dougs-select-option>
      <dougs-select-option value="6">6 CV</dougs-select-option>
      <dougs-select-option value=">7">7 CV et plus</dougs-select-option>
    </dougs-select>
    <span dougsFormFieldError *ngIf="formService.isNgModelInvalid(kilometricIndemnityPower)">Ce champ est requis</span>
  </dougs-form-field>
</div>
