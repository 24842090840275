import { Pipe, PipeTransform } from '@angular/core';
import { CollaboratorVariableType } from '@dougs/task/dto';

@Pipe({
  name: 'variableTypeLabel',
  standalone: true,
})
export class VariableTypeLabelPipe implements PipeTransform {
  transform(variableType: string): string {
    switch (variableType) {
      case CollaboratorVariableType.ABSENCE:
        return 'Absence';
      case CollaboratorVariableType.PRIME:
        return 'Prime';
      case CollaboratorVariableType.HOUR:
        return 'Heures';
      case CollaboratorVariableType.DEPOSIT:
        return 'Acompte';
      default:
        return '';
    }
  }
}
