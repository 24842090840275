import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[dougsAccountingStatsResponsive]',
  standalone: true,
})
export class AccountingStatsResponsiveDirective implements OnInit, OnDestroy {
  private observer!: ResizeObserver;
  private elementToObserve: HTMLElement | null = null;

  private readonly limitWidth = 930;

  @Output() accountingStatsUnderWidthEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly host: ElementRef) {}

  ngOnInit(): void {
    this.observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        const accountingStatsUnderWidth: boolean = entries[0].target.clientWidth <= this.limitWidth;
        this.accountingStatsUnderWidthEmitter.emit(accountingStatsUnderWidth);
      }
    });

    this.elementToObserve = this.host.nativeElement;
    if (this.elementToObserve) {
      this.observer.observe(this.elementToObserve);
    }
  }

  ngOnDestroy(): void {
    if (this.elementToObserve) {
      this.observer?.unobserve(this.elementToObserve);
    }
  }
}
