<div
  (click)="toggleSelect()"
  class="line p-8"
  [ngClass]="{ disabled: isDisabled, selected: _value, errors: !!errors.length }"
>
  <dougs-avatar [image]="partnerImageUrl" size="small" class="avatar"></dougs-avatar>
  <p class="pl-8 small" *ngIf="partner.legalPersonId; else naturalPerson">
    {{ partner.legalPerson?.metadata?.legalRepresentativeFirstName || '-' }}
    {{ partner.legalPerson?.metadata?.legalRepresentativeLastName || '-' }} (pour
    <span class="italic">{{ partner.fullName || '-' }}</span>
    )
  </p>
  <ng-template #naturalPerson>
    <p class="pl-8 small">{{ partner.fullName || '-' }}</p>
  </ng-template>
  <i *ngIf="errors.length" class="ml-4 fal fa-info-circle color-error"></i>
</div>
<p *ngIf="errors.length" class="color-error small">Il manque les informations suivantes : {{ errors.join(', ') }}</p>
