import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerSynchronizeBankAccountService extends TaskActionService {
  protected _isAvailable = true;

  constructor(private readonly modalService: ModalService) {
    super();
  }

  async execute(): Promise<void> {
    const { AddConnectionModalComponent } = await import('@dougs/synchronized-accounts/ui');
    await this.modalService.open(AddConnectionModalComponent, {
      data: { metadata: { location: 'Accounting Demo Mode Off' } },
    });
  }
}
