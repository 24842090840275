import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';
import { TaskActionFacadeService } from '../task-actions/task-action-facade.service';

@Pipe({
  name: 'taskActionTagIcon',
  standalone: true,
})
export class TaskActionTagIconPipe implements PipeTransform {
  constructor(private readonly taskActionFacadeService: TaskActionFacadeService) {}

  transform(task: Task): string {
    return this.taskActionFacadeService.getTaskActionTagIcon(task);
  }
}
