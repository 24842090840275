import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from '@dougs/ds';
import { PayslipInfo } from '@dougs/task/dto';
import { CollaboratorFormComponentService } from '../../../services/modals/collaborator-payslip/collaborator-form.component.service';
import { CustomerFormComponentService } from '../../../services/modals/collaborator-payslip/customer-form.component.service';
import { PayslipFormComponentService } from '../../../services/modals/collaborator-payslip/payslip-form.component.service';
import { PayrollVariablesComponent } from '../../payroll-survey/payroll-variables/payroll-variables.component';
import { CollaboratorFormComponent } from './collaborator-form/collaborator-form.component';
import { CustomerVariablesComponent } from './customer-variables/customer-variables.component';
import { EmployeeInfosComponent } from './employee-infos/employee-infos.component';

@Component({
  selector: 'dougs-payslip-form',
  standalone: true,
  imports: [
    CommonModule,
    EmployeeInfosComponent,
    PayrollVariablesComponent,
    FormsModule,
    CustomerVariablesComponent,
    CollaboratorFormComponent,
    LoaderComponent,
  ],
  providers: [PayslipFormComponentService, CustomerFormComponentService, CollaboratorFormComponentService],
  templateUrl: './payslip-form.component.html',
  styleUrl: './payslip-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayslipFormComponent {
  constructor(public readonly payslipFormComponentService: PayslipFormComponentService) {}

  @Input() set payslipLine(payslipInfo: PayslipInfo) {
    this.payslipFormComponentService.setPayslipLine(payslipInfo);
  }
}
