import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import {
  CheckboxComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
} from '@dougs/ds';

@Component({
  selector: 'dougs-referrer-partnership',
  templateUrl: './referrer-partnership.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DividerComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    FormsModule,
    ControlFormFieldDirective,
    CheckboxComponent,
    AsyncPipe,
    NgIf,
  ],
})
export class ReferrerPartnershipComponent {
  constructor(public readonly companyStateService: CompanyStateService) {}
}
