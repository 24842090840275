import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  ButtonComponent,
  FilesPreviewComponent,
  HelpModalComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalService,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task, TaskForm } from '@dougs/task/dto';
import { FourretoutTaskStateService } from '@dougs/task/shared';
import { TaskFormFieldsComponent } from '../../components/control-panel/task/task-form-fields/task-form-fields.component';
import { TaskFormComponentService } from '../../services/control-panel/task/task-form.component.service';

@Component({
  selector: 'dougs-double-fragment-task-form-modal',
  templateUrl: './double-fragment-task-form-modal.component.html',
  styleUrls: ['./double-fragment-task-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    TaskFormFieldsComponent,
    FilesPreviewComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
  providers: [TaskFormComponentService],
})
export class DoubleFragmentTaskFormModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      form: TaskForm | null;
      task: Task;
    },
    private readonly modalService: ModalService,
    private readonly cdr: ChangeDetectorRef,
    private readonly taskFormComponentService: TaskFormComponentService,
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
  ) {}

  async showRemarks(): Promise<void> {
    const remarks: string | null = await this.fourretoutTaskStateService.getTaskRemarks(this.data.task);

    if (remarks) {
      this.modalService.open(HelpModalComponent, {
        data: {
          title: "Message d'aide",
          body: remarks,
        },
      });
    }
  }

  async updateTaskForm(): Promise<void> {
    const formUpdated: TaskForm | null = await this.taskFormComponentService.updateTaskForm(
      this.data.task,
      this.data.form,
    );

    if (formUpdated) {
      this.data.form = formUpdated;
      this.cdr.markForCheck();
    }
  }

  async cancelTaskForm(): Promise<void> {
    this.data.form = await this.taskFormComponentService.cancelTaskForm(this.data.task);
    this.cdr.markForCheck();
  }
}
