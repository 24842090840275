<div dougsModalTitle>
  <h6>Nouveaux fichiers disponibles</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-avatar-message class="mb-16" [fullName]="lastCreatorFullName" [avatarUrl]="lastCreatorUrl" size="medium">
    Des fichiers ont été ajoutés sur votre espace Dougs depuis votre dernier passage.
  </dougs-avatar-message>
  <dougs-file-details
    [ngClass]="{ 'mb-16': !last }"
    *ngFor="let attachment of attachments; trackBy: 'id' | trackBy; let last = last"
    [attachment]="attachment"
  ></dougs-file-details>
</div>
<div dougsModalFooter>
  <dougs-button color="primary" (click)="markAsSeen()">Marquer comme vu</dougs-button>
</div>
