import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OwnershipService } from '@dougs/core/ownership';
import { BadgeComponent } from '@dougs/ds';
import { ActivityStateService } from '@dougs/task/shared';
import { ControlPanelSearchComponentService } from '../../services/control-panel/control-panel-search.component.service';
import { ControlPanelComponentService } from '../../services/control-panel/control-panel.component.service';
import { OpenControlPanelComponentService } from '../../services/control-panel/open-control-panel.component.service';
import { SelectedTabComponentService } from '../../services/control-panel/selected-tab.component.service';
import { ControlPanelActivitiesComponent } from './control-panel-activities/control-panel-activities.component';
import { ControlPanelLabelsComponent } from './control-panel-labels/control-panel-labels.component';
import { ControlPanelTaskComponent } from './control-panel-task/control-panel-task.component';

@Component({
  selector: 'dougs-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    BadgeComponent,
    ControlPanelTaskComponent,
    ControlPanelActivitiesComponent,
    ControlPanelLabelsComponent,
    AsyncPipe,
  ],
  providers: [ControlPanelComponentService, ControlPanelSearchComponentService],
})
export class ControlPanelComponent {
  constructor(
    public readonly selectedTabComponentService: SelectedTabComponentService,
    public readonly activityStateService: ActivityStateService,
    public readonly controlPanelComponentService: ControlPanelComponentService,
    public readonly ownershipService: OwnershipService,
    public readonly openControlPanelComponentService: OpenControlPanelComponentService,
  ) {}
}
