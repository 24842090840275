<div class="form-container" [formGroup]="collaboratorCardComponentService.cardFormGroup">
  <div>
    <label dougsFormFieldLabel> Saisie sur une </label>
    <dougs-radio-group
      formControlName="isOneDay"
      (radioGroupChange)="collaboratorCardComponentService.handleAbsenceTypeChange(!!$event.value)"
    >
      <dougs-radio [value]="false">Période</dougs-radio>
      <dougs-radio [value]="true">Journée</dougs-radio>
    </dougs-radio-group>
  </div>
  @if (collaboratorCardComponentService.absenceFormReady$()) {
    @if (collaboratorCardComponentService.cardFormGroup?.controls?.isOneDay?.value === false) {
      <div class="form-layout__two-columns">
        <dougs-input-datepicker
          [noMargin]="true"
          label="Du (inclus)"
          formControlName="fromDate"
        ></dougs-input-datepicker>
        <dougs-input-datepicker [noMargin]="true" label="Au (inclus)" formControlName="toDate"></dougs-input-datepicker>
      </div>
    } @else {
      <div class="form-layout__two-columns">
        <dougs-input-datepicker
          [noMargin]="true"
          label="Date d'application"
          formControlName="date"
        ></dougs-input-datepicker>
        <dougs-form-field [noMargin]="true">
          <label dougsFormFieldLabel>Durée totale (heures)</label>
          <input dougsFormFieldControl type="number" formControlName="totalHours" />
          @if (formService.isControlInvalid(collaboratorCardComponentService.cardFormGroup?.controls?.totalHours)) {
            <span dougsFormFieldError> Ce champ est requis </span>
          }
        </dougs-form-field>
      </div>
      <dougs-form-field [noMargin]="true">
        <label dougsFormFieldLabel>Nombre d’heures d’absence majorées</label>
        <input dougsFormFieldControl type="number" formControlName="extraHours" />
        @if (formService.isControlInvalid(collaboratorCardComponentService.cardFormGroup?.controls?.extraHours)) {
          <span dougsFormFieldError> Ce champ est requis </span>
        }
      </dougs-form-field>
      <dougs-form-field [noMargin]="true">
        <label dougsFormFieldLabel>Nombre d’heures d’absence libre1</label>
        <input dougsFormFieldControl type="number" formControlName="customHours" />
        @if (formService.isControlInvalid(collaboratorCardComponentService.cardFormGroup?.controls?.customHours)) {
          <span dougsFormFieldError> Ce champ est requis </span>
        }
      </dougs-form-field>
    }
  }
</div>
