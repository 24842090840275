import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Attachment } from '@dougs/core/files';
import { CheckboxComponent, FilePillComponent, TrackByPipe } from '@dougs/ds';
import { FileAttachmentSlot, Task, TaskTemplate } from '@dougs/task/dto';

@Component({
  selector: 'dougs-file-card-selection',
  templateUrl: './file-card-selection.component.html',
  styleUrls: ['./file-card-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileCardSelectionComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgFor, NgIf, NgClass, CheckboxComponent, FormsModule, FilePillComponent, TrackByPipe],
})
export class FileCardSelectionComponent {
  public state: { attachments: Attachment[]; slot: FileAttachmentSlot; selected: boolean }[] = [];
  private _taskTemplate!: TaskTemplate;
  private _task!: Task;

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  get taskTemplate(): TaskTemplate {
    return this._taskTemplate;
  }

  @Input()
  set taskTemplate(taskTemplate: TaskTemplate) {
    this._taskTemplate = taskTemplate;
    this.getState();
  }

  get task(): Task {
    return this._task;
  }

  @Input()
  set task(task: Task) {
    this._task = task;
    this.getState();
  }

  getState() {
    if (this.task && this.taskTemplate) {
      this.state = this.taskTemplate.fileAttachmentSlots
        .map((slot) => ({
          attachments: this.task.attachments.filter((attachment) => attachment.type === slot.type),
          slot,
          selected: false,
        }))
        .concat([
          {
            attachments: this.task.attachments.filter((a) => a.type === 'attachment'),
            slot: { label: 'Autre' } as FileAttachmentSlot,
            selected: false,
          },
        ]);

      const cniSubTask = this.task.tasks.find((t) => t.code.split('.')[1] === 'idCheck');
      if (cniSubTask) {
        const cniFileAttachmentSlots = cniSubTask.tasks.map((task, index) => ({
          attachments: task.attachments,
          slot: {
            label: task.title.replace('Vérifier / attacher la ', ''),
            type: `legal.idProof-${index + 1}`,
          } as FileAttachmentSlot,
          selected: false,
        }));

        this.state = [...this.state, ...cniFileAttachmentSlots];
      }
    }
  }

  isDisabled = false;
  onChange: (value: number[]) => void = () => [];
  onTouched: () => void = () => true;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: number[]): void {
    this.state = this.state.map((state) => {
      const hasIds = state.attachments.find((attachment) => value.includes(attachment.id as number));
      return { ...state, selected: !!hasIds };
    });
    this.cdr.markForCheck();
  }

  toggleSelect(
    $event: boolean,
    attachmentByTypeElement: { attachments: Attachment[]; slot: FileAttachmentSlot; selected: boolean },
  ) {
    if (this.isDisabled) {
      return;
    }
    this.state = this.state.map((value) => {
      if (value.slot.type === attachmentByTypeElement.slot.type) {
        return { ...value, selected: $event };
      }
      return value;
    });
    this.onChange(
      this.state
        .filter((value) => value.selected)
        .map((attachementsIds) => attachementsIds.attachments.map((value) => value.id as number))
        .flat(),
    );
    this.onTouched();
  }

  trackBySlot(index: number, item: { attachments: Attachment[]; slot: FileAttachmentSlot; selected: boolean }) {
    return item.slot;
  }
}
