import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { UserStateService } from '@dougs/user/shared';
import { PaymentStrongCustomerAuthenticationModalComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerPaymentStrongCustomerAuthService extends TaskActionService {
  protected _label = 'Afficher';
  protected _isAvailable = true;
  protected _tag = 'Banque';
  protected _tagColor: Pill = 'success';
  protected _tagIcon = 'fa-university';

  constructor(
    private readonly modalService: ModalService,
    private readonly userStateService: UserStateService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  shouldExecuteAutomatically(): boolean {
    return true;
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const succeeded: boolean | null | undefined = (
      await lastValueFrom(
        this.modalService.open<boolean>(PaymentStrongCustomerAuthenticationModalComponent, {
          disableClose: !this.userStateService.loggedInUser.isAccountantOrAdmin,
          disableCloseOnEscape: !this.userStateService.loggedInUser.isAccountantOrAdmin,
          data: {
            task: task,
          },
        }).afterClosed$,
      )
    ).data;

    if (succeeded) {
      await this.taskService.completeTask(task);
    }
  }
}
