<ng-container *ngIf="companyStateService.activeCompany$ | async as activeCompany">
  <div class="form-layout mt-16 mb-16">
    <dougs-divider></dougs-divider>
  </div>
  <div class="form-layout mb-16">
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Durée de période d'essai</label>
      <input
        id="partnershipAccountingTrialPeriodDays"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        [(ngModel)]="activeCompany.metadata.partnershipAccountingTrialPeriodDays"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="companyStateService.updateCompany(activeCompany)"
      />
    </dougs-form-field>
  </div>
  <div class="form-layout mb-16">
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Nombre de mois offerts</label>
      <input
        id="partnershipAccountingFreeMonths"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        [(ngModel)]="activeCompany.metadata.partnershipAccountingFreeMonths"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="companyStateService.updateCompany(activeCompany)"
      />
    </dougs-form-field>
  </div>
  <div class="form-layout mb-16">
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Nombre de mois payés auprès du partenaire</label>
      <input
        id="partnershipAlreadyPaidMonthCount"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        [(ngModel)]="activeCompany.metadata.partnershipAlreadyPaidMonthCount"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="companyStateService.updateCompany(activeCompany)"
      />
    </dougs-form-field>
  </div>
  <div class="form-layout mb-16">
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Activer le reliquat d'honoraire</label>
      <dougs-checkbox
        class="mt-4"
        appearance="toggle"
        [showYesNo]="true"
        [(ngModel)]="activeCompany.metadata.partnershipAccountingHasRemainder"
        (ngModelChange)="companyStateService.updateCompany(activeCompany)"
      ></dougs-checkbox>
    </dougs-form-field>
  </div>
  <div class="form-layout mb-16">
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Remise sur création d'entreprise</label>
      <input
        id="partnershipDiscountOnCompanyCreation"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        [(ngModel)]="activeCompany.metadata.partnershipDiscountOnCompanyCreation"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="companyStateService.updateCompany(activeCompany)"
      />
    </dougs-form-field>
  </div>
  <div class="form-layout mb-16">
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Moyen de paiement de l'abonnement</label>
      <input
        id="partnershipSubscriptionPaymentMethod"
        type="text"
        placeholder="card, partner"
        dougsFormFieldControl
        [(ngModel)]="activeCompany.metadata.partnershipSubscriptionPaymentMethod"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="companyStateService.updateCompany(activeCompany)"
      />
    </dougs-form-field>
  </div>
</ng-container>
