import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { CockpitAccountingStatsCollaborator } from '@dougs/task/dto';
import { CockpitAccountingStatsComponentService } from './cockpit-accounting-stats.component.service';

@Injectable()
export class CockpitLineComponentService {
  private readonly statSubject: ReplaySubject<CockpitAccountingStatsCollaborator> =
    new ReplaySubject<CockpitAccountingStatsCollaborator>(1);

  constructor(private readonly cockpitAccountingStatsComponentService: CockpitAccountingStatsComponentService) {}

  public totalPoints$: Observable<number> = combineLatest([
    this.statSubject.asObservable(),
    this.cockpitAccountingStatsComponentService.isCompletedStats$,
  ]).pipe(map(([stat, isCompletedStat]) => this.computeTotalPoints(stat, isCompletedStat)));

  public registerStat(stat: CockpitAccountingStatsCollaborator): void {
    this.statSubject.next(stat);
  }

  private computeTotalPoints(stat: CockpitAccountingStatsCollaborator | undefined, isCompleted: boolean): number {
    if (!stat) {
      return 0;
    }

    if (isCompleted) {
      return stat.activity.pointsCompletedThisWeek ?? 0;
    }

    return (stat.activity.pointsAssignedBeforeThisWeek ?? 0) + (stat.activity.pointsAssignedThisWeek ?? 0);
  }
}
